import { Autocomplete, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLocalCurrency,
  selectCurrencyList,
  selectVisualFilters,
} from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { useEffect, useState } from 'react';
import { getCurrencySchema } from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { CURRENCY_TYPES } from 'modules/dashboard/constants';

/**
 * Navigation bar currency dropdown common component that uses
 * across modules for display currency dropdown and select currency
 */
const NavbarCurrencyDropdown = () => {
  const dispatch = useDispatch();

  const localCurrency = useSelector(selectLocalCurrency);
  const currencyList = useSelector(selectCurrencyList);
  const visualFilters = useSelector(selectVisualFilters);
  //
  const [isCurrencyDropdownDisabled, setIsCurrencyDropdownDisabled] = useState(false);
  // set currency filter based on selected currency value in dropdown
  useEffect(() => {
    dispatch(
      dashboardActions.setCurrencyFilter(
        getCurrencySchema(
          visualFilters,
          localCurrency !== CURRENCY_TYPES.USD ? CURRENCY_TYPES.RC : CURRENCY_TYPES.USD
        )
      )
    );
  }, [localCurrency]);
  // disable currency dropdown when only USD is there
  useEffect(() => {
    if (currencyList?.length === 1) {
      setIsCurrencyDropdownDisabled(true);
    } else {
      setIsCurrencyDropdownDisabled(false);
    }
  }, [currencyList]);
    // set USD when change hotel when local currency other than USD selected and selected hotel does not have selected local currency
    useEffect(() => {
      if(currencyList?.every(curr => curr.id !== localCurrency)){
        dispatch(dashboardActions.setLocalCurrency(CURRENCY_TYPES.USD));
      }
    }, [currencyList]);
  //
  return (
    <Grid container flexDirection="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
      <Autocomplete
        options={currencyList ?? []}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
        filterSelectedOptions
        disableClearable
        value={{
          id: localCurrency,
          label: localCurrency,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            size="small"
            sx={{
              '.MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'gray',
                color: 'gray',
              },
            }}
          />
        )}
        onChange={(event, value) => dispatch(dashboardActions.setLocalCurrency(value.id))}
        fullWidth
        sx={{
          minWidth: 100,
          '& .MuiOutlinedInput-root': {
            color: '#e5e5e5',
          },
          '& .MuiSvgIcon-root': {
            color: '#e5e5e5',
            width: '2rem',
            height: '2rem',
            top: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiAutocomplete-input': {
            fontSize: { md: 13, lg: 15 },
            fontWeight: 'bold',
          },
        }}
        disabled={isCurrencyDropdownDisabled}
      />
    </Grid>
  );
};
//
export default NavbarCurrencyDropdown;
