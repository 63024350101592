import * as yup from 'yup';

/**
 * Sanitizes input to prevent XSS and SQL Injection attacks.
 *
 * @param {string} input - The input string to sanitize.
 * @throws {Error} Throws an error if a potential XSS attack is detected.
 * @throws {Error} Throws an error if a potential SQL Injection attack is detected.
 * @returns {string} The sanitized input if no threats are detected.
 */
export const sanitizeAndPreventSQLInjection = (input) => {
  if (input === undefined || input === null) {
    return '';
  }
  //
  const sanitizedInput = input.trim();

  // XSS attack detection pattern
  const xssPattern =
    /(<script\b[^>]*>(.*?)<\/script>)|(<.*?\b(?:javascript|onload|onerror|onmouseover|onfocus|onclick)\b.*?>)/gi;

  // `(<script\b[^>]*>(.*?)<\/script>)` detects any HTML <script> tags, including those with attributes.
  // `(<.*?\b(?:javascript|onload|onerror|onmouseover|onfocus|onclick)\b.*?>)` looks for HTML tags containing common event handler attributes
  // (e.g., `onload`, `onerror`, `onmouseover`) or inline JavaScript (e.g., `javascript:`).
  // This pattern is used to prevent typical cross-site scripting (XSS) attacks.

  if (xssPattern.test(sanitizedInput)) {
    throw new Error('Potential XSS attack detected.');
  }
  //
  const injectionPattern =
    /\b(?:SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|CREATE|EXEC|UNION|--|;|\/\*|\*\/|')\b/gi;

  // This pattern matches common SQL keywords used in SQL injection attacks
  // It also checks for SQL comment indicators `--` and `/* ... */` as well as semicolons `;`, which are used to terminate SQL statements.
  // The pattern looks for these words or symbols in word boundaries (`\b`) to ensure that it doesn't match parts of legitimate words.

  if (injectionPattern.test(sanitizedInput)) {
    throw new Error('Potential SQL Injection detected.');
  }
  //
  return sanitizedInput;
};

/**
 * Custom validation function to sanitize and prevent SQL injection
 * @param {string} value - The input value being validated
 * @param {string} fieldName - The name of the field being validated
 */
export const sanitizeValidation = (value, fieldName) => {
  try {
    sanitizeAndPreventSQLInjection(value);
    return true;
  } catch (error) {
    return new yup.ValidationError(
      `Input contains disallowed content: '${value}'`,
      null,
      fieldName
    );
  }
};
