export const paceSegmentDailyPreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(15).fill(12)],
    end: 'U',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceSegmentCumulativeLagAlignedPreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(18).fill(12)],
    end: 'T',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceSegmentCumulativePreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(18).fill(12)],
    end: 'T',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceSegmentLagAlinedPreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(15).fill(12)],
    end: 'P',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
