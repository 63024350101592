/* eslint-disable radix */
/* eslint-disable no-console */
import { faroSendActiveUserSession, faroSendActiveChunks } from './faro';

const chunckInMinutes = 1;
const chunkDuration = chunckInMinutes * 60 * 1000;

// introduced a new parameter to introduce new logic depending whether the
// calculation is on signout or triggered priodically
export default function calculateActiveUserTime(type = 'signout') {
  // this function will called either on the signout or may be called by a set interval
  // callcualte the active of the user by dividing activetime (the number of active chunks * number of minutes)
  // by the loggedInFor (currenttime when the calculation triggered * the logged in time of the user)
  const currentTime = Date.now();
  const loggedInTime = parseInt(localStorage.getItem('logInTime'));
  const counter = parseInt(localStorage.getItem('activeChunkCount'));
  const activeTime = counter * (chunkDuration / (60 * 1000));
  const loggedInFor = (currentTime - loggedInTime) / (60 * 1000);
  const activeness = activeTime / loggedInFor;

  // check the type if on logout remove the state in localStorage
  if (type === 'signout') {
    // simply remove the item when the user is signout
    localStorage.removeItem('isWindowUpdated');
    localStorage.removeItem('activeChunkCount');
    localStorage.removeItem('logInTime');
  } else {
    // reset values in the localstorage
    localStorage.setItem('currentWindowUpper', currentTime + chunkDuration);
    localStorage.setItem('currentWindowLower', currentTime);
    localStorage.setItem('isWindowUpdated', false);
    localStorage.setItem('activeChunkCount', 0);

    // reset the login time as the calculation is restarted
    localStorage.setItem('logInTime', Date.now());
  }

  // use faro to send a signal to grafana agent
  faroSendActiveUserSession(activeness, activeTime, loggedInFor, new Date(loggedInTime).toJSON());
}

export function recordUserLogTime() {
  localStorage.setItem('logInTime', Date.now());
}

export function updateUserActiveness() {
  // this is method will called everytime a filter is logged
  // and update the chunk details in the localstorage and send signals
  // to grafana agent using faro to visualize active chunks
  const currentTime = Date.now();
  const loggedInTime = parseInt(localStorage.getItem('logInTime'));
  let counter = parseInt(localStorage.getItem('activeChunkCount'));
  let currentWindowUpper = parseInt(localStorage.getItem('currentWindowUpper'));
  let currentWindowLower = parseInt(localStorage.getItem('currentWindowLower'));
  let isWindowUpdated = localStorage.getItem('isWindowUpdated');

  // check if current is set
  if (Number.isNaN(counter)) counter = 0;
  // set the current window if null
  if (Number.isNaN(currentWindowUpper)) currentWindowUpper = loggedInTime + chunkDuration;
  if (Number.isNaN(currentWindowLower)) currentWindowLower = loggedInTime;
  if (isWindowUpdated === null) isWindowUpdated = false;

  if (currentTime > currentWindowUpper) {
    // check if the currentTime is passed the currentWindowUpper
    // then adjust the window
    currentWindowLower = currentTime;
    currentWindowUpper = currentTime + chunkDuration;
    isWindowUpdated = false;
  }

  // increment the count only if the current chunk is not marked active
  // if not active, mark it active and send a signal to faro and increment the number the no. of chunk
  if (!isWindowUpdated) {
    counter += 1;
    faroSendActiveChunks(currentWindowLower, chunkDuration);
    isWindowUpdated = true;
  }

  // persists the values
  localStorage.setItem('currentWindowUpper', currentWindowUpper);
  localStorage.setItem('currentWindowLower', currentWindowLower);
  localStorage.setItem('isWindowUpdated', isWindowUpdated);
  localStorage.setItem('activeChunkCount', counter);
}

// append this code here to prevent the undefined function call error
// this creates a new worker and may create multiple workers on development server when uses HMR
if (window.Window) {
  // creates a new web worker
  const worker = new Worker('user-active-time-worker.js');
  worker.postMessage('created');

  // listen to web worker
  worker.onmessage = (e) => {
    if (e.data === 'calculate') {
      console.log('Calculate user activeness');
      // calculate the activeness
      calculateActiveUserTime('periodic');
    }
  };
}
