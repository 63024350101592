import { useDispatch, useSelector } from 'react-redux';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import { Typography } from '@mui/material';
import { selectForecastDisabledText, selectsHotelForecast } from '../../../../../selectors';
/**
 * Lag aligned window component common for the dashboard component
 * @param {Boolean} paceLagAligned - State of Lag Aligned toggle
 * @param {*} paceCumulative - State of Cumulative toggle
 * @returns
 */
const LagAlignedWindow = ({
  paceLagAligned,
  paceCumulative,
  changePaceLagAligned,
  changePaceCumulative,
  isPaceForecast,
  IsForecastDisabled,
  setIsPaceForecast,
}) => {
  const dispatch = useDispatch();
  const isHotelForecast = useSelector(selectsHotelForecast);
  const ForecastDisabledText = useSelector(selectForecastDisabledText);
  //
  return (
    <CustomStack>
      <CustomToggleSwitch
        label="Cumulative View"
        title="Change ‘Total Occupied Rooms’ and ‘Total Revenue’ graphs to display the cumulated booking pace for each statistic."
        onChange={(event) => dispatch(changePaceCumulative(event.target.checked))}
        checked={paceCumulative ?? false}
      />
      <CustomToggleSwitch
        id="auto-toggle-lag-aligned"
        label="Lag Aligned"
        title="Display the pace-time and pace-segment statistics considering lead time to stay date as opposed to the absolute booking date"
        onChange={(event) => dispatch(changePaceLagAligned(event.target.checked))}
        checked={paceLagAligned ?? false}
      />
      <CustomToggleSwitch
        label="Forecasts"
        title="Enable forecasts on the ‘Total Revenue’ and ‘Total Occupied Rooms’ graphs"
        onChange={(event) => dispatch(setIsPaceForecast(event.target.checked))}
        checked={isPaceForecast ?? false}
        disabled={IsForecastDisabled || !isHotelForecast}
      />
      {!isHotelForecast && (
        <Typography sx={{ fontSize: 11.5, color: 'gray' }}>{ForecastDisabledText}</Typography>
      )}
    </CustomStack>
  );
};
//
export default LagAlignedWindow;
