import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTrendTimeFilterList,
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendTimeVisuals,
  selectWeeklyTrendTimeVisuals,
  selectRevenueIsDisplayTT,
  selectOccupiedRoomsIsDisplayTT,
  selectAvgBookingWindowIsDisplayTT,
  selectAvgLosIsDisplayTT,
  selectCxlIsDisplayTT,
  selectAdrIsDisplayTT,
  selectTrendTimeCumulativeVisuals,
  selectIsPercentageChange,
  selectTrendTimePercentageVisuals,
  selectTrendHotelNormalizedView,
  selectIsMovingAverage,
  selectMovingAverageVisualTT,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { findVisual } from 'modules/dashboard/components/tab-container/trend-tabs/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { isEmpty } from '../../../../../../../../common/helpers/object';
/**
 * Trend Time report with filters
 * @param {Function} setIsTimeLoaded - set whether trend time visuals are loaded
 * @returns
 */
const TrendTimeGraphicalWidget = ({ setIsTimeLoaded }) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendTimeFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeVisuals);
  const weeklyVisuals = useSelector(selectWeeklyTrendTimeVisuals);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTT);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayTT);
  const adrIsDisplay = useSelector(selectAdrIsDisplayTT);
  const avgBookingWindowIsDisplay = useSelector(selectAvgBookingWindowIsDisplayTT);
  const avgLosIsDisplay = useSelector(selectAvgLosIsDisplayTT);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayTT);
  const cumulativeVisuals = useSelector(selectTrendTimeCumulativeVisuals);
  const percentageVisuals = useSelector(selectTrendTimePercentageVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const movingAverageVisuals = useSelector(selectMovingAverageVisualTT);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  //
  const [isInitialTotalRevenueLoaded, setIsInitialTotalRevenueLoaded] = useState(false);
  const [isInitialTotalOccupiedRoomsLoaded, setIsInitialTotalOccupiedRoomsLoaded] = useState(false);
  const [isInitialAdrLoaded, setIsInitialAdrLoaded] = useState(false);
  const [isInitialAvgBookingWindowLoaded, setIsInitialAvgBookingWindowLoaded] = useState(false);
  const [isInitialCancellationsLoaded, setIsInitialCancellationsLoaded] = useState(false);
  const [isInitialAvgLoSLoaded, setIsInitialAvgLoSLoaded] = useState(false);
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = findVisual(cumulativeVisuals, index, weeklyViewToggle, [
        REPORT_TYPE.WEEKLY_TREND_TIME,
        REPORT_TYPE.TREND_TIME,
      ]);
    } else if (weeklyViewToggle) {
      found = findVisual(weeklyVisuals, index, true, [REPORT_TYPE.TREND_TIME_CUMULATIVE]);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = findVisual(percentageVisuals, index, true, [REPORT_TYPE.TREND_TIME_PERCENTAGE], true);
    } else if (isMovingAverage) {
      found = findVisual(movingAverageVisuals, index, true, [
        REPORT_TYPE.TREND_TIME_CUMULATIVE,
        REPORT_TYPE.TREND_TIME_MOVING_AVERAGE,
      ]);
    } else {
      found = findVisual(visuals, index, true, [
        REPORT_TYPE.TREND_TIME_CUMULATIVE,
        REPORT_TYPE.TREND_TIME,
      ]);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          isPercentage={
            isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle
          }
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  useEffect(() => {
    // Calculate S based on the given conditions

    const pair1 = revenueIsDisplay ? revenueIsDisplay && isInitialTotalRevenueLoaded : true;
    const pair2 = occupiedRoomsIsDisplay
      ? occupiedRoomsIsDisplay && isInitialTotalOccupiedRoomsLoaded
      : true;
    const pair3 = adrIsDisplay ? adrIsDisplay && isInitialAdrLoaded : true;
    const pair4 = avgBookingWindowIsDisplay
      ? avgBookingWindowIsDisplay && isInitialAvgBookingWindowLoaded
      : true;
    const pair5 = avgLosIsDisplay ? avgLosIsDisplay && isInitialAvgLoSLoaded : true;
    const pair6 = cxlIsDisplay ? cxlIsDisplay && isInitialCancellationsLoaded : true;
    if (
      (revenueIsDisplay &&
      isInitialTotalRevenueLoaded) &&
      (occupiedRoomsIsDisplay &&
      isInitialTotalOccupiedRoomsLoaded) &&
      (adrIsDisplay &&
      isInitialAdrLoaded) &&
      (avgBookingWindowIsDisplay &&
      isInitialAvgBookingWindowLoaded) &&
      (avgLosIsDisplay &&
      isInitialAvgLoSLoaded) &&
      (cxlIsDisplay &&
      isInitialCancellationsLoaded)
    ) {
      setIsTimeLoaded(true);
    } else {
      setIsTimeLoaded(pair1 || pair2 || pair3 || pair4 || pair5 || pair6);
    }
  }, [
    revenueIsDisplay,
    isInitialTotalRevenueLoaded,
    occupiedRoomsIsDisplay,
    isInitialTotalOccupiedRoomsLoaded,
    adrIsDisplay,
    isInitialAdrLoaded,
    avgBookingWindowIsDisplay,
    isInitialAvgBookingWindowLoaded,
    avgLosIsDisplay,
    isInitialAvgLoSLoaded,
    cxlIsDisplay,
    isInitialCancellationsLoaded,
  ]);
  //
  return (
    !isEmpty(filterList) && (
      <>
        <VisualGrid
          isDisplay={revenueIsDisplay}
          visualName={getVisualName(0)}
          visual={getWidget(0, styles.paceVisual, () => {}, setIsInitialTotalRevenueLoaded)}
          onClick={() => {
            dispatch(trendActions.setRevenueIsDisplayTT(!revenueIsDisplay));
          }}
        />
        <VisualGrid
          isDisplay={occupiedRoomsIsDisplay}
          visualName={getVisualName(1)}
          visual={getWidget(1, styles.paceVisual, () => {}, setIsInitialTotalOccupiedRoomsLoaded)}
          onClick={() => {
            dispatch(trendActions.setOccupiedRoomIsDisplayTT(!occupiedRoomsIsDisplay));
          }}
        />
        <VisualGrid
          isDisplay={adrIsDisplay}
          visualName={getVisualName(2)}
          visual={getWidget(2, styles.paceVisual, () => {}, setIsInitialAdrLoaded)}
          onClick={() => {
            dispatch(trendActions.setAdrIsDisplayTT(!adrIsDisplay));
          }}
        />
        {getWidget(3, styles.paceVisual, () => {}, setIsInitialAvgLoSLoaded) && (
          <VisualGrid
            isDisplay={avgLosIsDisplay}
            visualName={getVisualName(3)}
            visual={getWidget(3, styles.paceVisual, () => {}, setIsInitialAvgLoSLoaded)}
            onClick={() => {
              dispatch(trendActions.setAvgLosIsDisplayTT(!avgLosIsDisplay));
            }}
          />
        )}
        {getWidget(4, styles.paceVisual, () => {}, setIsInitialAvgBookingWindowLoaded) && (
          <VisualGrid
            isDisplay={avgBookingWindowIsDisplay}
            visualName={getVisualName(4)}
            visual={getWidget(4, styles.paceVisual, () => {}, setIsInitialAvgBookingWindowLoaded)}
            onClick={() => {
              dispatch(trendActions.setAvgBookingWindowIsDisplayTT(!avgBookingWindowIsDisplay));
            }}
          />
        )}
        {getWidget(5, styles.paceVisual, () => {}, setIsInitialCancellationsLoaded) && (
          <VisualGrid
            isDisplay={cxlIsDisplay}
            visualName={getVisualName(5)}
            visual={getWidget(5, styles.paceVisual, () => {}, setIsInitialCancellationsLoaded)}
            onClick={() => {
              dispatch(trendActions.setCxlIsDisplayTT(!cxlIsDisplay));
            }}
          />
        )}
      </>
    )
  );
};
//
export default TrendTimeGraphicalWidget;
