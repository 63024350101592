/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
/**
 * Define the the sign in feature initial state
 */
export const initialState = {
  loading: false,
  errorCode: '',
  error: '',
};
/**
 * Here mentioned all the sign in feature related actions
 */
export const signInSlice = createSlice({
  name: SLICE.SIGN_IN,
  initialState,
  reducers: {
    signIn(state) {
      state.loading = true;
    },
    signInSucceeded(state, action) {
      return {
        ...state,
        loading: false,
        errorCode: action?.payload?.errorCode,
      };
    },
    signInFailed(state, action) {
      state.loading = false;
      state.error = action?.payload?.message;
    },
  },
});
//
export const { actions: signInActions } = signInSlice;
