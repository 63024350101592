import { UiController, events } from 'modules/common/ui-controller';
import { getHotelValuesFromList } from 'modules/common/utils/array';
import { useEffect, useState } from 'react';
import { dashboardActions } from 'modules/dashboard/slice';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { paceActions } from '../slice';
/**
 * Custom hook to execute pace hotel widget filter command flow in pace tab
 * @param {Boolean} isLoaded
 * @param {Array} hotelList
 * @param {Array} hotelGroupList
 */
const usePaceHotelCommandFlow = (isLoaded, hotelList, hotelGroupList) => {
  const dispatch = useDispatch();
  //
  const urlOrder = useSelector(selectUrlOrder);
  //
  const [compareHotelsValue, setCompareHotelsValue] = useState(null);
  const [hotelGroupCommand, setHotelGroupCommand] = useState(null);
  const [normalizeCommandValue, setNormalizedCommandValue] = useState(null);
  // Triggers when compareHotelsValue, hotelList and isLoaded value change
  useEffect(() => {
    if (compareHotelsValue !== null && hotelList?.length > 0 && isLoaded && urlOrder === 6) {
      const hotelsSelected = getHotelValuesFromList(compareHotelsValue, hotelList);
      if (hotelsSelected?.length > 0) {
        dispatch(paceActions.setEnableSecondaryHotelGroups(false));
        dispatch(paceActions.setHotelFocusOn(hotelsSelected[0]));
      }
      setCompareHotelsValue(null);
      dispatch(dashboardActions.setUrlOrder(7));
    }
  }, [compareHotelsValue, hotelList?.length, isLoaded, urlOrder]);
  // Triggers when hotelGroupCommand, hotelGroupList and isLoaded value change
  useEffect(() => {
    if (hotelGroupCommand !== null && hotelGroupList?.length > 0 && isLoaded && urlOrder === 6) {
      const hotelGroupsSelected = getHotelValuesFromList(hotelGroupCommand, hotelGroupList);
      if (hotelGroupsSelected?.length > 0) {
        dispatch(paceActions.setEnableSecondaryHotelGroups(true));
        dispatch(paceActions.setHotelFocusOn(hotelGroupsSelected[0]));
      }
      setHotelGroupCommand(null);
      dispatch(dashboardActions.setUrlOrder(7));
    }
  }, [hotelGroupCommand, hotelGroupList?.length, isLoaded, urlOrder]);
  // Triggers when normalizeDisabled, normalizeCommandValue, isLoaded values change
  useEffect(() => {
    if (normalizeCommandValue !== null && isLoaded && urlOrder === 7) {
      // execute dispatch function to set normalized view with command value
      dispatch(paceActions.setNormalizedView(normalizeCommandValue === 1));
      setNormalizedCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(8));
    }
  }, [normalizeCommandValue, isLoaded, urlOrder]);
  // Listener function to set compare hotels value
  const setCompareHotelsValueHandler = (value) => {
    setCompareHotelsValue(value);
  };
  // Listener function to set  trend hotel command value
  const setTrendHotelGroupsHandler = (data) => {
    setHotelGroupCommand(data);
  };
  // Listener function to set  normalized view command value
  const setNormalizedViewValue = (data) => {
    setNormalizedCommandValue(data);
  };
  // Add event listener function to set compare hotels command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_COMPARE_HOTELS, setCompareHotelsValueHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_COMPARE_HOTELS, setCompareHotelsValueHandler);
    };
  }, []);
  // Add event listener function to set hotel groups command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_HOTEL_GROUP, setTrendHotelGroupsHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_HOTEL_GROUP, setTrendHotelGroupsHandler);
    };
  }, []);
  // Add event listener function to set normalize view command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_NORMALIZED_VIEW, setNormalizedViewValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_NORMALIZED_VIEW, setNormalizedViewValue);
    };
  }, []);
};

export default usePaceHotelCommandFlow;
