/* eslint-disable import/prefer-default-export */
/**
 * Check object is empty or not
 * @param {*} obj - object element that needs to check whether empty or not
 * @returns
 */
export const isEmpty = (obj) => {
  if (obj) return Object.keys(obj).length === 0;
  return true;
};
