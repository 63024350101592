import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Portal,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TAB_VALUE } from 'modules/dashboard/constants';
import { dashboardActions } from 'modules/dashboard/slice';
import { commands as COMMANDS, consoleCommands } from 'modules/common/ui-controller';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectTabValue } from 'modules/dashboard/selectors';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { notificationActions } from 'modules/common/notifications/slice';
import saveUrlFilterValidation from 'modules/dashboard/components/tab-container/share/components/save-url-fliter/validation';
import {
  Alert,
  TextField,
} from 'modules/dashboard/components/tab-container/share/components/save-url-fliter/style';
import {
  IMPLIED_RANGES,
  dateRangeOptions,
} from 'modules/dashboard/components/tab-container/share/components/save-url-fliter/constants';
import { Share } from 'react-feather';
import { ENVIRONMENT } from 'config';
import { useLocation } from 'react-router-dom';
/**
 * Modal component to save command filter url
 */
const SaveUrlFilterDialog = ({ open, onClose, url }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const tabValue = useSelector(selectTabValue);
  //
  const [stayPreset, setStayPreset] = useState(dateRangeOptions.ABSOLUTE);
  const [bookingPreset, setBookingPreset] = useState(dateRangeOptions.ABSOLUTE);
  const [stayPresetValue, setStayPresetValue] = useState('');
  const [bookingPresetValue, setBookingPresetValue] = useState('');
  const [commandUrl, setCommandUrl] = useState('');
  const [copyValue, setValue] = useState('');
  const [copied, setCopied] = useState(false);
  //
  useEffect(() => {
    if (open) {
      setCommandUrl(url);
    }
  }, [open]);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
    setStayPreset(dateRangeOptions.ABSOLUTE);
    setBookingPreset(dateRangeOptions.ABSOLUTE);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      modalAction(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (stayPresetValue || bookingPresetValue) {
      let newCommandUrl = url;
      let commandSet = url.split('&');
      const stayDateCommand = commandSet.find((com) => com.includes(consoleCommands.STAY_DATE));
      const bookingDateCommand = commandSet.find((com) =>
        com.includes(consoleCommands.BOOKING_DATE)
      );
      //
      if (stayPresetValue && stayPreset === dateRangeOptions.RELATIVE) {
        if (stayDateCommand) {
          commandSet = commandSet.filter((com) => !com.includes(consoleCommands.STAY_DATE));
          const newStyCommand = stayDateCommand.replace(
            stayDateCommand.substring(stayDateCommand.lastIndexOf('=') + 1),
            stayPresetValue
          );
          commandSet = [newStyCommand, ...commandSet];
        }
      } else {
        commandSet = [stayDateCommand, ...commandSet];
      }
      //
      if (bookingPresetValue && bookingPreset === dateRangeOptions.RELATIVE) {
        if (bookingDateCommand) {
          commandSet = commandSet.filter((com) => !com.includes(consoleCommands.BOOKING_DATE));
          const newBokCommand = bookingDateCommand.replace(
            bookingDateCommand.substring(bookingDateCommand.lastIndexOf('=') + 1),
            bookingPresetValue
          );
          commandSet = [newBokCommand, ...commandSet];
        }
      } else {
        commandSet = [bookingDateCommand, ...commandSet];
      }
      //
      newCommandUrl = commandSet.join('&');
      setCommandUrl(newCommandUrl);
    }
  }, [stayPresetValue, bookingPresetValue, stayPreset, bookingPreset]);
  //
  const onSubmitAddMinorEvent = async (values) => {
    const valueObj = {
      userId: currentUser?.id,
      organizationId,
      commandFilterQuery: [
        {
          filterName: values?.filterName?.trim(),
          query: commandUrl.replace(/\s+/g, ''),
        },
      ],
    };
    dispatch(dashboardActions.saveCommandUrlFilter(valueObj));
  };
  //
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);
  //
  const handleClick = () => {
    setCopied(true);
  };
  //
  useEffect(() => {
    // Encrypt url to be shared
    setValue(
      `${ENVIRONMENT.APP_URL}${location?.pathname}?${
        ENVIRONMENT.ENCRYPTION === '1' ? btoa(commandUrl) : commandUrl
      }`
    );
  }, [commandUrl]);
  //
  const initialValues = {
    filterName: '',
    stayPreset: '',
    bookingPreset: COMMANDS.OTB,
  };
  //
  return (
    <Dialog
      open={open}
      onClose={() => modalAction(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Save Filter</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={saveUrlFilterValidation}
          onSubmit={onSubmitAddMinorEvent}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            dirty,
            handleReset,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="filterName"
                label="Filter name"
                value={values.filterName}
                error={Boolean(touched.filterName && errors.filterName)}
                helperText={touched.filterName && errors.filterName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                size="small"
              />
              <Grid container flexDirection="row" my={2}>
                <Grid item md={6}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Stay Date Selection
                    </FormLabel>
                    <RadioGroup
                      row
                      name="row-radio-buttons-group1"
                      value={stayPreset}
                      onChange={(event) => {
                        setStayPreset(event.target.value);
                        setFieldValue('stayPreset', '');
                      }}
                    >
                      <FormControlLabel
                        value={dateRangeOptions.ABSOLUTE}
                        control={<Radio />}
                        label="Absolute"
                      />
                      <FormControlLabel
                        value={dateRangeOptions.RELATIVE}
                        control={<Radio />}
                        label="Relative"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={stayPreset === dateRangeOptions.ABSOLUTE}
                    select
                    fullWidth
                    name="stayPreset"
                    label="Pre-set Options"
                    variant="filled"
                    value={values.stayPreset}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('stayPreset', event.target.value);
                      setStayPresetValue(event.target.value);
                    }}
                    defaultValue=""
                  >
                    {IMPLIED_RANGES.filter((range) => range.value !== COMMANDS.OTB).map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container flexDirection="row" my={2}>
                <Grid item md={6}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Booking Window Selection
                    </FormLabel>
                    <RadioGroup
                      row
                      name="row-radio-buttons-group2"
                      value={bookingPreset}
                      onChange={(event) => {
                        setBookingPreset(event.target.value);
                        setFieldValue('bookingPreset', COMMANDS.OTB);
                        setBookingPresetValue(COMMANDS.OTB);
                      }}
                    >
                      <FormControlLabel
                        value={dateRangeOptions.ABSOLUTE}
                        control={<Radio />}
                        label="Absolute"
                        disabled={
                          tabValue === TAB_VALUE.PACE_WIDGET || tabValue === TAB_VALUE.PACE_3D
                        }
                      />
                      <FormControlLabel
                        value={dateRangeOptions.RELATIVE}
                        control={<Radio />}
                        label="Relative"
                        disabled={
                          tabValue === TAB_VALUE.PACE_WIDGET || tabValue === TAB_VALUE.PACE_3D
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={bookingPreset === dateRangeOptions.ABSOLUTE}
                    select
                    fullWidth
                    label="Pre-set Options"
                    variant="filled"
                    name="bookingPreset"
                    value={values.bookingPreset}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('bookingPreset', event.target.value);
                      setBookingPresetValue(event.target.value);
                    }}
                    defaultValue=""
                  >
                    {IMPLIED_RANGES.filter(
                      (range) =>
                        range.value !== COMMANDS.TODAY &&
                        range.value !== COMMANDS.FORWARD_7_DAYS &&
                        range.value !== COMMANDS.THIS_WEEK &&
                        range.value !== COMMANDS.THIS_MONTH
                    ).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container flexDirection="row" justifyContent="space-between">
                <Grid container item xs={11}>
                  <Tooltip title={commandUrl}>
                    <TextField
                      label="URL"
                      value={commandUrl}
                      fullWidth
                      disabled
                      my={2}
                      size="small"
                    />
                  </Tooltip>
                </Grid>
                <Grid container item xs={1} justifyContent="flex-end">
                  <Portal>
                    <Snackbar
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      open={copied}
                      onClose={() => {
                        setCopied(false);
                      }}
                    >
                      <Alert severity="success">Copy to clipboard!</Alert>
                    </Snackbar>
                  </Portal>
                  <CopyToClipboard onCopy={onCopy} text={copyValue}>
                    <IconButton onClick={handleClick}>
                      <Tooltip title="Share View">
                        <Share size={16} />
                      </Tooltip>
                    </IconButton>
                  </CopyToClipboard>
                </Grid>
              </Grid>

              <DialogActions
                sx={{
                  justifyContent: 'right',
                  px: 0,
                }}
              >
                <Button
                  sx={{ px: 10 }}
                  onClick={() => {
                    if (dirty) {
                      handleReset();
                    }
                    setStayPresetValue('');
                    setBookingPresetValue('');
                    modalAction(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  sx={{ px: 10 }}
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default SaveUrlFilterDialog;
