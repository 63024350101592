/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';

export const initialState = {
  loading: false,
  emailId: '',
  userInvite: '',
  token: '',
};
/**
 * Here mentioned all the verify call back feature related actions
 */
export const verifyCallbackSlice = createSlice({
  name: SLICE.VERIFY_CALLBACK,
  initialState,
  reducers: {
    verifyToken(state) {
      state.loading = true;
    },
    verifyTokenSucceeded(state, action) {
      state.loading = false;
      state.userInvite = action?.payload?.userInvite;
      state.token = action?.payload?.token;
    },
    verifyTokenFailed(state) {
      state.loading = false;
    },
    setEmailId(state, action) {
      state.emailId = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: verifyCallbackActions } = verifyCallbackSlice;
