import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import {
  selectAdrIsDisplayPH,
  selectCxlCountIsDisplay,
  selectCxlIsDisplayPH,
  selectOccupiedRoomsIsDisplayPH,
  selectPaceCancellationFilterList,
  selectPaceCumulativeView,
  selectPaceHotelAbsoluteCumulativeVisuals,
  selectPaceHotelLagAlignedCumulativeVisuals,
  selectPaceHotelLagAlignedVisuals,
  selectPaceHotelVisuals,
  selectPaceLagAligned,
  selectPaceWidgetHotelFilterList,
  selectRevenueIsDisplayPH,
} from '../../../../selectors';
import { paceActions } from '../../../../slice';
import { isEmpty } from '../../../../../../../../common/helpers/object';

/**
 * Pace hotel widget report with out filters
 * @param {Boolean} setIsSegmentLoaded - Visual loading state
 * @returns
 */
const PaceHotelGraphicalWidget = ({ setIsHotelLoaded }) => {
  const dispatch = useDispatch();
  const tokenDetails = useSelector(selectEmbedToken);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const paceHotelVisualFilterList = useSelector(selectPaceWidgetHotelFilterList);
  const paceLagAligned = useSelector(selectPaceLagAligned);
  const paceCumulative = useSelector(selectPaceCumulativeView);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayPH);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayPH);
  const adrIsDisplay = useSelector(selectAdrIsDisplayPH);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayPH);
  const cxlCountIsDisplay = useSelector(selectCxlCountIsDisplay);
  const cancellationFilterList = useSelector(selectPaceCancellationFilterList);
  //
  const paceHotelVisuals = useSelector(selectPaceHotelVisuals);
  const paceHotelLagAlignedVisuals = useSelector(selectPaceHotelLagAlignedVisuals);
  const paceHotelAbsoluteCumulativeVisuals = useSelector(selectPaceHotelAbsoluteCumulativeVisuals);
  const paceHotelLagAlignedCumulativeVisuals = useSelector(
    selectPaceHotelLagAlignedCumulativeVisuals
  );
  //
  const [isPace1VisualsLoaded, setIsPace1VisualLoaded] = useState(false);
  const [isPace2VisualsLoaded, setIsPace2VisualLoaded] = useState(false);
  const [isPace3VisualsLoaded, setIsPace3VisualLoaded] = useState(false);
  const [isPace4VisualsLoaded, setIsPace4VisualLoaded] = useState(false);
  const [isPace5VisualsLoaded, setIsPace5VisualLoaded] = useState(false);
  //
  useEffect(() => {
    // Calculate S based on the given conditions
    const pair1 = revenueIsDisplay ? revenueIsDisplay && isPace1VisualsLoaded : true;
    const pair2 = occupiedRoomsIsDisplay ? occupiedRoomsIsDisplay && isPace2VisualsLoaded : true;
    const pair3 = adrIsDisplay ? adrIsDisplay && isPace3VisualsLoaded : true;
    const pair4 = cxlIsDisplay ? cxlIsDisplay && isPace4VisualsLoaded : true;
    const pair5 = cxlCountIsDisplay ? cxlCountIsDisplay && isPace5VisualsLoaded : true;

    if (
      revenueIsDisplay &&
      isPace1VisualsLoaded &&
      occupiedRoomsIsDisplay &&
      isPace2VisualsLoaded &&
      adrIsDisplay &&
      isPace3VisualsLoaded &&
      cxlIsDisplay &&
      isPace4VisualsLoaded &&
      cxlCountIsDisplay &&
      isPace5VisualsLoaded
    ) {
      setIsHotelLoaded(true);
    } else {
      setIsHotelLoaded(pair1 || pair2 || pair3 || pair4 || pair5);
    }
  }, [
    revenueIsDisplay,
    isPace1VisualsLoaded,
    occupiedRoomsIsDisplay,
    isPace2VisualsLoaded,
    adrIsDisplay,
    isPace3VisualsLoaded,
    cxlIsDisplay,
    isPace4VisualsLoaded,
    cxlCountIsDisplay,
    isPace5VisualsLoaded,
  ]);
  //
  const getVisual = (index) => {
    let found;
    if (paceCumulative && !paceLagAligned) {
      found = paceHotelAbsoluteCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceCumulative && paceLagAligned) {
      found = paceHotelLagAlignedCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && !paceCumulative) {
      found = paceHotelLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else {
      found = paceHotelVisuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = paceHotelVisualFilterList
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(report) => setPBIReport(report)}
          setIsLoaded={(loading) => setIsLoaded(loading)}
        />
      )
    );
  };
  //
  const getVisualName = (order) => {
    const found = getVisual(order);
    return found?.name;
  };
  //
  return (
    !isEmpty(paceHotelVisualFilterList) && (
      <>
        <VisualGrid
          visualName={getVisualName(0)}
          isDisplay={revenueIsDisplay}
          visual={getWidget(0, styles.paceVisual, () => {}, setIsPace1VisualLoaded)}
          onClick={() => {
            dispatch(paceActions.setRevenueIsDisplayPH(!revenueIsDisplay));
          }}
        />
        <VisualGrid
          visualName={getVisualName(1)}
          isDisplay={occupiedRoomsIsDisplay}
          visual={getWidget(1, styles.paceVisual, () => {}, setIsPace2VisualLoaded)}
          onClick={() => {
            dispatch(paceActions.setOccupiedRoomIsDisplayPH(!occupiedRoomsIsDisplay));
          }}
        />
        {getWidget(2, styles.paceVisual, () => {}, setIsPace3VisualLoaded) && (
          <VisualGrid
            visualName={getVisualName(2)}
            isDisplay={adrIsDisplay}
            visual={getWidget(2, styles.paceVisual, () => {}, setIsPace3VisualLoaded)}
            onClick={() => {
              dispatch(paceActions.setAdrIsDisplayPH(!adrIsDisplay));
            }}
          />
        )}
        {getWidget(3, styles.paceVisual, () => {}, setIsPace4VisualLoaded) && (
          <VisualGrid
            visualName={getVisualName(3)}
            isDisplay={cxlIsDisplay}
            visual={getWidget(3, styles.paceVisual, () => {}, setIsPace4VisualLoaded)}
            onClick={() => {
              dispatch(paceActions.setCxlIsDisplayPH(!cxlIsDisplay));
            }}
          />
        )}
        {getWidget(4, styles.paceVisual, () => {}, setIsPace5VisualLoaded) && (
          <VisualGrid
            visualName={getVisualName(4)}
            isDisplay={cxlCountIsDisplay}
            visual={getWidget(
              4,
              styles.paceVisual,
              () => {},
              setIsPace5VisualLoaded,
              cancellationFilterList
            )}
            onClick={() => {
              dispatch(paceActions.setCxlCountIsDisplay(!cxlCountIsDisplay));
            }}
          />
        )}
      </>
    )
  );
};
//
export default PaceHotelGraphicalWidget;
