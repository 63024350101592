/**
 * Pagination limits which use for entire features define limit in api request
 */
const PAGINATION = {
  VISUALS_PER_PAGE_COUNT: 500,
  EVENTS_PER_PAGE: 1000,
  HOTELS_PER_PAGE_COUNT: 200,
  PLOT_DATA_PER_TIME: 30,
  MAJOR_EVENT_CATEGORIES_PER_PAGE: 100,
  MINOR_EVENT_CATEGORIES_PER_PAGE: 100,
  USERS_PER_PAGE_COUNT: 500,
  USER_INVITES_PER_PAGE_COUNT: 500,
  BREAKDOWN_DATA: 100,
  FORECAST_DATA: 10000,
  SYSTEM_INFORMATION: 100,
  AGGREGATION_FILTER_PER_PAGE: 5,
  DROPDOWN_AGGREGATION_FILTER_PER_PAGE: 200,
  NOTIFICATIONS_LIST_COUNT: 100,
};
//
export default PAGINATION;
