/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AddToPhotos, DeleteOutline } from '@mui/icons-material';
import { useState } from 'react';
import { selectHotelDropdownList } from 'modules/dashboard/selectors';
import { Modal } from 'modules/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { getGroupSetValues } from '../../functions';
/**
 * Saved filters detailed view
 * @param {Array} hotels - List of saved Group A, Group B filters (queries)
 * @param {Function} onClose - Function to trigger on close event
 * @param {Function} deleteFilter - Function to delete saved filter
 * @param {Function} setLoadFilter - Function to set load filter true when the saved filter is clicked to be loaded
 * @returns
 */

const Row = ({ hotels, deleteFilters, onClose, setLoadFilter }) => {
  const dispatch = useDispatch();
  //
  const hotelList = useSelector(selectHotelDropdownList);
  //
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon sx={{ height: 22, width: 22 }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ height: 22, width: 22 }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {hotels?.hotelGroupName}
        </TableCell>
        <TableCell align="center">
          {/* Update local and global state to set filter values on filter load */}
          <IconButton
            onClick={() => {
              setLoadFilter(true);
              dispatch(dashboardActions.setHotelGroupQuery(hotels?.hotelQuery));
              dispatch(dashboardActions.setSelectedHotelGroupName(hotels?.hotelGroupName));
              onClose();
            }}
            size="medium"
          >
            <AddToPhotos sx={{ height: 18, width: 18 }} />
          </IconButton>
          <IconButton onClick={() => setOpenDeleteModal(true)} size="medium">
            <DeleteOutline sx={{ height: 18, width: 18 }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                Criteria
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Selected Hotels</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getGroupSetValues(hotels?.hotelQuery?.toLowerCase(), hotelList)?.map(
                    (hotel, index) => (
                      // react/no-array-index-key
                      <TableRow key={index}>
                        <TableCell>
                          <Chip
                            label={
                              <Typography style={{ whiteSpace: 'normal' }}>
                                {hotel?.label}
                              </Typography>
                            }
                            sx={{ marginRight: 2, height: '100%' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title="Delete Hotel Set"
        content="Are you sure you want to delete the hotel set?"
        handleSuccess={() => {
          deleteFilters(hotels?.hotelGroupName);
          onClose();
        }}
        closeLabel="Cancel"
        successLabel="Delete"
        variant="contained"
        color="error"
      />
    </>
  );
};
//
export default Row;
