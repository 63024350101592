import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  ALIGNED,
  BOOKING_DATE,
  PACE_HORIZON_SELECTION,
  REPORT_TYPE,
  SLICE,
  TAGS,
} from 'modules/dashboard/constants';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { getHotelFocusOnValue } from 'modules/dashboard/functions';
import { isEmpty } from 'modules/common/helpers/object';

/**
 * Initial states of Pace widget functions are defined here
 */
const bookingStartDate = new Date(BOOKING_DATE).toISOString();
//
export const initialState = {
  paceLagAligned: false,
  paceFocusOn: [],
  paceChartBreakdown: null,
  paceCumulativeView: true,
  isPaceDetailed: false,
  paceAlignmentToggle: null,
  datePaceComparison: null,
  paceAlignment: null,
  symmetricComparisonIsDisplay: false,
  percentage: null,
  paceSymmetricComparisonToggle: true,
  paceTimeVisuals: [],
  paceTimeLagAlignedVisuals: [],
  paceTimeAbsoluteCumulativeVisuals: [],
  paceTimeLagAlignedCumulativeVisuals: [],
  isPrimary: false,
  paceWidgetForecast: [],
  paceLoad: null,
  paceWidgetFilterList: [],
  paceTimeRevenueFilterList: [],
  paceTimeOccupiedRoomFilterList: [],
  cxlIsDisplayPT: false,
  adrIsDisplayPT: true,
  revenueIsDisplayPT: true,
  occupiedRoomsIsDisplayPT: true,
  cxlIsDisplayPS: false,
  adrIsDisplayPS: true,
  revenueIsDisplayPS: true,
  occupiedRoomsIsDisplayPS: true,
  paceSegmentVisuals: [],
  paceSegmentLagAlignVisuals: [],
  paceSegmentAbsoluteCumulativeVisuals: [],
  paceSegmentLagAlignCumulativeVisuals: [],
  paceWidgetSegmentFilterList: [],
  isPaceForecast: false,
  paceWidgetShareUrl: '',
  bookingPaceWindow: PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON,
  paceBookingDate: {
    startDate: new Date(bookingStartDate).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  enableSecondaryHotelGroups: false,
  hotelFocusOn: {},
  normalizedView: false,
  activeTab: REPORT_TYPE.PACE_TIME_WIDGET,
  paceHotelVisuals: [],
  paceHotelLagAlignVisuals: [],
  paceHotelAbsoluteCumulativeVisuals: [],
  paceHotelLagAlignCumulativeVisuals: [],
  paceWidgetHotelFilterList: [],
  cxlIsDisplayPH: false,
  adrIsDisplayPH: true,
  revenueIsDisplayPH: true,
  occupiedRoomsIsDisplayPH: true,
  tabularView: false,
  paceTimeTabularVisuals: [],
  paceTimeLagAlignedTabularVisuals: [],
  paceTimeCumulativeTabularVisuals: [],
  paceTimeCumulativeLagAlignedTabularVisuals: [],
  paceTimeTabularFilterList: [],
  adjustedStartDate: new Date(bookingStartDate).toISOString(),
  adjustedEndDate: new Date().toISOString(),
  granularity: null,
  paceHotelTabularVisuals: [],
  paceHotelLagAlignedTabularVisuals: [],
  paceHotelCumulativeTabularVisuals: [],
  paceHotelCumulativeLagAlignedTabularVisuals: [],
  paceHotelTabularFilterList: [],
  paceSegmentTabularVisuals: [],
  paceSegmentLagAlignedTabularVisuals: [],
  paceSegmentCumulativeTabularVisuals: [],
  paceSegmentCumulativeLagAlignedTabularVisuals: [],
  paceSegmentTabularFilterList: [],
  cxlCountIsDisplay: false,
  paceCancellationFilterList: [],
  isForecastDisabled: false,
  //
  isFocusOnSet : false
};
/**
 * All actions related to Pace Segment widget feature are defined here
 */
export const paceSlice = createSlice({
  name: SLICE.PACE,
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setPaceFocusOn(state, action) {
      state.paceFocusOn = action?.payload;
    },
    setPaceLagAligned(state, action) {
      state.paceLagAligned = action?.payload;
    },
    setPaceCumulativeView(state, action) {
      state.paceCumulativeView = action?.payload;
    },
    setPaceChartBreakdown(state, action) {
      state.paceChartBreakdown = action?.payload;
    },
    setIsPaceDetailed(state, action) {
      state.isPaceDetailed = action?.payload;
    },
    setPaceAlignmentToggle(state, action) {
      state.paceAlignmentToggle = action?.payload;
    },
    setPaceDateComparison(state, action) {
      state.datePaceComparison = action?.payload;
    },
    setSymmetricComparisonIsDisplay(state, action) {
      state.symmetricComparisonIsDisplay = action?.payload;
    },
    setPercentage(state, action) {
      state.percentage = action?.payload;
    },
    setPaceSymmetricComparisonToggle(state, action) {
      state.paceSymmetricComparisonToggle = action?.payload;
    },
    setRevenueIsDisplayPT(state, action) {
      state.revenueIsDisplayPT = action?.payload;
    },
    setOccupiedRoomIsDisplayPT(state, action) {
      state.occupiedRoomsIsDisplayPT = action?.payload;
    },
    setAdrIsDisplayPT(state, action) {
      state.adrIsDisplayPT = action?.payload;
    },
    setCxlIsDisplayPT(state, action) {
      state.cxlIsDisplayPT = action?.payload;
    },
    setRevenueIsDisplayPS(state, action) {
      state.revenueIsDisplayPS = action?.payload;
    },
    setOccupiedRoomIsDisplayPS(state, action) {
      state.occupiedRoomsIsDisplayPS = action?.payload;
    },
    setAdrIsDisplayPS(state, action) {
      state.adrIsDisplayPS = action?.payload;
    },
    setCxlIsDisplayPS(state, action) {
      state.cxlIsDisplayPS = action?.payload;
    },
    setIsPrimary(state, action) {
      state.isPrimary = action?.payload;
    },
    setPaceWidgetForecast(state, action) {
      state.paceWidgetForecast = action?.payload;
    },
    setPaceLoad(state, action) {
      state.paceLoad = action?.payload;
    },
    setPaceWidgetFilterList(state, action) {
      state.paceWidgetFilterList = action?.payload;
    },
    setPaceTimeRevenueFilterList(state, action) {
      state.paceTimeRevenueFilterList = action.payload;
    },
    setPaceTimeOccupiedFilterList(state, action) {
      state.paceTimeOccupiedRoomFilterList = action.payload;
    },
    setPaceWidgetSegmentFilterList(state, action) {
      state.paceWidgetSegmentFilterList = action?.payload;
    },
    setIsPaceForecast(state, action) {
      state.isPaceForecast = action?.payload;
    },
    setPaceWidgetShareUrl(state, action) {
      state.paceWidgetShareUrl = action?.payload;
    },
    setPaceBookingPaceWindow(state, action) {
      state.bookingPaceWindow = action?.payload;
    },
    setPaceBookingDate(state, action) {
      state.paceBookingDate = action?.payload;
    },
    setEnableSecondaryHotelGroups(state, action) {
      state.enableSecondaryHotelGroups = action.payload;
    },
    setHotelFocusOn(state, action) {
      state.hotelFocusOn = action?.payload;
      state.enableSecondaryHotelGroups = action?.payload && 'hotels' in action.payload;
    },
    setNormalizedView(state, action) {
      state.normalizedView = action?.payload;
    },
    setPaceWidgetHotelFilterList(state, action) {
      state.paceWidgetHotelFilterList = action?.payload;
    },
    setRevenueIsDisplayPH(state, action) {
      state.revenueIsDisplayPH = action?.payload;
    },
    setOccupiedRoomIsDisplayPH(state, action) {
      state.occupiedRoomsIsDisplayPH = action?.payload;
    },
    setAdrIsDisplayPH(state, action) {
      state.adrIsDisplayPH = action?.payload;
    },
    setCxlIsDisplayPH(state, action) {
      state.cxlIsDisplayPH = action?.payload;
    },
    setTabularView(state, action) {
      state.tabularView = action?.payload;
    },
    setPaceTimeTabularFilterList(state, action) {
      state.paceTimeTabularFilterList = action?.payload;
    },
    setAdjustedStartDate(state, action) {
      state.adjustedStartDate = action?.payload;
    },
    setAdjustedEndDate(state, action) {
      state.adjustedEndDate = action?.payload;
    },
    setGranularity(state, action) {
      state.granularity = action?.payload;
    },
    setPaceHotelTabularFilterList(state, action) {
      state.paceHotelTabularFilterList = action?.payload;
    },
    setPaceAlignment(state, action) {
      state.paceAlignment = action?.payload;
    },
    setPaceSegmentTabularFilterList(state, action) {
      state.paceSegmentTabularFilterList = action?.payload;
    },
    setCxlCountIsDisplay(state, action) {
      state.cxlCountIsDisplay = action?.payload;
    },
    setPaceCancellationFilterList(state, action) {
      state.paceCancellationFilterList = action?.payload;
    },
    setIsForecastDisabled(state, action) {
      state.isForecastDisabled = action?.payload;
    },
    setIsFocusOnSet (state, action){
      state.isFocusOnSet = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        );
        return {
          ...state,
          percentage: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.PERCENTAGE) && filter?.isDefault === true
          )?.id,
          paceAlignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          paceAlignmentToggle: dateComparisonFilter?.type === ALIGNED,
          datePaceComparison: dateComparisonFilter?.values?.find(
            (filter) => filter.isDefault === true
          )?.value,
          paceChartBreakdown: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
          )?.id,
        };
      })
      .addCase(dashboardActions.getVisualsSucceeded, (state, action) => {
        const visualDetails = action?.payload?.results;
        return {
          ...state,
          paceTimeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET)
          ),
          paceTimeLagAlignedVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET_LAG_ALIGNED)
          ),
          paceTimeAbsoluteCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET_ABSOLUTE_CUMULATIVE)
          ),
          paceTimeLagAlignedCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET_LAG_ALIGNED_CUMULATIVE)
          ),
          paceTimeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_TABULAR_DAILY_COMBINED)
          ),
          paceTimeLagAlignedTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_TABULAR_LAG_ALIGNED_COMBINED)
          ),
          paceTimeCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_TABULAR_CUMULATIVE_COMBINED)
          ),
          paceTimeCumulativeLagAlignedTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_TIME_TABULAR_CUMULATIVE_LAG_ALIGNED_COMBINED)
          ),
          paceSegmentVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET)
          ),
          paceSegmentLagAlignVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET_LAG_ALIGNED)
          ),
          paceSegmentAbsoluteCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET_ABSOLUTE_CUMULATIVE)
          ),
          paceSegmentLagAlignCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET_LAG_ALIGNED_CUMULATIVE)
          ),
          paceHotelVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_WIDGET)
          ),
          paceHotelLagAlignVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_WIDGET_LAG_ALIGNED)
          ),
          paceHotelAbsoluteCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_WIDGET_ABSOLUTE_CUMULATIVE)
          ),
          paceHotelLagAlignCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_WIDGET_LAG_ALIGNED_CUMULATIVE)
          ),
          paceHotelTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_TABULAR_DAILY_COMBINED)
          ),
          paceHotelLagAlignedTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_TABULAR_LAG_ALIGNED_COMBINED)
          ),
          paceHotelCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_TABULAR_CUMULATIVE_COMBINED)
          ),
          paceHotelCumulativeLagAlignedTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_HOTEL_TABULAR_CUMULATIVE_LAG_ALIGNED_COMBINED)
          ),
          paceSegmentTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_TABULAR_DAILY_COMBINED)
          ),
          paceSegmentLagAlignedTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_TABULAR_LAG_ALIGNED_COMBINED)
          ),
          paceSegmentCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_TABULAR_CUMULATIVE_COMBINED)
          ),
          paceSegmentCumulativeLagAlignedTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_TABULAR_CUMULATIVE_LAG_ALIGNED_COMBINED)
          ),
        };
      })
      .addCase(dashboardActions.getHotelListSucceeded, (state, action) => {
        const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
        return {
          ...state,
          hotelFocusOn: hotel?.focusOnValues?.[0],
        };
      })
      .addCase(dashboardActions.setHotelName, (state, action) => ({
        ...state,
        hotelFocusOn: action?.payload,
        normalizedView: false,
        enableSecondaryHotelGroups: action?.payload && 'hotels' in action.payload,
      }))
      .addCase(dashboardActions.setEnableHotelGroups, (state, action) => ({
        ...state,
        enableSecondaryHotelGroups: action?.payload,
        normalizedView: false,
      }))
      .addCase(dashboardActions.setSelectedHotelGroup, (state, action) => {
        let secondaryHotelSet;
        if (state && state.hotelFocusOn && 'hotels' in state.hotelFocusOn) {
          if (!isEmpty(state.hotelName) && action?.payload?.hotelGroupDropdownList?.length === 0) {
            secondaryHotelSet = action?.payload?.hotelList[0];
          } else if (state.hotelFocusOn && action?.payload?.hotelGroupDropdownList?.length > 0) {
            const newGroup = action?.payload?.hotelGroupDropdownList?.find(
              (a) => a?.id === state.hotelFocusOn?.id
            );
            if (newGroup) {
              secondaryHotelSet = newGroup;
            } else {
              secondaryHotelSet = action?.payload?.hotelList[0];
            }
          } else {
            secondaryHotelSet = action?.payload?.hotelList[0];
          }
          return {
            ...state,
            hotelFocusOn: secondaryHotelSet,
            enableSecondaryHotelGroups: secondaryHotelSet && 'hotels' in secondaryHotelSet,
          };
        }
        return { ...state };
      });
  },
});
//
export const { actions: paceActions } = paceSlice;
