/* eslint-disable import/prefer-default-export */
/**
 * Function to return selected color range to indicate the room availability in rate calender based on the number
 * @param {*} _number - available room count
 * @returns
 */
export const selectColorRange = (_number) => {
  let color = '';
  switch (true) {
    case _number > 41:
      color = '#FF0000';
      break;
    case _number >= 21 && _number <= 40:
      color = '#F9EC2B';
      break;
    case _number >= 0 && _number <= 20:
    default:
      color = '#6AB98C';
      break;
  }
  return color;
};
