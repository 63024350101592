/* eslint-disable import/no-cycle */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import store from 'store/index';
import { ENVIRONMENT } from 'config';
import { SLICE } from 'modules/dashboard/constants';
import API from '../auth/constants';
import { authActions } from '../auth/slice';

let authTokens;

const getTokens = () => {
  // when initializing tokens, subscribing to redux store changes for
  // get updated state each time store state get changed
  if (!authTokens) {
    store?.subscribe(() => {
      authTokens = store?.getState()[SLICE.BASE_AUTH]?.tokens;
    });
    authTokens = store?.getState()[SLICE.BASE_AUTH]?.tokens;
    return authTokens;
  }
  return authTokens;
};
//
const axiosConfig = axios.create({
  baseURL: ENVIRONMENT.BACKEND_API,
});
//
const doNeedRefresh = (accessToken) => {
  const { exp } = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000 + 3;
  return exp <= currentTime;
};
//
const checkSessionValidity = (refreshToken) => {
  if (refreshToken) {
    const { exp } = jwtDecode(refreshToken);
    const currentTime = Date.now() / 1000;
    return exp >= currentTime;
  }
  return false;
};
//
const getRefreshToken = () => {
  const { refreshToken } = getTokens();
  //
  return axiosConfig.post(API.POST_REFRESH_TOKENS.path, {
    refreshToken,
  });
};
/**
 * Here is the axios request interceptor. Here we add the request header into the
 */
axiosConfig.interceptors.request.use(async (requestConfig) => {
  const config = requestConfig;
  const { accessToken, refreshToken } = getTokens();
  if (accessToken && 'Authorization' in config.headers) {
    // check whether access token need to be refreshed before execute api call
    if (doNeedRefresh(accessToken)) {
      if (!config?.url?.includes('/auth/refresh-tokens')) {
        if (checkSessionValidity(refreshToken)) {
          const tokenData = await getRefreshToken();
          store.dispatch(authActions.refreshTokenSucceeded(tokenData.data));
          config.headers.Authorization = `Bearer ${tokenData.data.accessToken}`;
        }
      }
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  config.headers['X-Client-ID'] = ENVIRONMENT.KC_CLIENT_ID;
  return config;
});
/**
 * Here is the axios response interceptor.
 * Based on the response status fetch the new access token based on the refresh token
 */
axiosConfig.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error)
);
//
export default axiosConfig;
