import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import useIsTokenValid from 'modules/common/auth/hooks/use-access-token-validity';
import { selectIsSessionTimedOut, selectEmbedTokenExpiry } from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { selectAccessToken, selectOrganizationId, selectRefreshToken } from '../auth/selectors';
import { authActions } from '../auth/slice';
import TIME_OUTS from '../constants/time-outs';
/**
 * Based on refresh token get the new access token
 */
const useUpdateToken = () => {
  const dispatch = useDispatch();
  //
  const refreshToken = useSelector(selectRefreshToken);
  const accessToken = useSelector(selectAccessToken);
  const pbiTokenExpiry = useSelector(selectEmbedTokenExpiry);
  const organizationId = useSelector(selectOrganizationId);
  const isSessionTimedOut = useSelector(selectIsSessionTimedOut);
  const { checkAuthentication } = useIsAuthenticated();
  const { doNeedRefresh } = useIsTokenValid();
  //
  const tryUpdateToken = useCallback(() => {
    dispatch(authActions.refreshToken({ refreshToken }));
  }, [refreshToken]);
  //
  const tryUpdatePBIToken = useCallback(() => {
    dispatch(
      dashboardActions.getEmbedToken({
        organizationId,
      })
    );
  }, [organizationId]);
  //
  const doNeedRefreshPbiToken = (exp) => {
    if (exp) {
      const expireTime = new Date(exp);
      const currentTime = new Date();
      return expireTime.getTime() <= currentTime.getTime();
    }
    return false;
  };
  //
  useEffect(() => {
    // session observer
    const interval = setInterval(() => {
      if (checkAuthentication(refreshToken)) {
        // check whether access token need to refresh
        if (doNeedRefresh(accessToken)) {
          if (!isSessionTimedOut) {
            tryUpdateToken();
          }
        }
        // check whether pbi embed token need to refresh
        if (doNeedRefreshPbiToken(pbiTokenExpiry)) {
          tryUpdatePBIToken();
        }
        // session has been timed out
      } else if (!isSessionTimedOut) {
        dispatch(dashboardActions.setSessionTimeOut(true));
      }
    }, TIME_OUTS.SESSION_OBSERVER_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [isSessionTimedOut, accessToken, pbiTokenExpiry, refreshToken]);
};
//
export default useUpdateToken;
