/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import API from 'modules/dashboard/components/tab-container/trend-tabs/constants';
/**
 * Save segment filter generator function to save segment filter combination
 * @param {*} param0
 */
export function* saveSegmentFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SEGMENT_FILTER, payload);
    yield put(trendActions.saveSegmentFilterSucceeded(response));
  } catch (error) {
    yield put(trendActions.saveSegmentFilterFailed(error?.message));
  }
}
/**
 * validate filter name generator function to check filter name already exist or not
 * @param {*} param0
 */
export function* validateFilterNameGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SEGMENT_FILTER, payload);
    yield put(trendActions.getFilterNameValidationSucceeded(response));
  } catch (error) {
    yield put(trendActions.getFilterNameValidationFailed(error?.message));
  }
}
/**
 * Get aggregation filter generator function to obtain saved aggregation filter combination
 * @param {*} param0
 */
export function* getAggregationFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SEGMENT_FILTER, payload);
    yield put(trendActions.getAggregationFilterSucceeded(response));
  } catch (error) {
    yield put(trendActions.getAggregationFilterFailed(error?.message));
  }
}

/**
 * Delete aggregation filter generator function to obtain saved aggregation filter combination
 * @param {*} param0
 */
export function* deleteAggregationFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_SEGMENT_FILTER, payload);
    yield put(
      trendActions.deleteAggregationFilterSucceeded({
        response,
        data: payload,
      })
    );
  } catch (error) {
    yield put(trendActions.deleteAggregationFilterFailed(error?.message));
  }
}
//
export function* trendSaga() {
  yield takeLatest(trendActions.saveSegmentFilter.type, saveSegmentFilterGenerator);
  yield takeLatest(trendActions.getFilterNameValidation.type, validateFilterNameGenerator);
  yield takeLatest(trendActions.getAggregationFilter.type, getAggregationFilterGenerator);
  yield takeLatest(trendActions.deleteAggregationFilter.type, deleteAggregationFilterGenerator);
}
//
export default trendSaga;
