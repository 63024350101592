/* eslint-disable import/prefer-default-export */
/**
 * Workbook feature related request structures are defined here
 */
export const API = {
  POST_PDF_EXPORT_URL: {
    path: '/organizations/:organizationId/visual-export',
    method: 'POST',
  },
  POST_PDF_EXPORT: {
    path: '/organizations/:organizationId/visual-download',
    method: 'POST',
  },
};
