/* eslint-disable import/prefer-default-export */
/**
 * 3d Pace feature related request structures are defined here
 */
export const API = {
  GET_PLOT_DATA: {
    path: '/organizations/:organizationId/hotels/:hotelId/consumption-plot-data?:query',
    method: 'GET',
  },
};
