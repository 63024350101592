export const IMPLIED_RANGES = [
  {
    value: '',
    label: '',
  },
  {
    value: 'tdy',
    label: 'Today',
  },
  {
    value: 'fw7',
    label: 'Forward 7 Days',
  },
  {
    value: 'tw',
    label: 'This Week',
  },
  {
    value: 'tm',
    label: 'This Month',
  },
  {
    value: 'wtd',
    label: 'Week-to-Date',
  },
  {
    value: 'mtd',
    label: 'Month-to-Date',
  },
  {
    value: 'ytd',
    label: 'Year-to-Date',
  },
  {
    value: 'lw',
    label: 'Last Week',
  },
  {
    value: 'lm',
    label: 'Last Month',
  },
  {
    value: 'ly',
    label: 'Last Year',
  },
  {
    value: 'otb',
    label: 'OTB',
  },
];
//
export const dateRangeOptions = {
  ABSOLUTE: 'Absolute',
  RELATIVE: 'Relative',
};
