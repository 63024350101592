import { FormControl, FormControlLabel, RadioGroup, Radio, Typography } from '@mui/material';
import { CustomStack } from 'modules/common/components';
import BookingDatePicker from 'modules/dashboard/components/tab-container/inner-filter/components/booking-date-picker';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
/**
 * Booking window component common for the dashboard component
 * @param {Boolean} vOTB - State to enable custom booking
 * @param {Boolean} customBooking - State to enable custom booking
 * @param {Boolean} handleOTBChange - Function to call when the radio button selection changes
 * @param {Function} reportType - Report type
 * @param {Date} latestDate - Latest date value
 * @param {*} otbValue - OTB value
 * @returns
 */
const BookingWindow = ({
  vOTB,
  customBooking,
  handleOTBChange,
  reportType,
  latestDate,
  otbValue,
  id = '',
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Typography sx={{ color: '#0009' }}>Booking Window</Typography>
      <RadioGroup value={vOTB} name="radio-buttons-group" onChange={handleOTBChange}>
        <FormControlLabel
          sx={{
            my: -1,
          }}
          value={otbValue}
          control={<Radio id={`${id}-otb`} />}
          label="OTB"
        />
        <FormControlLabel
          sx={{
            my: -1,
          }}
          value
          control={<Radio id={`${id}-custom-booking-window`} />}
          label="Custom Booking Window"
        />
      </RadioGroup>
    </FormControl>
    {customBooking && (
      <BookingDatePicker reportType={reportType} latestDate={latestDate} id={`${id}-date-picker`} />
    )}
  </CustomStack>
);
//
export default BookingWindow;
