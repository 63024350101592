/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';
//
const selectDomain = (state) => state[SLICE.CONFIGURE_EVENT_CATEGORIES] || initialState;
/**
 * Getting the loading state
 */
export const selectLoader = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.loading
);
/**
 * Retrieving the major event categories
 */
export const selectEventCategoryConfigurations = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.eventCategories
);
/**
 * Retrieving the minor event categories of a major event
 */
export const selectMinorEventCategories = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.minorEventCategories
);
/**
 * Getting the updated minor event state into the component
 */
export const selectMinorEventUpdateState = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.updatedMinorEvent
);
/**
 * Getting the hotel Id state into the component
 */
export const selectHotelId = createSelector(
  [selectDomain],
  (configureEventCategoryState) => configureEventCategoryState.hotelId
);
