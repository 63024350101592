import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state) => state['feature/sign-up'] || initialState;
/**
 * Getting entire sign up state into the component
 */
export const selectSignUpState = createSelector([selectDomain], (state) => state);
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector([selectDomain], (state) => state.loading);
/**
 * Getting email id state to the component
 */
export const selectEmailId = createSelector([selectDomain], (state) => state.emailId);
