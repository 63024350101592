import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography, Button, Card, CardContent } from '@mui/material';
import { withTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Loader } from 'modules/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { configureHotelRoomsActions } from 'modules/hotel-configuration/slice';
import { selectLoader } from 'modules/hotel-configuration/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import HotelsGrid from '../hotels-table';
import AddHotelFormDialog from '../add-hotel';
/**
 * Main view for hotel configuration configuration layout
 * @param {*} theme
 * @returns
 */
const HotelRoomConfigurationView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const organizationId = useSelector(selectOrganizationId);
  //
  const [open, setOpen] = useState(false);
  // get hotel room configurations
  useEffect(() => {
    dispatch(configureHotelRoomsActions.configureHotelRooms({ organizationId }));
  }, [dispatch]);
  // position scrolling
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <Loader loading={loading}>
      <Box>
        <Grid className="sub-title-event-config" item padding={5}>
          <Typography variant="h2">Configure Hotel Rooms</Typography>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" sx={{ color: grey[500] }}>
              Last updated on: Thu, 11th Aug 2022 at 12:12
            </Typography>
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
              <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Add new
              </Button>
              <Typography
                variant="body2"
                sx={{
                  color: grey[500],
                  paddingLeft: 10,
                }}
              >
                Today
              </Typography>
              <IconButton>
                <KeyboardArrowDown />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Card>
          <CardContent
            sx={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid container spacing={2}>
              <HotelsGrid />
            </Grid>
          </CardContent>
          <AddHotelFormDialog open={open} onClose={() => setOpen(false)} />
        </Card>
      </Box>
    </Loader>
  );
};
//
export default withTheme(HotelRoomConfigurationView);
