/* eslint-disable react/no-this-in-sfc */
import { STAY_DATE_END_DATE, STAY_DATE_START_DATE } from 'modules/common/constants/date-range';
import { Grid } from '@mui/material';
import { DateRange } from 'react-date-range';
import { TextField } from './style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

/**
 * Custom stay date range picker common component that uses across modules for select stay date range
 * @param {Function} handleChange - function for setting data range
 * @param {Array} ranges - start date and end data of the picker
 * @param {String} label - textfield label
 * @param {String} adornment - whether we need to adornment is beginning or not
 * @param {String} dateValue - selected date value
 * @param {String} cssClassName - use style for entire component
 * @param {Boolean} isSaveDisabled - To show preset max count info
 * @returns
 */
const CustomPresetStayDateRangePicker = (props) => {
  // destruct the props object
  const { handleChange, ranges, dateValue, cssClassName } = props;
  // checks the range is exited or not and set date range
  const dateRange =
    ranges !== null
      ? [
          {
            startDate: new Date(ranges[0].startDate),
            endDate: new Date(ranges[0].endDate),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        ];
  //
  return (
    <Grid container flexDirection="row" justifyContent="space-between" spacing={2}>
      <Grid item xs={5}>
        <TextField
          label="Date Range"
          my={5}
          value={dateValue}
          size="small"
          color="primary"
          fullWidth
        />
      </Grid>
      <Grid item mt={5}>
        <DateRange
          onChange={handleChange}
          editableDateInputs
          moveRangeOnFirstSelection={false}
          ranges={dateRange}
          months={1}
          direction="horizontal"
          className={cssClassName}
          maxDate={new Date(STAY_DATE_END_DATE)}
          minDate={new Date(STAY_DATE_START_DATE)}
        />
      </Grid>
    </Grid>
  );
};
//
export default CustomPresetStayDateRangePicker;
