/* eslint-disable react/no-array-index-key */
import {
  AppBar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
/**
 * FAQ component define here.
 * @returns
 */
const FAQView = () => {
  const rows = Array.from({ length: 2 }, () => ({}));

  //
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{ height: 75, maxHeight: 75, padding: 0, margin: 0, backgroundColor: '#2e384a' }}
    >
      <Grid container spacing={2} direction="row" m={10}>
        <Grid
          m={10}
          item
          container
          justifyContent="space-between"
        >
          <Typography variant="h2" gutterBottom color="black">
            Frequently Asked Questions
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Questions</TableCell>
                  <TableCell>Answers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Array.from({ length: 2 }, (e, colIndex) => (
                      <TableCell key={colIndex} />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </AppBar>
  );
};
//
export default FAQView;
