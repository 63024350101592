import { useEffect, useState } from 'react';
import { Grid, Fade, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  selectIPreferCustomerCountFilterList,
  selectIPreferFilterList,
  selectIPreferTimeComparisonFilterList,
} from 'modules/dashboard/components/tab-container/i-prefer-tab/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import iPreferStyles from 'modules/dashboard/components/tab-container/i-prefer-tab/i-prefer.module.scss';
import { TAB_VALUE } from 'modules/dashboard/constants';
import IPreferFilter from 'modules/dashboard/components/tab-container/i-prefer-tab/components/filter-panel';
import useIPrefer from '../../hooks/use-iprefer';
import VisualCard from '../../../visual-card';
/**
 * Web click report with filters
 * @param {Number} value - Summary widget tab value
 * @param {String} accessToken - Power BI access token
 * @param {Object} visuals - Summary tab visual details
 * @param {Boolean} active - whether summary tab is active
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const IPrefer = (props) => {
  const {
    value,
    accessToken,
    visuals,
    active,
    latestDate,
    reportType,
    hotelTimezone,
    toggleDisabled,
  } = props;
  //
  useIPrefer();
  //
  const iPreferFilterList = useSelector(selectIPreferFilterList);
  const iPreferTimeComparisonFilterList = useSelector(selectIPreferTimeComparisonFilterList);
  const iPreferTCustomerCountFilterList = useSelector(selectIPreferCustomerCountFilterList);
  // local states of visual loading
  const [isIPrefer1VisualsLoaded, setIsIPrefer1VisualLoaded] = useState(false);
  const [isIPrefer2VisualsLoaded, setIsIPrefer2VisualLoaded] = useState(false);
  const [isIPrefer3VisualsLoaded, setIsIPrefer3VisualLoaded] = useState(false);
  const [isIPrefer4VisualsLoaded, setIsIPrefer4VisualLoaded] = useState(false);
  const [isIPrefer5VisualsLoaded, setIsIPrefer5VisualLoaded] = useState(false);
  const [isIPrefer6VisualsLoaded, setIsIPrefer6VisualLoaded] = useState(false);
  const [isIPrefer7VisualsLoaded, setIsIPrefer7VisualLoaded] = useState(false);
  const [isIPrefer8VisualsLoaded, setIsIPrefer8VisualLoaded] = useState(false);
  const [isIPrefer9VisualsLoaded, setIsIPrefer9VisualLoaded] = useState(false);
  const [isIPrefer10VisualsLoaded, setIsIPrefer10VisualLoaded] = useState(false);
  //
  const getWidget = (
    index,
    cssClassName,
    filter,
    setPBIReport = () => {},
    setIsLoaded = () => {}
  ) => {
    const found = visuals?.find((visual) => visual?.order === index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  //
  useEffect(() => {
    if (value === TAB_VALUE.I_PREFER) window.scrollTo(0, 0);
  }, [value, active]);
  //
  return (
    active && (
      <Fade in={active} {...{ timeout: 500 }}>
        <Grid
          container
          direction="row"
          sx={{
            display: value === TAB_VALUE.I_PREFER ? '' : 'none',
          }}
          className={styles.mainGrid}
        >
          <Grid item xs={2}>
            <IPreferFilter
              reportType={reportType}
              visual={getWidget(
                15,
                styles.symmetricComparisonWindow,
                iPreferTimeComparisonFilterList
              )}
              tabValue={value}
              latestDate={latestDate}
              hotelTimezone={hotelTimezone}
              toggleDisabled={toggleDisabled}
              isLoaded={
                isIPrefer1VisualsLoaded &&
                isIPrefer2VisualsLoaded &&
                isIPrefer3VisualsLoaded &&
                isIPrefer4VisualsLoaded &&
                isIPrefer5VisualsLoaded &&
                isIPrefer6VisualsLoaded &&
                isIPrefer7VisualsLoaded &&
                isIPrefer8VisualsLoaded &&
                isIPrefer9VisualsLoaded &&
                isIPrefer10VisualsLoaded
              }
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={10}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={styles.mainGrid}
                >
                  <Grid item>
                    {getWidget(
                      0,
                      styles.visualCard,
                      iPreferFilterList,
                      () => {},
                      setIsIPrefer1VisualLoaded
                    )}
                  </Grid>
                  <Grid item>
                    {getWidget(
                      1,
                      styles.visualCard,
                      iPreferFilterList,
                      () => {},
                      setIsIPrefer2VisualLoaded
                    )}
                  </Grid>
                  <Grid item>
                    {getWidget(
                      2,
                      styles.visualCard,
                      iPreferFilterList,
                      () => {},
                      setIsIPrefer3VisualLoaded
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={styles.mainGrid}
                >
                  <Grid item>
                    {getWidget(
                      3,
                      styles.visualCard,
                      iPreferFilterList,
                      () => {},
                      setIsIPrefer4VisualLoaded
                    )}
                  </Grid>
                  <Grid item>
                    {getWidget(
                      4,
                      styles.visualCard,
                      iPreferFilterList,
                      () => {},
                      setIsIPrefer5VisualLoaded
                    )}
                  </Grid>
                  <Grid item justifyContent="space-between">
                    <Grid item>
                      <Grid>
                        {getWidget(
                          5,
                          iPreferStyles.AvgVisualCard,
                          iPreferFilterList,
                          () => {},
                          setIsIPrefer6VisualLoaded
                        )}
                      </Grid>
                    </Grid>
                    <Grid item mt={3}>
                      <Grid>
                        {getWidget(
                          6,
                          iPreferStyles.AvgVisualCard,
                          iPreferFilterList,
                          () => {},
                          setIsIPrefer7VisualLoaded
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={styles.mainGrid}
                  mb={2}
                >
                  <Grid>
                    {getWidget(
                      7,
                      styles.paceVisual,
                      iPreferTimeComparisonFilterList,
                      () => {},
                      setIsIPrefer8VisualLoaded
                    )}
                  </Grid>
                  <Grid>
                    {getWidget(
                      8,
                      styles.paceVisual,
                      iPreferTimeComparisonFilterList,
                      () => {},
                      setIsIPrefer9VisualLoaded
                    )}
                  </Grid>
                  <Grid>
                    {getWidget(
                      9,
                      iPreferStyles.customerCount,
                      iPreferTCustomerCountFilterList,
                      () => {},
                      setIsIPrefer10VisualLoaded
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    )
  );
};
//
export default IPrefer;
