import { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox, Switch, Button } from '@mui/material';
import rateRange from 'modules/common/constants/heatmap-config';
import Calender from '../calendar';
/**
 * Rate calendar view is defined here
 * @returns
 */
const RateCalendarView = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [heatMapToggle, setHeatMapToggle] = useState(false);
  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <Grid container direction="row" spacing={6}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        ml={7}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={heatMapToggle}
                onChange={(event) => setHeatMapToggle(event.target.checked)}
              />
            }
            label="Heat map"
          />
          {heatMapToggle &&
            rateRange.map((element) => (
              <Button
                key={element.backgroundColor}
                variant="contained"
                disabled
                sx={{
                  marginRight: '3px',
                  '&:disabled': {
                    backgroundColor: element?.backgroundColor,
                    color: element?.textColor,
                  },
                }}
              >
                {element?.label}
              </Button>
            ))}
        </Grid>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />}
          label="Show Events and Metrics"
        />
      </Grid>
      <Calender isChecked={isChecked} heatMap={heatMapToggle} handleChange={setIsChecked} />
    </Grid>
  );
};
//
export default RateCalendarView;
