/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import { Mic, Send, X } from 'react-feather';
import { useRef, useState } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { Grid, IconButton, Snackbar, SnackbarContent, TextField, Typography } from '@mui/material';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { EXECUTION_TYPE, UiController, consoleCommands } from 'modules/common/ui-controller';
import {
  executeSingleCommands,
  setHotelCommand,
  setTabCommand,
} from 'modules/common/ui-controller/utils/functions';
import {
  // handleFetchChatResponse,
  transcribeAudio,
} from 'modules/common/utils/chat-util';
import { CHAT_ROLES } from 'modules/common/ui-controller/utils/constants';
import useChat from './hooks/use-chat';
/**
 * custom chat component that uses to maintain chat gpt flow and execute direct commands through text and audio
 * @param isOpen - open state of chat console
 * @param setOpen
 */
const CustomChatView = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch();
  //
  const buttonRef = useRef(null);
  // global selectors
  const organizationId = useSelector(selectOrganizationId);
  const userId = useSelector(selectAuthUser);
  // local states
  const [msg, setMsg] = useState('');
  const [streamData, setStreamData] = useState('');
  const [commandSet, setCommandSet] = useState('');
  const [chatState, setChatState] = useState([]);
  // mic recorder for record audio
  const recorder = new MicRecorder({ bitRate: 128 });
  // custom hook to handle chat flow functionalities
  useChat(
    streamData,
    setStreamData,
    commandSet,
    setCommandSet,
    setChatState,
    chatState,
    isOpen,
    setMsg
  );
  // start recording the audio
  const startRecording = () => {
    recorder.start().catch((error) => {
      console.error(error);
    });
  };
  // stop recording the audio
  const stopRecording = async () => {
    await transcribeAudio(recorder, organizationId, userId, setMsg, setChatState, chatState);
  };
  // handle start and stop recording on click on mic button
  const handlePushToTalk = () => {
    // checks mic is active or not
    if (buttonRef.current.classList.contains('micActive')) {
      buttonRef.current.classList.remove('micActive');
      stopRecording();
    } else {
      buttonRef.current.classList.add('micActive');
      startRecording();
    }
  };
  // function to execute direct command flow
  const handleCommands = async (data) => {
    try {
      // reset tab and hotel change states
      dispatch(dashboardActions.setTabChange(false));
      dispatch(dashboardActions.setHotelChange(false));

      // checking whether there is multiple cmd
      if (data?.value?.includes('&')) {
        setCommandSet(data?.value);
        // checks if tab command includes
        if (data?.value?.includes(consoleCommands.TAB)) {
          setTabCommand(data, EXECUTION_TYPE.COMMAND);
        } else {
          dispatch(dashboardActions.setTabChange(true));
        }
        // checks if hotel command includes
        if (
          data?.value?.includes(consoleCommands.HOTEL) ||
          data?.value?.includes(consoleCommands.HOTEL_GROUP)
        ) {
          setHotelCommand(data, EXECUTION_TYPE.COMMAND);
        } else {
          dispatch(dashboardActions.setHotelChange(true));
        }
      } else {
        // executing single commands
        executeSingleCommands(data, EXECUTION_TYPE.COMMAND);
      }
      //
    } catch (err) {
      UiController.onError(err);
    }
  };
  // set user chat messages in chat array
  const sendChatMessageObj = async (event) => {
    setStreamData('');
    event.preventDefault();
    setChatState([
      ...chatState,
      {
        message: msg,
        role: CHAT_ROLES.USER,
      },
    ]);
    // checks user message is a direct command or not
    const exists = Object.values(consoleCommands).some((s) => msg.includes(`${s}=`));
    if (exists) {
      handleCommands({ value: msg });
    } else {
      // TODO :  Need to set Authorization for chat flow
      // for now chat flow is not working
      // await handleFetchChatResponse(
      //   msg,
      //   organizationId,
      //   userId,
      //   setStreamData,
      //   setChatState,
      //   chatState
      // );
    }
    setMsg('');
  };
  // no chat component
  const noChat = (
    <Typography color="black">
      {chatState.length === 0 ? '' : <div className="lds-dual-ring" />}
    </Typography>
  );
  // chat component to display user and system messages
  const chat = chatState?.map((item, index) => (
    <Grid
      key={index}
      item
      xs={10}
      style={{
        padding: '5px',
        borderRadius: 10,
        marginTop: '5px',
        backgroundColor: item.role === 'system' ? '#2e384a' : '#e0e0e0',
        marginLeft: item.role === 'system' ? 'auto' : 0,
        marginRight: item.role === 'user' ? 'auto' : 0,
        justifyContent: 'center',
      }}
    >
      <Typography sx={{ color: item.role === 'system' ? 'white' : 'black' }}>
        {item.message}
      </Typography>
    </Grid>
  ));
  //
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpen}
      onClose={() => {
        setOpen();
      }}
    >
      <SnackbarContent
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 10,
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => setOpen()}
            sx={{
              position: 'absolute',
              right: 25,
              top: 20,
              color: 'white',
            }}
          >
            <X />
          </IconButton>
        }
        message={
          <Grid
            sx={{
              flexDirection: 'column',
              width: '300px',
              backgroundColor: '#f5f5f5',
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              boxShadow: 9,
            }}
          >
            <Grid
              sx={{
                backgroundColor: '#2e384a',
                display: 'flex',
                height: 50,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                alignItems: 'center',
              }}
            >
              <Typography sx={{ m: 2 }}>Console</Typography>
            </Grid>
            <Grid sx={{ width: '100%', height: 350, overflowY: 'scroll', mx: 1 }}>
              {chatState?.length === 0 ? noChat : chat}
            </Grid>

            <form className="search-box" style={{ padding: 2 }}>
              <TextField
                sx={{ width: '70%' }}
                className="search-input"
                type="text"
                placeholder="Type something.."
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                InputProps={{ sx: { borderRadius: 5, backgroundColor: '#e0e0e0' } }}
              />
              <IconButton className="search-btn" type="submit" onClick={sendChatMessageObj}>
                <Send size={20} />
              </IconButton>
              <IconButton
                ref={buttonRef}
                type="button"
                onClick={handlePushToTalk}
                className="search-btn"
              >
                <Mic size={20} />
              </IconButton>
            </form>
          </Grid>
        }
      />
    </Snackbar>
  );
};

export default CustomChatView;
