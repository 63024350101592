/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';

const selectDomain = (state) => state[SLICE.NOTIFICATION] || initialState;
/**
 * Select notification state into the all the component
 */
export const selectNotification = createSelector(
  [selectDomain],
  (notificationState) => notificationState
);
