import * as yup from 'yup';
import { sanitizeValidation } from '../../../utils/sanitize';

/**
 * Query box form validations define here
 */

const queryBoxValidation = yup.object().shape({
  setHotelGroup: yup
    .string()
    .trim()
    .test((value) => sanitizeValidation(value, 'setHotelGroup')),
});
//
export default queryBoxValidation;
