import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/forgot-password/selectors';
import { useSelector } from 'react-redux';
import ForgotPasswordForm from '../forgot-password-form';
/**
 * Forgot password parent component define here.
 * All the sub component include there
 * @returns
 */
const ForgotPasswordView = () => {
  const loading = useSelector(selectLoader);
  //
  return (
    <Loader loading={loading}>
      <ForgotPasswordForm />
    </Loader>
  );
};
//
export default ForgotPasswordView;
