import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import GlobalStyle from 'modules/common/style/global';
import { Footer, Navbar, SessionTimeOutPopup, TokenRevokedPopup } from 'modules/common/components';
import useUpdateToken from 'modules/common/hooks/use-update-token';
import { AppContent, MainContent, Root } from './style';
/**
 * Parent dashboard layout define here. And also define global hooks
 * App content wraps child components related to dashboard layout
 * @returns
 */
const DashboardLayout = () => {
  // Hook function to update refresh token and embed token
  useUpdateToken();
  //
  const [mobileOpen, setMobileOpen] = useState(false);
  // Handle open/close mobile drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent>
          <Outlet />
          <SessionTimeOutPopup />
          <TokenRevokedPopup />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};
//
export default DashboardLayout;
