import * as yup from 'yup';
/**
 * save command filter validations
 */
const saveUrlFilterValidation = yup.object().shape({
  filterName: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-Z0-9_ ]+$/,
      'Hotel set name should contain only alphanumeric characters, underscores, and spaces',
      {
        excludeEmptyString: true,
      }
    )
    .matches(
      /^[a-zA-Z0-9_]+(?:\s[a-zA-Z0-9_]+)*$/,
      'Ensure there is at most one space between words',
      {
        excludeEmptyString: true,
      }
    )
    .max(20, 'Filter name must be at most 20 characters')
    .required('Filter name is a required field'),
});
//
export default saveUrlFilterValidation;
