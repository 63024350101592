import { Button, Grid, Typography } from '@mui/material';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { rateCalenderActions } from 'modules/rate-calendar/slice';
import { selectHotelName } from 'modules/dashboard/selectors';
import { useDispatch, useSelector } from 'react-redux';
/**
 * Delete event popup is defined here
 * @param {*} param0
 * @returns
 */
const DeleteEvent = ({ deleteEventId, setDeleteSelected }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const hotel = useSelector(selectHotelName);
  //
  const onDeleteEvent = () => {
    dispatch(
      rateCalenderActions.deleteEventDetails({
        organizationId,
        hotelId: hotel.id,
        id: deleteEventId,
      })
    );
    setDeleteSelected(false);
  };
  //
  return (
    <Grid
      container
      sx={{
        backgroundColor: 'red',
        color: 'white',
        borderRadius: 5,
      }}
      alignSelf="center"
      m={4}
      p={4}
    >
      <Typography>Confirm to delete this event? </Typography>
      <div>
        <Button sx={{ color: 'white' }} onClick={() => onDeleteEvent()}>
          Yes
        </Button>
        <Button sx={{ color: 'white' }} onClick={() => setDeleteSelected(false)}>
          No
        </Button>
      </div>
    </Grid>
  );
};
//
export default DeleteEvent;
