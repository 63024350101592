/**
 * Rate range configurations are defined here
 * upper and lower limits, specified color codes for background color and text color in heat map are included
 * in a single configuration object
 */
const rateRange = [
  {
    lower: null,
    upper: 10,
    label: '10 >',
    backgroundColor: '#FFB0B2',
    textColor: '#000000',
  },
  {
    lower: 10,
    upper: 30,
    label: '10 - 30',
    backgroundColor: '#FFDFB2',
    textColor: '#000000',
  },
  {
    lower: 30,
    upper: 50,
    label: '30 - 50',
    backgroundColor: '#FFF9B4',
    textColor: '#000000',
  },
  {
    lower: 50,
    upper: 80,
    label: '50 - 80',
    backgroundColor: '#DBFFB9',
    textColor: '#000000',
  },
  {
    lower: 80,
    upper: 100,
    label: '80 - 100',
    backgroundColor: '#B6EEC0',
    textColor: '#000000',
  },
];
export default rateRange;
/**
 * Selected date color in rate calendar is defined here
 */
export const selectedDateColor = '#ebebeb';
/**
 * Heat map related color codes are defined here
 */
export const heatMapOffBackground = '#ffffff';
//
export const heatMapOnTextColor = '#000000';
