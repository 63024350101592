import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';
/**
 * Getting entire rate calendar state into the component
 */
const selectDomain = (state) => state[SLICE.RATE_CALENDAR] || initialState;
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.loading
);
/**
 * Getting the event details state into the component
 */
export const selectEvents = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.eventDetails
);
/**
 * Getting the forecast constraints details state into the component
 */
export const selectForecastDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.forecastDetails
);
/**
 * Getting the forecast rate details state into the component
 */
export const selectRateDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.rateDetails
);
/**
 * Getting the forecast rate details grouped by date state into the component
 */
export const selectGroupByRatesDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.groupRatesDetails
);
/**
 * Getting the forecast constraints details grouped by date state into the component
 */
export const selectGroupByForecastDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.groupForecastDetails
);
/**
 * Getting the events details grouped by date state into the component
 */
export const selectGroupByEventsDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.groupEventDetails
);
/**
 * Getting the hotel details state into the component
 */
export const selectHotelDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.hotelDetails
);
/**
 * Getting the room consumption details state into the component
 */
export const selectRoomConsumptionDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.roomConsumptionDetails
);
/**
 * Getting the room consumption details grouped by date state into the component
 */
export const selectGroupRoomConsumptionDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.groupRoomConsumptionDetails
);
/**
 * Getting the major event categories state into the component
 */
export const selectMajorEventCategories = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.majorEventCategories
);
/**
 * Getting the minor event categories state into the component
 */
export const selectMinorEventCategories = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.minorEventCategories
);
/**
 * Getting the previous year room consumption details state into the component
 */
export const selectPreviousYearRoomConsumptionDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.previousYearRoomConsumptionDetails
);
/**
 * Getting the previous year room consumption details grouped by date state into the component
 */
export const selectPreviousYearGroupRoomConsumptionDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.groupPreviousYearRoomConsumptionDetails
);
/**
 * Getting the previous year forecast rate details state into the component
 */
export const selectPreviousYearRateDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.previousYearRateDetails
);
/**
 * Getting the previous year forecast rate details grouped by date state into the component
 */
export const selectPreviousYearGroupByRatesDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.previousYearGroupRatesDetails
);
/**
 * Getting the previous year forecast constraints details state into the component
 */
export const selectPreviousYearForecastDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.previousYearforecastDetails
);
/**
 * Getting the previous year forecast constraints details grouped by date state into the component
 */
export const selectGroupPreviousYearForecastDetails = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.groupPreviousYearForecastDetails
);
/**
 * Getting the rate calendar selected date into the component
 */
export const selectDateSelected = createSelector([selectDomain], (rateCalenderState) =>
  rateCalenderState.selectedDate ? rateCalenderState.selectedDate : ''
);
/**
 * Getting the rate calendar previous year date into the component
 */
export const selectPreviousYearDate = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.previousYearDate
);
/**
 * Getting the rate calendar metrics for selected date into the component
 */
export const selectMetricsRows = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.metricsRows
);
/**
 * Getting the rate calendar event list into the component
 */
export const selectCalendarEventList = createSelector(
  [selectDomain],
  (rateCalenderState) => rateCalenderState.calendarEventList
);
