import Emitter from 'events';

const emitter = new Emitter();
/**
 * define event emitter functions to add, remove, emit events
 * once - add one time listener function for a event
 * on - add listener function for a event
 * off - remove listener function of a event
 * removeAllListeners - remove all event listeners
 * emit - trigger listener function of a event
 */
const EventEmitter = {
  emitter,
  eventBuffer: {},
  listeners: (event) => emitter.listeners(event),
  once: (event, fn) => emitter.once(event, fn),
  subscribe: (event, fn) => {
    emitter.on(event, fn);
    // If there are buffered events, dispatch them
    if (EventEmitter.eventBuffer[event]) {
      EventEmitter.eventBuffer[event].forEach((payload) => {
        emitter.emit(event, payload);
      });
      // Clear the buffer for this event
      EventEmitter.eventBuffer[event] = [];
    }
  },
  unsubscribe: (event, fn) => emitter.off(event, fn),
  unsubscribeAll: () => emitter.removeAllListeners(),
  dispatch: (event, payload) => {
    const listeners = emitter.listeners(event);
    if (listeners.length > 0) {
      // If there are listeners, dispatch the event immediately
      emitter.emit(event, payload);
    } else {
      // If no listeners, buffer the event
      if (!EventEmitter.eventBuffer[event]) {
        EventEmitter.eventBuffer[event] = [];
      }
      EventEmitter.eventBuffer[event].push(payload);
    }
  },
};
// Freeze event emitter object
Object.freeze(EventEmitter);
//
export default EventEmitter;
