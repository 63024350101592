/* eslint-disable react/jsx-no-constructed-context-values */

import THEMES from 'modules/common/constants/themes';
import React, { useEffect } from 'react';
/**
 * Handle the them change in the application
 */
const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: () => {},
};
//
const ThemeContext = React.createContext(initialState);
/**
 * Handle entire application theme
 * @param {*} param0
 * @returns
 */
const ThemeProvider = ({ children }) => {
  const [theme, _setTheme] = React.useState(initialState.theme);
  //
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);
  //
  const setTheme = (_theme) => {
    localStorage.setItem('theme', JSON.stringify(_theme));
    _setTheme(_theme);
  };
  //
  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};
//
export { ThemeProvider, ThemeContext };
