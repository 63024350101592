import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import { css } from '@emotion/react';
import { spacing } from '@mui/system';
import { Box, Card as MuiCard, CardContent as MuiCardContent } from '@mui/material';
import styled from '@emotion/styled';
/**
 * Styles for rate calendar are defined here
 */
export const calendarStyle = css`
  .fc-day-today {
    background: #ecf2fb !important;
    border: none !important;
  }
`;
//
export const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;
//
export const Card = styled(MuiCard)(spacing);
//
export const CardContent = styled(MuiCardContent)(spacing);
//
export const BoxIcon = styled(Box)`
  top: 214px;
  left: 2px;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  opacity: 1;
  border-radius: 50%;
`;
