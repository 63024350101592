export const trendSegmentDailyPreset = {
  rows: {
    header: [9, 10],
  },
  columns: {
    width: [25, 10, ...Array(44).fill(12)],
    end: 'AU',
  },
  table: {
    start: 9,
    end: 44,
    dataStart: 11,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendSegmentCumulativePreset = {
  rows: {
    header: [9, 10],
  },
  columns: {
    width: [25, 10, ...Array(20).fill(12)],
    end: 'W',
  },
  table: {
    start: 9,
    end: 44,
    dataStart: 11,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendSegmentPercentagePreset = {
  rows: {
    header: [8, 9],
  },
  columns: {
    width: [25, 10, ...Array(24).fill(12)],
    end: 'Z',
  },
  table: {
    start: 8,
    end: 44,
    dataStart: 10,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendSegmentWeeklyPreset = {
  rows: {
    header: [9, 10],
  },
  columns: {
    width: [25, 10, ...Array(44).fill(12)],
    end: 'AU',
  },
  table: {
    start: 9,
    end: 44,
    dataStart: 11,
  },
  totalrow: false,
  bordered: true,
};
//
export const cmatSegmentDailyPreset = {
  rows: {
    header: [9, 10],
  },
  columns: {
    width: [25, 10, ...Array(23).fill(12)],
    end: 'Y',
  },
  table: {
    start: 9,
    end: 42,
    dataStart: 11,
  },
  totalrow: false,
  bordered: true,
};
