import styled from '@emotion/styled';
import { Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';
/**
 * Styles for dashboard layout element
 */
export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;
//
export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
//
export const Paper = styled(MuiPaper)(spacing);
//
export const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
