/**
 * Reset password feature related request structures are defined here
 */
const API = {
  POST_REST_PASSWORD: {
    path: '/auth/reset-password',
    method: 'POST',
  },
};
//
export default API;
