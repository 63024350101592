/**
 * Theme related implementation added here
 */
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    cc: 744,
    md: 960,
    lg: 1280,
    xl: 1550,
    xxl: 1617,
  },
};
//
export default breakpoints;
