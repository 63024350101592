/* eslint-disable no-unneeded-ternary */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  PREFERRED_ROLE,
  PREFERRED_ADMIN_ROLE,
} from 'modules/common/constants/roles';
import {
  selectAllHotelList,
  selectAssignedHotelGroups,
  selectHotelDropdownList,
  selectHotelGroups,
  selectHotelName,
  selectIsDemoUser,
  SelectIsHotelDeleted,
  selectIsSignedIn,
  selectsHotelForecast,
  selectTabValue,
  selectUserRole,
  selectVisualFilters,
} from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { isEmpty } from 'modules/common/helpers/object';
import {
  getForecastMessage,
  getHotelGroupFocusOnValue,
  getPredefinedHotelSets,
} from '../functions';
import { selectHotelFocusOn as selectSummaryHotelFocusOn } from '../components/tab-container/summary-tab/selectors';
import { selectHotelFocusOn as selectWorkbookHotelFocusOn } from '../components/tab-container/workbook-report/selectors';
import { selectHotelFocusOn as selectPaceHotelFocusOn } from '../components/tab-container/pace-tabs/selectors';
import { selectTrendHotelFocusOn } from '../components/tab-container/trend-tabs/selectors';
import { selectWebClickHotelFocusOn } from '../components/tab-container/web-click-tab/selectors';
import { CURRENCY_TYPES, TAB_VALUE } from '../constants';
import { getCurrencySchema } from '../components/tab-container/inner-filter/functions';
import { paceActions } from '../components/tab-container/pace-tabs/slice';
import { trendActions } from '../components/tab-container/trend-tabs/slice';
import { ENVIRONMENT } from '../../../config';
/**
 * Custom hook to set tab accessibility based on user role
 */
const UseTabContainer = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const hotelList = useSelector(selectHotelDropdownList);
  const hotelGroups = useSelector(selectHotelGroups);
  const selectedHotelName = useSelector(selectHotelName);
  const assignedHotelGroups = useSelector(selectAssignedHotelGroups);
  const allHotelList = useSelector(selectAllHotelList);
  const isDemoUser = useSelector(selectIsDemoUser);
  const summaryHotelFocusOn = useSelector(selectSummaryHotelFocusOn);
  const workbookHotelFocusOn = useSelector(selectWorkbookHotelFocusOn);
  const trendHotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const paceHotelFocusOn = useSelector(selectPaceHotelFocusOn);
  const webClicksHotelFocusOn = useSelector(selectWebClickHotelFocusOn);
  const tabValue = useSelector(selectTabValue);
  const visualFilters = useSelector(selectVisualFilters);
  const isHotelForecast = useSelector(selectsHotelForecast);
  const isSignIn = useSelector(selectIsSignedIn);
  const isHotelDeleted = useSelector(SelectIsHotelDeleted);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  //
  useEffect(() => {
    // Set iPrefer access true for admin, super admin and preferred user roles
    dispatch(
      dashboardActions.setTabAccess({
        isIPrefer:
          userRole === PREFERRED_ADMIN_ROLE ||
          userRole === PREFERRED_ROLE ||
          userRole === ADMIN_ROLE ||
          userRole === SUPER_ADMIN_ROLE,
      })
    );
  }, []);
  // check whether hotels in hotel set have same local currency
  const allEqual = (arr) => arr.every((val) => val?.localCurrency === arr[0]?.localCurrency);

  // Function to get the common currency of a selected hotel or hotel set
  const getCommonCurrency = (selectedHotel) => {
    if (selectedHotel && selectedHotel?.hotels) {
      // Hotel set
      if (allEqual(selectedHotel?.hotels)) {
        return selectedHotel?.hotels[0]?.localCurrency;
      }
      return null;
    }
    // Single hotel
    return selectedHotel?.localCurrency;
  };

  // Function to check isforecastAvailable for hotels or hotel sets
  const checkForecastAvailability = (item) => {
    if (item.hotels) {
      return item.hotels.every((hotel) => hotel.isforecastAvailable);
    }
    return item.isforecastAvailable;
  };

  // Determin tabwise forecast toggle value based on hotel forecast availability
  useEffect(() => {
    if (!isEmpty(selectedHotelName) && isHotelForecast != null) {
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          dispatch(dashboardActions.setSummaryForecastFilter(isHotelForecast ? true : false));
          break;
        case TAB_VALUE.TREND_TIME:
          dispatch(trendActions.setIsTrendForecast(false));
          break;
        case TAB_VALUE.PACE:
          dispatch(paceActions.setIsPaceForecast(false));
          break;
        default:
          break;
      }
    }
  }, [isHotelForecast, selectedHotelName]);

  // Determine the local currency value and currency list on tab, primary or secondary hotel change
  useEffect(() => {
    let selectedTabFocusOn;
    if (
      selectedHotelName &&
      summaryHotelFocusOn &&
      workbookHotelFocusOn &&
      paceHotelFocusOn &&
      trendHotelFocusOn &&
      webClicksHotelFocusOn
    ) {
      //
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          selectedTabFocusOn = summaryHotelFocusOn;
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          selectedTabFocusOn = workbookHotelFocusOn;
          break;
        case TAB_VALUE.TREND_TIME:
          selectedTabFocusOn = trendHotelFocusOn;
          break;
        case TAB_VALUE.WEB_CLICK:
          selectedTabFocusOn = webClicksHotelFocusOn;
          break;
        case TAB_VALUE.PACE:
          selectedTabFocusOn = paceHotelFocusOn;
          break;
        default:
          break;
      }
    }
    const primaryCurrency = selectedHotelName ? getCommonCurrency(selectedHotelName) : null;
    //
    if (tabValue === TAB_VALUE.REGION_MAP) {
      if (primaryCurrency && primaryCurrency !== CURRENCY_TYPES.USD) {
        dashboardActions.setCurrencyFilter(getCurrencySchema(visualFilters, CURRENCY_TYPES.RC));
        dispatch(dashboardActions.setLocalCurrency(primaryCurrency));
        dispatch(dashboardActions.setLocalCurrencyList(primaryCurrency));
      } else {
        dispatch(dashboardActions.removeOtherLocalCurrenciesFromList());
      }
    } else {
      const secondaryCurrency = selectedTabFocusOn ? getCommonCurrency(selectedTabFocusOn) : null;
      if (
        primaryCurrency &&
        secondaryCurrency &&
        primaryCurrency === secondaryCurrency &&
        primaryCurrency !== CURRENCY_TYPES.USD
      ) {
        dispatch(
          dashboardActions.setCurrencyFilter(getCurrencySchema(visualFilters, CURRENCY_TYPES.RC))
        );
        dispatch(dashboardActions.setLocalCurrency(primaryCurrency));
        dispatch(dashboardActions.setLocalCurrencyList(primaryCurrency));
      } else {
        dispatch(dashboardActions.removeOtherLocalCurrenciesFromList());
        dispatch(
          dashboardActions.setCurrencyFilter(getCurrencySchema(visualFilters, CURRENCY_TYPES.USD))
        );
      }
      if (!isEmpty(selectedHotelName)) {
        const allForecastsAvailable = checkForecastAvailability(selectedHotelName);
        dispatch(
          dashboardActions.setHotelForecastDisabledText(getForecastMessage(selectedHotelName))
        );
        dispatch(dashboardActions.setIsHotelForecast(allForecastsAvailable));
      }
    }
  }, [
    selectedHotelName,
    tabValue,
    summaryHotelFocusOn,
    workbookHotelFocusOn,
    paceHotelFocusOn,
    trendHotelFocusOn,
    webClicksHotelFocusOn,
  ]);
  //
  useEffect(() => {
    if (
      allHotelList?.length > 0 &&
      hotelList?.length > 0 &&
      !isEmpty(hotelGroups) &&
      assignedHotelGroups !== null
    ) {
      const hotelGroup = getHotelGroupFocusOnValue(hotelGroups, hotelList);
      const assignedHotelSets = getPredefinedHotelSets(
        assignedHotelGroups,
        allHotelList,
        isDemoUser
      );

      const mergedArray = [
        ...(hotelList ?? []),
        ...(hotelGroup?.hotelGroupDropdownList ?? []),
        ...(assignedHotelSets ?? []),
      ];
      const sortedByLabel = mergedArray?.sort((a, b) => a?.label?.localeCompare(b?.label));
      const sortedByPredefined =
        [...sortedByLabel]?.sort((a, b) => {
          if (a.isPredefined === b.isPredefined) {
            return 0; // If both items have the same predefined status, they are considered equal
          }
          if (a.isPredefined) {
            return -1; // If a is predefined and b is not, a should come before b
          }
          return 1; // If b is predefined and a is not, b should come before a
        }) ?? [];
      const sortedByFavorite =
        [...sortedByPredefined]?.sort((a, b) => {
          if (a.isFavorite === b.isFavorite) {
            return 0; // If both items have the same favorite status, they are considered equal
          }
          if (a.isFavorite) {
            return -1; // If a is favorite and b is not, a should come before b
          }
          return 1; // If b is favorite and a is not, b should come before a
        }) ?? [];
      if (!selectedHotelName) {
        dispatch(dashboardActions.setHotelName(sortedByFavorite[0]));
      }
      dispatch(dashboardActions.setPredefinedHotelList(assignedHotelSets));
      dispatch(dashboardActions.setHotelGroupDropdownList(hotelGroup?.hotelGroupDropdownList));
      dispatch(dashboardActions.setMergedHotelList(sortedByFavorite));
      if (isHotelDeleted) {
        dispatch(
          dashboardActions.setSelectedHotelGroup({ ...hotelGroup, hotelList: sortedByFavorite })
        );
        dispatch(dashboardActions.setIsHotelDeleted(false));
      }
      if (!isSignIn && serializedState && !isEmpty(selectedHotelName)) {
        return;
      }
      dispatch(
        dashboardActions.setSelectedHotelGroup({ ...hotelGroup, hotelList: sortedByFavorite })
      );
    }
  }, [
    hotelList,
    hotelGroups,
    assignedHotelGroups,
    allHotelList,
    isDemoUser,
    isSignIn,
    isHotelDeleted,
  ]);
};
//
export default UseTabContainer;
