import { Grid, Toolbar } from '@mui/material';
import CollapseIcon from 'modules/common/components/collapse-icon';
import { getVisualName } from 'modules/dashboard/functions';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { InformationPopper } from 'modules/common/components';
import { selectHotelName } from 'modules/dashboard/selectors';
import { useSelector } from 'react-redux';

/**
 * Visual Grid component for visual embedding iframe
 * @param {Boolean} isDisplay - To determine the display state of the visual
 * @param {Number} order - The order of the embedding visual
 * @param {Array} visualsList - Visual list of current tab
 * @param {Object} visual - Embedded visual
 * @param {Function} onClick - Action to trigger on click event
 * @param {Boolean} extendedForecast - To determine the state to show/ hide extended forecast toggle
 * @param {Component} extendedForecastFragment - Extended Forecast grid component
 * @returns
 */
const VisualGrid = ({
  isDisplay,
  order = null,
  visualsList = [],
  visual,
  onClick,
  information = null,
  toggleFragment,
  visualName = null,
  style = styles.paceVisualGrid,
  isWorkbookHotelComparison = false,
  comparisonHotel = {},
  displayHotelName = false,
}) => {
  const selectedHotelName = useSelector(selectHotelName);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={styles.mainGrid}
      mb={2}
    >
      <Grid className={style}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height={40}
        >
          <Grid item pl={2}>
            {visualName ?? getVisualName(visualsList, order)}
          </Grid>
          <Grid item>
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item mt={2}>
                {toggleFragment}
              </Grid>
              {information && (
                <Grid item mt={1} ml={4}>
                  <InformationPopper information={information} placement="bottom-end" />
                </Grid>
              )}
              <CollapseIcon isDisplay={isDisplay} onClick={onClick} />
            </Grid>
          </Grid>
        </Grid>
        {displayHotelName && isDisplay && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height={isWorkbookHotelComparison ? 40 : 20}
            paddingLeft={4}
            sx={{ backgroundColor: 'white', fontWeight: 'bold' }}
          >
            {isWorkbookHotelComparison ? (
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                  {`Selection Hotel ${
                    selectedHotelName && 'hotels' in selectedHotelName ? 'Set' : ''
                  } (A) : ${selectedHotelName?.label}`}
                  <br />
                  {`Comparison Hotel ${
                    comparisonHotel && 'hotels' in comparisonHotel ? 'Set' : ''
                  } (B) : ${comparisonHotel?.label}`}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                {`Hotel ${selectedHotelName && 'hotels' in selectedHotelName ? 'Set' : ''} : ${
                  selectedHotelName?.label
                }`}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {isDisplay &&
        (style !== styles.paceVisualGrid ? (
          <Grid container sx={{ mt: displayHotelName ? 14 : 9.25 }}>
            {visual}
          </Grid>
        ) : (
          <Grid>{visual}</Grid>
        ))}
      {!isDisplay && <Toolbar />}
    </Grid>
  );
};

export default VisualGrid;
