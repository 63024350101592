import { Grid } from '@mui/material';
import { Formik } from 'formik';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'modules/common/helpers/object';
import ERROR_TYPES from 'modules/common/constants/error-types';
import ActionButton from 'modules/common/components/action-button';
import {
  Alert,
  TextField,
} from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/style';
import { LABELS } from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/constants';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import {
  selectSegmentSetAQuery,
  selectSegmentSetBQuery,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import replaceField from 'modules/dashboard/utils/query-modifier';
import dataHandler from '../../../../../querying-module/dataHandler';

/** Implementation to categorize Group A and Group B based on user input query
 * @param {Array} breakdownSubCategories - List of breakdown sub categories
 * @param {Function} setGroup1 - Function to set Group A values
 * @param {Function} setGroup2 - Function to set Group B values
 * @param {Function} onClose - Function to trigger on close event
 * @param {Boolean} open - To open/ close dialog box
 * @param {Boolean} isQueryEnabled - To enable/disable advanced querying feature
 * @param {Boolean} loadFilter - To load saved filter
 * @param {Function} setLoadFilter  - Function to set load filter true when the saved filter is clicked to be loaded
 * @returns
 */

const QueryBox = ({
  breakdownSubCategories,
  setGroup1,
  setGroup2,
  open,
  isQueryEnabled,
  loadFilter,
  setLoadFilter,
}) => {
  const dispatch = useDispatch();

  // setA Query and set B Query values are selected from store here. Refer each selector function for details
  const segmentSetAQuery = useSelector(selectSegmentSetAQuery);
  const segmentSetBQuery = useSelector(selectSegmentSetBQuery);
  //
  const initialValues = {
    setA: '',
    setB: '',
  };
  const [aNotification, setANotification] = useState({ type: '', message: '' });
  const [bNotification, setBNotification] = useState({ type: '', message: '' });

  // To preview values for Group A and B based on the input query
  const previewValues = (setAQuery = segmentSetAQuery, setBQuery = segmentSetBQuery) => {
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
    let setA = [];
    setGroup1([]);
    setGroup2([]);
    //
    if (setAQuery) {
      try {
        setA = dataHandler(replaceField(segmentSetAQuery), breakdownSubCategories);
        if (!isEmpty(setA)) {
          setGroup1(setA);
          setANotification({ type: '', message: '' });
        } else {
          setANotification({ type: 'error', message: 'No match found for Group A' });
        }
      } catch (e) {
        setANotification({ type: 'error', message: e.message });
      }
    } else {
      setGroup1([]);
      setANotification({ type: '', message: '' });
    }
    //
    if (setBQuery) {
      try {
        const setB = dataHandler(replaceField(segmentSetBQuery), breakdownSubCategories);
        let notInSetA = [];
        // remove the values available in Group A from Group B
        if (!isEmpty(setB)) {
          if (!isEmpty(setA))
            notInSetA = setB.filter((itemB) => !setA.some((itemA) => itemA.id === itemB.id));
          else notInSetA = setB;
        }
        if (!isEmpty(notInSetA)) {
          setGroup2(notInSetA);
          setBNotification({ type: '', message: '' });
        } else {
          setBNotification({ type: 'error', message: 'No match found for Group B' });
        }
      } catch (e) {
        setBNotification({ type: 'error', message: e.message });
      }
    } else {
      setGroup2([]);
      setBNotification({ type: '', message: '' });
    }
    setLoadFilter(false);
  };

  // Triggered when load filter value changes
  useEffect(() => {
    // Call preview values function if loadFilter is  true to display selected filter values in transfer list
    if (loadFilter) previewValues();
  }, [loadFilter]);
  //
  return (
    open && (
      <Formik initialValues={initialValues} onSubmit={() => previewValues()} enableReinitialize>
        {({ errors, handleBlur, handleSubmit, setFieldValue, touched, resetForm }) => (
          <form noValidate onSubmit={handleSubmit}>
            {aNotification?.type === ERROR_TYPES.ERROR && aNotification?.message && (
              <Alert mt={2} mb={3} severity={aNotification?.type}>
                {aNotification?.message}
              </Alert>
            )}
            <TextField
              multiline
              name="setA"
              value={segmentSetAQuery}
              error={Boolean(touched.setA && errors.setA)}
              helperText={touched.setA && errors.setA}
              fullWidth
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('setA', event.target.value);
                dispatch(trendActions.setSegmentSetAQuery(event.target.value));
              }}
              my={2}
              label={LABELS.GROUP_A}
              size="small"
              disabled={!isQueryEnabled}
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: 2,
                  height: 'fit-content',
                },
              }}
            />
            {bNotification?.type === ERROR_TYPES.ERROR && bNotification?.message && (
              <Alert mt={2} mb={3} severity={bNotification?.type}>
                {bNotification?.message}
              </Alert>
            )}
            <TextField
              multiline
              name="setB"
              value={segmentSetBQuery}
              error={Boolean(touched.setB && errors.setB)}
              helperText={touched.setB && errors.setB}
              fullWidth
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('setB', event.target.value);
                dispatch(trendActions.setSegmentSetBQuery(event.target.value));
              }}
              my={2}
              label={LABELS.GROUP_B}
              size="small"
              disabled={!isQueryEnabled}
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: 2,
                  height: 'fit-content',
                },
              }}
            />
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item mx={3}>
                <ActionButton
                  type="reset"
                  label="Clear"
                  onClick={() => {
                    resetForm();
                    dispatch(trendActions.setSegmentSetAQuery(''));
                    dispatch(trendActions.setSegmentSetBQuery(''));
                  }}
                  disabled={!isQueryEnabled}
                />
              </Grid>
              <Grid item>
                <ActionButton type="submit" label="Preview" disabled={!isQueryEnabled} />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    )
  );
};
//
export default QueryBox;
