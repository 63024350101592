import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  selectEnableHotelGroups,
  selectReportType,
  selectTabValue,
} from 'modules/dashboard/selectors';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectIsSegmentDetailed,
  selectSegmentGroup1,
  selectSegmentGroup2,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectActiveTrendTab,
  selectTabularView,
  selectAdjustedStartDate,
  selectAdjustedEndDate,
  selectGranularity,
  selectMovingAverage,
  selectIsMovingAverage,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import {
  getBookingDateSchema,
  getGranularitySchema,
  getGroup1And2Schema,
  getHotelIdSchema,
  getMovingAverageSchema,
  getPmsSyncToggleSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { REPORT_TYPE, TAB_VALUE } from 'modules/dashboard/constants';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import { getTSBreakdownAndFocusOnSchema } from '../functions';
import { GRANULARITY } from '../constants';
/**
 * Custom hook to generate Trend segment widget filter schemas
 */
const useTestTrendSegment = () => {
  const dispatch = useDispatch();
  //
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const targetStartDate = useSelector(selectWeeklyTrendStartDate);
  const targetEndDate = useSelector(selectWeeklyTrendEndDate);
  const isCumulative = useSelector(selectIsCumulative);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const segmentGroup1 = useSelector(selectSegmentGroup1);
  const segmentGroup2 = useSelector(selectSegmentGroup2);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const activeTab = useSelector(selectActiveTrendTab);
  const reportType = useSelector(selectReportType);
  const isTabular = useSelector(selectTabularView);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverage = useSelector(selectMovingAverage);
  const tabValue = useSelector(selectTabValue);
  //
  useEffect(() => {
    if (
      tabValue !== TAB_VALUE.TEST_TREND_TIME ||
      (!chartBreakdown && !targetDate && !hotelId && !bookingDate)
    ) {
      return;
    }
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );

    let startDate;
    let endDate;
    if (isTabular) {
      if (weeklyViewToggle) {
        startDate = targetStartDate;
        endDate = targetEndDate;
      } else if (granularity === GRANULARITY.DAILY) {
        startDate = targetDate?.startDate;
        endDate = targetDate?.endDate;
      } else {
        startDate = adjustedStartDate;
        endDate = adjustedEndDate;
      }
    } else if (isCumulative) {
      startDate = targetDate?.startDate;
      endDate = targetDate?.endDate;
    } else {
      startDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      endDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, startDate, endDate);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const segmentAndFocusOnFilter = getTSBreakdownAndFocusOnSchema(
      visualFilters,
      chartBreakdown,
      isDetailed,
      focusOn,
      segmentGroup1,
      segmentGroup2
    );
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const group1And2Filter = getGroup1And2Schema(
      visualFilters,
      isDetailed,
      segmentGroup1,
      segmentGroup2
    );
    const granularityFilter = getGranularitySchema(visualFilters, granularity);
    const movingAverageFilter = getMovingAverageSchema(visualFilters, movingAverage);
    //
    if (
      bookingDateFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      segmentAndFocusOnFilter &&
      group1And2Filter
    ) {
      let filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        targetDateFilter,
        hotelIdFilter,
        ...segmentAndFocusOnFilter,
        ...group1And2Filter,
      ];
      if (isMovingAverage) {
        filters = [...filters, movingAverageFilter];
      }
      if (activeTab === REPORT_TYPE.TREND_SEGMENT) {
        writeLog('V1 Trend Segment Filters', filters);
        storeVisualMapper(VISUAL_MAPPER.trendSegmentFilterList);
      }
      dispatch(trendActions.updateTestTrendSegmentFilterList(filters));

      //  tabular filter list
      let tabularFilters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        targetDateFilter,
        hotelIdFilter,
        ...segmentAndFocusOnFilter,
        ...group1And2Filter,
        movingAverageFilter,
      ];
      if (!weeklyViewToggle) {
        tabularFilters = [...tabularFilters, granularityFilter];
      }
      if (activeTab === REPORT_TYPE.TREND_SEGMENT) {
        writeLog('V1 Trend Segment Tabular Filters', tabularFilters);
        storeVisualMapper(VISUAL_MAPPER.trendSegmentTabularFilterList);
      }
      dispatch(trendActions.updateTestTrendSegmentTabularFilterList(tabularFilters));
    }
  }, [
    isDetailed,
    focusOn,
    chartBreakdown,
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    pmsSyncToggle,
    weeklyViewToggle,
    targetStartDate,
    targetEndDate,
    segmentGroup1,
    segmentGroup2,
    isCumulative,
    enableHotelGroups,
    reportType,
    activeTab,
    granularity,
    adjustedStartDate,
    adjustedEndDate,
    isTabular,
    isMovingAverage,
    movingAverage,
    tabValue,
  ]);
};
//
export default useTestTrendSegment;
