import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { X, Trash } from 'react-feather';
import { Tooltip, Box } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { selectHotelId } from 'modules/event-configuration/selectors';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { Modal } from 'modules/common/components';
/**
 * Editable Table component to list, edit, delete minor event categories
 * @param {*} props
 * @param {*} props.minorEventCategories - minor event categories list
 * @param {*} props.majorEventCategoryId - major event category id
 * @param {*} props.rowModesModel - row object model state
 * @param {*} props.setRowModesModel - function to set row object model state
 * @param {*} props.isEditable - state of row data editable or not
 * @param {*} props.isDeletable - state of row data deletable or not
 *
 */
const MinorCategoriesEditingGrid = (props) => {
  // destruct the props object
  const {
    minorEventCategories,
    majorEventCategoryId,
    rowModesModel,
    setRowModesModel,
    editedModel,
    setEditedModel,
    isEditable,
    isDeletable,
  } = props;
  //
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const hotelId = useSelector(selectHotelId);
  //
  const [deleteID, setDeleteID] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rows, setRows] = useState([]);
  // set values to edit model
  const handleEdit = (editedRow) => {
    setEditedModel({
      ...editedModel,
      [editedRow?.id]: {
        name: editedRow?.name,
        applicableHotels: editedRow?.applicableHotels,
      },
    });
  };
  // triggers when minorEventCategories and hotel id states change
  useEffect(() => {
    setRows([]);
    // set rows with minor category details
    if (minorEventCategories) {
      const filterMinorEventCategories = minorEventCategories?.results?.filter((filter) =>
        filter?.applicableHotels.includes(hotelId)
      );
      filterMinorEventCategories?.forEach((minorEvent) => {
        const transformMinorEventCategory = {};
        transformMinorEventCategory.id = minorEvent?.id;
        transformMinorEventCategory.name = minorEvent?.name;
        transformMinorEventCategory.applicableHotels = minorEvent?.applicableHotels;
        setRows((prevRows) => [...prevRows, transformMinorEventCategory]);
      });
    }
  }, [minorEventCategories, hotelId]);
  // delete minor categories and close delete modal
  const handleDelete = () => {
    dispatch(
      configureEventCategoriesActions.deleteMinorEventCategories({
        organizationId,
        majorEventCategoryId,
        minorEventCategoryId: deleteID,
      })
    );
    setDeleteID(null);
    setOpenDeleteModal(false);
  };
  // handle cancel event , set row mode into view
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    });
  };
  // define column configurations
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: isEditable,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      hide: !isDeletable,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <X />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <Trash size={14} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              setDeleteID(id);
              setOpenDeleteModal(true);
            }}
            color="error"
          />,
        ];
      },
    },
  ];
  //
  return (
    <>
      <Box sx={{ height: 500 }} px={4} my={4}>
        <DataGrid
          rows={rows}
          sx={{
            '& .super-app-theme--header': {
              backgroundColor: 'white',
              color: TABLE_HEADER_FONT_COLOR,
            },
            '& .MuiDataGrid-main': {
              backgroundColor: 'white',
            },
            '.MuiDataGrid-footerContainer': {
              backgroundColor: 'white',
            },
          }}
          columns={columns}
          initialState={{
            pagination: {
              pageSize: 10,
            },
          }}
          rowsPerPageOptions={[5, 10, 20]}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          processRowUpdate={handleEdit}
          editMode="row"
          experimentalFeatures={{
            newEditingApi: true,
          }}
          onProcessRowUpdateError={() => {}}
        />
      </Box>
      <Modal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title="Delete Minor Event Category"
        content="Are you sure you want to delete the minor event category?"
        handleSuccess={handleDelete}
        closeLabel="Cancel"
        successLabel="Delete"
        variant="contained"
        color="error"
      />
    </>
  );
};
//
export default MinorCategoriesEditingGrid;
