import { Grid, Paper, Typography } from '@mui/material';

const OutlinedDiv = ({ children, label }) => (
  <Paper variant="outlined" sx={{ p: 4, my: 4 }}>
    <Grid container display="flex">
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Grid>
  </Paper>
);
//
export default OutlinedDiv;
