import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  startOfMonth,
  subMonths,
  endOfMonth,
  previousSunday,
  isSunday,
  isSaturday,
  nextSaturday,
} from 'date-fns';
import { BOOKING_DATE, TAGS, SLICE, ALIGNED } from 'modules/dashboard/constants';
import { CLICK_STEP_LIST } from 'modules/dashboard/components/tab-container/web-click-tab/constants';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { getHotelFocusOnValue } from 'modules/dashboard/functions';
import { isEmpty } from 'modules/common/helpers/object';
/**
 * Initial states of  Web Click widget functions are defined here
 */
const trendStayDate = {
  startDate: new Date(startOfMonth(subMonths(new Date(), 1))).toISOString(),
  endDate: new Date(endOfMonth(subMonths(new Date(), 1))).toISOString(),
  key: 'selection',
};
const weeklyTrendStayDate = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};
if (isSunday(new Date(trendStayDate.startDate))) {
  weeklyTrendStayDate.startDate = new Date(trendStayDate.startDate).toISOString();
} else {
  weeklyTrendStayDate.startDate = previousSunday(new Date(trendStayDate.startDate)).toISOString();
}
if (isSaturday(new Date(trendStayDate.endDate))) {
  weeklyTrendStayDate.endDate = new Date(trendStayDate.endDate).toISOString();
} else {
  weeklyTrendStayDate.endDate = nextSaturday(new Date(trendStayDate.endDate)).toISOString();
}
export const initialState = {
  webClickBookingDate: {
    startDate: new Date(BOOKING_DATE).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  webClickOtb: false,
  webClickAlignmentToggle: null,
  webClickAlignment: null,
  webClickDateComparison: null,
  webClickSymmetricComparisonToggle: true,
  webClickTargetDate: trendStayDate,
  webClickPaceHorizon: null,
  webClickNormalizedView: true,
  webClickStartDate: trendStayDate.startDate,
  webClickEndDate: trendStayDate.endDate,
  webClickDateSelected: trendStayDate.startDate,
  webClickCustomDate: 'start',
  webClickChartBreakdown: null,
  webClickFocusOn: [],
  webClickHotelFocusOn: {},
  webClickEnableSecondaryHotelGroups: false,
  webClickScalingFactor: 1,
  webClickStep: [CLICK_STEP_LIST[0]],
  webClickTimePaceVisualFilter: [],
  webClickHotelPaceVisualFilter: [],
  webClickSegmentPaceVisualFilter: [],
  webClickHotelVisualFilter: [],
  webClickTimeVisualFilter: [],
  webClickSegmentVisualFilter: [],
  webClickWeeklyView: false,
  webClickCumulativeView: false,
  weeklyWebClickStartDate: weeklyTrendStayDate.startDate,
  weeklyWebClickEndDate: weeklyTrendStayDate.endDate,
  selectedUtmBreakdown: null,
  utmFocusOn: [],
  utmFilterList: [],
  webClickUrl: '',
  timeTrendIsDisplay: true,
  timePaceIsDisplay: false,
  segmentTrendIsDisplay: true,
  segmentPaceIsDisplay: false,
  hotelTrendIsDisplay: true,
  hotelPaceIsDisplay: false,
  utmTrendIsDisplay: true,
  utmPaceIsDisplay: true,
  symmetricComparisonIsDisplay: false,
};
/**
 * All actions related to Web Click widget feature are defined here
 */
export const webClickSlice = createSlice({
  name: SLICE.WEB_CLICK,
  initialState,
  reducers: {
    setWebClickOtb(state, action) {
      state.webClickOtb = action?.payload;
    },
    setWebClickBookingDate(state, action) {
      state.webClickBookingDate = action?.payload;
    },
    setWebClickAlignmentToggle(state, action) {
      state.webClickAlignmentToggle = action?.payload;
    },
    setWebClickAlignment(state, action) {
      state.webClickAlignment = action?.payload;
    },
    setWebClickDateComparison(state, action) {
      state.webClickDateComparison = action?.payload;
    },
    setWebClickSymmetricComparisonToggle(state, action) {
      state.webClickSymmetricComparisonToggle = action?.payload;
    },
    setWebClickTargetDate(state, action) {
      state.webClickTargetDate = action?.payload;
    },
    setWebClickPaceHorizon(state, action) {
      state.webClickPaceHorizon = action?.payload;
    },
    setWebClickNormalizedView(state, action) {
      state.webClickNormalizedView = action?.payload;
    },
    setWebClickStartDate(state, action) {
      state.webClickStartDate = action?.payload;
    },
    setWebClickEndDate(state, action) {
      state.webClickEndDate = action?.payload;
    },
    setWebClickDateSelected(state, action) {
      state.webClickDateSelected = action?.payload;
    },
    setWebClickCustomDate(state, action) {
      state.webClickCustomDate = action?.payload;
    },
    setWebClickBreakdown(state, action) {
      state.webClickChartBreakdown = action?.payload;
    },
    setWebClickBreakdownFocusOn(state, action) {
      state.webClickFocusOn = action?.payload;
    },
    setWebClickHotelFocusOn(state, action) {
      state.webClickHotelFocusOn = action?.payload;
      state.webClickEnableSecondaryHotelGroups = action?.payload && 'hotels' in action.payload;
    },
    setWebClickScalingFactor(state, action) {
      state.webClickScalingFactor = action?.payload;
    },
    setWebClickStep(state, action) {
      state.webClickStep = action?.payload;
    },
    setWebClickTimePaceVisualFilter(state, action) {
      state.webClickTimePaceVisualFilter = action?.payload;
    },
    setWebClickHotelPaceVisualFilter(state, action) {
      state.webClickHotelPaceVisualFilter = action?.payload;
    },
    setWebClickSegmentPaceVisualFilter(state, action) {
      state.webClickSegmentPaceVisualFilter = action?.payload;
    },
    setWebClickHotelVisualFilter(state, action) {
      state.webClickHotelVisualFilter = action?.payload;
    },
    setWebClickTimeVisualFilter(state, action) {
      state.webClickTimeVisualFilter = action?.payload;
    },
    setWebClickSegmentVisualFilter(state, action) {
      state.webClickSegmentVisualFilter = action?.payload;
    },
    setWebClickWeeklyView(state, action) {
      state.webClickWeeklyView = action?.payload;
    },
    setWebClickCumulativeView(state, action) {
      state.webClickCumulativeView = action?.payload;
    },
    setWeeklyWebClickStartDate(state, action) {
      state.weeklyWebClickStartDate = action?.payload;
    },
    setWeeklyWebClickEndDate(state, action) {
      state.weeklyWebClickEndDate = action?.payload;
    },
    setSelectedUtmBreakdown(state, action) {
      state.selectedUtmBreakdown = action?.payload;
    },
    setUtmFocusOn(state, action) {
      state.utmFocusOn = action?.payload;
    },
    setUtmFilterList(state, action) {
      state.utmFilterList = action?.payload;
    },
    setWebClickUrl(state, action) {
      state.webClickUrl = action.payload;
    },
    setTimeTrendIsDisplay(state, action) {
      state.timeTrendIsDisplay = action.payload;
    },
    setTimePaceIsDisplay(state, action) {
      state.timePaceIsDisplay = action.payload;
    },
    setSegmentTrendIsDisplay(state, action) {
      state.segmentTrendIsDisplay = action.payload;
    },
    setSegmentPaceIsDisplay(state, action) {
      state.segmentPaceIsDisplay = action.payload;
    },
    setHotelTrendIsDisplay(state, action) {
      state.hotelTrendIsDisplay = action.payload;
    },
    setHotelPaceIsDisplay(state, action) {
      state.hotelPaceIsDisplay = action.payload;
    },
    setUtmTrendIsDisplay(state, action) {
      state.utmTrendIsDisplay = action.payload;
    },
    setUtmPaceIsDisplay(state, action) {
      state.utmPaceIsDisplay = action.payload;
    },
    setSymmetricComparisonIsDisplay(state, action) {
      state.symmetricComparisonIsDisplay = action?.payload;
    },
    setEnableSecondaryHotelGroups(state, action) {
      state.webClickEnableSecondaryHotelGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        );
        return {
          ...state,
          webClickAlignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          webClickAlignmentToggle: dateComparisonFilter?.type === ALIGNED,
          webClickDateComparison: dateComparisonFilter?.values?.find(
            (filter) => filter.isDefault === true
          )?.value,
          webClickPaceHorizon: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
          )?.id,
          webClickChartBreakdown: action?.payload?.results?.find(
            (filter) =>
              filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN && TAGS.WEB_CLICK) &&
              filter?.isDefault === true
          )?.id,
          selectedUtmBreakdown: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.UTM_BREAKDOWN) && filter?.isDefault === true
          )?.id,
        };
      })
      .addCase(dashboardActions.getHotelListSucceeded, (state, action) => {
        const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
        return {
          ...state,
          webClickHotelFocusOn: hotel?.focusOnValues?.[0],
        };
      })
      .addCase(dashboardActions.setHotelName, (state, action) => ({
        ...state,
        webClickHotelFocusOn: action?.payload,
        webClickNormalizedView: false,
        webClickEnableSecondaryHotelGroups: action?.payload && 'hotels' in action.payload,
      }))
      .addCase(dashboardActions.setEnableHotelGroups, (state, action) => ({
        ...state,
        webClickEnableSecondaryHotelGroups: action?.payload,
        webClickNormalizedView: false,
      }))
      .addCase(dashboardActions.setSelectedHotelGroup, (state, action) => {
        let secondaryHotelSet;
        if (state && state.webClickHotelFocusOn && 'hotels' in state.webClickHotelFocusOn) {
          if (!isEmpty(state.hotelName) && action?.payload?.hotelGroupDropdownList?.length === 0) {
            secondaryHotelSet = action?.payload?.hotelList[0];
          } else if (
            state.webClickHotelFocusOn &&
            action?.payload?.hotelGroupDropdownList?.length > 0
          ) {
            const newGroup = action?.payload?.hotelGroupDropdownList?.find(
              (a) => a?.id === state.webClickHotelFocusOn?.id
            );
            if (newGroup) {
              secondaryHotelSet = newGroup;
            } else {
              secondaryHotelSet = action?.payload?.hotelList[0];
            }
          } else {
            secondaryHotelSet = action?.payload?.hotelList[0];
          }
          return {
            ...state,
            webClickHotelFocusOn: secondaryHotelSet,
            webClickEnableSecondaryHotelGroups: secondaryHotelSet && 'hotels' in secondaryHotelSet,
          };
        }
        return { ...state };
      });
  },
});
//
export const { actions: webClickActions } = webClickSlice;
