/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { configureEventCategoriesActions } from './slice';
import API from './constants';
/**
 * Event category configuration generator function to get major event categories
 * @param {*} payload
 */
export function* eventCategoryConfigurationGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_EVENT_CATEGORIES, payload);
    yield put(configureEventCategoriesActions.configureEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(configureEventCategoriesActions.configureEventCategoriesFailed(error?.message));
  }
}
/**
 * Add minor event category configuration generator function to add minor event categories
 * @param {*} payload
 */
export function* addMinorEventCategoryConfigurationGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_MINOR_EVENT_CATEGORIES, payload);
    yield put(configureEventCategoriesActions.addMinorEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(configureEventCategoriesActions.addMinorEventCategoriesFailed(error?.message));
  }
}
/**
 * Get Minor event category configuration generator function to get minor event categories
 * @param {*} payload
 */
export function* getMinorEventCategoryConfigurationGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_MINOR_EVENT_CATEGORIES, payload);
    yield put(configureEventCategoriesActions.getMinorEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(configureEventCategoriesActions.getMinorEventCategoriesFailed(error?.message));
  }
}
/**
 * Update minor event categories configuration generator function to update multiple minor event categories
 * @param {*} payload
 */
export function* updateMinorEventCategoriesConfigurationGenerator({ payload }) {
  try {
    const response = yield call(request, API.UPDATE_MINOR_EVENT_CATEGORIES, payload);
    yield put(configureEventCategoriesActions.updateMinorEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(configureEventCategoriesActions.updateMinorEventCategoriesFailed(error?.message));
  }
}
/**
 * Delete minor event category configuration generator function to delete minor event categories
 * @param {*} payload
 */
export function* deleteMinorEventCategoryConfigurationGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_MINOR_EVENT_CATEGORIES, payload);
    yield put(configureEventCategoriesActions.deleteMinorEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(configureEventCategoriesActions.deleteMinorEventCategoriesFailed(error?.message));
  }
}
//
export function* eventCategoryConfigurationSaga() {
  yield takeLatest(
    configureEventCategoriesActions.configureEventCategories.type,
    eventCategoryConfigurationGenerator
  );
  yield takeLatest(
    configureEventCategoriesActions.addMinorEventCategories.type,
    addMinorEventCategoryConfigurationGenerator
  );
  yield takeLatest(
    configureEventCategoriesActions.getMinorEventCategories.type,
    getMinorEventCategoryConfigurationGenerator
  );
  yield takeLatest(
    configureEventCategoriesActions.updateMinorEventCategories.type,
    updateMinorEventCategoriesConfigurationGenerator
  );
  yield takeLatest(
    configureEventCategoriesActions.deleteMinorEventCategories.type,
    deleteMinorEventCategoryConfigurationGenerator
  );
}
//
export default eventCategoryConfigurationSaga;
