/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';
import { verifyCallbackActions } from 'modules/verify-callback/slice';

export const initialState = {
  user: {},
  organization: {},
  tokens: {},
  loading: false,
  resetPasswordToken: '',
  sessionId: '',
};
/**
 * All actions related to auth feature are defined here
 */
export const authSlice = createSlice({
  name: SLICE.BASE_AUTH,
  initialState,
  reducers: {
    fetchUser(state) {
      state.loading = true;
    },
    fetchUserSucceeded(state, action) {
      state.loading = false;
      state.user = action?.payload;
    },
    fetchUserFailed(state) {
      state.loading = false;
    },
    getOrganization(state) {
      state.loading = true;
    },
    getOrganizationSucceeded(state, action) {
      state.loading = false;
      state.organization = action?.payload;
    },
    getOrganizationFailed(state) {
      state.loading = false;
    },
    updateOrganization(state) {
      state.loading = true;
    },
    updateOrganizationSucceeded(state, action) {
      state.loading = false;
      state.organization = action?.payload;
    },
    updateOrganizationFailed(state) {
      state.loading = false;
    },
    signOut(state) {
      state.loading = true;
    },
    signOutSucceeded(state) {
      state.loading = false;
      state.user = {};
      state.tokens = {};
    },
    signOutFailed(state) {
      state.loading = false;
      state.user = {};
      state.tokens = {};
    },
    refreshToken(state) {
      state.loading = true;
    },
    refreshTokenSucceeded(state, action) {
      state.loading = false;
      state.tokens = action?.payload;
      state.sessionId = jwtDecode(action?.payload?.accessToken)?.sid;
    },
    refreshTokenFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(signInActions.signInSucceeded, (state, action) => ({
        ...state,
        tokens: action?.payload?.tokens,
        user: action?.payload?.user,
        sessionId: jwtDecode(action?.payload?.tokens?.accessToken)?.sid,
      }))
      .addCase(signInActions.signInFailed, (state) => ({
        ...state,
        tokens: {},
        user: {},
      }))
      .addCase(verifyCallbackActions.verifyTokenSucceeded, (state, action) => ({
        ...state,
        resetPasswordToken: action?.payload?.token,
      }))
      .addCase(verifyCallbackActions.verifyTokenFailed, (state) => ({
        ...state,
        resetPasswordToken: '',
      }));
  },
});
//
export const { actions: authActions } = authSlice;
