import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import { CUSTOM_VALUE, KEYS, OTB_VALUE, PRIMARY } from 'modules/dashboard/constants';
import CustomDatePicker from 'modules/dashboard/components/tab-container/inner-filter/components/custom-date-comparison';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import CollapseIcon from 'modules/common/components/collapse-icon';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
/**
 * Vs comparison window component common for the dashboard component
 * @param {Boolean} customBooking - Custom booking selected state
 * @param {Boolean} alignmentToggle - Alignment toggle on/off state
 * @param {Function} handleToggleChange - Function to call when alignment toggle value changes
 * @param {Boolean} symmetricComparisonToggle - Symmetric comparison toggle on/off state
 * @param {Object} visual - PBI visuals for comparison window
 * @param {String} visualName - Display name for symmetric comparison visual
 * @param {*} dateComparison - Selected date comparison value
 * @param {Array} dateComparisonList - Date comparison list
 * @param {Function} handleComparisonChange - Function to call when date comparison value changes
 * @param {Function} handleSymmetricComparisonChange - Function to call symmetric comparison value changes
 * @param {Boolean} isCustomOptionAvailable - state to hide/show custom option
 * @param {Date} customDate - Custom date
 * @param {Date} startDate -  Start date
 * @param {Date} endDate - End date
 * @param {Date} targetDate - Target date
 * @param {*} reportType - reportType
 * @param {Function} handleCustomDates - Function to call when custom dates value changes
 * @returns
 */
const VsComparisonWindow = ({
  customBooking,
  alignmentToggle,
  handleToggleChange,
  symmetricComparisonToggle,
  visual,
  visualName,
  dateComparison,
  dateComparisonList,
  handleComparisonChange,
  handleSymmetricComparisonChange,
  isCustomOptionAvailable,
  customDate = null,
  reportType = null,
  startDate = null,
  endDate = null,
  targetDate = null,
  handleCustomDates = () => {},
  isDisplay,
  isDisplayOnClickAction = () => {},
  hideOTBAndCustom = false,
  isEnableBreakdownCheckbox = false,
  handlePrimaryChange = () => {},
  isTimeComparisonDisabled = false,
  isPrimary,
  id = '',
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container justifyContent="space-between" flexDirection="row" alignItems="flex-start">
        <Grid item xs={4} paddingTop={1}>
          <FormLabel sx={{ fontSize: 13 }}>VS. Time</FormLabel>
        </Grid>
        {/* <Grid item xs={10} container flexDirection="row">
          <Grid
            container
            item
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={11}>
              <Typography fontSize={11}>{visualName}</Typography>
            </Grid>
            <Grid item xs={1}>
              <CollapseIcon isDisplay={isDisplay} onClick={isDisplayOnClickAction} tooltipText />
            </Grid>
            <Grid item xs={12}>
              {isDisplay && visual}
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <br />
      <CustomToggleSwitch
        label="Alignment"
        secondaryLabel="day-of-week"
        title="Align day-of-week between selected & comparison stay dates."
        onChange={(event) => handleToggleChange(event)}
        checked={alignmentToggle}
        disabled={isTimeComparisonDisabled}
        paddingBottom={3}
      />
      <CustomToggleSwitch
        label="Same Time Last Period"
        title="Use comparable lead-time and booking window between selected & comparison stay dates."
        onChange={handleSymmetricComparisonChange}
        checked={symmetricComparisonToggle}
        disabled={isTimeComparisonDisabled}
        paddingBottom={3}
      />
      <Grid item xs={10} container flexDirection="row" paddingBottom={2}>
        <Grid container item flexDirection="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={11}>
            <Typography fontSize={11}>{visualName}</Typography>
          </Grid>
          <Grid item xs={1}>
            <CollapseIcon isDisplay={isDisplay} onClick={isDisplayOnClickAction} tooltipText />
          </Grid>
          <Grid item xs={12}>
            {isDisplay && visual}
          </Grid>
        </Grid>
      </Grid>
      {isEnableBreakdownCheckbox && (
        <FormGroup>
          <FormControlLabel
            id={`auto-${id}-breakdown-visual-switch`}
            key={0}
            value={PRIMARY}
            control={<Checkbox icon={<CircleOutlinedIcon />} checkedIcon={<CircleIcon />} />}
            label={PRIMARY}
            checked={isPrimary}
            onChange={handlePrimaryChange}
            sx={{
              mb: 1,
            }}
          />
        </FormGroup>
      )}
      {dateComparison && (
        <RadioGroup
          disabled
          value={dateComparison}
          onChange={(event) => handleComparisonChange(event)}
        >
          {isCustomOptionAvailable &&
            dateComparisonList?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.value}
                control={<Radio />}
                label={item?.label}
                disabled={(item?.value === OTB_VALUE && !customBooking) || isTimeComparisonDisabled}
                sx={{
                  my: -1,
                }}
              />
            ))}

          {!isCustomOptionAvailable &&
            dateComparisonList?.map((item) => {
              if (hideOTBAndCustom)
                return (
                  item.value !== CUSTOM_VALUE &&
                  item.value !== OTB_VALUE && (
                    <FormControlLabel
                      key={item?.id}
                      value={item?.value}
                      control={<Radio />}
                      label={item?.label}
                      disabled={
                        (item?.value === OTB_VALUE && !customBooking) ||
                        (isEnableBreakdownCheckbox && isPrimary) ||
                        isTimeComparisonDisabled
                      }
                      sx={{
                        my: -1,
                      }}
                    />
                  )
                );
              return (
                item.value !== CUSTOM_VALUE && (
                  <FormControlLabel
                    key={item?.id}
                    value={item?.value}
                    control={<Radio />}
                    label={item?.label}
                    disabled={
                      (item?.value === OTB_VALUE && !customBooking) || isTimeComparisonDisabled
                    }
                    sx={{
                      my: -1,
                    }}
                  />
                )
              );
            })}
        </RadioGroup>
      )}
    </FormControl>
    {dateComparison && dateComparison === KEYS.CUSTOM && (
      <>
        <FormControl>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={customDate}
            exclusive
            onChange={handleCustomDates}
          >
            <ToggleButton key="start" value={KEYS.START_DATE}>
              Start Date
            </ToggleButton>
            <ToggleButton key="end" value={KEYS.END_DATE}>
              End Date
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>

        <Grid container direction="row" alignItems="center" justifyContent="center" paddingTop={0}>
          <CustomDatePicker
            reportType={reportType}
            targetDate={targetDate}
            customDate={customDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </>
    )}
  </CustomStack>
);
//
export default VsComparisonWindow;
