import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGroupByRatesDetails,
  selectGroupByForecastDetails,
  selectHotelDetails,
  selectGroupByEventsDetails,
  selectGroupRoomConsumptionDetails,
} from 'modules/rate-calendar/selectors';
import { rateCalenderActions } from 'modules/rate-calendar/slice';
import { dateTimeFormat } from 'modules/common/helpers/date-time';
import { selectColorRange } from 'modules/common/helpers/color-range';
import EVENT_COLORS from 'modules/common/constants/event-colors';
import { calculateForecastTotalValue } from 'modules/common/utils/calculate-forecast-total';
import rateRange, {
  heatMapOffBackground,
  heatMapOnTextColor,
} from 'modules/common/constants/heatmap-config';
/**
 * Custom hook for rate calendar is defined here
 * @param {*} heatMap
 */
const useCalendar = (heatMap) => {
  const dispatch = useDispatch();
  //
  const constraintsGroupedByDate = useSelector(selectGroupByRatesDetails);
  const forecastDataGroupedByDate = useSelector(selectGroupByForecastDetails);
  const eventsGroupedByDate = useSelector(selectGroupByEventsDetails);
  const roomConsumptionDataGroupedByDate = useSelector(selectGroupRoomConsumptionDetails);
  const hotelData = useSelector(selectHotelDetails);
  //
  let backgroundColor = heatMapOffBackground;
  useEffect(() => {
    const list = [];
    const forecastTotalList = calculateForecastTotalValue(constraintsGroupedByDate);
    const uniqueDays = new Set([
      ...Object.keys(constraintsGroupedByDate),
      ...Object.keys(forecastDataGroupedByDate),
      ...Object.keys(eventsGroupedByDate),
      ...Object.keys(roomConsumptionDataGroupedByDate),
    ]);
    const dateKeys = [...uniqueDays];
    //
    dateKeys.forEach((element) => {
      const eventByDate = eventsGroupedByDate?.[element];
      const ratesByDate = constraintsGroupedByDate?.[element];
      const forecastTotalByDate = forecastTotalList[element];
      const constraintsDate = forecastDataGroupedByDate?.[element];
      const consumptionDataByDate = roomConsumptionDataGroupedByDate?.[element];
      const availableRooms =
        hotelData.roomCapacity - (constraintsDate ? constraintsDate[0].blocked : 0);
      const occupancyRate = consumptionDataByDate
        ? (
            ((consumptionDataByDate?.[0]?.occupiedRoomCount ?? 0) / (availableRooms ?? 1)) *
            100
          ).toFixed(3)
        : '';
      if (heatMap && occupancyRate) {
        rateRange.map((item) => {
          if (item.lower === null && occupancyRate < item.upper) {
            backgroundColor = item.backgroundColor;
          }
          if (item.lower !== null && occupancyRate >= item.lower && occupancyRate < item.upper) {
            backgroundColor = item.backgroundColor;
          }
          return '';
        });
      }
      const eventObject = {
        backgroundColor,
        borderColor: backgroundColor,
        id: element,
        display: 'background',
        start: new Date(element).toLocaleString('fr-CA', dateTimeFormat),
        extendedProps: {
          date: new Date(element).toLocaleString('fr-CA', dateTimeFormat),
          specialEvents: eventByDate
            ? eventByDate?.map((event) => ({
                fill: EVENT_COLORS[event.majorCategory],
                id: event?.id,
                date: event?.date,
                majorCategory: event?.majorCategory,
                minorCategory: event?.minorCategory,
                description: event?.description,
              }))
            : [],
          topHeader: {
            title: {
              // room availability
              remaining: consumptionDataByDate
                ? `${
                    (consumptionDataByDate?.[0]?.availableRoomCount ?? 0) -
                    (constraintsDate ? constraintsDate[0].blocked : 0)
                  }`
                : `${availableRooms}`,
              // sell limit
              available: `${availableRooms}`,
            },
            fontSize: 'h4',
            textColor: heatMap
              ? heatMapOnTextColor
              : selectColorRange(consumptionDataByDate?.[0]?.availableRoomCount),
            id: constraintsDate ? constraintsDate?.id : '',
          },
          middleBand: {
            // lead rate
            title: ratesByDate
              ? `$${ratesByDate
                  ?.filter((segment) => segment.marketSegment === 'retail')[0]
                  .rate?.toLocaleString('en')}`
              : '',
            fontSize: 'h2',
            textColor: heatMap ? heatMapOnTextColor : '#000000',
          },
          footer: {
            // ADR
            titleFirst: consumptionDataByDate
              ? `$${consumptionDataByDate?.[0]?.adr?.toLocaleString('en')}`
              : '',
            // Human Forecast
            titleSecond: forecastTotalByDate
              ? `$${forecastTotalByDate?.forecastTotal?.toLocaleString('en')}`
              : '',
            fontSize: 'h6',
            textColor: heatMap ? heatMapOnTextColor : '#B5B5B5',
          },
          occupancyPercentage: occupancyRate ? `${occupancyRate}%` : '',
          blockedRoom: constraintsDate ? constraintsDate[0].blocked : '',
          revenue: consumptionDataByDate
            ? `$${(
                (consumptionDataByDate?.[0]?.adr ?? 0) *
                (consumptionDataByDate?.[0]?.occupiedRoomCount ?? 0)
              )?.toLocaleString('en')}`
            : '',
          revPar: consumptionDataByDate
            ? (
                ((consumptionDataByDate?.[0]?.adr ?? 0) *
                  (consumptionDataByDate?.[0]?.occupiedRoomCount ?? 0)) /
                availableRooms
              ).toFixed(3)
            : '',
        },
      };
      list.push(eventObject);
    });
    dispatch(rateCalenderActions.setCalendarEventList(list));
  }, [
    eventsGroupedByDate,
    constraintsGroupedByDate,
    forecastDataGroupedByDate,
    roomConsumptionDataGroupedByDate,
    heatMap,
  ]);
};
//
export default useCalendar;
