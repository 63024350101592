/* eslint-disable import/prefer-default-export */
/**
 * Click step list for web click view is defined here
 */
export const CLICK_STEP_LIST = [
  {
    label: 'Rooms',
    id: 1,
  },
  {
    label: 'Add-ons',
    id: 2,
  },
  {
    label: 'Checkout',
    id: 3,
  },
  {
    label: 'Confirmation',
    id: 4,
  },
  {
    label: 'Rates',
    id: 5,
  },
  {
    label: 'Review',
    id: 6,
  },
  {
    label: 'Trues',
    id: 7,
  },
];
