import { useCallback, useState } from 'react';
import { selectEmailId } from 'modules/verify-callback/selectors';
import { useSelector } from 'react-redux';
/**
 * This custom hook will handle for error messages based on the error type
 * @returns
 */
const useAuthErrors = () => {
  const emailId = useSelector(selectEmailId);
  const [message, setErrorMessage] = useState('');
  //
  const generateError = useCallback((notification) => {
    switch (notification?.message) {
      case 'Token expired':
        if (!emailId) {
          setErrorMessage('Your link has expired. Please request new password reset link.');
        } else {
          setErrorMessage('Your link has expired. Please contact dev.amica@surge.global.');
        }
        break;
      case 'Token already used':
        if (!emailId) {
          setErrorMessage('Your link was already used. Please request new password reset link.');
        } else {
          setErrorMessage(
            'Your link was already used or expired. Please contact dev.amica@surge.global.'
          );
        }

        break;
      case '"token" is not allowed to be empty':
        if (!emailId) {
          setErrorMessage(
            'The system does not support cross-browser password resetting. Please request for a new password reset link and reset the password in the same browser as the password reset page.'
          );
        } else {
          setErrorMessage('Error! Please contact dev.amica@surge.global.');
        }
        break;
      default:
        setErrorMessage('Something went wrong');
    }
  });
  return { generateError, message };
};
//
export default useAuthErrors;
