import { initializeFaro, FetchTransport } from '@grafana/faro-web-sdk';
import { ENVIRONMENT } from 'config';

export const faro = initializeFaro({
  transports: [
    new FetchTransport({
      url: ENVIRONMENT.FARO_URL,
      bufferSize: 1000,
      concurrency: 5,
      defaultRateLimitBackoffMs: 1000,
    }),
  ],
  paused: ENVIRONMENT.ENABLE_FARO.toLowerCase() !== 'true',
  app: {
    name: 'frontend',
    version: '1.0.0',
    environment: ENVIRONMENT.FARO_ENV,
  },
});
/**
 * creates a new log
 * @param {string} msg - log message.
 * @param {LogLevel} level - log level
 * @param {Object.<string, number>} metaObj - additional meta data for the log
 */
export default function faroLog(msg, level, metaObj) {
  if (ENVIRONMENT.ENABLE_FARO.toLowerCase() === 'true') {
    faro?.api.pushLog([msg], { level, context: metaObj });
  }
}

// eslint-disable-next-line no-unused-vars
export function faroSendActiveUserSession(activeness, activeTime, loggedInFor, loggedInTime) {
  if (ENVIRONMENT.ENABLE_FARO.toLowerCase() === 'true') {
    faro?.api.pushEvent(
      'user_active_time_calculation',
      {
        activeness: activeness.toString(),
        activeTime: activeTime.toString(),
        loggedInFor: loggedInFor.toString(),
        loggedInTime,
        loggedOutTime: new Date().toJSON(),
      },
      'active-user'
    );
  }
}

export function faroSendActiveChunks(time, chunkDuration) {
  if (ENVIRONMENT.ENABLE_FARO.toLowerCase() === 'true') {
    // this is to set the spike
    faro?.api.pushMeasurement({
      type: 'user_active_windows',
      values: {
        time,
        value: 1,
      },
    });

    // this is to set the line to false after the chunk window
    faro?.api.pushMeasurement({
      type: 'user_active_windows',
      values: {
        time: time + chunkDuration,
        value: 0,
      },
    });
  }
}
