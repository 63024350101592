/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';

export const initialState = {
  loading: false,
};
/**
 * All actions related to forgot password feature are defined here
 */
export const forgotPasswordSlice = createSlice({
  name: SLICE.FORGOT_PASSWORD,
  initialState,
  reducers: {
    forgotPassword(state) {
      state.loading = true;
    },
    forgotPasswordSucceeded(state) {
      state.loading = false;
    },
    forgotPasswordFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: forgotPasswordActions } = forgotPasswordSlice;
