import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
/**
 * Here define tabular common switch component that is used to turn on tabular views in trend tabs
 * @param {*} dispatch - react useDispatch
 * @param {*} onChangeEvent - event dispatch when tabular switch enable and disable
 * @param {*} status - react switch active and inactive status
 * @returns
 */
const TabularSwitch = ({ dispatch, onChangeEvent, status, gridSx }) => (
  <Grid container direction="row" justifyContent="flex-end" sx={gridSx}>
    <Typography
      sx={{
        mr: 4,
      }}
    >
      Tabular View
    </Typography>
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={status ?? false}
          onChange={(event) => dispatch(onChangeEvent(event.target.checked))}
        />
      }
    />
  </Grid>
);
//
export default TabularSwitch;
