/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';

export const initialState = {
  loading: false,
};
/**
 * Here mentioned all the reset password feature related actions
 */
export const resetPasswordSlice = createSlice({
  name: SLICE.RESET_PASSWORD,
  initialState,
  reducers: {
    resetPassword(state) {
      state.loading = true;
    },
    resetPasswordSucceeded(state) {
      state.loading = false;
    },
    resetPasswordFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: resetPasswordActions } = resetPasswordSlice;
