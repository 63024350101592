import { FormLabel, Grid, Slider, TextField } from '@mui/material';
/**
 * Sliding scale component to display scaling factor in web clicks
 * @param {*} value - selected value
 * @param {*} label - title of the component
 * @param {*} startLabel - start label of the component
 * @param {*} endLabel - end label of the component
 * @param {*} onChange - trigger function of the input change it self
 * @param {*} onInputChange - trigger function of the input change below box
 * @param {*} defaultValue - initial value of the component
 * @param {*} maxValue - maximum range of the component
 * @param {*} minValue - minimum range of the component
 * @returns
 */
const SlidingScale = ({
  value,
  label,
  startLabel,
  endLabel,
  onChange,
  onInputChange,
  defaultValue = 1,
  maxValue = 100,
  minValue = 1,
  innerRef = null,
}) => (
  <>
    <FormLabel>{label}</FormLabel>

    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={2} paddingTop={2}>
        {startLabel}
      </Grid>
      <Grid item xs={8} paddingTop={2}>
        <Slider
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          max={maxValue}
          min={minValue}
          valueLabelDisplay="auto"
          size="medium"
        />
      </Grid>

      <Grid item xs={2} paddingLeft={2} paddingTop={2}>
        {endLabel}
      </Grid>
      <Grid item xs={3.5} paddingX={1} paddingTop={1} minWidth={80}>
        <TextField
          ref={innerRef}
          value={value}
          type="number"
          onChange={onInputChange}
          InputProps={
            ({
              disableUnderline: true,
            },
            {
              inputProps: {
                max: maxValue,
                min: minValue,
              },
            })
          }
          fullWidth
        />
      </Grid>
    </Grid>
  </>
);
//
export default SlidingScale;
