import * as yup from 'yup';
/**
 * Sign up form validations are defined here
 */
const forbiddenUsernames = ['user', 'users', 'admin', 'admins', 'root', 'preferred'];
//
const signUpFormValidation = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required('Username is required')
    .matches(/^[a-zA-Z0-9_.@]*$/, 'Can contain only Alphabets, numbers . and @')
    .max(25, 'Username should not have more than 25 characters')
    .test('is-not-forbidden', (value) => {
      if (value && forbiddenUsernames.includes(value.toLowerCase())) {
        return new yup.ValidationError(`Username cannot be '${value}'`, null, 'username');
      }
      return true;
    }),
  firstName: yup.string().trim().required('First Name is required'),
  lastName: yup.string().trim().required('Last Name is required'),
  newPassword: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});
//
export default signUpFormValidation;
