/* eslint-disable no-nested-ternary */
import { useSelector, useDispatch } from 'react-redux';
import {
  selectPreviousYearGroupRoomConsumptionDetails,
  selectPreviousYearGroupByRatesDetails,
  selectGroupPreviousYearForecastDetails,
  selectHotelDetails,
  selectPreviousYearDate,
} from 'modules/rate-calendar/selectors';
import { rateCalenderActions } from 'modules/rate-calendar/slice';
import { useEffect } from 'react';
import { calculateForecastTotalValue } from 'modules/common/utils/calculate-forecast-total';
/**
 * Custom hook for metrics table is defined here
 * @param {*} event
 */
const useMetrics = (event) => {
  //
  const dispatch = useDispatch();
  //
  const prevYearGroupRoomConsumptionDetails = useSelector(
    selectPreviousYearGroupRoomConsumptionDetails
  );
  const prevYearGroupByRatesDetails = useSelector(selectPreviousYearGroupByRatesDetails);
  const prevYearForecastDataGroupedByDate = useSelector(selectGroupPreviousYearForecastDetails);
  const hotelData = useSelector(selectHotelDetails);
  const selectedDate = useSelector(selectPreviousYearDate);
  //
  useEffect(() => {
    const prevYearForecastTotalList = calculateForecastTotalValue(prevYearGroupByRatesDetails);
    const prevYearRoomRate = prevYearGroupByRatesDetails[selectedDate];
    const prevYearRoomConsumption = prevYearGroupRoomConsumptionDetails?.[selectedDate];
    const prevYearForecast = prevYearForecastTotalList[selectedDate];
    const prevYearGroupByForecast = prevYearForecastDataGroupedByDate?.[selectedDate];
    //
    const prevYearAvailableRooms =
      (hotelData?.roomCapacity ?? 0) -
      (prevYearGroupByForecast ? prevYearGroupByForecast[0]?.blocked ?? 0 : 0);
    const leadRate = prevYearRoomRate
      ? `$${prevYearRoomRate
          ?.filter((segment) => segment?.marketSegment === 'retail')[0]
          ?.rate?.toLocaleString('en')}`
      : '';
    const HumanForecast = prevYearForecast
      ? `$${prevYearForecast?.forecastTotal?.toLocaleString('en')}`
      : '';
    const adr = prevYearRoomConsumption
      ? `$${prevYearRoomConsumption?.[0]?.adr?.toLocaleString('en')}`
      : '';
    const sellLimit = selectedDate ? `${prevYearAvailableRooms}` : '';
    const roomAvailability = selectedDate
      ? prevYearRoomConsumption && prevYearGroupByForecast
        ? `${
            (prevYearRoomConsumption?.[0]?.availableRoomCount ?? 0) -
            (prevYearGroupByForecast[0]?.blocked ?? 0)
          }`
        : `${prevYearAvailableRooms}`
      : '';
    const occupancyPercentage = prevYearRoomConsumption
      ? `${(
          ((prevYearRoomConsumption?.[0]?.occupiedRoomCount ?? 0) / (prevYearAvailableRooms ?? 1)) *
          100
        ).toFixed(3)}%`
      : '';
    const blockedRoom = prevYearGroupByForecast ? prevYearGroupByForecast[0]?.blocked : '';
    const revenue = prevYearRoomConsumption
      ? `$${(
          (prevYearRoomConsumption?.[0]?.adr ?? 0) *
          (prevYearRoomConsumption?.[0]?.occupiedRoomCount ?? 0)
        )?.toLocaleString('en')}`
      : '';
    const revPar = prevYearRoomConsumption
      ? `$${(
          Math.round(
            (prevYearRoomConsumption?.[0]?.adr ?? 0) *
              (prevYearRoomConsumption?.[0]?.occupiedRoomCount ?? 0)
          ) / (prevYearAvailableRooms ?? 1)
        ).toFixed(2)}`
      : '';
    //
    const metricsTable = [
      {
        id: '1',
        metrics: 'Lead Rate',
        value: selectedDate ? event?.middleBand?.title ?? '' : '',
        previousValue: selectedDate ? leadRate ?? '' : '',
      },
      {
        id: '2',
        metrics: 'Human Forecast',
        value: selectedDate ? event?.footer?.titleSecond ?? '' : '',
        previousValue: selectedDate ? HumanForecast ?? '' : '',
      },
      {
        id: '3',
        metrics: 'ADR',
        value: selectedDate ? event?.footer?.titleFirst ?? '' : '',
        previousValue: selectedDate ? adr ?? '' : '',
      },
      {
        id: '4',
        metrics: 'Sell Limit',
        value: selectedDate ? event?.topHeader?.title?.available ?? '' : '',
        previousValue: selectedDate ? sellLimit ?? '' : '',
      },
      {
        id: '5',
        metrics: 'Room Availability',
        value: selectedDate ? event?.topHeader?.title?.remaining ?? '' : '',
        previousValue: selectedDate ? roomAvailability ?? '' : '',
      },
      {
        id: '6',
        metrics: 'Occupancy Rate',
        value: selectedDate ? event?.occupancyPercentage ?? '' : '',
        previousValue: selectedDate ? occupancyPercentage ?? '' : '',
      },
      {
        id: '7',
        metrics: 'Blocked Rooms',
        value: selectedDate ? event?.blockedRoom ?? '' : '',
        previousValue: selectedDate ? blockedRoom ?? '' : '',
      },
      {
        id: '8',
        metrics: 'Revenue',
        value: selectedDate ? event?.revenue ?? '' : '',
        previousValue: selectedDate ? revenue ?? '' : '',
      },
      {
        id: '9',
        metrics: 'RevPAR',
        value: selectedDate && event ? `$${Math.round(event.revPar * 100) / 100}` ?? '' : '',
        previousValue: selectedDate ? revPar ?? '' : '',
      },
    ];
    dispatch(rateCalenderActions.setMetricsRows(metricsTable));
  }, [
    hotelData,
    selectedDate,
    prevYearForecastDataGroupedByDate,
    prevYearGroupByRatesDetails,
    prevYearGroupRoomConsumptionDetails,
  ]);
};
//
export default useMetrics;
