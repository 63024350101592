import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { selectForecastDisabledText, selectsHotelForecast } from '../../../../../selectors';

/**
 * Weekly cumulative window component common for the dashboard component
 * @param {Boolean} isCumulative - State of Cumulative toggle
 * @param {Boolean} weeklyViewToggle - State of Weekly view toggle
 * @param {Function} handleIsCumulativeChange - Function to set cumulative toggle value
 * @param {Function} handleWeeklyViewChange - Function to set weekly toggle value
 * @returns
 */
const WeeklyCumulativeWindow = ({
  isCumulative,
  weeklyViewToggle,
  handleIsCumulativeChange,
  handleWeeklyViewChange,
  isForecastToggle = false,
  handleIsForecastChange = () => {},
  isTrendForecast = false,
  isForecastDisabled = false,
  isPercentageToggle = false,
  isTrendPercentageChange = false,
  isTrendPercentageDisabled = false,
  handleIsPercentageToggleChange = () => {},
  isWeeklyViewDisabled = false,
  tabName = 'tab',
}) => {
  const isHotelForecast = useSelector(selectsHotelForecast);
  const ForecastDisabledText = useSelector(selectForecastDisabledText);
  //
  return (
    <CustomStack cssClassName={styles.innerFilter}>
      <CustomToggleSwitch
        label="Cumulative View"
        title="Change ‘Total Occupied Rooms’ and ‘Total Revenue’ graphs to display the cumulated booking pace for each statistic."
        onChange={handleIsCumulativeChange}
        checked={isCumulative ?? false}
        id={`auto-toggle-${tabName}-cumulative`}
      />
      <CustomToggleSwitch
        label="Weekly View"
        title="Change X-axis to ‘days of the week’ to view results aggregated to the relevant weekday."
        onChange={handleWeeklyViewChange}
        checked={weeklyViewToggle ?? false}
        disabled={isWeeklyViewDisabled}
        id={`auto-toggle-${tabName}-weekly`}
      />
      {isPercentageToggle && (
        <CustomToggleSwitch
          label="Percentage"
          title="Enable percentage change on the ‘Total Revenue’ , ‘Total Occupied Rooms’ and 'Total Average Daily Rate' graphs"
          onChange={handleIsPercentageToggleChange}
          checked={isTrendPercentageChange ?? false}
          disabled={isTrendPercentageDisabled}
          id={`auto-toggle-${tabName}-percentage`}
        />
      )}
      {isForecastToggle && (
        <>
          <CustomToggleSwitch
            label="Forecasts"
            title="Enable forecasts on the ‘Total Revenue’ and ‘Total Occupied Rooms’ graphs"
            onChange={handleIsForecastChange}
            checked={isTrendForecast ?? false}
            disabled={isForecastDisabled}
            id={`auto-toggle-${tabName}-forecast`}
          />
          {!isHotelForecast && (
            <Typography sx={{ fontSize: 11.5, color: 'gray' }}>{ForecastDisabledText}</Typography>
          )}
        </>
      )}
    </CustomStack>
  );
};
//
export default WeeklyCumulativeWindow;
