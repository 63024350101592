import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLoader } from 'modules/rate-calendar-upload/selectors';
import { Loader } from 'modules/common/components';
import RateCalendarUpload from '../dropzone-form';
/**
 * Main component for Rate calendar upload view.
 */
const RateCalendarUploadView = () => {
  //
  const loading = useSelector(selectLoader);
  // position scrolling
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <Loader loading={loading}>
      <RateCalendarUpload />
    </Loader>
  );
};
//
export default RateCalendarUploadView;
