/* eslint-disable no-nested-ternary */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Grid, IconButton, Tooltip } from '@mui/material';
/**
 * Collapse or expand action icon implementation
 * @param {Boolean} isDisplay - Value to determine the icon to display
 * @param {Function} onClick - Function to trigger on click event
 * @returns
 */
const CollapseIcon = ({ isDisplay, onClick, tooltipText = false }) => (
  <Grid item>
    <Tooltip
      title={
        tooltipText
          ? isDisplay
            ? 'Hide Value'
            : 'Show Value'
          : isDisplay
          ? 'Minimize Frame'
          : 'Maximize Frame'
      }
      placement="top"
    >
      <IconButton
        color="inherit"
        size="small"
        sx={{
          '& .MuiSvgIcon-root': {
            width: '1.5rem',
            height: '1.25rem',
          },
        }}
        onClick={onClick}
      >
        {isDisplay ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>
    </Tooltip>
  </Grid>
);
//
export default CollapseIcon;
