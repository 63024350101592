import { AppBar, Grid, Typography } from '@mui/material';
/**
 * Support component define here.
 * @returns
 */
const SupportView = () => (
  <AppBar
    position="sticky"
    elevation={0}
    sx={{ height: 75, maxHeight: 75, padding: 0, margin: 0, backgroundColor: '#2e384a' }}
  >
    <Grid container spacing={2} direction="row" m={10}>
      <Grid m={10} item container justifyContent="space-between">
        <Typography variant="h2" gutterBottom color="black">
          Contact Us
        </Typography>
      </Grid>
    </Grid>
  </AppBar>
);
//
export default SupportView;
