/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectRevenueIsDisplayTH,
  selectTrendHotelFocusOn,
  selectTrendHotelNormalizedView,
  selectTrendHotelTabularVisuals,
  selectTrendHotelWeeklyTabularVisuals,
  selectTrendHotelCumulativeTabularVisuals,
  selectTrendHotelPercentageTabularVisuals,
  selectIsPercentageChange,
  selectActiveTrendTab,
  selectIsMovingAverage,
  selectMovingAverageTabularVisualTH,
  selectTestTrendHotelTabularFilterList,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import ExportToCSV from 'modules/dashboard/components/tab-container/export-visual-data/export-csv';
import { REPORT_TYPE } from 'modules/dashboard/constants';
/**
 * Trend Hotel tabular report with filters
 */
const TestTrendHotelTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const tabularFilterList = useSelector(selectTestTrendHotelTabularFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const visuals = useSelector(selectTrendHotelTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendHotelWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendHotelCumulativeTabularVisuals);
  const tokenDetails = useSelector(selectEmbedToken);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTH);
  const focusOn = useSelector(selectTrendHotelFocusOn);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const percentageVisuals = useSelector(selectTrendHotelPercentageTabularVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const activeTab = useSelector(selectActiveTrendTab);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverageHotelTabularVisuals = useSelector(selectMovingAverageTabularVisualTH);
  //
  const [tabularCombined, setTabularCombined] = useState({});
  const [isTabularCombinedRendered, setIsTabularCombinedRendered] = useState({});
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = cumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (weeklyViewToggle) {
      found = weeklyVisuals?.find((visual) => visual?.order === index);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = percentageVisuals?.find((visual) => visual?.order === index);
    } else if (isMovingAverage && !isPercentageChange && !weeklyViewToggle) {
      found = movingAverageHotelTabularVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get PBI visual instance based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsRendered = () => {},
    filter = tabularFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsRendered={(r) => setIsRendered(r)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  const trendFilters = `Compared Hotels: , "${focusOn?.label}"\r\n`;
  //
  return (
    <VisualGrid
      isDisplay={revenueIsDisplay}
      visualName={getVisualName(0)}
      visual={getWidget(
        0,
        styles.tabularVisual,
        setTabularCombined,
        setIsTabularCombinedRendered,
        tabularFilterList
      )}
      onClick={() => {
        dispatch(trendActions.setRevenueIsDisplayTH(!revenueIsDisplay));
      }}
      toggleFragment={
        <ExportToCSV
          report={tabularCombined}
          isLoaded={isTabularCombinedRendered}
          fileName={
            isPercentageChange
              ? 'TVH_Percentage'
              : isMovingAverage
              ? 'CMATH_Daily'
              : normalizedViewToggle
              ? 'TVH_Normalized'
              : isCumulative
              ? 'TVH_Cumulative'
              : weeklyViewToggle
              ? 'TVH_Weekly'
              : 'TVH_Daily'
          }
          weeklyViewToggle={weeklyViewToggle}
          isCumulative={isCumulative}
          otherFilters={trendFilters}
        />
      }
      isWorkbookHotelComparison={activeTab === REPORT_TYPE.TREND_HOTEL}
      comparisonHotel={focusOn}
      displayHotelName
    />
  );
};
//
export default TestTrendHotelTabularWidget;
