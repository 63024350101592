/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { rateCalendarUploadActions } from './slice';
import API from './constants';
/**
 * Rate calendar upload generator function to upload rate calendar files
 * @param {*} param0
 */
export function* rateCalendarUploadGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_FORECAST_UPLOAD, payload, true, true);
    yield put(rateCalendarUploadActions.rateCalendarUploadSucceeded(response));
  } catch (error) {
    yield put(rateCalendarUploadActions.rateCalendarUploadFailed(error.message));
  }
}
/**
 * Rate calendar force upload generator function to force upload rate calendar files
 * @param {*} param0
 */
export function* rateCalendarForceUploadGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_FORECAST_FORCE_UPLOAD, payload, true, true);
    yield put(rateCalendarUploadActions.rateCalendarForceUploadSucceeded(response));
  } catch (error) {
    yield put(rateCalendarUploadActions.rateCalendarForceUploadFailed(error.message));
  }
}
//
export function* rateCalendarUploadSaga() {
  yield takeLatest(rateCalendarUploadActions.rateCalendarUpload.type, rateCalendarUploadGenerator);
  yield takeLatest(
    rateCalendarUploadActions.rateCalendarForceUpload.type,
    rateCalendarForceUploadGenerator
  );
}
//
export default rateCalendarUploadSaga;
