import { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { grey } from '@mui/material/colors';
import { Loader } from 'modules/common/components';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import {
  selectEventCategoryConfigurations,
  selectLoader,
} from 'modules/event-configuration/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import PAGINATION from 'modules/common/constants/pagination-limits';
import MinorEventDataDrawer from '../minor-event-data-drawer';
import MajorEventsTableView from '../major-events-table';
/**
 * Main view for event category configuration layout
 * @param {*} theme - application theme configuration
 */
const EventCategoryConfigurationView = ({ theme }) => {
  const dispatch = useDispatch();
  // global selectors
  const loading = useSelector(selectLoader);
  const organizationId = useSelector(selectOrganizationId);
  const eventCategories = useSelector(selectEventCategoryConfigurations);
  //
  const [show, setShow] = useState(false);
  const [selectedEventCategoryId, setSelectedEventCategoryId] = useState(null);
  // triggers when component renders for first time
  useEffect(() => {
    // adjust window position to 0,0
    window.scrollTo(0, 0);
    // get configure event categories
    const query = `limit=${PAGINATION.MAJOR_EVENT_CATEGORIES_PER_PAGE}`;
    dispatch(
      configureEventCategoriesActions.configureEventCategories({
        organizationId,
        query,
      })
    );
  }, []);
  //
  return (
    <Loader loading={loading}>
      <Box>
        <Grid className="sub-title-event-config" item xs={4} padding={5}>
          <Typography variant="h2">Configure event categories</Typography>
          <Grid container direction="row">
            <Typography variant="h4" sx={{ marginRight: 5 }}>
              Major event categories{' '}
            </Typography>
            <Typography variant="h6" sx={{ color: grey[500] }}>
              ( {eventCategories?.totalResults} categories )
            </Typography>
          </Grid>
        </Grid>
        <Card>
          <CardContent
            sx={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid container direction="row" spacing={2} justifyContent="space-between">
              <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
                {!isEmpty(eventCategories) && (
                  <MajorEventsTableView
                    isShow={setShow}
                    setSelectedMajorEventId={setSelectedEventCategoryId}
                  />
                )}
              </Grid>
              <MinorEventDataDrawer
                show={show}
                isShow={setShow}
                majorEventCategoryId={selectedEventCategoryId}
              />
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Loader>
  );
};
//
export default withTheme(EventCategoryConfigurationView);
