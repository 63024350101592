import {
  selectAccessToken,
  selectIdToken,
  // selectRefreshToken,
} from 'modules/common/auth/selectors';
import store from 'store/index';
import { useSelector } from 'react-redux';
import { SLICE } from 'modules/dashboard/constants';
/**
 * Get only the access token from the state
 * @returns
 */
export const getAuthToken = () => useSelector(selectAccessToken);
/**
 * Get only the refresh token from the state
 * @returns
 */
// export const getRefreshToken = () => useSelector(selectRefreshToken);
export const getRefreshToken = () => {
  let authTokens;
  // when initializing tokens, subscribing to redux store changes for
  // get updated state each time store state get changed
  if (!authTokens) {
    store?.subscribe(() => {
      authTokens = store?.getState()[SLICE.BASE_AUTH]?.tokens?.refreshToken;
    });
    authTokens = store?.getState()[SLICE.BASE_AUTH]?.tokens?.refreshToken;
    return authTokens;
  }
  return authTokens;
};
/**
 * Function to retrieve the id token from the auth slice
 */
export const getIdToken = () => useSelector(selectIdToken);
