import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { selectHotelId } from 'modules/event-configuration/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { notificationActions } from 'modules/common/notifications/slice';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import addMinorCategoryValidation from '../../validation/add-event-category-form-validation';
import { Alert, TextField } from './style';
/**
 * Modal component to add a minor event category
 * @param {*} open - modal open/close status
 * @param {*} onClose - function to close the modal
 * @param {*} majorEventCategoryId - major event category id
 */
const AddMinorCategoryDialog = ({ open, onClose, majorEventCategoryId }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const hotelId = useSelector(selectHotelId);
  // reset  notifications and close the modal
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  // triggers when notification state changes
  useEffect(() => {
    // toast the success notification
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, {
        type: TOAST_TYPES.SUCCESS,
      });
      modalAction(false);
    }
  }, [notification]);
  /**
   * dispatch add minor event categories
   * @param {*} values - form values including minor event name
   */
  const onSubmitAddMinorEvent = async (values) => {
    dispatch(
      configureEventCategoriesActions.addMinorEventCategories({
        organizationId,
        majorEventCategoryId,
        name: values?.minorName,
        applicableHotels: [hotelId],
      })
    );
    modalAction(false);
  };
  // set initial values
  const initialValues = {
    minorName: '',
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Minor Category</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addMinorCategoryValidation}
          onSubmit={onSubmitAddMinorEvent}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="minorName"
                label="Minor category name"
                value={values.minorName}
                error={Boolean(touched.minorName && errors.minorName)}
                helperText={touched.minorName && errors.minorName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                size="small"
              />
              <DialogActions
                sx={{
                  justifyContent: 'space-between',
                  px: 0,
                }}
              >
                <Button
                  sx={{ px: 10 }}
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  Add
                </Button>
                <Button
                  sx={{ px: 10 }}
                  onClick={() => modalAction(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddMinorCategoryDialog;
