/* eslint-disable import/prefer-default-export */
/**
 * Determines the granularity based on the difference in calendar days between
 * the start and end dates of a target date range.
 * @param {Object} targetDate - The target date range object with 'startDate' and 'endDate'.
 * @param {Date} targetDate.startDate - The start date of the target range.
 * @param {Date} targetDate.endDate - The end date of the target range.
 * @returns {string|undefined} - The calculated granularity, or undefined if not matched.
 */

import { differenceInCalendarDays } from 'date-fns';
import { PACE_HORIZON_SELECTION } from 'modules/dashboard/constants';
import { GRANULARITY } from './constants';
import { getSubPaceFilter } from '../inner-filter/functions';

export const getGranularity = (bookingDate, paceHorizon, bookingPaceWindow, visualFilters) => {
  let granularity;
  let difference;

  if (paceHorizon && visualFilters && bookingDate) {
    if (bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW) {
      difference =
        differenceInCalendarDays(new Date(bookingDate.endDate), new Date(bookingDate.startDate)) +
        1;
    } else {
      const subPaceFilter = getSubPaceFilter(visualFilters);
      const selectedPaceHorizon = subPaceFilter?.find((sub) => sub?.id === paceHorizon);
      difference = Number(selectedPaceHorizon?.label?.[0]?.split(' ')[0]);
    }
  }
  //
  if (difference <= 60) {
    granularity = GRANULARITY.DAILY;
  } else if (difference < 365) {
    granularity = GRANULARITY.WEEKLY;
  } else if (difference <= 1460) {
    granularity = GRANULARITY.MONTHLY;
  } else {
    granularity = GRANULARITY.YEARLY;
  }
  return granularity;
};
