/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getDate, sub } from 'date-fns';
import groupByDate from 'modules/common/utils/groups';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';
/**
 * Define the the rate calendar feature initial state
 */
export const initialState = {
  loading: false,
  eventDetails: {},
  forecastDetails: {},
  rateDetails: {},
  groupRatesDetails: {},
  groupForecastDetails: {},
  groupEventDetails: {},
  hotelDetails: {},
  roomConsumptionDetails: {},
  groupRoomConsumptionDetails: {},
  majorEventCategories: [],
  minorEventCategories: [],
  previousYearRateDetails: {},
  previousYearGroupRatesDetails: {},
  previousYearRoomConsumptionDetails: {},
  groupPreviousYearRoomConsumptionDetails: {},
  previousYearforecastDetails: {},
  groupPreviousYearForecastDetails: {},
  selectedDate: '',
  previousYearDate: '',
  metricsRows: [],
  calendarEventList: [],
};
/**
 * Here mentioned all the rate calendar feature related actions
 */
export const rateCalenderSlice = createSlice({
  name: SLICE.RATE_CALENDAR,
  initialState,
  reducers: {
    getEventDetails(state) {
      state.loading = true;
    },
    getEventDetailsSucceeded(state, action) {
      state.loading = false;
      state.eventDetails = action.payload.results;
      state.groupEventDetails = groupByDate(action.payload.results, 'date');
    },
    getEventDetailsFailed(state) {
      state.loading = false;
    },
    getForecastDetails(state) {
      state.loading = true;
    },
    getForecastDetailsSucceeded(state, action) {
      state.loading = false;
      state.forecastDetails = action.payload.results;
      state.groupForecastDetails = groupByDate(action.payload.results, 'date');
    },
    getForecastDetailsFailed(state) {
      state.loading = false;
    },
    getRateDetails(state) {
      state.loading = true;
    },
    getRateDetailsSucceeded(state, action) {
      state.loading = false;
      state.rateDetails = action.payload.results;
      state.groupRatesDetails = groupByDate(action.payload.results, 'date');
    },
    getRateDetailsFailed(state) {
      state.loading = false;
    },
    getRoomConsumptionDetails(state) {
      state.loading = true;
    },
    getRoomConsumptionDetailsSucceeded(state, action) {
      state.loading = false;
      state.roomConsumptionDetails = action.payload.results;
      state.groupRoomConsumptionDetails = groupByDate(action.payload.results, 'stayDate');
    },
    getRoomConsumptionDetailsFailed(state) {
      state.loading = false;
    },
    getHotelDetails(state) {
      state.loading = true;
    },
    getHotelDetailsSucceeded(state, action) {
      state.loading = false;
      state.hotelDetails = action.payload;
    },
    getHotelDetailsFailed(state) {
      state.loading = false;
    },
    addEventDetails(state) {
      state.loading = true;
    },
    addEventDetailsSucceeded(state, action) {
      state.loading = false;
      state.eventDetails = [...state.eventDetails, action?.payload];
      state.groupEventDetails = groupByDate(state.eventDetails, 'date');
    },
    addEventDetailsFailed(state) {
      state.loading = false;
    },
    deleteEventDetails(state) {
      state.loading = true;
    },
    deleteEventDetailsSucceeded(state, action) {
      state.loading = false;
      state.eventDetails = state?.eventDetails?.filter(
        (object) => object?.id !== action?.payload?.data?.Id
      );
      state.groupEventDetails = groupByDate(state.eventDetails, 'date');
    },
    deleteEventDetailsFailed(state) {
      state.loading = false;
    },
    updateEventDetails(state) {
      state.loading = true;
    },
    updateEventDetailsSucceeded(state, action) {
      state.loading = false;
      state.eventDetails = state?.eventDetails?.map((object) =>
        object?.id === action?.payload?.id ? { ...object, ...action.payload } : object
      );
      state.groupEventDetails = groupByDate(state.eventDetails, 'date');
    },
    updateEventDetailsFailed(state) {
      state.loading = false;
    },
    getMajorEventCategories(state) {
      state.loading = true;
    },
    getMajorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.majorEventCategories = action?.payload?.results;
    },
    getMajorEventCategoriesFailed(state) {
      state.loading = false;
    },
    getMinorEventCategories(state) {
      state.loading = true;
    },
    getMinorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.minorEventCategories = action?.payload?.results;
    },
    getMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    getPreviousYearRoomConsumptionDetails(state) {
      state.loading = true;
    },
    getPreviousYearRoomConsumptionDetailsSucceeded(state, action) {
      state.loading = false;
      state.previousYearRoomConsumptionDetails = action?.payload?.results;
      state.groupPreviousYearRoomConsumptionDetails = groupByDate(
        action.payload.results,
        'stayDate'
      );
    },
    getPreviousYearRoomConsumptionDetailsFailed(state) {
      state.loading = false;
    },
    getPreviousYearRateDetails(state) {
      state.loading = true;
    },
    getPreviousYearRateDetailsSucceeded(state, action) {
      state.loading = false;
      state.previousYearRateDetails = action?.payload?.results;
      state.previousYearGroupRatesDetails = groupByDate(action?.payload?.results, 'date');
    },
    getPreviousYearRateDetailsFailed(state) {
      state.loading = false;
    },
    getPreviousYearForecastDetails(state) {
      state.loading = true;
    },
    getPreviousYearForecastDetailsSucceeded(state, action) {
      state.loading = false;
      state.previousYearforecastDetails = action?.payload?.results;
      state.groupPreviousYearForecastDetails = groupByDate(action?.payload?.results, 'date');
    },
    getPreviousYearForecastDetailsFailed(state) {
      state.loading = false;
    },
    setSelectedDate(state, action) {
      state.selectedDate = action?.payload;
      if (
        action?.payload &&
        getDate(new Date(action?.payload)) === getDate(sub(new Date(action?.payload), { years: 1 }))
      ) {
        state.previousYearDate = sub(new Date(action?.payload), {
          years: 1,
        }).toISOString();
      } else {
        state.previousYearDate = '';
      }
    },
    setMetricsRows(state, action) {
      state.metricsRows = action?.payload;
    },
    setCalendarEventList(state, action) {
      state.calendarEventList = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: rateCalenderActions } = rateCalenderSlice;
