import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from 'modules/common/constants/route';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
/**
 * This custom hook for check the user is authenticated or not and
 * if its not authenticated then redirect to the login
 * @returns
 */
const useAuthorization = () => {
  const { isAuthenticated, isActiveToken } = useIsAuthenticated();
  const history = useLocation();
  //
  const navigate = useNavigate();
  //
  useEffect(() => {
    if (!isAuthenticated() || !isActiveToken()) {
      if (history.key === 'default') {
        localStorage.setItem(
          'redirect-to',
          btoa(`${history.pathname}${history.search}${history.hash}`)
        );
        navigate(ROUTES.LOGIN);
      } else {
        navigate(ROUTES.LOGIN);
      }
    }
  }, [isAuthenticated, isActiveToken]);
  //
  return { isAuthenticated };
};
//
export default useAuthorization;
