import { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { updatePasswordValidation } from 'modules/users/validation/add-user-form-validation';
import { notificationActions } from 'modules/common/notifications/slice';
import { usersActions } from 'modules/users/slice';
import { Alert, TextField } from './style';
/**
 * Update password implementation to change user password
 * @param {string} organizationId - organization id
 * @param {string} userId - user id
 * @param {function} onClose - function to cancel the action
 * @returns
 */
const UpdatePassword = ({ organizationId, userId, onClose }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // toggle show password state
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  // toggle show confirm password state
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  // update new password
  const onSubmitChangePassword = async (values) => {
    const valueObj = {
      organizationId,
      userId,
      newPassword: values.password,
      currentPassword: values.currentPassword,
    };
    dispatch(usersActions.changeUserPassword(valueObj));
  };
  // define initial values
  const initialValues = {
    password: '',
    currentPassword: '',
  };
  // reset notifications on first render
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  return (
    <Box
      xs={12}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{ backgroundColor: 'white', flexShrink: 0 }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={updatePasswordValidation}
        onSubmit={onSubmitChangePassword}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert mt={2} mb={3} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={12} item>
              <TextField
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                name="currentPassword"
                value={values.currentPassword}
                error={Boolean(touched.currentPassword && errors.currentPassword)}
                helperText={touched.currentPassword && errors.currentPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                label="Current Password"
                my={2}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              />
              <TextField
                size="small"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                label="New Password"
                my={2}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onClose()}
                sx={{ marginLeft: 2, marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                Save
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default UpdatePassword;
