/**
 * Define all the calender showing event types
 */
const EVENT_TYPES = {
  ANNUAL_SPORTS_EVENT: 'Special / Annual sports event',
  WEATHER_FORCASTABLE: 'Weather (forecastable)',
  WEATHER_NON_FORCASTABLE: 'Weather / Natural Disaster (non-forecastable)',
  US_HOLIDAY: 'US Holiday',
  US_LONG_WEEKEND: 'US Long Weekend',
  FESTIVAL: 'Festivals',
  ENTERTAINMENT: 'Entertainment',
  CITY_WIDE_CONFERENCE: 'City Wide Conferences (with rooms)',
  CONFERENCE_AND_SHOWS: 'Conferences & Shows (no rooms)',
  HOTEL_GROUP_BOOKINGS: 'Hotel Group Bookings',
  OTHER: 'Other',
  REGULAR_SPORTS_EVENT: 'Regular Sports Event',
};
//
export default EVENT_TYPES;
