/**
 * Forgot Password feature related request structures are defined here
 */
const API = {
  POST_FORGOT_PASSWORD: {
    path: '/auth/forgot-password',
    method: 'POST',
  },
};
//
export default API;
