import { FormControl, Tooltip, FormControlLabel, Switch } from '@mui/material';
import { CustomStack } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
/**
 * PMS toggle switch component common for the dashboard component
 * @param {Boolean} disabled - State to disable toggle
 * @param {Boolean} value - Toggle value
 * @param {Function} onChange - Function to call when the toggle value changes
 * @returns
 */
const PmsToggle = ({ disabled, value, onChange, id = '' }) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Tooltip title="Include PMS in addition to CRS bookings." placement="top">
        <FormControlLabel
          disabled={disabled}
          control={<Switch checked={value} onChange={onChange} id={id} />}
          label="Incl. PMS"
          sx={{
            width: 'fit-content',
            height: 10,
          }}
        />
      </Tooltip>
    </FormControl>
  </CustomStack>
);
//
export default PmsToggle;
