import { useSelector, useDispatch } from 'react-redux';
import { selectLoader } from 'modules/sign-in/selectors';
import { useEffect } from 'react';
import { Loader } from 'modules/common/components';
import { notificationActions } from 'modules/common/notifications/slice';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'modules/common/constants/route';
import { authActions } from 'modules/common/auth/slice';
import { selectTokens } from 'modules/common/auth/selectors';
import { isEmpty } from 'modules/common/helpers/object';
import openAuthWindow from 'modules/common/utils/auth';
import calculateActiveUserTime from 'modules/common/utils/active-user-time';
/**
 * Parent sign in component define here. All the sub component load into here
 * @returns
 */
const SignInView = () => {
  // get authenticated state
  const { isAuthenticated } = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const loading = useSelector(selectLoader);
  const tokens = useSelector(selectTokens);
  // reset notifications
  useEffect(() => dispatch(notificationActions.resetNotification()), [isAuthenticated]);
  //
  useEffect(() => {
    if (!isAuthenticated()) {
      if (tokens && !isEmpty(tokens) && tokens?.refreshToken !== '') {
        // calculate the user activeness on signout
        calculateActiveUserTime();
        dispatch(authActions.signOut());
      }
      openAuthWindow();
    } else {
      navigate(ROUTES.DASHBOARD);
    }
  }, [tokens]);

  return <Loader loading={loading} />;
};
//
export default SignInView;
