import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { ALIGNED, BOOKING_DATE, REPORT_TYPE, SLICE, TAGS } from 'modules/dashboard/constants';
import { dashboardActions } from 'modules/dashboard/slice';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { getHotelFocusOnValue } from 'modules/dashboard/functions';
import { isEmpty } from 'modules/common/helpers/object';
//
const bookingStartDate = new Date(BOOKING_DATE).toISOString();
const stayDate = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};
/**
 * Initial states of Summary widget functions are defined here
 */
export const initialState = {
  summaryBookingDate: {
    startDate: new Date(bookingStartDate).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  chartBreakdown: null,
  dateComparison: null,
  summaryFilterList: [],
  summaryAlignmentToggle: null,
  summaryOtb: false,
  summaryCustomDate: 'start',
  summaryStartDate: stayDate.startDate,
  summaryEndDate: stayDate.endDate,
  summaryDateSelected: stayDate.startDate,
  summaryVisualToggle: false,
  summaryUrl: '',
  symmetricComparisonToggle: true,
  isDetailed: false,
  paceVisualFilterList: [],
  alignment: null,
  symmetricComparisonIsDisplay: false,
  paceVisualIsDisplay: true,
  revenueSetIsDisplay: true,
  cxlSetIsDisplay: false,
  enableSecondaryHotelGroups: false,
  hotelFocusOn: {},
  normalizedView: false,
  summaryHotelVisuals: [],
  summaryVisuals: [],
  isSummaryHotelComparison: false,
};
/**
 * All actions related to summary widget feature are defined here
 */
export const summarySlice = createSlice({
  name: SLICE.SUMMARY,
  initialState,
  reducers: {
    setSummaryBookingDate(state, action) {
      state.summaryBookingDate = action?.payload;
    },
    setBreakdown(state, action) {
      state.chartBreakdown = action?.payload;
    },

    setDateComparison(state, action) {
      state.dateComparison = action?.payload;
    },
    updateSummaryFilterList(state, action) {
      state.summaryFilterList = action?.payload;
    },
    setSummaryAlignmentToggle(state, action) {
      state.summaryAlignmentToggle = action?.payload;
    },
    setSummaryOtb(state, action) {
      state.summaryOtb = action?.payload;
    },
    setSummaryCustomDate(state, action) {
      state.summaryCustomDate = action?.payload;
    },
    setSummaryStartDate(state, action) {
      state.summaryStartDate = action?.payload;
    },
    setSummaryEndDate(state, action) {
      state.summaryEndDate = action?.payload;
    },
    setSummaryDateSelected(state, action) {
      state.summaryDateSelected = action?.payload;
    },
    setSummaryVisualToggle(state, action) {
      state.summaryVisualToggle = action.payload;
    },
    setSummaryUrl(state, action) {
      state.summaryUrl = action.payload;
    },
    setSymmetricComparisonToggle(state, action) {
      state.symmetricComparisonToggle = action?.payload;
    },
    setIsDetailed(state, action) {
      state.isDetailed = action?.payload;
    },
    setPaceVisualFilterList(state, action) {
      state.paceVisualFilterList = action?.payload;
    },
    setAlignment(state, action) {
      state.alignment = action?.payload;
    },
    setSymmetricComparisonIsDisplay(state, action) {
      state.symmetricComparisonIsDisplay = action?.payload;
    },
    setPaceVisualIsDisplay(state, action) {
      state.paceVisualIsDisplay = action?.payload;
    },
    setRevenueSetIsDisplay(state, action) {
      state.revenueSetIsDisplay = action?.payload;
    },
    setCxlSetIsDisplay(state, action) {
      state.cxlSetIsDisplay = action?.payload;
    },
    setEnableSecondaryHotelGroups(state, action) {
      state.enableSecondaryHotelGroups = action.payload;
    },
    setHotelFocusOn(state, action) {
      state.hotelFocusOn = action?.payload;
      state.enableSecondaryHotelGroups = action?.payload && 'hotels' in action.payload;
    },
    setNormalizedView(state, action) {
      state.normalizedView = action?.payload;
    },
    setIsSummaryHotelComparison(state, action) {
      state.isSummaryHotelComparison = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        );
        return {
          ...state,
          chartBreakdown: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
          )?.id,
          dateComparison: dateComparisonFilter?.values?.find((filter) => filter.isDefault === true)
            ?.value,
          alignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          summaryAlignmentToggle: dateComparisonFilter?.type === ALIGNED,
        };
      })
      .addCase(dashboardActions.getHotelListSucceeded, (state, action) => {
        const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
        return {
          ...state,
          hotelFocusOn: hotel?.focusOnValues?.[0],
        };
      })
      .addCase(dashboardActions.getVisualsSucceeded, (state, action) => {
        const visualDetails = action?.payload?.results;
        return {
          ...state,
          summaryHotelVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.SUMMARY_HOTEL)
          ),
          summaryVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.SUMMARY)
          ),
        };
      })
      .addCase(dashboardActions.setHotelName, (state, action) => ({
        ...state,
        hotelFocusOn: action?.payload,
        normalizedView: false,
        enableSecondaryHotelGroups: action?.payload && 'hotels' in action.payload,
      }))

      .addCase(dashboardActions.setSelectedHotelGroup, (state, action) => {
        let secondaryHotelSet;
        if (state && state.hotelFocusOn && 'hotels' in state.hotelFocusOn) {
          if (!isEmpty(state.hotelName) && action?.payload?.hotelGroupDropdownList?.length === 0) {
            secondaryHotelSet = action?.payload?.hotelList[0];
          } else if (state.hotelFocusOn && action?.payload?.hotelGroupDropdownList?.length > 0) {
            const newGroup = action?.payload?.hotelGroupDropdownList?.find(
              (set) => set?.id === state.hotelFocusOn?.id
            );

            if (newGroup) {
              secondaryHotelSet = newGroup;
            } else {
              secondaryHotelSet = action?.payload?.hotelList[0];
            }
          } else {
            secondaryHotelSet = action?.payload?.hotelList[0];
          }
          return {
            ...state,
            hotelFocusOn: secondaryHotelSet,
            enableSecondaryHotelGroups: secondaryHotelSet && 'hotels' in secondaryHotelSet,
          };
        }
        return { ...state };
      })
      .addCase(dashboardActions.setEnableHotelGroups, (state, action) => ({
        ...state,
        enableSecondaryHotelGroups: action?.payload,
        normalizedView: false,
      }));
  },
});
//
export const { actions: summaryActions } = summarySlice;
