import { Stack } from '@mui/material';
/**
 * Stack component implementation with custom styling
 * @param {String} cssClassName - css class name for user defined styling
 * @param {*} children - child elements of the stack
 * @returns
 */
const CustomStack = ({ cssClassName, children }) => (
  <Stack
    direction="column"
    spacing={{ sm: 1, cc: 3, md: 2, lg: 3 }}
    sx={{
      background: 'white',
      borderRadius: 3,
      py: { sm: 1, cc: 3, md: 2, lg: 3 },
    }}
    padding={{ sm: 1, cc: 1, md: 2, lg: 3 }}
    marginLeft={{ md: 1 }}
    minWidth={200}
    className={cssClassName}
  >
    {children}
  </Stack>
);
//
export default CustomStack;
