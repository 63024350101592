import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CustomStack } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
/**
 * Percentage window component common for the dashboard component
 * @param {*} percentage - percentage selected state
 * @param {Array} percentageList - Percentages list
 * @param {Function} handlePercentageChange - Function to set percentage value
 * @param {Boolean} isPercentageDisabled - To enable or disable percentage filter
 * @returns
 */
const PercentageWindow = ({
  percentage,
  percentageList,
  handlePercentageChange,
  isPercentageDisabled,
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container alignItems="center">
        <FormLabel>Percentage</FormLabel>
        <Tooltip
          color="primary"
          className={styles.infoIcon}
          title="The 'Percentage' filter will only apply when the filter is in an 'Active' state."
        >
          <InfoIcon />
        </Tooltip>
      </Grid>
      <RadioGroup value={percentage ?? null} onChange={handlePercentageChange}>
        {percentageList?.map((item) => (
          <FormControlLabel
            key={item?.id}
            value={item?.id}
            control={<Radio />}
            label={item?.label}
            sx={{
              my: -1,
            }}
            disabled={isPercentageDisabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </CustomStack>
);
//
export default PercentageWindow;
