/**
 * Define all the major event categories which use for event configuration
 */
const MAJOR_CATEGORY_COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
];
//
export default MAJOR_CATEGORY_COLUMNS;
