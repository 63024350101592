import {
  TABULAR_VIEWS,
  UiController,
  commands,
  compareWindows,
  events,
} from 'modules/common/ui-controller';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import { KEYS, REPORT_TYPE, RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { dashboardActions } from 'modules/dashboard/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trendActions } from '../slice';
import {
  selectActiveTrendTab,
  selectAdrIsDisplayTH,
  selectAdrIsDisplayTS,
  selectAdrIsDisplayTT,
  selectAvgBookingWindowIsDisplayTH,
  selectAvgBookingWindowIsDisplayTS,
  selectAvgBookingWindowIsDisplayTT,
  selectAvgLosIsDisplayTH,
  selectAvgLosIsDisplayTS,
  selectAvgLosIsDisplayTT,
  selectCxlIsDisplayTH,
  selectCxlIsDisplayTS,
  selectCxlIsDisplayTT,
  selectIsPercentageChangeDisabled,
  selectIsTrendDeAggregateDisabled,
  selectOccupiedRoomsIsDisplayTH,
  selectOccupiedRoomsIsDisplayTS,
  selectOccupiedRoomsIsDisplayTT,
  selectRevenueIsDisplayTH,
  selectRevenueIsDisplayTS,
  selectRevenueIsDisplayTT,
  selectSymmetricComparisonIsDisplay,
} from '../selectors';

/**
 * Custom hook to execute trend time filter command flow
 * @param {Boolean} isLoaded
 * @param {Boolean} customBooking
 * @param {Array} visualFilters
 * @param {Function} handleAlignmentToggleChange
 * @param {Array} dateComparison
 * @param {Function} setArbitraryComparisonDate
 * @param {*} setCustomDatesRef
 */
const useTrendTimeCommandFlow = (
  isLoaded,
  customBooking,
  visualFilters,
  handleAlignmentToggleChange,
  dateComparison,
  setArbitraryComparisonDate,
  setCustomDatesRef,
  isForecastDisabled,
  isMultiple,
  movingAverageList
) => {
  const dispatch = useDispatch();
  //
  const activeTab = useSelector(selectActiveTrendTab);
  const urlOrder = useSelector(selectUrlOrder);
  const revenueIsDisplayTT = useSelector(selectRevenueIsDisplayTT);
  const occupiedRoomsIsDisplayTT = useSelector(selectOccupiedRoomsIsDisplayTT);
  const adrIsDisplayTT = useSelector(selectAdrIsDisplayTT);
  const avgBookingWindowIsDisplayTT = useSelector(selectAvgBookingWindowIsDisplayTT);
  const avgLosIsDisplayTT = useSelector(selectAvgLosIsDisplayTT);
  const cxlIsDisplayTT = useSelector(selectCxlIsDisplayTT);
  //
  const revenueIsDisplayTS = useSelector(selectRevenueIsDisplayTS);
  const occupiedRoomsIsDisplayTS = useSelector(selectOccupiedRoomsIsDisplayTS);
  const adrIsDisplayTS = useSelector(selectAdrIsDisplayTS);
  const avgBookingWindowIsDisplayTS = useSelector(selectAvgBookingWindowIsDisplayTS);
  const avgLosIsDisplayTS = useSelector(selectAvgLosIsDisplayTS);
  const cxlIsDisplayTS = useSelector(selectCxlIsDisplayTS);
  //
  const revenueIsDisplayTH = useSelector(selectRevenueIsDisplayTH);
  const occupiedRoomsIsDisplayTH = useSelector(selectOccupiedRoomsIsDisplayTH);
  const adrIsDisplayTH = useSelector(selectAdrIsDisplayTH);
  const avgBookingWindowIsDisplayTH = useSelector(selectAvgBookingWindowIsDisplayTH);
  const avgLosIsDisplayTH = useSelector(selectAvgLosIsDisplayTH);
  const cxlIsDisplayTH = useSelector(selectCxlIsDisplayTH);
  //
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  //
  const isPercentageChangeDisabled = useSelector(selectIsPercentageChangeDisabled);
  const isTrendDeAggregateDisabled = useSelector(selectIsTrendDeAggregateDisabled);
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [customDatesValueCommand, setCustomDatesValueCommand] = useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  const [tabularViewCommandValue, setTabularViewCommandValue] = useState(null);
  const [customBookingCommandValue, setCustomBookingCommandValue] = useState(null);
  const [forecastCommandValue, setForecastCommandValue] = useState(null);
  const [visualCollapseCommandValue, setVisualCollapseCommandValue] = useState(null);
  const [activeTabCommandValue, setActiveTabCommandValue] = useState(null);
  const [breakdownToggleCommandValue, setBreakdownToggleCommandValue] = useState(null);
  const [symmetricComparisonCollapseCommandValue, setSymmetricComparisonCollapseCommandValue] =
    useState(null);
  const [percentageToggleCommandValue, setPercentageToggleCommandValue] = useState(null);
  const [segmentBreakdownToggleCommandValue, setSegmentBreakdownToggleCommandValue] =
    useState(null);
  const [movingAverageValueCommand, setMovingAverageValueCommand] = useState(null);
  //
  const setMovingAverageHandler = (value) => {
    setMovingAverageValueCommand(value);
  };
  //
  const setTrendTimeAlignmentToggleHandler = (value) => {
    setAlignmentCommandValue(value);
  };
  //
  const setTrendCustomBookingHandler = (value) => {
    setCustomBookingCommandValue(value);
  };
  //
  useEffect(() => {
    if (customBookingCommandValue !== null && isLoaded && urlOrder === 1) {
      if (customBookingCommandValue === commands.OTB) {
        dispatch(dashboardActions.setTrendOtb(0));
        dispatch(dashboardActions.setTrendBookingStyle(false));
        dispatch(dashboardActions.setTrendBookingDate(RESET_BOOKING_DATE));
        dispatch(dashboardActions.setUrlOrder(2));
        setCustomBookingCommandValue(null);
      } else {
        dispatch(dashboardActions.setTrendOtb(true));
        dispatch(dashboardActions.setTrendBookingStyle(true));
      }
    }
  }, [customBookingCommandValue, isLoaded, urlOrder]);
  //
  useEffect(() => {
    if (
      customBookingCommandValue !== null &&
      customBookingCommandValue !== commands.OTB &&
      customBooking &&
      isLoaded &&
      urlOrder === 1
    ) {
      UiController.setBookingDateRange(customBookingCommandValue);
      setCustomBookingCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(2));
    }
  }, [customBookingCommandValue, customBooking, isLoaded, urlOrder]);
  //
  const setTrendTimeCompareWindowHandler = (value) => {
    setCompareWindowValueCommand(value);
  };
  //
  const setCustomDateRangeValue = (data) => {
    setCustomDatesValueCommand(data);
  };
  //
  const setTrendTimeTabularViewValue = (value) => {
    setTabularViewCommandValue(value);
  };
  //
  const setForecastToggleValueHandler = (value) => {
    setForecastCommandValue(value);
  };
  //
  const setVisualCollapseValue = (data) => {
    setVisualCollapseCommandValue(data);
  };
  //
  const setActiveTrendTabValue = (data) => {
    setActiveTabCommandValue(data);
  };
  //
  const setBreakdownToggleValue = (data) => {
    setBreakdownToggleCommandValue(data);
  };
  //
  const setSymmetricComparisonCollapseValue = (data) => {
    setSymmetricComparisonCollapseCommandValue(data);
  };
  //
  const setPercentageToggleValue = (data) => {
    setPercentageToggleCommandValue(data);
  };
  //
  const setSegmentBreakdownToggleValue = (data) => {
    setSegmentBreakdownToggleCommandValue(data);
  };
  // Triggers when movingAverageValueCommand, isLoaded, paceList change
  useEffect(() => {
    if (
      movingAverageValueCommand !== null &&
      isLoaded &&
      movingAverageList?.length > 0 &&
      urlOrder === 4
    ) {
      const option = movingAverageValueCommand?.substring(1);
      const status = parseInt(movingAverageValueCommand?.substring(0, 1), 10);
      if (option !== undefined && status !== undefined) {
        const averages = movingAverageList?.filter((pace) => pace?.label === Number(option));
        if (averages?.length > 0) {
          dispatch(trendActions.setIsMovingAverage(Number(status) === 1));
          dispatch(trendActions.setMovingAverage(averages[0]));
        }
        setMovingAverageValueCommand(null);
        dispatch(dashboardActions.setUrlOrder(5));
      }
    }
  }, [movingAverageValueCommand, isLoaded, urlOrder]);
  // Triggers when breakdownToggleCommandValue, isPercentageChangeDisabled isLoaded change
  useEffect(() => {
    if (segmentBreakdownToggleCommandValue !== null && isLoaded && urlOrder === 14) {
      dispatch(trendActions.setIsTrendDeAggregate(segmentBreakdownToggleCommandValue === 1));
      setSegmentBreakdownToggleCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(15));
    }
  }, [segmentBreakdownToggleCommandValue, isLoaded, isTrendDeAggregateDisabled, urlOrder]);
  // Triggers when breakdownToggleCommandValue, isPercentageChangeDisabled isLoaded change
  useEffect(() => {
    if (percentageToggleCommandValue !== null && isLoaded && urlOrder === 13) {
      dispatch(trendActions.setIsTrendPercentageChange(percentageToggleCommandValue === 1));
      setPercentageToggleCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(14));
    }
  }, [percentageToggleCommandValue, isLoaded, isPercentageChangeDisabled, urlOrder]);
  // Triggers when breakdownToggleCommandValue, isLoaded change
  useEffect(() => {
    if (breakdownToggleCommandValue !== null && isLoaded && urlOrder === 8) {
      dispatch(trendActions.setIsPrimary(breakdownToggleCommandValue === 1));
      setBreakdownToggleCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(10));
    }
  }, [breakdownToggleCommandValue, isLoaded, urlOrder]);

  // Triggers when symmetricComparisonCollapseCommandValue, isLoaded change
  // Triggers when visualCollapseCommandValue, activeTabCommandValue  and isLoaded value change
  useEffect(() => {
    if (
      visualCollapseCommandValue !== null &&
      isLoaded &&
      symmetricComparisonCollapseCommandValue !== null &&
      activeTabCommandValue !== null &&
      String(activeTabCommandValue) === activeTab &&
      urlOrder === 100
    ) {
      if (activeTab === REPORT_TYPE.TREND_SEGMENT && activeTabCommandValue === activeTab) {
        dispatch(trendActions.setRevenueIsDisplayTS(false));
        dispatch(trendActions.setOccupiedRoomIsDisplayTS(false));
        dispatch(trendActions.setAdrIsDisplayTS(false));
        dispatch(trendActions.setAvgBookingWindowIsDisplayTS(false));
        dispatch(trendActions.setAvgLosIsDisplayTS(false));
        dispatch(trendActions.setCxlIsDisplayTS(false));
        dispatch(trendActions.setSymmetricComparisonIsDisplay(false));
        setTimeout(() => {
          dispatch(dashboardActions.setUrlOrder(101));
        }, 3000);
      }

      if (activeTab === REPORT_TYPE.TREND_TIME && activeTabCommandValue === activeTab) {
        dispatch(trendActions.setRevenueIsDisplayTT(false));
        dispatch(trendActions.setOccupiedRoomIsDisplayTT(false));
        dispatch(trendActions.setAdrIsDisplayTT(false));
        dispatch(trendActions.setAvgBookingWindowIsDisplayTT(false));
        dispatch(trendActions.setAvgLosIsDisplayTT(false));
        dispatch(trendActions.setCxlIsDisplayTT(false));
        dispatch(trendActions.setSymmetricComparisonIsDisplay(false));
        setTimeout(() => {
          dispatch(dashboardActions.setUrlOrder(101));
        }, 3000);
      }
      if (activeTab === REPORT_TYPE.TREND_HOTEL && activeTabCommandValue === activeTab) {
        dispatch(trendActions.setRevenueIsDisplayTH(false));
        dispatch(trendActions.setOccupiedRoomIsDisplayTH(false));
        dispatch(trendActions.setAdrIsDisplayTH(false));
        dispatch(trendActions.setAvgBookingWindowIsDisplayTH(false));
        dispatch(trendActions.setAvgLosIsDisplayTH(false));
        dispatch(trendActions.setCxlIsDisplayTH(false));
        dispatch(trendActions.setSymmetricComparisonIsDisplay(false));
        setTimeout(() => {
          dispatch(dashboardActions.setUrlOrder(101));
        }, 3000);
      }
    }
  }, [
    visualCollapseCommandValue,
    activeTabCommandValue,
    activeTab,
    isLoaded,
    urlOrder,
    symmetricComparisonCollapseCommandValue,
  ]);
  // Triggers when visualCollapseCommandValue,activeTabCommandValue, isLoaded  change
  useEffect(() => {
    if (
      visualCollapseCommandValue !== null &&
      symmetricComparisonCollapseCommandValue !== null &&
      activeTabCommandValue !== null &&
      String(activeTabCommandValue) === activeTab &&
      urlOrder === 101
    ) {
      const visuals = visualCollapseCommandValue?.split(',');
      visuals?.forEach((visual) => {
        // get the visual collapse enable/disable status
        const status = parseInt(visual?.substring(1), 10);
        // get the type of visual view
        const visualName = visual?.substring(0, 1);
        if (visualName && status !== undefined) {
          if (
            activeTab === REPORT_TYPE.TREND_TIME &&
            activeTabCommandValue === activeTab &&
            (revenueIsDisplayTT &&
              occupiedRoomsIsDisplayTT &&
              adrIsDisplayTT &&
              avgBookingWindowIsDisplayTT &&
              avgLosIsDisplayTT &&
              cxlIsDisplayTT &&
              symmetricComparisonIsDisplay) === false
          ) {
            switch (visualName) {
              case TABULAR_VIEWS.TOTAL_REVENUE:
                dispatch(trendActions.setRevenueIsDisplayTT(status === 1));
                break;
              case TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS:
                dispatch(trendActions.setOccupiedRoomIsDisplayTT(status === 1));
                break;
              case TABULAR_VIEWS.ADR:
                dispatch(trendActions.setAdrIsDisplayTT(status === 1));
                break;
              case TABULAR_VIEWS.BOOKING_WINDOW:
                dispatch(trendActions.setAvgBookingWindowIsDisplayTT(status === 1));
                break;
              case TABULAR_VIEWS.LENGTH_OF_STAY:
                dispatch(trendActions.setAvgLosIsDisplayTT(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS:
                dispatch(trendActions.setCxlIsDisplayTT(status === 1));
                break;
              default:
                break;
            }
            dispatch(
              trendActions.setSymmetricComparisonIsDisplay(
                symmetricComparisonCollapseCommandValue === 1
              )
            );
            setSymmetricComparisonCollapseCommandValue(null);
            setVisualCollapseCommandValue(null);
            dispatch(dashboardActions.setUrlOrder(102));
            setActiveTabCommandValue(null);
          }
          if (
            activeTab === REPORT_TYPE.TREND_SEGMENT &&
            activeTabCommandValue === activeTab &&
            (revenueIsDisplayTS &&
              occupiedRoomsIsDisplayTS &&
              adrIsDisplayTS &&
              avgBookingWindowIsDisplayTS &&
              avgLosIsDisplayTS &&
              cxlIsDisplayTS &&
              symmetricComparisonIsDisplay) === false
          ) {
            switch (visualName) {
              case TABULAR_VIEWS.TOTAL_REVENUE:
                dispatch(trendActions.setRevenueIsDisplayTS(status === 1));
                break;
              case TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS:
                dispatch(trendActions.setOccupiedRoomIsDisplayTS(status === 1));
                break;
              case TABULAR_VIEWS.ADR:
                dispatch(trendActions.setAdrIsDisplayTS(status === 1));
                break;
              case TABULAR_VIEWS.BOOKING_WINDOW:
                dispatch(trendActions.setAvgBookingWindowIsDisplayTS(status === 1));
                break;
              case TABULAR_VIEWS.LENGTH_OF_STAY:
                dispatch(trendActions.setAvgLosIsDisplayTS(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS:
                dispatch(trendActions.setCxlIsDisplayTS(status === 1));
                break;
              default:
                break;
            }
            dispatch(
              trendActions.setSymmetricComparisonIsDisplay(
                symmetricComparisonCollapseCommandValue === 1
              )
            );
            setSymmetricComparisonCollapseCommandValue(null);
            setVisualCollapseCommandValue(null);
            dispatch(dashboardActions.setUrlOrder(102));
            setActiveTabCommandValue(null);
          }
          if (
            activeTab === REPORT_TYPE.TREND_HOTEL &&
            activeTabCommandValue === activeTab &&
            (revenueIsDisplayTH &&
              occupiedRoomsIsDisplayTH &&
              adrIsDisplayTH &&
              avgBookingWindowIsDisplayTH &&
              avgLosIsDisplayTH &&
              cxlIsDisplayTH &&
              symmetricComparisonIsDisplay) === false
          ) {
            switch (visualName) {
              case TABULAR_VIEWS.TOTAL_REVENUE:
                dispatch(trendActions.setRevenueIsDisplayTH(status === 1));
                break;
              case TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS:
                dispatch(trendActions.setOccupiedRoomIsDisplayTH(status === 1));
                break;
              case TABULAR_VIEWS.ADR:
                dispatch(trendActions.setAdrIsDisplayTH(status === 1));
                break;
              case TABULAR_VIEWS.BOOKING_WINDOW:
                dispatch(trendActions.setAvgBookingWindowIsDisplayTH(status === 1));
                break;
              case TABULAR_VIEWS.LENGTH_OF_STAY:
                dispatch(trendActions.setAvgLosIsDisplayTH(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS:
                dispatch(trendActions.setCxlIsDisplayTH(status === 1));
                break;
              default:
                break;
            }
            dispatch(
              trendActions.setSymmetricComparisonIsDisplay(
                symmetricComparisonCollapseCommandValue === 1
              )
            );
            setSymmetricComparisonCollapseCommandValue(null);
            setVisualCollapseCommandValue(null);
            dispatch(dashboardActions.setUrlOrder(102));
            setActiveTabCommandValue(null);
          }
        }
      });
    }
  }, [
    visualCollapseCommandValue,
    activeTabCommandValue,
    activeTab,
    urlOrder,
    revenueIsDisplayTT,
    occupiedRoomsIsDisplayTT,
    adrIsDisplayTT,
    avgBookingWindowIsDisplayTT,
    avgLosIsDisplayTT,
    cxlIsDisplayTT,
    revenueIsDisplayTS,
    occupiedRoomsIsDisplayTS,
    adrIsDisplayTS,
    avgBookingWindowIsDisplayTS,
    avgLosIsDisplayTS,
    cxlIsDisplayTS,
    revenueIsDisplayTH,
    occupiedRoomsIsDisplayTH,
    adrIsDisplayTH,
    avgBookingWindowIsDisplayTH,
    avgLosIsDisplayTH,
    cxlIsDisplayTH,
    symmetricComparisonCollapseCommandValue,
    symmetricComparisonIsDisplay,
  ]);
  // Triggers when forecastCommandValue and isLoaded value change
  useEffect(() => {
    if (forecastCommandValue !== null && isLoaded && urlOrder === 16) {
      dispatch(trendActions.setIsTrendForecast(forecastCommandValue === 1));
      setForecastCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(17));
    }
  }, [forecastCommandValue, isForecastDisabled, isLoaded, urlOrder]);

  // Triggers when tabularViewCommandValue and isLoaded states change
  useEffect(() => {
    if (tabularViewCommandValue !== null && isLoaded && urlOrder === 11) {
      // tabular view command flow
      dispatch(trendActions.setTabularView(Number(tabularViewCommandValue) === 1));
      setTabularViewCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(12));
    }
  }, [tabularViewCommandValue, isLoaded, urlOrder]);
  // Triggers when alignmentCommandValue,visualFilters  and isLoaded states change
  useEffect(() => {
    if (alignmentCommandValue !== null && visualFilters && isLoaded && urlOrder === 6) {
      handleAlignmentToggleChange({
        target: {
          checked: alignmentCommandValue,
        },
      });
      setAlignmentCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(7));
    }
  }, [alignmentCommandValue, visualFilters, isLoaded, urlOrder]);
  /**
   * set compare window value based on command value
   * @param {String} value - command value
   * @param {String} comparison - date comparison
   */
  const compareWindowCommandHandler = (value, comparison) => {
    if (comparison) {
      // get compare window value
      const windowSelected = compareWindows[value];
      if (windowSelected !== undefined) {
        const index = comparison.indexOf(windowSelected);
        // checks if option is already selected
        if (index === -1) {
          dispatch(trendActions.setTrendTimeDateComparison(windowSelected));
        }
        if (windowSelected === KEYS.CUSTOM) {
          setArbitraryComparisonDate();
        }
        dispatch(dashboardActions.setUrlOrder(11));
      }
    }
  };

  // Triggers when compareWindowValueCommand, date comparison and isLoaded value change
  useEffect(() => {
    if (
      compareWindowValueCommand !== null &&
      dateComparison?.length > 0 &&
      isLoaded &&
      urlOrder === 10
    ) {
      compareWindowCommandHandler(compareWindowValueCommand, dateComparison);
      setCompareWindowValueCommand(null);
    }
  }, [compareWindowValueCommand, dateComparison?.length, isLoaded, urlOrder]);
  // reference function to set custom compare window
  setCustomDatesRef.current = () => {
    setTimeout(() => {
      const datesExtracted = customDatesValueCommand?.split('-');
      dispatch(trendActions.setTrendTimeCustomDate(datesExtracted[0]));
      UiController.setCustomDateRange(customDatesValueCommand);
      setCustomDatesValueCommand(null);
    }, 2000);
  };
  // Triggers when customDatesValueCommand, dateComparison, isLoaded values change
  useEffect(() => {
    if (customDatesValueCommand !== null && isLoaded && urlOrder >= 10) {
      // check date comparison value is custom
      if (dateComparison && dateComparison?.includes(KEYS.CUSTOM)) {
        setCustomDatesRef?.current();
      }
    }
  }, [customDatesValueCommand, dateComparison, isLoaded, urlOrder]);
  // Add event listener function to set custom booking date range command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_CUSTOM_BOOKING, setTrendCustomBookingHandler);
    return () => {
      UiController.unsubscribe(events.SET_TREND_CUSTOM_BOOKING, setTrendCustomBookingHandler);
    };
  }, []);
  // Add event listener function to set alignment toggle command value
  useEffect(() => {
    UiController.subscribe(events.TREND_TIME_ALIGNMENT, setTrendTimeAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.TREND_TIME_ALIGNMENT, setTrendTimeAlignmentToggleHandler);
    };
  }, []);
  // Add event listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_COMPARE_WINDOW, setTrendTimeCompareWindowHandler);
    return () => {
      UiController.unsubscribe(events.SET_TREND_COMPARE_WINDOW, setTrendTimeCompareWindowHandler);
    };
  }, [dateComparison]);
  // Add event listener function to set custom date range command value in custom compare window
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_TIME_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    UiController.subscribe(events.SET_TREND_TIME_TABULAR_VIEW, setTrendTimeTabularViewValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_TIME_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
      UiController.unsubscribe(events.SET_TREND_TIME_TABULAR_VIEW, setTrendTimeTabularViewValue);
    };
  }, []);
  // Add event listener function to set forecast toggle command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_FORECAST, setForecastToggleValueHandler);
    return () => {
      UiController.unsubscribe(events.SET_TREND_FORECAST, setForecastToggleValueHandler);
    };
  }, [dateComparison]);
  // Add Listener function to set visual collapse
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_VISUAL_COLLAPSE, setVisualCollapseValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_VISUAL_COLLAPSE, setVisualCollapseValue);
    };
  }, []);
  // Add Listener function to set active trend tab
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_ACTIVE_TAB, setActiveTrendTabValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_ACTIVE_TAB, setActiveTrendTabValue);
    };
  }, []);
  // Add Listener function to set breakdown toggle
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_BREAKDOWN_TOGGLE, setBreakdownToggleValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_BREAKDOWN_TOGGLE, setBreakdownToggleValue);
    };
  }, []);
  // Add Listener function to set symmetric comparison collapse
  useEffect(() => {
    UiController.subscribe(
      events.SET_TREND_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_TREND_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseValue
      );
    };
  }, []);
  // Add Listener function to set percentage toggle
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_PERCENTAGE_TOGGLE, setPercentageToggleValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_PERCENTAGE_TOGGLE, setPercentageToggleValue);
    };
  }, []);
  // Add Listener function to set segment breakdown toggle
  useEffect(() => {
    UiController.subscribe(
      events.SET_TREND_SEGMENT_BREAKDOWN_TOGGLE,
      setSegmentBreakdownToggleValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_TREND_SEGMENT_BREAKDOWN_TOGGLE,
        setSegmentBreakdownToggleValue
      );
    };
  }, []);
  // Add Listener function to set moving  average
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_MOVING_AVERAGE, setMovingAverageHandler);
    return () => {
      UiController.unsubscribe(events.SET_TREND_MOVING_AVERAGE, setMovingAverageHandler);
    };
  }, []);
};
//
export default useTrendTimeCommandFlow;
