/**
 * Here mentioned users feature request structure
 */
const API = {
  GET_USER_LIST: {
    path: '/organizations/:organizationId/users?:query',
    method: 'GET',
  },
  GET_SELECTED_USER_LIST: {
    path: '/organizations/:organizationId/users?:query',
    method: 'GET',
  },
  GET_ROLES: {
    path: '/organizations/:organizationId/roles',
    method: 'GET',
  },
  GET_HOTELS: {
    path: '/organizations/:organizationId/users/:userId/hotels?:query',
    method: 'GET',
  },
  GET_ALL_HOTELS: {
    path: '/organizations/:organizationId/hotels?:query',
    method: 'GET',
  },
  POST_USERS: {
    path: '/organizations/:organizationId/users',
    method: 'POST',
  },
  POST_USERS_ADMIN: {
    path: '/organizations/:organizationId/users/admin',
    method: 'POST',
  },
  POST_USERS_GENERAL: {
    path: '/organizations/:organizationId/users/general',
    method: 'POST',
  },
  PATCH_USER: {
    path: '/organizations/:organizationId/users/:userId',
    method: 'PATCH',
  },
  PATCH_ROLE: {
    path: '/organizations/:organizationId/users/:userId/assign-roles',
    method: 'PATCH',
  },
  PATCH_HOTEL: {
    path: '/organizations/:organizationId/users/:userId/hotels',
    method: 'PATCH',
  },
  DELETE_USER: {
    path: '/organizations/:organizationId/users/:userId',
    method: 'DELETE',
  },
  GET_USER_BY_ID: {
    path: '/organizations/:organizationId/users/:userId',
    method: 'GET',
  },
  POST_CHANGE_PASSWORD: {
    path: '/organizations/:organizationId/users/:userId/update-password',
    method: 'POST',
  },
  GET_LANDING_PAGE: {
    path: '/organizations/:organizationId/users/:userId/configurations/landing-page',
    method: 'GET',
  },
  POST_LANDING_PAGE: {
    path: '/organizations/:organizationId/users/:userId/configurations/landing-page',
    method: 'POST',
  },
  GET_STAY_DATE_PRESET: {
    path: '/organizations/:organizationId/users/:userId/configurations',
    method: 'GET',
  },
  POST_STAY_DATE_PRESET: {
    path: '/organizations/:organizationId/users/:userId/configurations/custom-date-range',
    method: 'POST',
  },
  DELETE_STAY_DATE_PRESET: {
    path: '/organizations/:organizationId/users/:userId/configurations/custom-date-range/:customDateRangeId',
    method: 'DELETE',
  },
  POST_HOTEL_METADATA: {
    path: '/organizations/:organizationId/users/:userId/hotel-metadata',
    method: 'POST',
  },
  GET_CURRENCY_DATA: {
    path: '/organizations/:organizationId/currency?:query',
    method: 'GET',
  },
};
//
export default API;
