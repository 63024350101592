// Define a function getResult using arrow function syntax
const getMapperResult = (alignment, dateComparison) => {
  // Define a mapping object that maps alignment states and dateComparison values to index numbers
  const mapping = {
    true: {
      'vs OTB': 1,
      'vs Previous Week (OTB)': 2,
      'vs Previous Month (OTB)': 5,
      'vs Previous Year (OTB)': 6,
      Custom: 7, // Add Custom index for alignment true
    },
    false: {
      'vs OTB': 1,
      'vs Previous Week (OTB)': 2,
      'vs Previous Month (OTB)': 3,
      'vs Previous Year (OTB)': 4,
      Custom: 7, // Add Custom index for alignment false
    },
  };

  // Select the appropriate mapping based on the alignment
  const alignmentMapping = alignment ? mapping.true : mapping.false;

  // Return the index number corresponding to the dateComparison value
  return alignmentMapping[dateComparison];
};
//
export default getMapperResult;
