import { ThemeProvider } from 'modules/common/contexts/theme-context';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import useTheme from 'modules/common/hooks/use-theme';
import createTheme from 'modules/common/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import store from './store';

/**
 * @returns Entry point in the react application. And also register the providers
 * store-provider :-The single Redux store in your application.
 * BrowserRouter :-  BrowserRouter is a router implementation that uses the HTML5 history API(pushState,
 * replaceState and the popstate event) to keep your UI in sync with the URL (https://reactrouter.com/en/main/router-components/browser-router)
 * ThemeProvider :- Common theme context for entire application
 * MuiThemeProvider :- It makes the theme available down the React tree thanks to React context (https://v3.mui.com/api/mui-theme-provider/)
 * Router :- Entire application protected and public routes
 * ToastContainer :- The ToastContainer is just a simple component, which you can write text or even custom (https://fkhadra.github.io/react-toastify/api/toast-container/)
 * JSX elements in to customize the toast even more
 */
const App = () => {
  const { theme } = useTheme();
  //
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <MuiThemeProvider theme={createTheme(theme)}>
            <Router />
            <ToastContainer />
          </MuiThemeProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};
//
export default App;
