/* eslint-disable import/prefer-default-export */
/**
 * Calculate forecast total value function to calculate forecast total for a date
 */
export const calculateForecastTotalValue = (object) => {
  //
  const forecastTotalList = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    const sum = value.reduce(
      (previousValue, currentValue) => previousValue + currentValue.rate * currentValue.forecast,
      0
    );
    const sumOfForecast = value.reduce(
      (previousValue, currentValue) => previousValue + currentValue.forecast,
      0
    );
    //
    forecastTotalList[key] = {
      forecastTotal: Math.round(sum / sumOfForecast),
    };
  }
  //
  return forecastTotalList;
};
