/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { workbookActions } from './slice';
import { API } from './constants';

/**
 * Workbook data export url generator function to obtain data for PDF
 * @param {*} param0
 */
export function* getPdfExportUrlGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_PDF_EXPORT_URL, payload);
    yield put(workbookActions.getWorkbookPdfExportUrlSucceeded(response));
  } catch (error) {
    yield put(workbookActions.getWorkbookPdfExportUrlFailed(error?.message));
  }
}
/**
 * Workbook data export url generator function to obtain data for PDF
 * @param {*} param0
 */
export function* getPdfExportGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_PDF_EXPORT, payload);
    yield put(workbookActions.getWorkbookPdfExportSucceeded(response));
  } catch (error) {
    yield put(workbookActions.getWorkbookPdfExportFailed(error?.message));
  }
}
//
export function* workbookReportSaga() {
  yield takeLatest(workbookActions.getWorkbookPdfExportUrl.type, getPdfExportUrlGenerator);
}
//
export default workbookReportSaga;
