/**
 * Accepts the object and value and returns the key
 * @param {*} object
 * @param {*} value
 * @returns
 */
//
const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);
//
export default getKeyByValue;
