/* eslint-disable no-unused-vars */
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import { KEYS, OTB_VALUE, REPORT_TYPE } from 'modules/dashboard/constants';
import CustomDatePicker from 'modules/dashboard/components/tab-container/inner-filter/components/custom-date-comparison';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { PRIMARY } from 'modules/dashboard/components/tab-container/trend-tabs/constants';
import CollapseIcon from 'modules/common/components/collapse-icon';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
/**
 * trend time Vs comparison window component common for the dashboard component
 * @param {Boolean} customBooking - Custom booking selected state
 * @param {Boolean} alignmentToggle - Alignment toggle on/off state
 * @param {Function} handleToggleChange - Function to call when alignment toggle value changes
 * @param {Boolean} symmetricComparisonToggle - Symmetric comparison toggle on/off state
 * @param {Object} visual - PBI visuals for comparison window
 * @param {*} dateComparison - Selected date comparison value
 * @param {Array} dateComparisonList - Date comparison list
 * @param {Function} handleComparisonChange - Function to call when date comparison value changes
 * @param {Function} handleSymmetricComparisonChange - Function to call symmetric comparison value changes
 * @param {Boolean} weeklyViewToggle - weekly view toggle state
 * @param {Boolean} isMultiple - is Multiple state
 * @param {Date} customDate - Custom date
 * @param {Date} startDate -  Start date
 * @param {Date} endDate - End date
 * @param {Date} targetDate - Target date
 * @param {*} reportType - reportType
 * @param {Function} handleCustomDates - Function to call when custom dates value changes
 * @returns
 */
const TrendTimeVsComparisonWindow = ({
  customBooking,
  alignmentToggle,
  handleToggleChange,
  symmetricComparisonToggle,
  visual,
  visualName,
  dateComparison,
  dateComparisonList,
  handleComparisonChange,
  handleSymmetricComparisonChange,
  weeklyViewToggle,
  activeTab,
  showBreakdown = true,
  customDate = null,
  reportType = null,
  startDate = null,
  endDate = null,
  targetDate = null,
  handleCustomDates = () => {},
  isDisplay,
  isDisplayOnClickAction = () => {},
  isPrimary,
  handlePrimaryChange,
  id = '',
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container justifyContent="space-between" flexDirection="row" alignItems="flex-start">
        <Grid item xs={4} paddingTop={1}>
          <FormLabel sx={{ fontSize: 13 }}>VS. Time</FormLabel>
        </Grid>

        {/* <Grid item xs={10} container flexDirection="row">
          <Grid
            container
            item
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={11}>
              <Typography fontSize={11}>{visualName}</Typography>
            </Grid>
            <Grid item xs={1}>
              <CollapseIcon isDisplay={isDisplay} onClick={isDisplayOnClickAction} tooltipText />
            </Grid>
            <Grid item xs={12}>
              {isDisplay && visual}
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <br />
      <CustomToggleSwitch
        label="Alignment"
        secondaryLabel="day-of-week"
        title="Align day-of-week between selected & comparison stay dates."
        onChange={
          weeklyViewToggle && activeTab === REPORT_TYPE.TREND_TIME ? undefined : handleToggleChange
        }
        checked={alignmentToggle}
        paddingBottom={3}
      />
      <CustomToggleSwitch
        label="Same Time Last Period"
        title="Use comparable lead-time and booking window between selected & comparison stay dates."
        onChange={handleSymmetricComparisonChange}
        checked={symmetricComparisonToggle}
        paddingBottom={3}
      />
      <Grid item xs={10} container flexDirection="row" paddingBottom={2}>
        <Grid container item flexDirection="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={11}>
            <Typography fontSize={11}>{visualName}</Typography>
          </Grid>
          <Grid item xs={1}>
            <CollapseIcon isDisplay={isDisplay} onClick={isDisplayOnClickAction} tooltipText />
          </Grid>
          <Grid item xs={12}>
            {isDisplay && visual}
          </Grid>
        </Grid>
      </Grid>
      {showBreakdown && (
        <FormGroup>
          <FormControlLabel
            key={0}
            value={PRIMARY}
            control={<Checkbox icon={<CircleOutlinedIcon />} checkedIcon={<CircleIcon />} />}
            label={PRIMARY}
            checked={isPrimary}
            onChange={handlePrimaryChange}
            sx={{
              mb: 1,
            }}
          />
        </FormGroup>
      )}
      <FormGroup>
        <RadioGroup value={dateComparison} onChange={handleComparisonChange}>
          {dateComparisonList?.map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.value}
              control={<Radio id={`${id}-${item?.label.toLowerCase()?.replace(' ', '-')}`} />}
              label={item?.label}
              disabled={
                (item?.value === OTB_VALUE && !customBooking) ||
                activeTab === REPORT_TYPE.TREND_HOTEL
              }
              sx={{
                my: -1,
              }}
            />
          ))}
        </RadioGroup>
      </FormGroup>
    </FormControl>
    {dateComparison && dateComparison?.includes(KEYS.CUSTOM) && (
      <>
        <FormControl>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={customDate}
            exclusive
            onChange={handleCustomDates}
          >
            <ToggleButton key="start" value={KEYS.START_DATE}>
              Start Date
            </ToggleButton>
            <ToggleButton key="end" value={KEYS.END_DATE}>
              End Date
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <Grid container direction="row" alignItems="center" justifyContent="center" paddingTop={0}>
          <CustomDatePicker
            reportType={reportType}
            targetDate={targetDate}
            customDate={customDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </>
    )}
  </CustomStack>
);
//
export default TrendTimeVsComparisonWindow;
