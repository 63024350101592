import { ENVIRONMENT } from 'config';
import setHeaders from './fetch';
import getUrlTail from './string';
import { CHAT_ROLES } from '../ui-controller/utils/constants';
/**
 * Function to set url and chat msg from text response from chat gpt
 * @param {*} dataResponse - text response coming form chat api endpoint
 * @param {*} setUrl - function to set retrieved url
 * @param {*} setChatMsg - function to set message to chat flow
 */
export const setUrlTailAndChatMessage = (dataResponse, setUrl, setChatMsg) => {
  if (dataResponse?.includes('URL_TAIL==')) {
    // spilt string by "{ACK} "
    const splitCommands = dataResponse
      .trim()
      ?.split('{ACK}')
      .filter((com) => com);
    //
    splitCommands?.forEach((com) => {
      // check response array has one value and it includes "URL_TAIL==" substring
      if (splitCommands?.length === 1 && com?.includes('URL_TAIL==')) {
        // set url for execute command flow by removing "URL_TAIL==" substring
        const urlTail = getUrlTail(com);
        setUrl(urlTail.trim());
        // check if there is only url tail part if not display whole message
        if (com?.startsWith('URL_TAIL'.trim())) {
          setChatMsg('Applying filter...');
        } else {
          setChatMsg(com);
        }
      } else if (com?.includes('URL_TAIL==')) {
        // check command has "URL_TAIL==" substring and set url
        const urlTail = getUrlTail(com);
        setUrl(urlTail.trim());
      } else {
        // set display response with other commands that does not include "URL_TAIL==" substring
        setChatMsg(com?.trim());
      }
    });
  } else {
    // set display response  does not include "URL_TAIL==" substring by removing {ACK}
    const stream = dataResponse.trim()?.replaceAll('{ACK}', '');
    setChatMsg(stream);
  }
};
/**
 * Function to decode byte stream
 * @param {*} dataStream - data stream response
 * @param {*} setData - Function to set decoded text data
 */
export const decodeDataStream = async (dataStream, setData) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await dataStream.read();
    if (done) {
      break;
    }
    // Process the streamed data
    const chunk = new TextDecoder('utf-8').decode(value);
    // set chat response
    setData(chunk);
  }
};
/**
 * Function to call transcribe audio api endpoint and set transcribe audio message
 * @param {*} recorder - mic recorder instance
 * @param {*} organizationId - organization Id
 * @param {*} userId user id
 * @param {*} setMsg - Function to set chat input field value
 * @param {*} setChatState - Function to set chat flow array
 * @param {*} chatState - chat message array
 */
export const transcribeAudio = async (
  recorder,
  organizationId,
  userId,
  setMsg,
  setChatState,
  chatState
) => {
  try {
    // get audio buffer
    const [buffer] = await recorder.stop().getMp3();
    // create a audio file
    const file = new File(buffer, 'file.mp3', {
      type: 'audio/webm',
    });
    const formData = new FormData();
    formData.append('file', file);
    //

    const optionsFetch = {
      method: 'POST',
      responseType: 'stream',
      headers: {
        Authorization: '',
      },
      body: formData,
    };
    // add authentication headers
    const authenticatedOptions = await setHeaders(optionsFetch);
    // calls for chat endpoint with recorded audio file
    const response = await fetch(
      `${ENVIRONMENT.BACKEND_API}/organizations/${organizationId}/users/${userId?.id}/transcribe-audio`,
      authenticatedOptions
    );
    // get response stream
    const stream = response?.body.getReader();
    //
    const processStreamAudio = async () => {
      decodeDataStream(stream, setMsg);
    };
    processStreamAudio();
  } catch (error) {
    // set error msg in chat flow
    setChatState([
      ...chatState,
      {
        message: 'Something went wrong',
        role: CHAT_ROLES.SYSTEM,
      },
    ]);
  }
};
/**
 * Function to call chat api endpoint and set chat response
 * @param {*} message
 * @param {*} organizationId
 * @param {*} userId
 * @param {*} setStreamData
 * @param {*} setChatState
 * @param {*} chatState
 */
export const handleFetchChatResponse = async (
  message,
  organizationId,
  userId,
  setStreamData,
  setChatState,
  chatState
) => {
  const optionsFetch = {
    method: 'POST',
    responseType: 'stream',
    headers: {
      Authorization: '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message,
    }),
  };
  // add authentication headers
  const authenticatedOptions = await setHeaders(optionsFetch);
  //
  try {
    const response = await fetch(
      `${ENVIRONMENT.BACKEND_API}/organizations/${organizationId}/users/${userId?.id}/chat`,
      authenticatedOptions
    );
    // get response stream
    const stream = response?.body.getReader();
    const processStream = async () => {
      await decodeDataStream(stream, setStreamData);
    };
    processStream();
  } catch (error) {
    // set error msg in chat flow
    setChatState([
      ...chatState,
      {
        message: 'Something went wrong',
        role: CHAT_ROLES.SYSTEM,
      },
    ]);
  }
};
