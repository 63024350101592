import { useState, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  selectPmsSync,
  selectVisualFilters,
  selectHotelName,
  selectBreakdownDetails,
  selectEnableHotelGroups,
} from 'modules/dashboard/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { getSelectedBreakdown, shortcut } from 'modules/dashboard/functions';
import {
  FILTER_VALUES,
  RESET_BOOKING_DATE,
  SHORTCUT_KEY,
  TAB_VALUE,
  TAGS,
} from 'modules/dashboard/constants';
import PAGINATION from 'modules/common/constants/pagination-limits';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { CheckboxDropdown, CustomStack } from 'modules/common/components';
import ActionButton from 'modules/common/components/action-button';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import PmsToggle from 'modules/dashboard/components/tab-container/inner-filter/components/pms-toggle';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import {
  getFocusOnFilter,
  getSubBreakdownFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import {
  selectRegionMapFocusOn,
  selectRegionMapChartBreakdown,
  selectRegionalMapOtb,
  selectIsRegionDetailed,
  selectIsFocusOnSet,
} from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';
import { regionalMapActions } from 'modules/dashboard/components/tab-container/regional-map-tab/slice';
import useRegionalMapCommandFlow from '../../hooks/use-regional-map-command-flow';
import { COMMON_FOCUS_ON_OPTIONS } from '../../../trend-tabs/constants';
/**
 * Filters for Regional Map widget include PMS Sync, Booking Dates, Chart Breakdown and Focus on
 * @param {Number} tabValue - Pace widget tab value
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Boolean} hotelTimezone - Timezone of selected hotel
 * @param {Number} toggleDisabled - PMS sync toggle disabled state
 * @param {Boolean} isLoaded - Visual is loaded state
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const RegionalMapFilter = ({
  tabValue,
  latestDate,
  hotelTimezone,
  toggleDisabled,
  isLoaded,
  reportType,
}) => {
  const dispatch = useDispatch();

  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const pmsSyncToggle = useSelector(selectPmsSync);
  const visualFilters = useSelector(selectVisualFilters);
  const focusOn = useSelector(selectRegionMapFocusOn);
  const chartBreakdown = useSelector(selectRegionMapChartBreakdown);
  const hotel = useSelector(selectHotelName);
  const breakdownData = useSelector(selectBreakdownDetails);
  const organizationId = useSelector(selectOrganizationId);
  const customBooking = useSelector(selectRegionalMapOtb);
  const isDetailed = useSelector(selectIsRegionDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const isFocusOnSet = useSelector(selectIsFocusOnSet);
  //
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = useState();
  const [isDetailDisabled, setIsDetailDisabled] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  //
  useEffect(() => {
    if (focusOn?.length > 0 && !isFocusOnSet) {
      dispatch(regionalMapActions.setIsFocusOnSet(true));
    }
  }, [focusOn, isFocusOnSet]);
  // set all focus on options when trend segment is selected with overall option
  useEffect(() => {
    if (focusOnList?.length > 0) {
      const filteredValues = focusOnList?.filter(
        (option) => option?.label !== COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label
      );
      dispatch(regionalMapActions.setRegionMapFocusOn(filteredValues));
    }
  }, [focusOnList]);
  // check whether all focus on options are selected or not
  useEffect(() => {
    if (focusOnList?.length > 0) {
      const allFocusOnOptionsSelected = focusOnList.length - 1 === focusOn.length;
      setAllSelected(allFocusOnOptionsSelected);
    }
  }, [focusOn, focusOnList]);

  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(regionalMapActions.setRegionalMapOtb(value !== 'false'));
    dispatch(regionalMapActions.setRegionMapBookingDate(RESET_BOOKING_DATE));
  };

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(regionalMapActions.setRegionalMapOtb(false));
    dispatch(regionalMapActions.setRegionMapBookingDate(RESET_BOOKING_DATE));
    dispatch(dashboardActions.setPmsSync(false));
    const allOptions = focusOnList.filter(
      (option) => option.label !== COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label
    );
    dispatch(regionalMapActions.setRegionMapFocusOn(allOptions));
    setAllSelected(true);
    dispatch(regionalMapActions.setIsRegionDetailed(false));
    dispatch(
      regionalMapActions.setRegionMapChartBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0].id
      )
    );
  };

  // Triggered when visualFilter value changes
  useEffect(() => {
    // Set chart breakdown list
    const subChartBreakdownList = getSubBreakdownFilter(visualFilters, TAGS.REGION);
    if (subChartBreakdownList) {
      setChartBreakdownList(subChartBreakdownList);
    }
  }, [visualFilters]);

  // Triggered on value change of chartBreakdown, visualFilters, breakdownData or selectedBreakdown
  useEffect(() => {
    // Set focus on list relevant for selected chart breakdown
    if (chartBreakdown) {
      const focusOnFilter = getFocusOnFilter(breakdownData, selectedBreakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [chartBreakdown, visualFilters, breakdownData, selectedBreakdown]);

  // Triggered on hotel change
  useEffect(() => {
    // Get hotel breakdown sub categories data
    if (hotel?.id) {
      const query = `hotelId=${hotel?.id}&limit=${PAGINATION.BREAKDOWN_DATA}`;
      dispatch(dashboardActions.getBreakdown({ organizationId, query }));
    }
  }, [hotel]);
  //
  const focusOnLabel = focusOn?.length ? 'Focused on' : 'Select Focus on';

  // Shortcut function to set pms sync value
  shortcut(tabValue, TAB_VALUE.REGION_MAP, SHORTCUT_KEY.PMS_TOGGLE, () =>
    dispatch(dashboardActions.setPmsSync(!pmsSyncToggle))
  );

  // Triggered on isDetailed, chartBreakdown, visualFilters change
  useEffect(() => {
    // Assign values to selected breakdown on chart breakdown or isDetails toggle change
    const breakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    setSelectedBreakdown(breakdown);
  }, [isDetailed, chartBreakdown, visualFilters]);

  // Triggered on isDetailed, selectedBreakdown change
  useEffect(() => {
    // Disabled rate category details toggle
    if (
      selectedBreakdown === FILTER_VALUES.RATE_CATEGORY ||
      selectedBreakdown === FILTER_VALUES.RATE_CATEGORY_RAW
    ) {
      dispatch(regionalMapActions.setIsRegionDetailed(false));
      dispatch(regionalMapActions.setRegionMapFocusOn([]));
      setIsDetailDisabled(true);
    } else {
      setIsDetailDisabled(false);
    }
  }, [isDetailed, selectedBreakdown]);
  /**
   * set breakdown value
   * @param {*} event - onChanged event
   */
  const handleBreakdownChange = (event) => {
    dispatch(regionalMapActions.setRegionMapChartBreakdown(event.target.value));
    dispatch(regionalMapActions.setRegionMapFocusOn([]));
  };
  /**
   * set isDetailed value
   * @param {*} event - onChanged event
   */
  const handleIsDetailedChange = (event) => {
    dispatch(regionalMapActions.setIsRegionDetailed(event.target.checked));
    dispatch(regionalMapActions.setRegionMapFocusOn([]));
  };
  /**
   * set focus on value
   * @param {*} event - on changed event
   * @param {*} value - selected value
   */
  const handleFocusOnChange = (event, value, reason) => {
    // dispatch(regionalMapActions.setRegionMapFocusOn(value));
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (
        value.find((option) => option.label === COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label) &&
        value?.filter((option) => option.label !== COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label)
          .length === focusOn.length &&
        value?.length === focusOnList.length
      ) {
        dispatch(regionalMapActions.setRegionMapFocusOn([]));
        setAllSelected(false);
      } else if (
        value.find((option) => option.label === COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label)
      ) {
        // Select all options if "Select All" is not selected
        const allOptions = focusOnList.filter(
          (option) => option.label !== COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label
        );
        dispatch(regionalMapActions.setRegionMapFocusOn(allOptions));
        setAllSelected(true);
      } else {
        dispatch(regionalMapActions.setRegionMapFocusOn(value));
        setAllSelected(
          value?.filter((option) => option.label !== COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label)
            .length ===
            focusOnList?.filter(
              (option) => option.label !== COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label
            ).length
        );
      }
    }
  };
  // reset focus on option when hotel groups is selected
  useEffect(() => {
    dispatch(regionalMapActions.setRegionMapFocusOn([]));
  }, [enableHotelGroups]);
  //
  // Regional map command flow implementation
  useRegionalMapCommandFlow(
    isLoaded,
    customBooking,
    chartBreakdownList,
    isDetailDisabled,
    focusOnList
  );
  //
  return (
    <>
      <PmsToggle
        disabled={toggleDisabled}
        value={pmsSyncToggle}
        onChange={(event) => dispatch(dashboardActions.setPmsSync(event.target.checked))}
      />
      <br />
      <BookingWindow
        vOTB={customBooking}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={false}
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <FormControl>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ color: '#0009' }}>Breakdown</Typography>
            <FormControl>
              <FormControlLabel
                control={<Switch checked={isDetailed} onChange={handleIsDetailedChange} />}
                sx={{ '& .css-1cn7wjw-MuiTypography-root': { fontSize: 12 } }}
                label="Details"
                disabled={isDetailDisabled}
              />
            </FormControl>
          </Grid>
          <RadioGroup value={chartBreakdown ?? null} onChange={handleBreakdownChange}>
            {chartBreakdownList?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio />}
                label={item?.label}
                sx={{
                  my: -1,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <CheckboxDropdown
            options={focusOnList}
            values={focusOn}
            label={focusOnLabel}
            onchange={handleFocusOnChange}
            isOptionEqualTo={(option, value) => option?.id === value?.id}
            allSelected={allSelected}
          />
        </FormControl>
      </CustomStack>
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default RegionalMapFilter;
