import { memo } from 'react';
import useAuthorization from 'modules/common/auth/hooks/use-authorization';
/**
 * Auth wrapper for entire authentication routes
 */
const AuthWrapper = memo(({ children }) => {
  const { isAuthenticated } = useAuthorization();

  return isAuthenticated() && children;
});
//
export default AuthWrapper;
