import { useSelector } from 'react-redux';
import { Loader } from 'modules/common/components';
import { selectLoader } from 'modules/dashboard/selectors';
// import usePingSqlWarehouse from 'modules/common/hooks/use-ping-sql-warehouse';
import TabContainer from 'modules/dashboard/components/tab-container';
/**
 * Dashboard View including all tab containers
 * @returns
 */
const DashboardView = () => {
  // usePingSqlWarehouse();
  //
  const loading = useSelector(selectLoader);
  //
  return (
    <Loader loading={loading}>
      <TabContainer />
    </Loader>
  );
};
//
export default DashboardView;
