import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTargetDate,
  selectVisualFilters,
  selectHotelName,
  selectEnableHotelGroups,
  selectPmsSync,
  selectReportType,
  selectIsDemoUser,
  selectLocalCurrency,
  selectWorkbookDateType,
} from 'modules/dashboard/selectors';
import {
  generateWorkbookShareUrl,
  getAlignmentAndDateComparisonIndexSchema,
  getBookingDateSchema,
  getCustomDateComparisonSchema,
  getDashboardPmsSyncSchema,
  getDateSwitchSchema,
  getHotelGroupASchema,
  getHotelGroupOrderSchema,
  getHotelGroupSchema,
  getHotelIdSchema,
  getNormalizedViewSchema,
  getPmsSyncSchema,
  getSymmetricComparisonSchema,
  getWorkbookTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { workbookActions } from 'modules/dashboard/components/tab-container/workbook-report/slice';
import {
  selectAlignment,
  selectDateComparison,
  selectWorkbookBookingDate,
  selectSymmetricComparisonToggle,
  selectWorkbookEndDate,
  selectWorkbookStartDate,
  selectWorkbookOtb,
  selectWorkbookAlignmentToggle,
  selectWorkbookCustomDate,
  selectD1SetIsDisplay,
  selectD2SetIsDisplay,
  selectD3SetIsDisplay,
  selectWorkbookFilterList,
  selectEnableSecondaryHotelGroups,
  selectHotelFocusOn,
  selectNormalizedView,
  selectWorkbookHotelComparison,
  selectWorkbookHotelFilterList,
  selectRatePlanFilterList,
  selectIsRatePlan,
  selectSymmetricComparisonIsDisplay
} from 'modules/dashboard/components/tab-container/workbook-report/selectors';
import { REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import getMapperResult from '../function';
/**
 * Custom hook to generate summary widget filter schemas
 */
const useWorkbookWidget = () => {
  const dispatch = useDispatch();
  //
  const alignment = useSelector(selectAlignment);
  const dateComparison = useSelector(selectDateComparison);
  const bookingDate = useSelector(selectWorkbookBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const symmetricComparisonToggle = useSelector(selectSymmetricComparisonToggle);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const endDate = useSelector(selectWorkbookEndDate);
  const startDate = useSelector(selectWorkbookStartDate);
  const customBooking = useSelector(selectWorkbookOtb);
  const alignmentToggle = useSelector(selectWorkbookAlignmentToggle);
  const customDate = useSelector(selectWorkbookCustomDate);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const d1SetIsDisplay = useSelector(selectD1SetIsDisplay);
  const d2SetIsDisplay = useSelector(selectD2SetIsDisplay);
  const d3SetIsDisplay = useSelector(selectD3SetIsDisplay);
  const reportType = useSelector(selectReportType);
  const workbookFilterList = useSelector(selectWorkbookFilterList);
  const workbookHotelFilterList = useSelector(selectWorkbookHotelFilterList);
  const isDemoUser = useSelector(selectIsDemoUser);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const normalizedViewToggle = useSelector(selectNormalizedView);
  const isWorkbookHotelComparison = useSelector(selectWorkbookHotelComparison);
  const localCurrency = useSelector(selectLocalCurrency);
  const selectedDateType = useSelector(selectWorkbookDateType);
  const ratePlanFilterList = useSelector(selectRatePlanFilterList);
  const isRatePlan = useSelector(selectIsRatePlan);

  // Triggered when bookingDate,visualFilters,dateComparison,alignment,targetDate,hotelId,symmetricComparisonToggle value changes
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.WORKBOOK_REPORT ||
      (!dateComparison && !alignment && !bookingDate)
    )
      return;
    // Generate filter list for summary widget top 8 visuals
    const targetDateFilter = getWorkbookTargetDateSchema(
      visualFilters,
      selectedDateType?.id,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const dateSwitchFilter = getDateSwitchSchema(visualFilters, selectedDateType?.id);
    //
    const groupFocusOnIds = hotelFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    let hotelGroup1Filter;
    let primaryHotelIdFilter;
    const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    //

    if (enableHotelGroups) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds);
      primaryHotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId]);
      primaryHotelIdFilter = getHotelIdSchema([hotelId?.databricksId], visualFilters);
    }
    //
    let hotelGroupAFilter;
    if (enableSecondaryHotelGroups) {
      hotelGroupAFilter = getHotelGroupASchema(groupFocusOnIds);
    } else {
      hotelGroupAFilter = getHotelGroupASchema([hotelFocusOn?.databricksId]);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelFilters = isWorkbookHotelComparison
      ? [secondaryHotelIdFilter, hotelGroupAFilter, hotelGroup1Filter]
      : [secondaryHotelIdFilter];
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.SUMMARY);
    const dashboardPmsSyncSchema = getDashboardPmsSyncSchema(pmsSyncToggle);
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonIndexSchema(
      visualFilters,
      alignment?.id,
      getMapperResult(alignmentToggle, dateComparison)
    );
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    //
    if (
      pmsSyncFilter &&
      dashboardPmsSyncSchema &&
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelFilters &&
      symmetricComparisonFilter &&
      hotelGroupOrderFilter &&
      normalizedFilter &&
      dateSwitchFilter &&
      primaryHotelIdFilter
    ) {
      const filters = [
        ...pmsSyncFilter,
        ...dashboardPmsSyncSchema,
        bookingDateFilter,
        targetDateFilter,
        secondaryHotelIdFilter,
        ...isCustomDateComparisonFilter,
        alignmentAndDateComparisonFilter,
        symmetricComparisonFilter,
        dateSwitchFilter,
      ];
      const ratefilters = [
        ...pmsSyncFilter,
        ...dashboardPmsSyncSchema,
        bookingDateFilter,
        targetDateFilter,
        ...isCustomDateComparisonFilter,
        alignmentAndDateComparisonFilter,
        symmetricComparisonFilter,
        dateSwitchFilter,
        primaryHotelIdFilter,
      ];

      const hotelComparisonFilters = [
        ...pmsSyncFilter,
        ...dashboardPmsSyncSchema,
        bookingDateFilter,
        targetDateFilter,
        secondaryHotelIdFilter,
        ...isCustomDateComparisonFilter,
        hotelGroupAFilter,
        hotelGroup1Filter,
        hotelGroupOrderFilter,
        normalizedFilter,
        dateSwitchFilter,
      ];
      if (JSON.stringify(filters) !== JSON.stringify(workbookFilterList)) {
        dispatch(workbookActions.updateWorkbookFilterList(filters));
        storeVisualMapper(VISUAL_MAPPER.dashboardVisualFilter);
        writeLog('Dashboard Visual Filters', filters);
      }
      if (JSON.stringify(ratefilters) !== JSON.stringify(ratePlanFilterList)) {
        dispatch(workbookActions.updateRatePlanFilterList(ratefilters));
        storeVisualMapper(VISUAL_MAPPER.dashboardVisualFilter);
        writeLog('Rate Plan Visual Filters', ratefilters);
      }
      if (JSON.stringify(hotelComparisonFilters) !== JSON.stringify(workbookHotelFilterList)) {
        storeVisualMapper(VISUAL_MAPPER.dashboardVisualFilter);
        writeLog('Dashboard Visual Filters', hotelComparisonFilters);
      }
      dispatch(workbookActions.updateWorkbookHotelFilterList(hotelComparisonFilters));
    }
  }, [
    bookingDate,
    pmsSyncToggle,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    enableHotelGroups,
    reportType,
    startDate,
    endDate,
    isDemoUser,
    enableSecondaryHotelGroups,
    hotelFocusOn,
    normalizedViewToggle,
    isWorkbookHotelComparison,
    selectedDateType,
  ]);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (!dateComparison && !bookingDate && !targetDate && !hotelId) return;
    // Generate workbook filter url
    const workbookUrl = generateWorkbookShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      dateComparison,
      startDate,
      endDate,
      customDate,
      customBooking,
      bookingDate,
      alignmentToggle,
      symmetricComparisonToggle,
      enableHotelGroups,
      symmetricComparisonIsDisplay,
      d1SetIsDisplay,
      d2SetIsDisplay,
      d3SetIsDisplay,
      enableSecondaryHotelGroups,
      hotelFocusOn,
      normalizedViewToggle,
      localCurrency,
      selectedDateType,
      isRatePlan
    );
    //
    dispatch(workbookActions.setWorkbookUrl(workbookUrl));
  }, [
    pmsSyncToggle,
    hotelId,
    targetDate,
    dateComparison,
    startDate,
    endDate,
    customDate,
    customBooking,
    bookingDate,
    alignmentToggle,
    symmetricComparisonToggle,
    enableHotelGroups,
    symmetricComparisonIsDisplay,
    d1SetIsDisplay,
    d2SetIsDisplay,
    d3SetIsDisplay,
    enableSecondaryHotelGroups,
    hotelFocusOn,
    normalizedViewToggle,
    localCurrency,
    selectedDateType,
    isRatePlan,
  ]);
};
//
export default useWorkbookWidget;
