/* eslint-disable no-use-before-define */
/**
 * Helper function to determine if a value can be treated as a number.
 *
 * @param {string} value - The value to check.
 * @returns {boolean} - True if the value is numeric, false otherwise.
 */
const isNumeric = (value) => !Number.isNaN(Number(value));

/**
 * Parses a primary expression from the token array.
 *
 * @param {Array} tokens - The array of tokens to parse.
 * @param {number} position - The current position in the token array.
 * @returns {Object} - The parsed node and the new position in the token array.
 */
const parsePrimary = (tokens, position) => {
  const token = tokens[position];
  position += 1;

  // Handle NOT operator by creating a UnaryExpression node
  if (token.type === 'NOT_OPERATOR') {
    const { node: right, position: newPosition } = parsePrimary(tokens, position);
    return {
      node: {
        type: 'UnaryExpression',
        operator: 'NOT',
        argument: right,
      },
      position: newPosition,
    };
  }

  // Handle FIELD token by creating a Comparison node
  if (token.type === 'FIELD') {
    const field = token;
    const operator = tokens[position];
    position += 1;
    const value = tokens[position];
    position += 1;
    return {
      node: {
        type: 'Comparison',
        field: field.lexeme,
        operator: operator.lexeme,
        value: isNumeric(value.lexeme) ? Number(value.lexeme) : value.lexeme,
      },
      position,
    };
  }

  // Handle SUBEXPRESSION token by recursively parsing the subexpression
  if (token.type === 'SUBEXPRESSION') {
    const subExpressionTokens = token.subExpressions;
    const { node } = parseExpression(subExpressionTokens, 0);
    return { node, position };
  }

  // Throw an error for unexpected tokens
  throw new Error(`Unexpected token: ${token.lexeme}`);
};

/**
 * Parses an expression from the token array.
 *
 * @param {Array} tokens - The array of tokens to parse.
 * @param {number} [position=0] - The current position in the token array.
 * @param {number} [precedence=0] - The current precedence level.
 * @returns {Object} - The parsed node and the new position in the token array.
 */
const parseExpression = (tokens, position = 0, precedence = 0) => {
  // Parse the primary expression
  let { node: left, position: newPosition } = parsePrimary(tokens, position);

  // Process combine operators with appropriate precedence
  while (
    newPosition < tokens.length &&
    tokens[newPosition].type === 'COMBINE_OPERATOR' &&
    tokens[newPosition].precedence >= precedence
  ) {
    const operator = tokens[newPosition];
    newPosition += 1;
    const { node: right, position: finalPosition } = parseExpression(
      tokens,
      newPosition,
      operator.precedence
    );
    left = {
      type: 'BinaryExpression',
      operator: operator.lexeme,
      left,
      right,
    };
    newPosition = finalPosition;
  }

  // Return the constructed node and the new position
  return { node: left, position: newPosition };
};

/**
 * Parses the token array into an abstract syntax tree (AST).
 *
 * @param {Array} tokens - The array of tokens to parse.
 * @returns {Object} - The root node of the parsed AST.
 * @throws {Error} - If the token array is empty.
 */
const parse = (tokens) => {
  if (tokens.length === 0) {
    throw new Error('Empty query');
  }
  return parseExpression(tokens).node;
};
//
export default parse;
