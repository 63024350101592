import { spacing } from '@mui/system';
import styled from '@emotion/styled';
import { Alert as MuiAlert } from '@mui/material';

export const Alert = styled(MuiAlert)(spacing);
//
export const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;
