/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
//
const selectDomain = (state) => state['feature/configure-hotel-rooms'] || initialState;
/**
 * Getting loader state to hotel configuration component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (configureHotelRoomState) => configureHotelRoomState.loading
);
