/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Define the the sign up feature initial state
 */
export const initialState = {
  loading: false,
  userInvite: {},
};
/**
 * Here mentioned all the sign up feature related actions
 */
export const signUpSlice = createSlice({
  name: 'feature/sign-up',
  initialState,
  reducers: {
    signUp(state) {
      state.loading = true;
    },
    signUpSucceeded(state) {
      state.loading = false;
    },
    signUpFailed(state) {
      state.loading = false;
    },
  },
});
//
export const { actions: signUpActions } = signUpSlice;
