import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/common/auth/slice';
import { capitalizeFirst } from 'modules/common/helpers/strings';

const selectDomain = (state) => state[SLICE.BASE_AUTH] || initialState;
/**
 * Getting user state to auth component
 */
export const selectAuthUser = createSelector([selectDomain], (authState) => authState.user);
/**
 * Getting user session id
 */
export const selectSessionId = createSelector([selectDomain], (authState) => authState.sessionId);
/**
 * Getting user access and refresh tokens into the auth component
 */
export const selectTokens = createSelector([selectDomain], (authState) => authState.tokens);
/**
 * Getting only user refresh token into the auth component
 */
export const selectRefreshToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.refreshToken
);
/**
 * Getting only user access token into the auth component
 */
export const selectAccessToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.accessToken
);
/**
 * Obtain id token from auth slice
 */
export const selectIdToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.idToken
);
/**
 * Getting user reset password verify token into the auth component
 */
export const selectResetPasswordToken = createSelector(
  [selectDomain],
  (authState) => authState?.resetPasswordToken
);
/**
 * Getting user full name into the auth component
 */
export const selectUserFullName = createSelector(
  [selectDomain],
  (authState) =>
    `${capitalizeFirst(authState?.user?.firstName)} ${capitalizeFirst(authState?.user?.lastName)}`
);
/**
 * Getting current user organization ID
 */
export const selectOrganizationId = createSelector(
  [selectDomain],
  (authState) => authState?.user?.organizationId
);
/**
 * Getting token expired time into the auth component
 */
export const selectAccessTokenExpired = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.expiresIn
);
/**
 * Getting auth request loading state to components
 */
export const selectAuthLoading = createSelector([selectDomain], (authState) => authState?.loading);
/**
 * Getting select organization state to components
 */
export const selectOrganization = createSelector(
  [selectDomain],
  (authState) => authState?.organization
);
