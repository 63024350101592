export const trendHotelDailyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, ...Array(22).fill(12)],
    end: 'W',
  },
  table: {
    start: 11,
    end: 43,
    dataStart: 13,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendHotelWeeklyPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, ...Array(22).fill(12)],
    end: 'W',
  },
  table: {
    start: 11,
    end: 20,
    dataStart: 13,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendHotelCumulativePreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, ...Array(13).fill(12)],
    end: 'N',
  },
  table: {
    start: 11,
    end: 43,
    dataStart: 13,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendHotelMovingAveragePreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, ...Array(6).fill(12)],
    end: 'G',
  },
  table: {
    start: 10,
    end: 42,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendHotelPercentagePreset = {
  rows: {
    header: [9, 10],
  },
  columns: {
    width: [25, ...Array(6).fill(12)],
    end: 'G',
  },
  table: {
    start: 9,
    end: 41,
    dataStart: 11,
  },
  totalrow: false,
  bordered: true,
};
//
export const trendHotelNormalizedPreset = {
  rows: {
    header: [11, 12],
  },
  columns: {
    width: [25, ...Array(22).fill(12)],
    end: 'W',
  },
  table: {
    start: 11,
    end: 43,
    dataStart: 13,
  },
  totalrow: false,
  bordered: true,
};
