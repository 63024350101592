import { Autocomplete, TextField, Checkbox, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { COMMON_FOCUS_ON_OPTIONS } from 'modules/dashboard/components/tab-container/trend-tabs/constants';
import { CustomPopper } from './custom-popper';

/**
 * Dropdown component with check lists
 * @param {*} options - result set
 * @param {*} values - selected values of the dropdown
 * @param {*} label - select dropdown label
 * @param {*} onchange - trigger event when option select
 * @param {*} disabledOption - for disable specific items
 * @param {*} disableClearable - for disable the clear all button
 * @param {*} isOptionEqualTo - for selected value is equal to available option
 * @param {*} sx - for custom styling purpose
 * @returns
 */
const CheckboxDropdown = ({
  options,
  values,
  label,
  onchange,
  disabledOption,
  disableClearable,
  isOptionEqualTo,
  sx,
  id = '',
  allSelected = false,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Autocomplete
      PopperComponent={CustomPopper}
      size="small"
      multiple
      limitTags={2}
      id={id}
      options={options ?? []}
      isOptionEqualToValue={isOptionEqualTo}
      onChange={onchange}
      disableClearable={disableClearable ?? true}
      disableCloseOnSelect
      fullWidth
      value={values}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 'auto',
              overflow: 'auto',
              maxHeight: 100,
            },
          }}
          label={label}
          size="small"
          color="primary"
        />
      )}
      getOptionLabel={(option) => option?.label ?? option}
      renderOption={(props, option, { selected }) => {
        const selectAllProps =
          option.label === COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label ? { checked: allSelected } : {};
        const changedIconSelectAll =
          option.label === COMMON_FOCUS_ON_OPTIONS.SELECT_ALL.label ? { sx: { mb: 3 } } : {};
        const changedOverall =
          option.label === COMMON_FOCUS_ON_OPTIONS.OVERALL.label ? { sx: { mb: 3 } } : {};
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              {...changedIconSelectAll}
              {...changedOverall}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {option?.label ?? option}
          </li>
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option.label}
              {...tagProps}
              sx={{ display: option.label === disabledOption ? 'none' : '' }}
            />
          );
        })
      }
      componentsProps={{
        paper: {
          sx: {
            width: '100%',
            whiteSpace: 'unset',
            wordBreak: 'break-all',
          },
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: 0,
          height: 'fit-content',
        },
        marginY: 2,
        ...sx,
      }}
      getOptionDisabled={(option) => option.label === disabledOption}
    />
  );
};
//
export default CheckboxDropdown;
