import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from 'modules/common/notifications/slice';
import { selectNotification } from 'modules/common/notifications/selectors';
import { toast } from 'react-toastify';
import ERROR_TYPES from 'modules/common/constants/error-types';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { Button, Grid, Box, Typography, Tabs, Tab } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddEventForm from 'modules/rate-calendar/components/add-event';
import UpdateEventForm from '../update-event';
import DeleteEvent from '../delete-event';
import EventInformationView from '../event-information';
import { Card, CardContent } from './style';
import MetricsTable from './metrics-table';
import RoomCategoriesTable from './room-categories-table';
/**
 * Metrics and event data drawer is defined here
 * @param {*} param0
 * @returns
 */
const MetricsEventsDrawer = ({ isChecked, event }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const [isNewEventAdded, setNewEventAdded] = useState(false);
  const [isDeleteSelected, setDeleteSelected] = useState(false);
  const [isEditSelected, setEditSelected] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState('');
  const [editEvent, setEditEvent] = useState({
    id: '',
    date: '',
    majorCategory: '',
    minorCategory: '',
    description: '',
  });
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    setNewEventAdded(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, {
        type: TOAST_TYPES.SUCCESS,
      });
      modalAction(false);
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  const [tabValue, setTabValue] = useState(0);
  //
  return (
    <Box
      mx={4}
      xs={12}
      md={3}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{
        borderRadius: 3,
        flexShrink: 0,
        display: isChecked ? 'flex' : 'none',
      }}
    >
      <Card mb={6}>
        <CardContent>
          {isChecked && (
            <>
              <Tabs
                sx={{ width: '100%' }}
                value={tabValue}
                onChange={(e, val) => setTabValue(val)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Metrics" />
                <Tab label="Room Categories" />
              </Tabs>
              <MetricsTable event={event} value={tabValue} />
              <RoomCategoriesTable value={tabValue} />
            </>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item container direction="row" justifyContent="space-between">
              <Typography>Events</Typography>
              <Button
                variant="text"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setNewEventAdded(true)}
                disabled={isNewEventAdded}
              >
                New Event
              </Button>
            </Grid>
            <Grid>
              <AddEventForm
                isNewEventAdded={isNewEventAdded}
                setNewEventAdded={setNewEventAdded}
                event={event}
              />
              <UpdateEventForm
                editEvent={editEvent}
                isEditSelected={isEditSelected}
                setEditSelected={setEditSelected}
              />
            </Grid>
            {isDeleteSelected && (
              <DeleteEvent deleteEventId={deleteEventId} setDeleteSelected={setDeleteSelected} />
            )}
            {event?.specialEvents?.map((e) => (
              <EventInformationView
                e={e}
                key={e?.id}
                setDeleteSelected={setDeleteSelected}
                setEditSelected={setEditSelected}
                setDeleteEventId={setDeleteEventId}
                setEditEvent={setEditEvent}
              />
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
//
export default MetricsEventsDrawer;
