/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';
/**
 * Define the the Event category configuration feature initial state
 */
export const initialState = {
  loading: false,
  eventCategories: [],
  minorEventCategories: [],
  updatedMinorEvent: false,
  hotelId: null,
};
/**
 * All the event category configuration feature related actions are defined here
 */
export const configureEventCategoriesSlice = createSlice({
  name: SLICE.CONFIGURE_EVENT_CATEGORIES,
  initialState,
  reducers: {
    configureEventCategories(state) {
      state.loading = true;
    },
    configureEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.eventCategories = action?.payload;
    },
    configureEventCategoriesFailed(state) {
      state.loading = false;
    },
    addMinorEventCategories(state) {
      state.loading = true;
      state.updatedMinorEvent = false;
    },
    addMinorEventCategoriesSucceeded(state) {
      state.loading = false;
      state.updatedMinorEvent = true;
    },
    addMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    getMinorEventCategories(state) {
      state.loading = true;
    },
    getMinorEventCategoriesSucceeded(state, action) {
      state.loading = false;
      state.minorEventCategories = action?.payload;
    },
    getMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    updateMinorEventCategories(state) {
      state.loading = true;
      state.updatedMinorEvent = false;
    },
    updateMinorEventCategoriesSucceeded(state) {
      state.loading = false;
      state.updatedMinorEvent = true;
    },
    updateMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    deleteMinorEventCategories(state) {
      state.loading = true;
      state.updatedMinorEvent = false;
    },
    deleteMinorEventCategoriesSucceeded(state) {
      state.loading = false;
      state.updatedMinorEvent = true;
    },
    deleteMinorEventCategoriesFailed(state) {
      state.loading = false;
    },
    setHotelId(state, action) {
      state.hotelId = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: configureEventCategoriesActions } = configureEventCategoriesSlice;
