import { useEffect, useState } from 'react';
import { Grid, Fade, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWebClickTimeVisualFilter,
  selectWebClickTimePaceVisualFilter,
  selectWebClickHotelVisualFilter,
  selectWebClickHotelPaceVisualFilter,
  selectWebClickSegmentVisualFilter,
  selectWebClickSegmentPaceVisualFilter,
  selectWebClickWeeklyView,
  selectWebClickCumulativeView,
  selectWebClickTargetDate,
  selectWeeklyWebClickStartDate,
  selectWeeklyWebClickEndDate,
  selectUtmFilterList,
  selectTimeTrendIsDisplay,
  selectTimePaceIsDisplay,
  selectSegmentTrendIsDisplay,
  selectSegmentPaceIsDisplay,
  selectHotelTrendIsDisplay,
  selectHotelPaceIsDisplay,
  selectUtmTrendIsDisplay,
  selectUtmPaceIsDisplay,
} from 'modules/dashboard/components/tab-container/web-click-tab/selectors';
import { isSameDay } from 'date-fns';
import { formatDate } from 'modules/dashboard/functions';
import { TAB_VALUE } from 'modules/dashboard/constants';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import WebClickFilter from 'modules/dashboard/components/tab-container/web-click-tab/components/filter-panel';
import { Box } from '@mui/system';
import useWebClickView from 'modules/dashboard/components/tab-container/web-click-tab/hooks/use-web-click';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
/**
 * Web click report with filters
 * @param {*} props
 * @returns
 */
const WebClick = ({
  value,
  accessToken,
  visuals,
  weeklyVisuals,
  cumulativeVisuals,
  active,
  latestDate,
  reportType,
  hotelTimezone,
}) => {
  useWebClickView();
  //
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const timeVisualFilterList = useSelector(selectWebClickTimeVisualFilter);
  const timePaceVisualFilterList = useSelector(selectWebClickTimePaceVisualFilter);
  const hotelVisualFilterList = useSelector(selectWebClickHotelVisualFilter);
  const hotelPaceVisualFilterList = useSelector(selectWebClickHotelPaceVisualFilter);
  const segmentVisualFilterList = useSelector(selectWebClickSegmentVisualFilter);
  const segmentPaceVisualFilterList = useSelector(selectWebClickSegmentPaceVisualFilter);
  const utmVisualFilterList = useSelector(selectUtmFilterList);
  const weeklyViewToggle = useSelector(selectWebClickWeeklyView);
  const isCumulative = useSelector(selectWebClickCumulativeView);
  const startDate = useSelector(selectWeeklyWebClickStartDate);
  const endDate = useSelector(selectWeeklyWebClickEndDate);
  const targetDate = useSelector(selectWebClickTargetDate);
  //
  const timeTrendIsDisplay = useSelector(selectTimeTrendIsDisplay);
  const timePaceIsDisplay = useSelector(selectTimePaceIsDisplay);
  const segmentTrendIsDisplay = useSelector(selectSegmentTrendIsDisplay);
  const segmentPaceIsDisplay = useSelector(selectSegmentPaceIsDisplay);
  const hotelTrendIsDisplay = useSelector(selectHotelTrendIsDisplay);
  const hotelPaceIsDisplay = useSelector(selectHotelPaceIsDisplay);
  const utmTrendIsDisplay = useSelector(selectUtmTrendIsDisplay);
  const utmPaceIsDisplay = useSelector(selectUtmPaceIsDisplay);
  //
  const [label, setLabel] = useState(null);
  const [show, setShow] = useState(true);
  const [isTimeTrendVisualsLoaded, setIsTimeTrendVisualLoaded] = useState(false);
  const [isHotelTrendVisualLoaded, setIsHotelTrendVisualLoaded] = useState(false);
  const [isSegmentTrendVisualsLoaded, setIsSegmentTrendVisualsLoaded] = useState(false);
  const [isUtmTrendVisualsLoaded, setIsUtmTrendVisualsLoaded] = useState(false);
  const [isTimeVPaceVisualsLoaded, setIsTimePaceVisualLoaded] = useState(false);
  const [isHotelPaceVisualLoaded, setIsHotelPaceVisualLoaded] = useState(false);
  const [isSegmentPaceVisualsLoaded, setIsSegmentPaceVisualsLoaded] = useState(false);
  const [isUtmPaceVisualsLoaded, setIsUtmPaceVisualsLoaded] = useState(false);
  // To get visual details based on index
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = cumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (weeklyViewToggle) {
      found = weeklyVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visual name based on index
  const getVisualName = (index) => getVisual(index)?.name;

  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    filter,
    setPBIReport = () => {},
    setIsLoaded = () => {}
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };

  // Triggered when startDate, endDate, targetDate, weeklyViewToggle, isCumulative values change
  useEffect(() => {
    // Show adjusted stay dates for weekly view toggle on
    if (isCumulative) {
      setLabel(null);
    } else if (
      weeklyViewToggle &&
      (!isSameDay(new Date(startDate), new Date(targetDate.startDate)) ||
        !isSameDay(new Date(endDate), new Date(targetDate.endDate)))
    ) {
      setLabel(
        `Stay date selection adjusted.  New stay date range from: ${formatDate(
          startDate
        )} to ${formatDate(endDate)}`
      );
    } else {
      setLabel(null);
    }
  }, [startDate, endDate, targetDate, weeklyViewToggle, isCumulative]);

  // Triggered when tab value or active tab change
  useEffect(() => {
    // Scroll up when the trend segment tab is clicked
    if (value === TAB_VALUE.WEB_CLICK) window.scrollTo(0, 0);
  }, [value, active]);

  // Triggered on weeklyViewToggle, isCumulative values change
  useEffect(() => {
    // Hide of pace visuals when weekly toggle on
    if (weeklyViewToggle && isCumulative) {
      setShow(true);
    } else if (weeklyViewToggle) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [weeklyViewToggle, isCumulative]);
  //
  return (
    active && (
      <Fade in={active} {...{ timeout: 500 }}>
        <Grid
          container
          direction="row"
          sx={{
            display: value === TAB_VALUE.WEB_CLICK ? '' : 'none',
          }}
          className={styles.mainGrid}
        >
          <Grid item xs={2} />
          <Grid
            container
            item
            xs={10}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {label && <Typography className={styles.weeklyLabel}>{label}</Typography>}
          </Grid>
          <Grid item xs={2}>
            <WebClickFilter
              reportType={reportType}
              visual={getWidget(100, styles.symmetricComparisonWindow, timeVisualFilterList)}
              visualName={getVisualName(100)}
              latestDate={latestDate}
              hotelTimezone={hotelTimezone}
              isLoaded={
                (isTimeTrendVisualsLoaded &&
                  isSegmentTrendVisualsLoaded &&
                  isHotelTrendVisualLoaded &&
                  isUtmTrendVisualsLoaded &&
                  isUtmPaceVisualsLoaded) ||
                isTimeVPaceVisualsLoaded ||
                isSegmentPaceVisualsLoaded ||
                isHotelPaceVisualLoaded
              }
            />
          </Grid>
          <Grid item xs={10}>
            <Box sx={{ flexGrow: 1 }}>
              <VisualGrid
                isDisplay={timeTrendIsDisplay}
                visualName={getVisualName(0)}
                visual={getWidget(
                  0,
                  styles.paceVisual,
                  timeVisualFilterList,
                  () => {},
                  setIsTimeTrendVisualLoaded
                )}
                onClick={() => {
                  dispatch(webClickActions.setTimeTrendIsDisplay(!timeTrendIsDisplay));
                }}
              />
              {show && (
                <VisualGrid
                  isDisplay={timePaceIsDisplay}
                  visualName={getVisualName(1)}
                  visual={getWidget(
                    1,
                    styles.paceVisual,
                    timePaceVisualFilterList,
                    () => {},
                    setIsTimePaceVisualLoaded
                  )}
                  onClick={() => {
                    dispatch(webClickActions.setTimePaceIsDisplay(!timePaceIsDisplay));
                  }}
                />
              )}
              <VisualGrid
                isDisplay={segmentTrendIsDisplay}
                visualName={getVisualName(2)}
                visual={getWidget(
                  2,
                  styles.paceVisual,
                  segmentVisualFilterList,
                  () => {},
                  setIsSegmentTrendVisualsLoaded
                )}
                onClick={() => {
                  dispatch(webClickActions.setSegmentTrendIsDisplay(!segmentTrendIsDisplay));
                }}
              />
              {show && (
                <VisualGrid
                  isDisplay={segmentPaceIsDisplay}
                  visualName={getVisualName(3)}
                  visual={getWidget(
                    3,
                    styles.paceVisual,
                    segmentPaceVisualFilterList,
                    () => {},
                    setIsSegmentPaceVisualsLoaded
                  )}
                  onClick={() => {
                    dispatch(webClickActions.setSegmentPaceIsDisplay(!segmentPaceIsDisplay));
                  }}
                />
              )}
              <VisualGrid
                isDisplay={hotelTrendIsDisplay}
                visualName={getVisualName(4)}
                visual={getWidget(
                  4,
                  styles.paceVisual,
                  hotelVisualFilterList,
                  () => {},
                  setIsHotelTrendVisualLoaded
                )}
                onClick={() => {
                  dispatch(webClickActions.setHotelTrendIsDisplay(!hotelTrendIsDisplay));
                }}
              />
              <VisualGrid
                isDisplay={hotelPaceIsDisplay}
                visualName={getVisualName(5)}
                visual={getWidget(
                  5,
                  styles.paceVisual,
                  !isCumulative && weeklyViewToggle
                    ? utmVisualFilterList
                    : hotelPaceVisualFilterList,
                  () => {},
                  setIsHotelPaceVisualLoaded
                )}
                onClick={() => {
                  dispatch(webClickActions.setHotelPaceIsDisplay(!hotelPaceIsDisplay));
                }}
              />
              {show && (
                <VisualGrid
                  isDisplay={utmTrendIsDisplay}
                  visualName={getVisualName(6)}
                  visual={getWidget(
                    6,
                    styles.paceVisual,
                    utmVisualFilterList,
                    () => {},
                    setIsUtmTrendVisualsLoaded
                  )}
                  onClick={() => {
                    dispatch(webClickActions.setUtmTrendIsDisplay(!utmTrendIsDisplay));
                  }}
                />
              )}
              {show && (
                <VisualGrid
                  isDisplay={utmPaceIsDisplay}
                  visualName={getVisualName(7)}
                  visual={getWidget(
                    7,
                    styles.paceVisual,
                    utmVisualFilterList,
                    () => {},
                    setIsUtmPaceVisualsLoaded
                  )}
                  onClick={() => {
                    dispatch(webClickActions.setUtmPaceIsDisplay(!utmPaceIsDisplay));
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Fade>
    )
  );
};
//
export default WebClick;
