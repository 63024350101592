import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button, Wrapper } from './style';
/**
 * Not found page define here
 * @returns
 */
const Page404 = () => (
  <Wrapper>
    <Typography component="h1" variant="h1" align="center" gutterBottom>
      404
    </Typography>
    <Typography component="h2" variant="h5" align="center" gutterBottom>
      Page not found.
    </Typography>
    <Typography component="h2" variant="body1" align="center" gutterBottom>
      The page you are looking for might have been removed.
    </Typography>

    <Button component={Link} to="/" variant="contained" color="secondary" mt={2}>
      Return to website
    </Button>
  </Wrapper>
);
//
export default Page404;
