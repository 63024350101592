/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectGroupRoomConsumptionDetails,
  selectDateSelected,
} from 'modules/rate-calendar/selectors';
import { Fade } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTheme } from '@emotion/react';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import './index.scss';
/**
 * Room categories table column is defined here
 */
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 0,
    hide: true,
  },
  {
    field: 'roomCategories',
    headerName: 'Room Categories',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'unitsSold',
    headerName: 'Units Sold',
    headerClassName: 'super-app-theme--header',
    flex: 0.5,
  },
  {
    field: 'revenue',
    headerName: 'Revenue',
    headerClassName: 'super-app-theme--header',
    flex: 0.7,
  },
];
/**
 * Room categories table view is defined here
 * @param {*} param0
 * @returns
 */
const RoomCategoriesTable = ({ value }) => {
  //
  const roomConsumptionDetails = useSelector(selectGroupRoomConsumptionDetails);
  const selectedDate = useSelector(selectDateSelected);
  //
  const [rows, setRows] = useState([]);
  //
  useEffect(() => {
    if (!selectedDate) {
      setRows([]);
      return;
    }
    const roomConsumption = roomConsumptionDetails?.[selectedDate][0]?.roomBreakdown;
    if (roomConsumption) {
      setRows(
        roomConsumption?.map((element) => ({
          id: element?._id,
          roomCategories: element?.roomCategoryName,
          unitsSold: element?.numOfRooms,
          revenue: `$${((element?.adr ?? 0) * (element?.numOfRooms ?? 0) ?? 0).toFixed(2)}`,
        }))
      );
    } else {
      setRows([]);
    }
  }, [selectedDate, roomConsumptionDetails]);
  //
  return (
    value === 1 && (
      <Fade in={value === 1} {...{ timeout: 500 }}>
        <div
          style={{
            height: 500,
            flex: 1,
            marginTop: 10,
          }}
        >
          <DataGrid
            sx={{
              overflowWrap: 'break-word',
              '& .super-app-theme--header': {
                backgroundColor: 'white',
                color: TABLE_HEADER_FONT_COLOR,
              },
              '& .MuiDataGrid-main': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            initialState={{
              pagination: {
                pageSize: 10,
              },
            }}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            columns={columns}
            rows={rows}
            disableSelectionOnClick
          />
        </div>
      </Fade>
    )
  );
};
//
export default withTheme(RoomCategoriesTable);
