import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from 'modules/common/helpers/object';
import PAGINATION from 'modules/common/constants/pagination-limits';
import TIME_OUTS from 'modules/common/constants/time-outs';
import ORDER_BY from 'modules/common/constants/order-by';
import { dashboardActions } from '../slice';
import { selectIsSessionTimedOut, selectTokenRevocation } from '../selectors';
/**
 * This is for fetching notifications from the server
 */
const useFetchNotifications = () => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const isSessionTimedOut = useSelector(selectIsSessionTimedOut);
  const isTokenRevoked = useSelector(selectTokenRevocation);
  const authUser = useSelector(selectAuthUser);
  //
  useEffect(() => {
    dispatch(
      dashboardActions.getNotificationsList({
        organizationId,
        userId: authUser?.id,
        query: `limit=${PAGINATION.NOTIFICATIONS_LIST_COUNT}&notificationType=WEB&sortBy=createdAt:${ORDER_BY.DESCENDING}`,
      })
    );
    let intervalId;
    if (organizationId && !isEmpty(authUser) && !isSessionTimedOut && !isTokenRevoked) {
      intervalId = setInterval(() => {
        dispatch(
          dashboardActions.getNotificationsList({
            organizationId,
            userId: authUser?.id,
            query: `limit=${PAGINATION.NOTIFICATIONS_LIST_COUNT}&notificationType=WEB&sortBy=createdAt:${ORDER_BY.DESCENDING}`,
          })
        );
      }, TIME_OUTS.NOTIFICATION_INTERVAL);
      //
    }
    return () => clearInterval(intervalId);
  }, [organizationId, authUser, isSessionTimedOut, isTokenRevoked]);
};
//
export default useFetchNotifications;
