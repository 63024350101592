import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'modules/dashboard/slice';
import { SLICE } from 'modules/dashboard/constants';
//
const selectDomain = (state) => state[SLICE.I_PREFER] || initialState;
/**
 *  Getting generated i prefer tab url
 */
export const selectIPreferUrl = createSelector([selectDomain], (state) => state.iPreferUrl);

/**
 * Getting iPrefer target date state to dashboard component
 */
export const selectIPreferTargetDate = createSelector(
  [selectDomain],
  (state) => state.iPreferTargetDate
);
/**
 *Getting iPrefer end date state for dashboard component for custom date comparison
 */
export const selectIPreferEndDate = createSelector(
  [selectDomain],
  (state) => state?.iPreferEndDate
);
/**
 *Getting iPrefer start date state for dashboard component for custom date comparison
 */
export const selectIPreferStartDate = createSelector(
  [selectDomain],
  (state) => state?.iPreferStartDate
);
/**
 *Getting iPrefer  date selected state for dashboard component for custom date comparison
 */
export const selectIPreferDateSelected = createSelector(
  [selectDomain],
  (state) => state?.iPreferDateSelected
);
/**
 *Getting iPrefer custom date state for dashboard component
 */
export const selectIPreferCustomDate = createSelector(
  [selectDomain],
  (state) => state?.iPreferCustomDate
);
/**
 * Getting iPrefer otb state for dashboard component
 */
export const selectIPreferOtb = createSelector([selectDomain], (state) => state?.iPreferOtb);
/**
 * Getting iPrefer booking date state to dashboard component
 */
export const selectIPreferBookingDate = createSelector(
  [selectDomain],
  (state) => state.iPreferBookingDate
);
/**
 * Getting iPrefer chart breakdown state to dashboard component
 */
export const selectIPreferChartBreakdown = createSelector(
  [selectDomain],
  (state) => state.iPreferChartBreakdown
);
/**
 * Getting iPrefer alignment state to dashboard component
 */
export const selectIPreferAlignment = createSelector(
  [selectDomain],
  (state) => state.iPreferAlignment
);
/**
 * Getting iPrefer date comparison state to dashboard component
 */
export const selectIPreferDateComparison = createSelector(
  [selectDomain],
  (state) => state.iPreferDateComparison
);
/**
 *  Getting iPrefer alignment toggle state to dashboard component
 */
export const selectIPreferAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.iPreferAlignmentToggle
);
/**
 * Getting iPrefer symmetric comparison toggle state for dashboard component
 */
export const selectIPreferSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.iPreferSymmetricComparisonToggle
);
/**
 *Getting is detailed in iPrefer tab state for dashboard component
 */
export const selectIPreferIsDetailed = createSelector(
  [selectDomain],
  (state) => state?.iPreferIsDetailed
);
/**
 *  Getting iPrefer focus on state to dashboard component
 */
export const selectIPreferFocusOn = createSelector([selectDomain], (state) => state.iPreferFocusOn);
/**
 *  Getting iPrefer metric selection state to dashboard component
 */
export const selectIPreferMetricSelection = createSelector(
  [selectDomain],
  (state) => state.iPreferMetricSelection
);
/**
 *  Getting iPrefer filter list  state to dashboard component
 */
export const selectIPreferFilterList = createSelector(
  [selectDomain],
  (state) => state.iPreferFilterList
);
/**
 *  Getting iPrefer time comparison filter list  state to dashboard component
 */
export const selectIPreferTimeComparisonFilterList = createSelector(
  [selectDomain],
  (state) => state.iPreferTimeComparisonFilterList
);
/**
 *  Getting iPrefer customer count filter list  state to dashboard component
 */
export const selectIPreferCustomerCountFilterList = createSelector(
  [selectDomain],
  (state) => state.iPreferCustomerCountFilterList
);

/**
 *  Getting iPrefer time frame state to the component
 */
export const selectIPreferTimeFrame = createSelector(
  [selectDomain],
  (state) => state.iPreferTimeFrame
);
