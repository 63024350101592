/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { Edit } from 'react-feather';
/**
 * Styles for compare hotels component
 */

export const CustomEditIcon = styled(Edit)(() => ({
    color: '#3F8CFC',
    '.Mui-disabled &': {
      color: '#9e9e9e',
    },
  }));
