import { useState, useRef, useEffect } from 'react';
import { Grid, IconButton, Stack } from '@mui/material';
import { ChevronsLeft, ChevronsRight, ChevronLeft, ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { ENVIRONMENT } from 'config';
import { BookingDateRangePicker } from 'modules/common/components';
import {
  formatDateLabel,
  getNextMonthStayDate,
  getPreviousMonthStayDate,
} from 'modules/dashboard/functions';
import { selectRegionMapBookingDate } from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';
import { selectTargetDate, selectTrendBookingDate } from 'modules/dashboard/selectors';
import { selectWebClickBookingDate } from 'modules/dashboard/components/tab-container/web-click-tab/selectors';
import { selectSummaryBookingDate } from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import { selectIPreferBookingDate } from 'modules/dashboard/components/tab-container/i-prefer-tab/selectors';
import {
  differenceInDays,
  addDays,
  subDays,
  addSeconds,
  differenceInCalendarMonths,
  parse,
  startOfWeek,
  endOfWeek,
  endOfDay,
  startOfYear,
  subYears,
  endOfYear,
  startOfMonth,
  endOfMonth,
  subMonths,
  isValid,
} from 'date-fns';
import { BOOKING_DATE_START_DATE } from 'modules/common/constants/date-range';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { UiController, commands, events } from 'modules/common/ui-controller';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { regionalMapActions } from 'modules/dashboard/components/tab-container/regional-map-tab/slice';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import { iPreferActions } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';
import { selectWorkbookBookingDate } from 'modules/dashboard/components/tab-container/workbook-report/selectors';
import { workbookActions } from 'modules/dashboard/components/tab-container/workbook-report/slice';
import { paceActions } from '../../../pace-tabs/slice';
import { selectPaceBookingDate } from '../../../pace-tabs/selectors';
/**
 * Booking date-range picker for each widget with scrollable date navigation
 * @param {String} reportType - Report type of  active tab
 * @param {String} latestDate - Latest booking date of selected hotel
 * @returns
 */
const BookingDatePicker = ({ reportType, latestDate, id = '' }) => {
  const dispatch = useDispatch();

  // Current booking date values for each tab is selected here. Refer each selector function for details
  const summaryBookingDate = useSelector(selectSummaryBookingDate);
  const regionalBookingDate = useSelector(selectRegionMapBookingDate);
  const webClickBookingDate = useSelector(selectWebClickBookingDate);
  const trendBookingDate = useSelector(selectTrendBookingDate);
  const paceBookingDate = useSelector(selectPaceBookingDate);
  const iPreferBookingDate = useSelector(selectIPreferBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const workbookBookingDate = useSelector(selectWorkbookBookingDate);

  // Local states to maintain selected date values and to disable scroll navigation icons are defined here
  const [dates, setDates] = useState(summaryBookingDate);
  const [triggerTime, setTriggerTime] = useState();
  const [disableGetPreviousMonth, setDisableGetPreviousMonth] = useState(false);
  const [disableGetNextMonth, setDisableGetNextMonth] = useState(false);
  const [disableGetPreviousPeriod, setDisableGetPreviousPeriod] = useState(false);
  const [disableGetNextPeriod, setDisableGetNextPeriod] = useState(false);
  //
  const triggerTimeRef = useRef(triggerTime);
  const datesRef = useRef(dates);
  triggerTimeRef.current = triggerTime;
  datesRef.current = dates;

  // Triggered when selected report type changes
  useEffect(() => {
    // Set local state of selected booking date based on the report type.
    // Eg: 'summaryBookingDate' is set as  'dates' for summary tab
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
        setDates(summaryBookingDate);
        break;
      case REPORT_TYPE.WORKBOOK_REPORT:
        setDates(workbookBookingDate);
        break;
      case REPORT_TYPE.REGION_MAP:
        setDates(regionalBookingDate);
        break;
      case REPORT_TYPE.TREND_TIME:
        setDates(trendBookingDate);
        break;
      case REPORT_TYPE.WEB_CLICK:
        setDates(webClickBookingDate);
        break;
      case REPORT_TYPE.PACE:
        setDates(paceBookingDate);
        break;
      case REPORT_TYPE.I_PREFER:
        setDates(iPreferBookingDate);
        break;
      default:
        break;
    }
  }, [reportType]);

  // Update global states of booking dates based on the report type to trigger Power BI filter
  const updateStore = (item) => {
    const bookingDate = {
      startDate: new Date(item.selection.startDate).toISOString(),
      endDate: new Date(item.selection.endDate).toISOString(),
      key: 'selection',
    };
    // Booking dates are set using respective dashboard actions based on the active report type.
    // Eg: setSummaryBookingDate is used to update booking date for summary tab
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
        dispatch(summaryActions.setSummaryBookingDate(bookingDate));
        break;
      case REPORT_TYPE.WORKBOOK_REPORT:
        dispatch(workbookActions.setWorkbookBookingDate(bookingDate));
        break;
      case REPORT_TYPE.REGION_MAP:
        dispatch(regionalMapActions.setRegionMapBookingDate(bookingDate));
        break;
      case REPORT_TYPE.TREND_TIME:
        dispatch(dashboardActions.setTrendBookingDate(bookingDate));
        break;
      case REPORT_TYPE.WEB_CLICK:
        dispatch(webClickActions.setWebClickBookingDate(bookingDate));
        break;
      case REPORT_TYPE.PACE:
        dispatch(paceActions.setPaceBookingDate(bookingDate));
        break;
      case REPORT_TYPE.I_PREFER:
        dispatch(iPreferActions.setIPreferBookingDate(bookingDate));
        break;
      default:
        break;
    }
  };
  //
  const handleChange = (item, fromDatePicker = true) => {
    setDates(item.selection);
    // Apply power bi filters directly if the change triggered from the date picker
    if (fromDatePicker) {
      updateStore(item);
    } else {
      // Implemented 2s delay in applying Power BI filter for the date change triggered through scroll navigation
      setTimeout(() => {
        const currentTime = new Date();
        if (currentTime.getTime() - triggerTimeRef.current.getTime() > 999) {
          updateStore(item);
        }
      }, Number(ENVIRONMENT.TIMEOUT_DELAY));
    }
  };
  // Get date range based on report type
  const dateRange = () => {
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
        return [summaryBookingDate];
      case REPORT_TYPE.WORKBOOK_REPORT:
        return [workbookBookingDate];
      case REPORT_TYPE.REGION_MAP:
        return [regionalBookingDate];
      case REPORT_TYPE.TREND_TIME:
        return [trendBookingDate];
      case REPORT_TYPE.WEB_CLICK:
        return [webClickBookingDate];
      case REPORT_TYPE.PACE:
        return [paceBookingDate];
      case REPORT_TYPE.I_PREFER:
        return [iPreferBookingDate];
      default:
        return null;
    }
  };

  // Move back by 1 month scroll navigation implementation
  const getPreviousMonth = () => {
    const newDates = getPreviousMonthStayDate(dates.startDate, dates.endDate);
    let { newStartDate, newEndDate } = newDates;
    //
    if (differenceInDays(new Date(BOOKING_DATE_START_DATE), newStartDate) >= 0)
      newStartDate = new Date(BOOKING_DATE_START_DATE);
    if (differenceInDays(new Date(BOOKING_DATE_START_DATE), newEndDate) >= 0)
      newEndDate = new Date(BOOKING_DATE_START_DATE);
    //
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move forward by 1 month scroll navigation implementation
  const getNextMonth = () => {
    const newDates = getNextMonthStayDate(dates.startDate, dates.endDate);
    let { newStartDate, newEndDate } = newDates;
    // To prevent selected booking date from going beyond current date
    if (differenceInDays(new Date(), newStartDate) <= 0) newStartDate = new Date();
    if (differenceInDays(new Date(), newEndDate) <= 0) newEndDate = new Date();
    //
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move back by 1 period scroll navigation implementation
  const getPreviousDate = () => {
    const range = differenceInDays(new Date(dates.endDate), new Date(dates.startDate));
    const newEndDate = subDays(new Date(dates.startDate), 1);
    let newStartDate;
    newStartDate = subDays(newEndDate, range);
    // To check if new start date is less than default booking start date
    if (
      differenceInDays(
        subDays(
          subDays(new Date(dates?.startDate), 1),
          differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
        ),
        new Date(BOOKING_DATE_START_DATE)
      ) <= 0
    ) {
      newStartDate = new Date(BOOKING_DATE_START_DATE);
    } else {
      newStartDate = subDays(newEndDate, range);
    }
    //
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Move forward by 1 period scroll navigation implementation
  const getNextDate = () => {
    const range = differenceInDays(new Date(dates.endDate), new Date(dates.startDate));
    const newStartDate = addDays(new Date(dates.endDate), 1);
    let newEndDate;
    newEndDate = addDays(newStartDate, range);
    // To check if new end date is greater than today's date
    if (
      differenceInDays(
        addDays(
          addDays(new Date(dates?.endDate), 1),
          differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
        ),
        new Date()
      ) >= 0
    ) {
      newEndDate = new Date();
    } else {
      newEndDate = addDays(newStartDate, range);
    }
    setDates((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    const item = {
      selection: {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    };
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false);
  };

  // Booking date change command flow implementation
  const setBookingDateHandler = (data) => {
    let obj = {
      startDate: new Date().toString(),
      endDate: new Date().toString(),
    };
    if (data?.includes('-')) {
      const datesExtracted = data?.split('-');
      const parsedDate1 = parse(datesExtracted[0].trim(), 'yyyyMMdd', new Date());
      const parsedDate2 = parse(datesExtracted[1].trim(), 'yyyyMMdd', new Date());
      if (isValid(parsedDate1) && isValid(parsedDate2)) {
        obj = {
          startDate: parsedDate1,
          endDate: parsedDate2,
        };
      }
    } else if (/^\d/.test(data) && parse(data.trim(), 'yyyyMMdd', new Date())) {
      const parsedDate = parse(data.trim(), 'yyyyMMdd', new Date());
      if (isValid(parsedDate)) {
        obj = {
          startDate: parsedDate,
          endDate: parsedDate,
        };
      }
    } else if (data === commands.LAST_WEEK) {
      obj = {
        startDate: startOfWeek(subDays(new Date(), 7)),
        endDate: endOfWeek(subDays(new Date(), 7)),
      };
    } else if (data === commands.WEEK_TO_DATE) {
      obj = {
        startDate: startOfWeek(new Date()),
        endDate: endOfDay(new Date()),
      };
    } else if (data === commands.LAST_MONTH) {
      obj = {
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      };
    } else if (data === commands.MONTH_TO_DATE) {
      obj = {
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
      };
    } else if (data === commands.LAST_YEAR) {
      obj = {
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      };
    } else if (data === commands.YEAR_TO_DATE) {
      obj = {
        startDate: startOfYear(new Date()),
        endDate: endOfDay(new Date()),
      };
    }
    handleChange({ selection: { ...obj, key: 'selection' } });
  };

  // subscribe ui controller events
  useEffect(() => {
    UiController.subscribe(events.BOOKING_DATE, setBookingDateHandler);
    return () => {
      UiController.unsubscribe(events.BOOKING_DATE, setBookingDateHandler);
    };
  }, []);

  //
  useEffect(() => {
    // Get previous month icon disabled if the selected start date is less than default booking start date
    setDisableGetPreviousMonth(
      differenceInCalendarMonths(new Date(BOOKING_DATE_START_DATE), new Date(dates?.startDate)) >= 0
    );
    //  Get previous period icon disabled if the selected start date is less than default booking start date
    setDisableGetPreviousPeriod(
      differenceInDays(new Date(BOOKING_DATE_START_DATE), new Date(dates?.startDate)) >= 0
    );
    // Get next period icon disabled if the selected end date is greater than today
    setDisableGetNextPeriod(differenceInDays(new Date(), new Date(dates?.endDate)) <= 0);

    //  Get next month icon disabled if the selected end date is greater than today
    setDisableGetNextMonth(differenceInCalendarMonths(new Date(), new Date(dates?.endDate)) <= 0);
  }, [dates]);
  //
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" paddingTop={0}>
      <Stack direction="row" xs={12} paddingTop={1}>
        <IconButton
          sx={{
            padding: 0,
            marginRight: 1,
          }}
          size="small"
          onClick={getPreviousMonth}
          className={styles.scrollIcon}
          disabled={disableGetPreviousMonth}
        >
          <ChevronsLeft fontSize="small" strokeWidth={4} />
        </IconButton>

        <IconButton
          sx={{
            padding: 0,
            marginRight: 0.5,
          }}
          className={styles.scrollIcon}
          size="small"
          onClick={getPreviousDate}
          disabled={disableGetPreviousPeriod}
        >
          <ChevronLeft fontSize="small" strokeWidth={4} />
        </IconButton>
        <BookingDateRangePicker
          id={id}
          handleChange={(item) => handleChange(item)}
          ranges={dateRange()}
          dateValue={formatDateLabel(dates)}
          cssClassName={styles.bookingCalendarElement}
          maxDate={new Date()}
          targetDate={targetDate}
          dataFeedDate={latestDate}
        />
        <IconButton
          sx={{
            padding: 0,
            marginLeft: 1,
          }}
          className={styles.scrollIcon}
          onClick={getNextDate}
          size="small"
          disabled={disableGetNextPeriod}
        >
          <ChevronRight fontSize="small" strokeWidth={4} />
        </IconButton>
        <IconButton
          sx={{
            padding: 0,
            marginLeft: 0.5,
          }}
          size="small"
          className={styles.scrollIcon}
          onClick={getNextMonth}
          disabled={disableGetNextMonth}
        >
          <ChevronsRight fontSize="small" strokeWidth={4} />
        </IconButton>
      </Stack>
    </Grid>
  );
};
//
export default BookingDatePicker;
