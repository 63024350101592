import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { SLICE, TAGS } from 'modules/dashboard/constants';
import { startOfMonth, subMonths, endOfMonth } from 'date-fns';

/**
 * Initial states of 3D Pace widget functions are defined here
 */

export const initialState = {
  loading: false,
  plotData: [],
  plotTargetDate: {
    startDate: new Date(startOfMonth(subMonths(new Date(), 1))).toISOString(),
    endDate: new Date(endOfMonth(subMonths(new Date(), 1))).toISOString(),
    key: 'selection',
  },
  isOccupied: false,
  plotPaceHorizon: null,
  plotUrl: '',
};
/**
 * All actions related to 3D Pace widget feature are defined here
 */
export const plotPaceSlice = createSlice({
  name: SLICE.PLOT_PACE,
  initialState,
  reducers: {
    getPlotData(state) {
      state.loading = true;
    },
    getPlotDataSucceeded(state, action) {
      state.loading = false;
      state.plotData = action?.payload;
    },
    getPlotDataFailed(state) {
      state.loading = false;
    },
    setPlotTargetDate(state, action) {
      state.plotTargetDate = action?.payload;
    },
    setPlotIsOccupied(state, action) {
      state.isOccupied = action?.payload;
    },
    setPlotPaceHorizon(state, action) {
      state.plotPaceHorizon = action?.payload;
    },
    set3dPaceUrl(state, action) {
      state.plotUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => ({
        ...state,
        plotPaceHorizon: action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
        )?.id,
      }));
  },
});
//
export const { actions: plotPaceActions } = plotPaceSlice;
