import { isEmpty } from 'modules/common/helpers/object';
import { OPERATORS, SPLIT_AND_REGEX } from './constants';

/**
 * Generate query based on selected breakdown sub categories
 * @param {Array} subCategories
 * @returns
 */
export const generateQuery = (subCategories) => {
  if (isEmpty(subCategories)) return null;
  const categoryLabel = subCategories.map((element) => {
    if (element.label.indexOf(' ') >= 0) return `"${element.label}"`;
    return element.label;
  });
  return `= ${categoryLabel?.join(OPERATORS.OR)}`;
};
/**
 * Generate regular expression for 'like' comparison
 * @param {String} value
 * @returns
 */
export const generateRegExp = (value) => {
  const formattedValue = value
    .trimStart()
    .replaceAll(/'/g, '')
    .replaceAll(/"/g, '')
    .replaceAll(/%/g, '.*');
  return RegExp(`^${formattedValue}$`, 'gi');
};
/**
 * Split by 'and' outside the quotes
 * @param {*} sentence 
 * @returns 
 */
export const  splitByAndIgnoringQuotes = (sentence) => {
  // Extract matches
  const matches = sentence.match(SPLIT_AND_REGEX);
  // Filter out empty matches and trim spaces
  const results = matches?.filter(match => match?.trim() !== '' && match?.trim() !== undefined);
  return results;
}
/**
 * Get values for segment sub categories set based on user input query
 * @param {String} setQuery
 * @param {Array} hotelList
 * @returns
 */
export const getGroupSetValues = (setQuery, hotelList) => {
  const querySplit = splitByAndIgnoringQuotes(setQuery);
  let finalResult = [];
  let setObject = [];
  const finalArray = [];
  const notArray = [];
  const categories = hotelList?.map((value) => value?.label?.toLowerCase());
  querySplit?.forEach((v, i) => {
    if (querySplit[i] !== '') {
      const query = querySplit[i]?.trim();
      const resultArray = [];
      const notResultArray = [];
      const equalValues = query?.slice(2)?.split(OPERATORS.OR);
      const likeValue = equalValues?.filter((filter) => filter?.includes(OPERATORS.LIKE));
      const exactValue = equalValues?.filter((filter) => !filter?.includes(OPERATORS.LIKE));
      const trimmedValue = exactValue?.map((value) =>
        value?.toLowerCase()?.trim()?.replaceAll(/"/g, '')
      );
      const splitLike = likeValue?.map((value) =>
        value?.split(OPERATORS.LIKE)?.filter((item) => item.trim())
      );
      const regExp = [];
      if (splitLike?.length > 0 && !isEmpty(splitLike))
        regExp.push(
          ...splitLike.map((value) => value?.map((exp) => exp !== '' && generateRegExp(exp)))
        );

      // get values to match against the breakdown sub categories (=)
      if (query?.split(OPERATORS.SPACE)[0] === OPERATORS.EQUAL) {
        if (!isEmpty(trimmedValue)) {
          resultArray?.push(...categories.filter((element) => trimmedValue?.indexOf(element) >= 0));
        }
        if (!isEmpty(regExp) && !isEmpty(categories)) {
          regExp?.forEach((array) => {
            resultArray?.push(...categories.filter((element) => element?.match(...array)));
          });
        }
        //
        finalArray.push(resultArray);
      }
      // get values to exclude from the breakdown sub categories (!=)
      else if (query?.split(OPERATORS.SPACE)[0] === OPERATORS.NOT_EQUAL) {
        if (!isEmpty(trimmedValue)) {
          notResultArray.push(
            ...categories.filter((element) => trimmedValue?.indexOf(element) >= 0)
          );
        }
        if (!isEmpty(regExp)) {
          regExp?.forEach((array) => {
            notResultArray.push(...categories.filter((element) => element?.match(...array)));
          });
        }
        //
        notArray.push(notResultArray);
      }
    }
    // obtain intersection values in all sub arrays (and operation)
    const matchValues =
      !isEmpty(finalArray) &&
      finalArray?.reduce((accumulator, currentValue) =>
        accumulator?.filter((element) => currentValue?.includes(element))
      );
    // get unique values for not in value set
    const notInVAlues = !isEmpty(notArray) && [...new Set(notArray?.flat())];
    if (!isEmpty(matchValues)) {
      if (!isEmpty(notInVAlues))
        finalResult = matchValues?.filter((value) => !notInVAlues?.includes(value));
      else finalResult = matchValues;
      setObject = hotelList?.filter((filter) =>
        finalResult?.includes(filter?.label?.toLowerCase())
      );
    } else if (!isEmpty(notInVAlues)) {
      setObject = hotelList?.filter(
        (filter) => !notInVAlues?.includes(filter?.label?.toLowerCase())
      );
    }
  });
  return setObject;
};
