import * as yup from 'yup';
import { sanitizeValidation } from '../../../utils/sanitize';
/**
 * Add validation for save  filter form
 */
const addFilterFormValidation = yup.object().shape({
  filterName: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-Z0-9_ ]+$/,
      'Hotel set name should contain only alphanumeric characters, underscores, and spaces',
      {
        excludeEmptyString: true,
      }
    )
    .matches(
      /^[a-zA-Z0-9_]+(?:\s[a-zA-Z0-9_]+)*$/,
      'Ensure there is at most one space between words',
      {
        excludeEmptyString: true,
      }
    )
    .max(20, 'Hotel set name must be at most 20 characters')
    .required('Hotel set name is a required field')
    .test((value) => sanitizeValidation(value, 'filterName')),
});
//
export default addFilterFormValidation;
