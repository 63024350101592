/* eslint-disable react/no-this-in-sfc */
import { useState, useRef, useEffect } from 'react';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { InputAdornment } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { STAY_DATE_END_DATE, STAY_DATE_START_DATE } from 'modules/common/constants/date-range';
import { DateRange, defaultStaticRanges, DefinedRange } from 'react-date-range';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useSelector } from 'react-redux';
import { selectStayDatesPresetList } from 'modules/users/selectors';
import { TextField } from './style';
import customPresets from '../../constants/custom-presets';

/**
 * Custom stay date range picker common component that uses across modules for select stay date range
 * @param {Function} handleChange - function for setting data range
 * @param {Array} ranges - start date and end data of the picker
 * @param {String} label - textfield label
 * @param {String} adornment - whether we need to adornment is beginning or not
 * @param {String} dateValue - selected date value
 * @param {String} cssClassName - use style for entire component
 * @returns
 */
const CustomStayDateRangePicker = (props) => {
  // destruct the props object
  const { handleChange, ranges, label, adornment, dateValue, cssClassName, id = '' } = props;
  //
  const presetList = useSelector(selectStayDatesPresetList);
  const [open, setOpen] = useState(false);
  const [formattedPresetList, setFormattedPresetList] = useState([]);
  //
  const refTarget = useRef(null);
  //
  useEffect(() => {
    if (presetList?.length > 0) {
      const formattedSet = presetList?.map((set) => ({
        label: set?.presetName,
        range: () => ({
          startDate: startOfDay(new Date(set?.startDate)),
          endDate: endOfDay(new Date(set?.endDate)),
        }),
        isSelected(range) {
          const definedRange = this.range();
          return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
          );
        },
      }));
      setFormattedPresetList(formattedSet);
    }
  }, [presetList]);
  // checks the range is exited or not and set date range
  const dateRange =
    ranges !== null
      ? [
          {
            startDate: new Date(ranges[0].startDate),
            endDate: new Date(ranges[0].endDate),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        ];
  //
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="calendarWrap">
        <TextField
          id="stay date range picker"
          InputProps={{
            startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
          }}
          label={label}
          value={dateValue}
          size="small"
          color="primary"
          onClick={() => setOpen(!open)}
          fullWidth
        />
        <div ref={refTarget}>
          {open && (
            <>
              <DefinedRange
                onChange={handleChange}
                ranges={dateRange}
                className={styles.calendarElement3}
                inputRanges={[]}
                staticRanges={[
                  defaultStaticRanges[0],
                  customPresets[0],
                  defaultStaticRanges[2],
                  defaultStaticRanges[4],
                  customPresets[1],
                  customPresets[2],
                  ...formattedPresetList,
                ]}
              />
              <DateRange
                id={id}
                onChange={handleChange}
                editableDateInputs
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                months={1}
                fixedHeight
                direction="horizontal"
                className={cssClassName}
                maxDate={new Date(STAY_DATE_END_DATE)}
                minDate={new Date(STAY_DATE_START_DATE)}
              />
            </>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
//
export default CustomStayDateRangePicker;
