import {
  EXECUTION_TYPE,
  TABULAR_VIEWS,
  UiController,
  breakdownList,
  commands,
  events,
} from 'modules/common/ui-controller';
import { dashboardActions } from 'modules/dashboard/slice';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import { useEffect, useState } from 'react';
import { RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { getValuesFromList, isIncluded } from 'modules/common/utils/array';
import { useDispatch, useSelector } from 'react-redux';
import { regionalMapActions } from '../slice';

/**
 * Custom hook to execute regional map filter command flow
 * @param {Boolean} isLoaded
 * @param {Boolean} customBooking
 * @param {Array} chartBreakdownList
 * @param {Boolean} isDetailDisabled
 * @param {Array} focusOnList
 */
const useRegionalMapCommandFlow = (
  isLoaded,
  customBooking,
  chartBreakdownList,
  isDetailDisabled,
  focusOnList
) => {
  const dispatch = useDispatch();
  //
  const urlOrder = useSelector(selectUrlOrder);
  //
  const [options, setOptions] = useState(null);
  const [breakDownValueOptions, setBreakDownValueOptions] = useState(null);
  const [customBookingCommandValue, setCustomBookingCommandValue] = useState(null);
  const [tabularViewCommandValue, setTabularViewCommandValue] = useState(null);

  // Listener function to set custom booking command value
  const setRelationalMapCustomBookingHandler = (value) => {
    setCustomBookingCommandValue(value);
  };
  // Triggers when customBookingCommandValue, isLoaded change
  useEffect(() => {
    if (customBookingCommandValue !== null && isLoaded && urlOrder === 1) {
      // checks command value is 'OTB'
      if (customBookingCommandValue === commands.OTB) {
        dispatch(regionalMapActions.setRegionalMapOtb(false));
        dispatch(regionalMapActions.setRegionMapBookingDate(RESET_BOOKING_DATE));
        setCustomBookingCommandValue(null);
        dispatch(dashboardActions.setUrlOrder(2));
      } else {
        dispatch(regionalMapActions.setRegionalMapOtb(true));
      }
    }
  }, [customBookingCommandValue, isLoaded, urlOrder]);
  // Triggers when customBooking, customBookingCommandValue, isLoaded change
  useEffect(() => {
    if (
      customBooking &&
      customBookingCommandValue !== null &&
      customBookingCommandValue !== commands.OTB &&
      isLoaded &&
      urlOrder === 1
    ) {
      UiController.setBookingDateRange(customBookingCommandValue);
      setCustomBookingCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(2));
    }
  }, [customBooking, customBookingCommandValue, isLoaded, urlOrder]);
  // set breakdown command value
  const setReginalMapBreakdownHandler = (value) => {
    // get breakdown option
    const breakdownOption = breakdownList[value?.substring(0, 2)];
    // get isDetailed state from command value
    const detailsStatus = parseInt(value?.substring(2, 4), 10);
    if (breakdownOption !== undefined) {
      // get selected breakdown option from command value
      const option = chartBreakdownList?.filter((val) => val?.label?.includes(breakdownOption));
      if (option?.length > 0) {
        dispatch(regionalMapActions.setRegionMapChartBreakdown(option[0]?.id));
        dispatch(regionalMapActions.setRegionMapFocusOn([]));
      }
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(detailsStatus) && !isDetailDisabled) {
        dispatch(regionalMapActions.setIsRegionDetailed(detailsStatus === 1));
      }
    }
    setBreakDownValueOptions(null);
    dispatch(dashboardActions.setUrlOrder(3));
  };
  // set focus on command value
  const setRegionalMapFocusOnHandler = (data) => {
    // get selected focus on values from command value
    const optionsSelected = getValuesFromList(data, focusOnList);
    if (optionsSelected?.length > 0) {
      dispatch(regionalMapActions.setRegionMapFocusOn(optionsSelected));
    } else {
      dispatch(regionalMapActions.setRegionMapFocusOn([]));
    }
    setOptions(null);
    dispatch(dashboardActions.setUrlOrder(4));
  };
  // Add event listener function to set custom booking range command value
  useEffect(() => {
    UiController.subscribe(
      events.SET_RELATION_MAP_CUSTOM_BOOKING,
      setRelationalMapCustomBookingHandler
    );
    return () => {
      UiController.unsubscribe(
        events.SET_RELATION_MAP_CUSTOM_BOOKING,
        setRelationalMapCustomBookingHandler
      );
    };
  }, []);
  // Triggers when options, focusOnList, isLoaded change
  useEffect(() => {
    if (
      focusOnList.length > 0 &&
      options &&
      isIncluded(focusOnList, options) &&
      isLoaded &&
      urlOrder === 3
    ) {
      setTimeout(() => {
        setRegionalMapFocusOnHandler(options);
      }, 2000);
    } else if (
      focusOnList.length > 0 &&
      options !== null &&
      options?.length === 1 &&
      options[0] === EXECUTION_TYPE.EMPTY &&
      isLoaded &&
      urlOrder === 3
    ) {
      setTimeout(() => {
        dispatch(regionalMapActions.setRegionMapFocusOn([]));
        setOptions(null);
        dispatch(dashboardActions.setUrlOrder(4));
      }, 2000);
    }
  }, [options, focusOnList, isLoaded, urlOrder]);
  // Triggers when breakDownValueOptions, chartBreakdownList, isLoaded change
  useEffect(() => {
    if (chartBreakdownList.length > 0 && breakDownValueOptions && isLoaded && urlOrder === 2) {
      setReginalMapBreakdownHandler(breakDownValueOptions);
    }
  }, [breakDownValueOptions, chartBreakdownList, isLoaded, urlOrder]);
  // Triggers when tabularViewCommandValue, isLoaded change
  useEffect(() => {
    if (tabularViewCommandValue !== null && isLoaded && urlOrder === 4) {
      // get the tabular view enable/disable status
      const status = parseInt(tabularViewCommandValue?.substring(1), 10);
      // get the type of tabular view
      const type = tabularViewCommandValue?.substring(0, 1);
      if (status && type) {
        // execute set tabular view toggle based on type
        switch (type) {
          case TABULAR_VIEWS.REGIONAL_BREAKDOWN:
            dispatch(regionalMapActions.setRegionalBreakdownTabularView(status === 1));
            break;
          default:
            break;
        }
      }
      setTabularViewCommandValue(null);
      setTimeout(() => {
        dispatch(dashboardActions.setUrlOrder(18));
      }, 4000);
    }
  }, [tabularViewCommandValue, isLoaded, urlOrder]);
  //
  // Listener function to set focus on command value
  const setFocusOnValue = (data) => {
    setOptions(data);
  };
  // Listener function to set breakdown value
  const setBreakDownValue = (data) => {
    setBreakDownValueOptions(data);
  };
  //
  const setRegionalMapTabularViewValue = (value) => {
    setTabularViewCommandValue(value);
  };
  // Add Listener function to set breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_REGINAL_MAP_BREAKDOWN, setBreakDownValue);
    return () => {
      UiController.unsubscribe(events.SET_REGINAL_MAP_BREAKDOWN, setBreakDownValue);
    };
  }, []);
  // Add Listener function to set focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_REGIONAL_MAP_FOCUS_ON, setFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_REGIONAL_MAP_FOCUS_ON, setFocusOnValue);
    };
  }, []);
  // Add event listener function to set tabular view command value
  useEffect(() => {
    UiController.subscribe(events.SET_REGIONAL_MAP_TABULAR_VIEW, setRegionalMapTabularViewValue);
    return () => {
      UiController.unsubscribe(
        events.SET_REGIONAL_MAP_TABULAR_VIEW,
        setRegionalMapTabularViewValue
      );
    };
  }, []);
};
//
export default useRegionalMapCommandFlow;
