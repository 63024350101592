/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// define initial state
export const initialState = {
  loading: false,
};
/**
 * All actions related to hotel configuration feature are defined here
 */
export const configureHotelRoomsSlice = createSlice({
  name: 'feature/configure-hotel-rooms',
  initialState,
  reducers: {
    configureHotelRooms(state) {
      state.loading = true;
    },
  },
});
//
export const { actions: configureHotelRoomsActions } = configureHotelRoomsSlice;
