import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDateBasedOnTimeFrame } from 'modules/dashboard/functions';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectEnableHotelGroups,
  selectLocalCurrency,
} from 'modules/dashboard/selectors';
import {
  selectIPreferStartDate,
  selectIPreferEndDate,
  selectIPreferTargetDate,
  selectIPreferChartBreakdown,
  selectIPreferAlignment,
  selectIPreferDateComparison,
  selectIPreferSymmetricComparisonToggle,
  selectIPreferIsDetailed,
  selectIPreferFocusOn,
  selectIPreferMetricSelection,
  selectIPreferBookingDate,
  selectIPreferTimeFrame,
  selectIPreferOtb,
  selectIPreferAlignmentToggle,
  selectIPreferCustomDate,
} from 'modules/dashboard/components/tab-container/i-prefer-tab/selectors';
import { TAGS, KEYS } from 'modules/dashboard/constants';
import {
  generateIPreferShareUrl,
  getAlignmentAndDateComparisonSchema,
  getBookingDateSchema,
  getBreakdownAndFocusOnSchemaByBreakdown,
  getChainNameSchema,
  getChartBreakDownSchema,
  getCustomDateComparisonSchema,
  getHotelIdSchema,
  getMetricSelectionSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
  getTimeFrameFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { iPreferActions } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';
/**
 * Custom hook to generate iPrefer tab filter schemas
 */
const useIPrefer = () => {
  const dispatch = useDispatch();
  //
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const targetDate = useSelector(selectIPreferTargetDate);
  const startDate = useSelector(selectIPreferStartDate);
  const endDate = useSelector(selectIPreferEndDate);
  const chartBreakdown = useSelector(selectIPreferChartBreakdown);
  const alignment = useSelector(selectIPreferAlignment);
  const dateComparison = useSelector(selectIPreferDateComparison);
  const symmetricComparisonToggle = useSelector(selectIPreferSymmetricComparisonToggle);
  const isDetailed = useSelector(selectIPreferIsDetailed);
  const focusOn = useSelector(selectIPreferFocusOn);
  const metricSelection = useSelector(selectIPreferMetricSelection);
  const bookingDate = useSelector(selectIPreferBookingDate);
  const timeFrame = useSelector(selectIPreferTimeFrame);
  const vOTB = useSelector(selectIPreferOtb);
  const alignmentToggle = useSelector(selectIPreferAlignmentToggle);
  const customDate = useSelector(selectIPreferCustomDate);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const localCurrency = useSelector(selectLocalCurrency);
  //
  useEffect(() => {
    if (
      (!dateComparison && !chartBreakdown && !alignment && !bookingDate) ||
      (dateComparison && dateComparison === KEYS.CUSTOM && endDate === null)
    )
      return;

    const chartBreakdownFilter = getChartBreakDownSchema(
      visualFilters,
      chartBreakdown,
      isDetailed,
      TAGS.I_PREFER_SEGMENT_BREAKDOWN
    );
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    // const hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    //
    if (
      chartBreakdownFilter &&
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      symmetricComparisonFilter
    )
      dispatch(
        iPreferActions.setIPreferFilterList([
          chartBreakdownFilter,
          bookingDateFilter,
          ...alignmentAndDateComparisonFilter,
          targetDateFilter,
          hotelIdFilter,
          symmetricComparisonFilter,
          ...isCustomDateComparisonFilter,
        ])
      );
    else dispatch(iPreferActions.setIPreferFilterList([]));
  }, [
    chartBreakdown,
    bookingDate,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    isDetailed,
    endDate,
    startDate,
    enableHotelGroups,
  ]);
  //
  useEffect(() => {
    if (
      (!dateComparison && !chartBreakdown && !alignment && !bookingDate) ||
      (dateComparison && dateComparison === KEYS.CUSTOM && endDate === null)
    )
      return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const chainNameFilter = getChainNameSchema(visualFilters);
    // const hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    const metricSelectionFilter = getMetricSelectionSchema(visualFilters, metricSelection);
    //
    if (
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      chainNameFilter &&
      hotelIdFilter &&
      symmetricComparisonFilter &&
      metricSelectionFilter
    )
      dispatch(
        iPreferActions.setIPreferTimeComparisonFilterList([
          bookingDateFilter,
          ...alignmentAndDateComparisonFilter,
          targetDateFilter,
          chainNameFilter,
          hotelIdFilter,
          symmetricComparisonFilter,
          ...isCustomDateComparisonFilter,
          metricSelectionFilter,
        ])
      );
    else dispatch(iPreferActions.setIPreferTimeComparisonFilterList([]));
  }, [
    bookingDate,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    isDetailed,
    endDate,
    startDate,
    metricSelection,
    enableHotelGroups,
  ]);
  // Generate filter list for iPrefer customer count visual
  useEffect(() => {
    if (
      (!dateComparison && !chartBreakdown && !alignment && !bookingDate) ||
      (dateComparison && dateComparison === KEYS.CUSTOM && endDate === null)
    )
      return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const timeFrameVisualFilter = getTimeFrameFilter(visualFilters, timeFrame);
    const stayStartDate = getDateBasedOnTimeFrame(timeFrameVisualFilter, targetDate?.startDate);
    const targetDateFilter = getTargetDateSchema(visualFilters, stayStartDate, targetDate?.endDate);
    // const hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const segmentAndFocusOnFilter = getBreakdownAndFocusOnSchemaByBreakdown(
      visualFilters,
      chartBreakdown,
      isDetailed,
      focusOn
    );

    if (bookingDateFilter && targetDateFilter && hotelIdFilter && segmentAndFocusOnFilter)
      dispatch(
        iPreferActions.setIPreferCustomerCountFilterList([
          bookingDateFilter,
          targetDateFilter,
          hotelIdFilter,
          ...segmentAndFocusOnFilter,
        ])
      );
    else dispatch(iPreferActions.setIPreferCustomerCountFilterList([]));
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    focusOn,
    chartBreakdown,
    timeFrame,
    enableHotelGroups,
  ]);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (
      !bookingDate &&
      !targetDate &&
      !hotelId &&
      !dateComparison &&
      !metricSelection &&
      !timeFrame &&
      !chartBreakdown &&
      !alignment
    )
      return;
    // Generate i prefer filter url
    const iPreferUrl = generateIPreferShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      dateComparison,
      vOTB,
      visualFilters,
      startDate,
      endDate,
      customDate,
      focusOn,
      bookingDate,
      alignmentToggle,
      symmetricComparisonToggle,
      chartBreakdown,
      isDetailed,
      timeFrame,
      metricSelection,
      enableHotelGroups,
      hotelId,
      localCurrency
    );
    //
    dispatch(iPreferActions.setIPreferUrl(iPreferUrl));
  }, [
    pmsSyncToggle,
    bookingDate,
    chartBreakdown,
    dateComparison,
    visualFilters,
    alignmentToggle,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    vOTB,
    focusOn,
    startDate,
    endDate,
    customDate,
    metricSelection,
    timeFrame,
    enableHotelGroups,
    localCurrency
  ]);
};
//
export default useIPrefer;
