import { Button, Grid, Box, TextField, MenuItem, Alert } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectHotelName } from 'modules/dashboard/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { rateCalenderActions } from 'modules/rate-calendar/slice';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'modules/common/helpers/object';
import ERROR_TYPES from 'modules/common/constants/error-types';
import {
  selectLoader,
  selectMajorEventCategories,
  selectMinorEventCategories,
} from 'modules/rate-calendar/selectors';
import { Loader } from 'modules/common/components';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { addEventValidation } from '../../validations/event-validations';
/**
 * Form to add events is defined here
 * @param {*} param0
 * @returns
 */
const AddEventForm = ({ isNewEventAdded, setNewEventAdded, event }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const hotel = useSelector(selectHotelName);
  const majorEventCategories = useSelector(selectMajorEventCategories);
  const minorEventCategories = useSelector(selectMinorEventCategories);
  const loading = useSelector(selectLoader);
  //
  const formElement = useRef(null);
  //
  const onAddEvent = async (values) => {
    dispatch(
      rateCalenderActions.addEventDetails({
        ...values,
        organizationId,
        hotelId: hotel.id,
      })
    );
    setNewEventAdded(false);
  };
  const initialValues = {
    date: event?.date === '' ? new Date() : new Date(event?.date).toISOString().substring(0, 10),
    majorCategory: '',
    minorCategory: '',
    description: '',
  };
  useEffect(() => {
    const query = `limit=${PAGINATION.MAJOR_EVENT_CATEGORIES_PER_PAGE}`;
    dispatch(
      rateCalenderActions.getMajorEventCategories({
        organizationId,
        query,
      })
    );
  }, []);
  //
  const getMinorCategories = (majorEventCategory) => {
    const query = `limit=${PAGINATION.MINOR_EVENT_CATEGORIES_PER_PAGE}`;
    const majorEventCategoryId = majorEventCategories?.filter(
      (filter) => filter?.name === majorEventCategory
    )[0].id;
    dispatch(
      rateCalenderActions.getMinorEventCategories({
        organizationId,
        majorEventCategoryId,
        query,
      })
    );
  };
  //
  return (
    <Loader loading={loading}>
      <Grid item container direction="column" sx={{ flexGrow: 1, flexBasis: 1 }}>
        <Box
          xs={12}
          md={3}
          mx={2}
          component={Grid}
          container
          item
          display="flex"
          direction="row"
          sx={{
            borderRadius: 3,
            flexShrink: 0,
            display: isNewEventAdded ? 'flex' : 'none',
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={addEventValidation}
              onSubmit={onAddEvent}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                    <Alert mt={2} mb={3} severity={notification?.type}>
                      {notification?.message}
                    </Alert>
                  )}
                  <TextField
                    label=" Date"
                    type="date"
                    fullWidth
                    name="date"
                    value={values.date}
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ borderRadius: 5 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="filled"
                    margin="normal"
                  />
                  {majorEventCategories && (
                    <TextField
                      select
                      fullWidth
                      name="majorCategory"
                      value={values.majorCategory}
                      error={Boolean(touched.majorCategory && errors.majorCategory)}
                      helperText={touched.majorCategory && errors.majorCategory}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('majorCategory', e.target.value);
                        getMinorCategories(e.target.value);
                        setFieldValue('minorCategory', '');
                      }}
                      label="Major Category"
                      variant="filled"
                      margin="normal"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {majorEventCategories?.map((type) => (
                        <MenuItem key={type?.id} value={type?.name}>
                          {type?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  {minorEventCategories && (
                    <TextField
                      select
                      fullWidth
                      name="minorCategory"
                      disabled={isEmpty(values.majorCategory)}
                      value={values.minorCategory}
                      error={Boolean(touched.minorCategory && errors.minorCategory)}
                      helperText={touched.minorCategory && errors.minorCategory}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Minor Category"
                      variant="filled"
                      margin="normal"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {minorEventCategories?.map((type) => (
                        <MenuItem key={type?.id} value={type?.name}>
                          {type?.name}
                        </MenuItem>
                      )) ?? <MenuItem />}
                    </TextField>
                  )}
                  <TextField
                    label=" Description"
                    variant="filled"
                    name="description"
                    fullWidth
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <Grid item container direction="row" justifyContent="space-between" my={2}>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      Add Event
                    </Button>
                    <Button variant="outlined" onClick={() => setNewEventAdded(false)}>
                      Cancel
                    </Button>
                  </Grid>
                  <input
                    ref={formElement}
                    style={{
                      visibility: 'hidden',
                    }}
                    type="submit"
                  />
                </form>
              )}
            </Formik>
          </Grid>
        </Box>
      </Grid>
    </Loader>
  );
};
//
export default AddEventForm;
