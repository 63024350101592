import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';

const selectDomain = (state) => state[SLICE.SIGN_IN] || initialState;
/**
 * Getting entire sign in state into the component
 */
export const selectSignInState = createSelector([selectDomain], (sighInState) => sighInState);
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector([selectDomain], (sighInState) => sighInState.loading);
/**
 * Obtain sign in error code from sign in slice
 */
export const selectSignInErrorCode = createSelector(
  [selectDomain],
  (signInState) => signInState.errorCode
);
/**
 * Getting the sign in error state into the component
 */
export const selectSignInError = createSelector([selectDomain], (sighInState) => sighInState.error);
