/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import ExportToCSV from 'modules/dashboard/components/tab-container/export-visual-data/export-csv';
import {
  selectActivePaceTab,
  selectHotelFocusOn,
  selectPaceAlignmentToggle,
  selectPaceCumulativeView,
  selectPaceLagAligned,
  selectPaceSymmetricComparisonToggle,
  selectPaceTimeCumulativeLagAlignedTabularVisuals,
  selectPaceTimeCumulativeTabularVisuals,
  selectPaceTimeLagAlignedTabularVisuals,
  selectPaceTimeTabularFilterList,
  selectPaceTimeTabularVisuals,
  selectRevenueIsDisplayPT,
} from '../../../../selectors';
import { paceActions } from '../../../../slice';
import { isEmpty } from '../../../../../../../../common/helpers/object';
/**
 * Pace Time tabular visual report with filters
 */
const PaceTimeTabularWidget = ({setIsTimeLoaded}) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const tabularFilterList = useSelector(selectPaceTimeTabularFilterList);
  const alignmentToggle = useSelector(selectPaceAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectPaceSymmetricComparisonToggle);
  const tokenDetails = useSelector(selectEmbedToken);
  const paceLagAligned = useSelector(selectPaceLagAligned);
  const paceCumulative = useSelector(selectPaceCumulativeView);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayPT);
  const paceTimeVisuals = useSelector(selectPaceTimeTabularVisuals);
  const paceTimeLagAlignedVisuals = useSelector(selectPaceTimeLagAlignedTabularVisuals);
  const paceTimeAbsoluteCumulativeVisuals = useSelector(selectPaceTimeCumulativeTabularVisuals);
  const paceTimeCumulativeLagAlignedVisuals = useSelector(
    selectPaceTimeCumulativeLagAlignedTabularVisuals
  );
  const activeTab = useSelector(selectActivePaceTab);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  //
  const [tabularCombined, setTabularCombined] = useState({});
  const [isTabularCombinedRendered, setIsTabularCombinedRendered] = useState(false);
  //
  const trendFilters = `Alignment : , ${
    alignmentToggle ? 'On' : 'Off'
  }\r\nSymmetric Comparison : , ${symmetricComparisonToggle ? 'On' : 'Off'}\r\n`;
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (paceCumulative && !paceLagAligned) {
      found = paceTimeAbsoluteCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && !paceCumulative) {
      found = paceTimeLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && paceCumulative) {
      found = paceTimeCumulativeLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else {
      found = paceTimeVisuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    setIsRendered = () => {},
    filter = tabularFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(r) => setIsLoaded(r)}
          setIsRendered={(d) => setIsRendered(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  useEffect(() => {
    setIsTabularCombinedRendered(false);
  }, [paceCumulative, paceLagAligned]);
  //
  return (
    !isEmpty(tabularFilterList) && (
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(
          0,
          styles.tabularVisual,
          setTabularCombined,
          setIsTimeLoaded,
          setIsTabularCombinedRendered
        )}
        onClick={() => {
          dispatch(paceActions.setRevenueIsDisplayPT(!revenueIsDisplay));
        }}
        toggleFragment={
          <ExportToCSV
            exportExcel
            report={tabularCombined}
            isLoaded={isTabularCombinedRendered}
            fileName={
              paceCumulative && paceLagAligned
                ? 'PVT_Cumulative_Lag_Aligned'
                : paceCumulative && !paceLagAligned
                ? 'PVT_Cumulative'
                : paceLagAligned && !paceCumulative
                ? 'PVT_Lag_Aligned'
                : 'PVT_Daily'
            }
            otherFilters={trendFilters}
          />
        }
        isWorkbookHotelComparison={activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET}
        comparisonHotel={hotelFocusOn}
        displayHotelName
      />
    )
  );
};
//
export default PaceTimeTabularWidget;
