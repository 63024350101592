import { CssBaseline } from '@mui/material';
import GlobalStyle from 'modules/common/style/global';
import AuthWrapper from 'modules/common/wrappers/auth';
import { Outlet } from 'react-router-dom';
import { Root } from './style';
/**
 * Parent layout for the entire auth components that wraps outlet with Auth wrapper
 * @returns
 */
const AuthLayout = () => (
  <Root>
    <CssBaseline />
    <GlobalStyle />
    <AuthWrapper>
      <Outlet />
    </AuthWrapper>
  </Root>
);
//
export default AuthLayout;
