/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { usersActions } from './slice';
import API from './constants';

/**
 * Users list generator function to get users list
 * @param {*} param0
 */
export function* usersListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USER_LIST, payload);
    yield put(usersActions.usersListSucceeded(response));
  } catch (error) {
    yield put(usersActions.usersListFailed(error?.message));
  }
}
/**
 * Admin users generator function to create admin user
 * @param {*} param0
 */
export function* adminUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_USERS_ADMIN, payload);
    yield put(usersActions.createAdminUsersSucceeded(response));
  } catch (error) {
    yield put(usersActions.createAdminUsersFailed(error?.message));
  }
}
/**
 * General users generator function to create general user
 * @param {*} param0
 */
export function* generalUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_USERS_GENERAL, payload);
    yield put(usersActions.createGeneralUsersSucceeded(response));
  } catch (error) {
    yield put(usersActions.createGeneralUsersFailed(error?.message));
  }
}
/**
 * Create users generator function to create user
 * @param {*} param0
 */
export function* createUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_USERS, payload);
    yield put(usersActions.createUsersSucceeded(response));
  } catch (error) {
    yield put(usersActions.createUsersFailed(error?.message));
  }
}
/**
 * Get hotels list generator function to get hotels list for user
 * @param {*} param0
 */
export function* getHotelsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_HOTELS, payload);
    yield put(usersActions.getHotelsListSucceeded(response));
  } catch (error) {
    yield put(usersActions.getHotelsListFailed(error?.message));
  }
}
/**
 * Update user generator function to update user
 * @param {*} param0
 */
export function* updateUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_USER, payload);
    yield put(usersActions.updateUserSucceeded(response));
  } catch (error) {
    yield put(usersActions.updateUserFailed(error?.message));
  }
}
/**
 * Get roles generator function to get roles
 * @param {*} param0
 */
export function* getRolesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ROLES, payload);
    yield put(usersActions.getRolesSucceeded(response));
  } catch (error) {
    yield put(usersActions.getRolesFailed(error?.message));
  }
}
/**
 * Update role generator function to update user role
 * @param {*} param0
 */
export function* updateRoleGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_ROLE, payload);
    yield put(usersActions.updateRoleSucceeded(response));
  } catch (error) {
    yield put(usersActions.updateRoleFailed(error?.message));
  }
}
/**
 * Update hotels list generator function to update user hotels list
 * @param {*} param0
 */
export function* updateHotelsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_HOTEL, payload);
    yield put(usersActions.updateHotelsListSucceeded(response));
  } catch (error) {
    yield put(usersActions.updateHotelsListFailed(error?.message));
  }
}
/**
 * Delete user generator function to delete user
 * @param {*} param0
 */
export function* deleteUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_USER, payload);
    yield put(
      usersActions.deleteUserSucceeded({
        response,
        data: payload,
      })
    );
  } catch (error) {
    yield put(usersActions.deleteUserFailed(error?.message));
  }
}
/**
 * All hotels selection list generator function to obtain the list of all registered hotels
 * @param {*} param0
 */
export function* getHotelsSelectionListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTELS, payload);
    yield put(usersActions.getSelectionHotelsListSucceeded(response));
  } catch (error) {
    yield put(usersActions.getSelectionHotelsListFailed(error?.message));
  }
}
/**
 * Get user by Id generator function to get user details by id
 * @param {*} param0
 */
export function* getUserByIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USER_BY_ID, payload);
    yield put(usersActions.getUserByIdSucceeded(response));
  } catch (error) {
    yield put(usersActions.getUserByIdFailed(error?.message));
  }
}
/**
 * Change user password generator function to change user's password
 * @param {*} param0
 */
export function* changeUserPasswordGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_CHANGE_PASSWORD, payload);
    yield put(usersActions.changeUserPasswordSucceeded(response));
  } catch (error) {
    yield put(usersActions.changeUserPasswordFailed(error?.message));
  }
}
/**
 * Update preference generator function to update user preferences
 * @param {*} param0
 */
export function* updatePreferenceGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_USER, payload);
    yield put(usersActions.updatePreferenceSucceeded(response));
  } catch (error) {
    yield put(usersActions.updatePreferenceFailed(error?.message));
  }
}
/**
 * Update landing page generator function to update user custom landing page
 * @param {*} param0
 */
export function* updateLandingGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_LANDING_PAGE, payload);
    yield put(usersActions.updateLandingPageSucceeded(response));
  } catch (error) {
    yield put(usersActions.updateLandingPageFailed(error?.message));
  }
}
/**
 * Get landing page generator function to get user custom landing page
 * @param {*} param0
 */
export function* getLandingGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_LANDING_PAGE, payload);
    yield put(usersActions.getLandingPageSucceeded(response));
  } catch (error) {
    yield put(usersActions.getLandingPageFailed(error?.message));
  }
}
/**
 * Save stay date preset generator function to save user stay date presets
 * @param {*} param0
 */
export function* saveStayDatePresetGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_STAY_DATE_PRESET, payload);
    yield put(usersActions.saveStayDatesPresetSucceeded(response));
  } catch (error) {
    yield put(usersActions.saveStayDatesPresetFailed(error?.message));
  }
}
/**
 * Get Stay Date Preset generator function to get user stay date presets
 * @param {*} param0
 */
export function* getStayDatePresetGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_STAY_DATE_PRESET, payload);
    yield put(usersActions.getStayDatesPresetsSucceeded(response));
  } catch (error) {
    yield put(usersActions.getStayDatesPresetsFailed(error?.message));
  }
}
/**
 * Delete Stay Date Preset generator function to delete user stay date presets
 * @param {*} param0
 */
export function* deleteStayDatePresetGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_STAY_DATE_PRESET, payload);
    yield put(usersActions.deleteStayDatesPresetSucceeded(response));
  } catch (error) {
    yield put(usersActions.deleteStayDatesPresetFailed(error?.message));
  }
}
/**
 * Update hotel metadata generator function to update user preferences
 * @param {*} param0
 */
export function* updateHotelMetadataGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_HOTEL_METADATA, payload);
    yield put(usersActions.updateHotelMetadataSucceeded(response));
  } catch (error) {
    yield put(usersActions.updateHotelMetadataFailed(error?.message));
  }
}
/**
 * Get currency  generator function to obtain currency data
 * @param {*} param0
 */
export function* getCurrencyDataGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_CURRENCY_DATA, payload);
    yield put(usersActions.getCurrencyDataSucceeded(response));
  } catch (error) {
    yield put(usersActions.getCurrencyDataFailed(error?.message));
  }
}
//
export function* userSaga() {
  yield takeLatest(usersActions.usersList.type, usersListGenerator);
  yield takeLatest(usersActions.createAdminUsers.type, adminUsersGenerator);
  yield takeLatest(usersActions.createGeneralUsers.type, generalUsersGenerator);
  yield takeLatest(usersActions.updateUser.type, updateUserGenerator);
  yield takeLatest(usersActions.updateRole.type, updateRoleGenerator);
  yield takeLatest(usersActions.deleteUser.type, deleteUserGenerator);
  yield takeLatest(usersActions.getRoles.type, getRolesGenerator);
  yield takeLatest(usersActions.getHotelsList.type, getHotelsListGenerator);
  yield takeLatest(usersActions.updateHotelsList.type, updateHotelsListGenerator);
  yield takeLatest(usersActions.getSelectionHotelsList.type, getHotelsSelectionListGenerator);
  yield takeLatest(usersActions.getUserById.type, getUserByIdGenerator);
  yield takeLatest(usersActions.changeUserPassword.type, changeUserPasswordGenerator);
  yield takeLatest(usersActions.updatePreference.type, updatePreferenceGenerator);
  yield takeLatest(usersActions.createUsers.type, createUsersGenerator);
  yield takeLatest(usersActions.updateLandingPage.type, updateLandingGenerator);
  yield takeLatest(usersActions.getLandingPage.type, getLandingGenerator);
  yield takeLatest(usersActions.saveStayDatesPreset.type, saveStayDatePresetGenerator);
  yield takeLatest(usersActions.getStayDatesPresets.type, getStayDatePresetGenerator);
  yield takeLatest(usersActions.deleteStayDatesPreset.type, deleteStayDatePresetGenerator);
  yield takeLatest(usersActions.updateHotelMetadata.type, updateHotelMetadataGenerator);
  yield takeLatest(usersActions.getCurrencyData.type, getCurrencyDataGenerator);
}
//
export default userSaga;
