import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from 'modules/common/notifications/slice';
import CustomToggleSwitch from 'modules/common/components/toggle-switch';
import { useEffect, useState } from 'react';
import SaveFilter from 'modules/common/components/add-hotel-group/components/save-filter';
import LoadFilter from 'modules/common/components/add-hotel-group/components/load-filter';
import AggregationTransferList from 'modules/common/components/add-hotel-group/components/add-hotel-group-transfer-list';
import QueryBox from 'modules/common/components/add-hotel-group/components/query-box';
import { dashboardActions } from 'modules/dashboard/slice';
import { X } from 'react-feather';
import { selectEnableHotelGroups, selectHotelGroups } from 'modules/dashboard/selectors';

/**
 * Custom aggregation filter component is implemented
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const AddHotelGroup = ({
  open,
  onClose,
  hotelList,
  selectedHotelGroupName,
  isEditable = false,
}) => {
  const dispatch = useDispatch();
  //
  const [groupHotelList, setGroupHotelList] = useState([]);
  const [showLoadFilter, setShowLoadFilter] = useState(false);
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  const [temGroupList, setTemGroupList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isAllClear, setAllClear] = useState(false);
  const filters = useSelector(selectHotelGroups);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  //
  const [loadList, setLoadList] = useState([]);

  useEffect(() => {
    if (filters?.results?.length > 0) {
      const list = filters?.results?.map((a) => ({
        id: a?.hotelGroups?.hotelGroupName,
        label: a?.hotelGroups?.hotelGroupName,
        query: a?.hotelGroups?.hotelQuery,
      }));

      setLoadList(list);
    }
  }, [filters]);
  //
  useEffect(() => {
    if (enableHotelGroups && isEditable && loadList?.length > 0 && open) {
      const selectedGroup = loadList?.find((a) => a.id === selectedHotelGroupName?.id);
      setLoadFilter(true);
      setSelectedValue(selectedGroup);
      dispatch(dashboardActions.setHotelGroupQuery(selectedGroup?.query?.trim()));
      dispatch(dashboardActions.setSelectedHotelGroupName(selectedGroup?.label));
    }
  }, [isEditable, loadList, selectedHotelGroupName, open, enableHotelGroups]);
  // Triggered once when the component mounted
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  useEffect(() => {
    // Update global and local states
    if (open && (!isEditable || isAllClear)) {
      setGroupHotelList([]);
      setTemGroupList([]);
      setSelectedValue(null);
      dispatch(dashboardActions.setHotelGroupQuery(''));
      dispatch(dashboardActions.setSelectedHotelGroupName(''));
      setAllClear(false);
    }
  }, [open, isEditable, isAllClear]);
  //
  const modalAction = (reason, action) => {
    if (reason && reason === 'backdropClick') return;
    setSelectedValue(null);
    onClose(action);
  };
  //
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => modalAction(reason, false)}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: 2 }}>
        <Grid container item flexDirection="row" justifyContent="space-between" xs={12}>
          <Grid item xs={3.5}>
            <Typography variant="h5">Add/Edit Hotel Set</Typography>
          </Grid>

          <Grid item xs={5}>
            <Autocomplete
              sx={{
                minWidth: 150,
              }}
              value={selectedValue}
              options={loadList ?? []}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              isOptionEqualToValue={(option, value) => value !== null && option?.id === value?.id}
              filterSelectedOptions
              onChange={(event, value) => {
                setLoadFilter(true);
                setSelectedValue(value);
                dispatch(dashboardActions.setHotelGroupQuery(value?.query));
                dispatch(dashboardActions.setSelectedHotelGroupName(value?.label));
              }}
              disableClearable
              disabled={loadList?.length < 1}
              renderInput={(params) => (
                <TextField {...params} label="Hotel Sets" size="small" color="primary" />
              )}
            />
          </Grid>
          <Grid item xs={3.5}>
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                setAllClear(false);
                modalAction(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <X />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 4,
          }}
        >
          <AggregationTransferList
            focusOnList={hotelList}
            groupList={groupHotelList}
            setGroupList={(value) => setGroupHotelList(value)}
            isQueryEnabled={isQueryEnabled}
            temGroupList={temGroupList}
            setTemGroupList={setTemGroupList}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 2,
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          <CustomToggleSwitch
            label="Advanced Querying"
            title=""
            onChange={(event) => setIsQueryEnabled(event.target.checked)}
            checked={isQueryEnabled}
          />

          <QueryBox
            setGroupList={setGroupHotelList}
            onClose={() => {
              modalAction(false);
            }}
            open={open}
            isQueryEnabled={isQueryEnabled}
            loadFilter={loadFilter}
            setLoadFilter={setLoadFilter}
            focusOnList={hotelList}
            setTemGroupList={setTemGroupList}
          />
        </Box>
        <SaveFilter
          groupList={groupHotelList}
          setGroupList={setGroupHotelList}
          setTemGroupList={setTemGroupList}
          onClose={() => {
            modalAction(false);
            setAllClear(false);
          }}
          setAllClear={setAllClear}
        />
        <LoadFilter
          openLoad={showLoadFilter}
          onClose={() => setShowLoadFilter(false)}
          setLoadFilter={setLoadFilter}
        />
      </DialogContent>
    </Dialog>
  );
};
//
export default AddHotelGroup;
