import { useState } from 'react';
import { Grid, TextField, IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
/**
 * Event information view is defined here
 * @param {*} param0
 * @returns
 */
const EventInformationView = ({
  e,
  setDeleteSelected,
  setEditSelected,
  setDeleteEventId,
  setEditEvent,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  //
  const open = Boolean(anchorEl);
  //
  const handleClick = (moreClick) => {
    setAnchorEl(moreClick.currentTarget);
  };
  //
  const onEditClick = () => {
    setEditSelected(true);
    setEditEvent({ ...e }, setAnchorEl(null));
  };
  //
  const onDeleteClick = () => {
    setDeleteSelected(true);
    setDeleteEventId(e?.id, setAnchorEl(null));
  };
  //
  return (
    <Grid item container direction="row" key={e?.id}>
      <TextField
        fullWidth
        size="small"
        sx={{
          borderLeft: `5px solid ${e?.fill}`,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          textOverflow: 'ellipsis',
        }}
        id="filled-read-only-input"
        variant="filled"
        label={e?.minorCategory}
        defaultValue={e?.majorCategory}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          endAdornment: (
            <div>
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => onEditClick()}>Edit</MenuItem>
                <MenuItem onClick={() => onDeleteClick()}>Delete</MenuItem>
              </Menu>
            </div>
          ),
        }}
      />
    </Grid>
  );
};
//
export default EventInformationView;
