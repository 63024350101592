import { Loader } from 'modules/common/components';
import useQuery from 'modules/common/hooks/use-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoader } from 'modules/verify-callback/selectors';
import { selectNotification } from 'modules/common/notifications/selectors';
import { Button, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from 'modules/common/constants/route';
import { verifyCallbackActions } from 'modules/verify-callback/slice';
import { selectResetPasswordToken } from 'modules/common/auth/selectors';
import useAuthErrors from 'modules/common/hooks/use-error-message';
import { notificationActions } from 'modules/common/notifications/slice';
import { Alert, Root } from './style';
/**
 * Centralized component for capturing all the call back endpoints
 * @returns
 */
const VerifyCallback = () => {
  const { generateError, message } = useAuthErrors();
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const loading = useSelector(selectLoader);
  const notification = useSelector(selectNotification);
  const incomingToken = useSelector(selectResetPasswordToken);
  //
  const query = useQuery();
  const token = query.get('token');
  const tokenType = query.get('type');
  const email = query.get('email');
  //
  useEffect(() => {
    if (token) {
      dispatch(verifyCallbackActions.verifyToken({ token }));
    }
  }, [token]);
  //
  useEffect(() => {
    if (notification?.isEnabled) {
      generateError(notification);
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  useEffect(() => {
    if (incomingToken) {
      if (tokenType === 'resetPassword') {
        navigate(ROUTES.RESET_PASSWORD);
      }
      if (tokenType === 'userInvitation') {
        navigate(ROUTES.SIGN_UP);
      }
    }
  }, [incomingToken]);
  useEffect(() => {
    if (email) {
      dispatch(verifyCallbackActions.setEmailId(email));
    } else {
      dispatch(verifyCallbackActions.setEmailId(''));
    }
  }, [email]);
  //
  return (
    <Root>
      <Loader loading={loading}>
        {notification?.isEnabled && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            paddingTop={20}
            direction="column"
          >
            <Grid item xs={12}>
              😢 😢 😢
            </Grid>
            <Grid item xs={12}>
              <Alert mt={2} mb={3} severity={notification?.type}>
                {message}
              </Alert>
            </Grid>
          </Grid>
        )}

        {incomingToken && tokenType === 'resetPassword' && (
          <Button component={Link} to={ROUTES.FORGOT_PASSWORD} fullWidth color="primary">
            Go Back
          </Button>
        )}
      </Loader>
    </Root>
  );
};
//
export default VerifyCallback;
