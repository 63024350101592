import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';
//
const selectDomain = (state) => state[SLICE.PACE] || initialState;
/**
 * Getting pace cumulative state for pace segment component
 */
export const selectPaceCumulativeView = createSelector(
  [selectDomain],
  (state) => state?.paceCumulativeView
);
/**
 * Getting pace widget segment visuals filter list state to pace segment component
 */
export const selectPaceLagAligned = createSelector([selectDomain], (state) => state.paceLagAligned);
/**
 * Getting is pace detailed  state for pace segment component
 */
export const selectIsPaceDetailed = createSelector(
  [selectDomain],
  (state) => state?.isPaceDetailed
);
/**
 *  Getting pace widget focus on state to pace segment component
 */
export const selectPaceFocusOn = createSelector([selectDomain], (state) => state.paceFocusOn);
/**
 *  Getting pace widget chart breakdown state to pace segment component
 */
export const selectPaceChartBreakdown = createSelector(
  [selectDomain],
  (state) => state.paceChartBreakdown
);
/**
 *  Getting  revenue is display state to the  component
 */
export const selectRevenueIsDisplayPT = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplayPT
);
/**
 *  Getting  Occupied Rooms is display state to the  component
 */
export const selectOccupiedRoomsIsDisplayPT = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplayPT
);
/**
 *  Getting  Adr is display state to the  component
 */
export const selectAdrIsDisplayPT = createSelector([selectDomain], (state) => state.adrIsDisplayPT);
/**
 *  Getting  Cxl count is display state to the  component
 */
export const selectCxlIsDisplayPS = createSelector([selectDomain], (state) => state.cxlIsDisplayPS);
/**
 *  Getting  revenue is display state to the  component
 */
export const selectRevenueIsDisplayPS = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplayPS
);
/**
 *  Getting  Occupied Rooms is display state to the  component
 */
export const selectOccupiedRoomsIsDisplayPS = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplayPS
);
/**
 *  Getting  Adr is display state to the  component
 */
export const selectAdrIsDisplayPS = createSelector([selectDomain], (state) => state.adrIsDisplayPS);
/**
 *  Getting  Cxl count is display state to the  component
 */
export const selectCxlIsDisplayPT = createSelector([selectDomain], (state) => state.cxlIsDisplayPT);
/**
 * Getting pace alignment pace  time component
 */
export const selectPaceAlignment = createSelector([selectDomain], (state) => state.paceAlignment);
/**
 *  Getting  Symmetric Comparison is display state to the  component
 */
export const selectSymmetricComparisonIsDisplay = createSelector(
  [selectDomain],
  (state) => state.symmetricComparisonIsDisplay
);
/**
 *  Getting is percentage forecast state to pace  time component
 */
export const selectPercentage = createSelector([selectDomain], (state) => state.percentage);
/**
 *  Getting pace alignment toggle state to pace  time component
 */
export const selectPaceAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.paceAlignmentToggle
);
/**
 * Getting symmetric comparison toggle state for pace  time component
 */
export const selectPaceSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.paceSymmetricComparisonToggle
);
/**
 * Getting date comparison state to pace  time component
 */
export const selectPaceDateComparison = createSelector(
  [selectDomain],
  (state) => state.datePaceComparison
);
/**
 * Getting date comparison state to pace  time component
 */
export const selectPaceTimeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeVisuals
);
/**
 * Getting page time lag align visuals into the component
 */
export const selectPaceTimeLagAlignedVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeLagAlignedVisuals
);
/**
 * Getting page time absolute cumulative visuals into the component
 */
export const selectPaceTimeAbsoluteCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeAbsoluteCumulativeVisuals
);
/**
 * Getting pace time tabular visuals to pace  time component
 */
export const selectPaceTimeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeTabularVisuals
);
/**
 * Getting page time lag align tabular visuals into the component
 */
export const selectPaceTimeLagAlignedTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeLagAlignedTabularVisuals
);
/**
 * Getting page time cumulative  tabular visuals into the component
 */
export const selectPaceTimeCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeCumulativeTabularVisuals
);
/**
 * Getting pace time lag align visuals into the component
 */
export const selectPaceTimeLagAlignedCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeLagAlignedCumulativeVisuals
);
/**
 * Getting pace time lag align cumulative visuals into the component
 */
export const selectPaceTimeCumulativeLagAlignedTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceTimeCumulativeLagAlignedTabularVisuals
);
/**
 *  Getting is primary state to the component
 */
export const selectIsPrimary = createSelector([selectDomain], (state) => state.isPrimary);
/**
 *  Getting is trend hotel forecast state to pace  time component
 */
export const selectPaceTimeRevenueFilterList = createSelector(
  [selectDomain],
  (state) => state.paceTimeRevenueFilterList
);
/**
 *  Getting is trend hotel forecast state to pace  time component
 */
export const selectPaceTimeOccupiedRoomFilterList = createSelector(
  [selectDomain],
  (state) => state.paceTimeOccupiedRoomFilterList
);
/**
 * Getting date comparison state to pace  time component
 */
export const selectPaceSegmentVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentVisuals
);
/**
 * Getting pace segment lag align visuals into the component
 */
export const selectPaceSegmentLagAlignedVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentLagAlignVisuals
);
/**
 * Getting pace segment absolute visuals into the component
 */
export const selectPaceSegmentAbsoluteCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentAbsoluteCumulativeVisuals
);
/**
 * Getting pace segment lag align visuals into the component
 */
export const selectPaceSegmentLagAlignedCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentLagAlignCumulativeVisuals
);
/**
 * Getting pace widget segment visuals filter list state to pace segment component
 */
export const selectPaceWidgetSegmentFilterList = createSelector(
  [selectDomain],
  (state) => state.paceWidgetSegmentFilterList
);
/**
 *  Getting is pace forecast state to the component
 */
export const selectIsForecast = createSelector([selectDomain], (state) => state.isPaceForecast);
/**
 * Getting pace widget share url state to pace segment component
 */
export const selectPaceWidgetShareUrl = createSelector(
  [selectDomain],
  (state) => state.paceWidgetShareUrl
);
/**
 * Getting pace widget booking pace window selection state to pace segment component
 */
export const selectPaceBookingPaceWindow = createSelector(
  [selectDomain],
  (state) => state.bookingPaceWindow
);
/**
 *  Getting pace booking date state to pace component
 */
export const selectPaceBookingDate = createSelector(
  [selectDomain],
  (state) => state.paceBookingDate
);
/**
 *  Getting selected enable secondary hotel state to the component
 */
export const selectEnableSecondaryHotelGroups = createSelector(
  [selectDomain],
  (state) => state.enableSecondaryHotelGroups
);
/**
 *  Getting hotel view focus on state to dashboard component
 */
export const selectHotelFocusOn = createSelector([selectDomain], (state) => state.hotelFocusOn);
/**
 *Getting normalized view state for the component
 */
export const selectNormalizedView = createSelector(
  [selectDomain],
  (state) => state?.normalizedView
);
/**
 *Getting active pace tab state for the  component
 */
export const selectActivePaceTab = createSelector([selectDomain], (state) => state?.activeTab);
/**
 * Getting pace hotel visuals state to pace  hotel component
 */
export const selectPaceHotelVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelVisuals
);
/**
 * Getting pace hotel lag align visuals into the component
 */
export const selectPaceHotelLagAlignedVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelLagAlignVisuals
);
/**
 * Getting pace hotel absolute visuals into the component
 */
export const selectPaceHotelAbsoluteCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelAbsoluteCumulativeVisuals
);
/**
 * Getting pace hotel lag align visuals into the component
 */
export const selectPaceHotelLagAlignedCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelLagAlignCumulativeVisuals
);
/**
 * Getting pace widget segment visuals filter list state to pace segment component
 */
export const selectPaceWidgetHotelFilterList = createSelector(
  [selectDomain],
  (state) => state.paceWidgetHotelFilterList
);
/**
 *  Getting  revenue is display state to the hotel component
 */
export const selectRevenueIsDisplayPH = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplayPH
);
/**
 *  Getting  Occupied Rooms is display state to the hotel component
 */
export const selectOccupiedRoomsIsDisplayPH = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplayPH
);
/**
 *  Getting  Adr is display state to the hotel component
 */
export const selectAdrIsDisplayPH = createSelector([selectDomain], (state) => state.adrIsDisplayPH);
/**
 *  Getting  Cxl count is display state to the hotel component
 */
export const selectCxlIsDisplayPH = createSelector([selectDomain], (state) => state.cxlIsDisplayPH);
/**
 *  Getting tabular view state to trend  component
 */
export const selectTabularView = createSelector([selectDomain], (state) => state.tabularView);
/**
 * Getting pace time widget tabular visuals filter list state to pace time component
 */
export const selectPaceTimeTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.paceTimeTabularFilterList
);
/**
 *  Getting adjusted start date state to component
 */
export const selectAdjustedStartDate = createSelector(
  [selectDomain],
  (state) => state.adjustedStartDate
);
/**
 *  Getting trend booking date state to component
 */
export const selectAdjustedEndDate = createSelector(
  [selectDomain],
  (state) => state.adjustedEndDate
);
/**
 *  Getting granularity state to component
 */
export const selectGranularity = createSelector([selectDomain], (state) => state.granularity);
/**
 * Getting pace hotel widget tabular visuals filter list state to pace time component
 */
export const selectPaceHotelTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.paceHotelTabularFilterList
);
/**
 * Getting pace hotel tabular visuals to pace  time component
 */
export const selectPaceHotelTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelTabularVisuals
);
/**
 * Getting page hotel lag align tabular visuals into the component
 */
export const selectPaceHotelLagAlignedTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelLagAlignedTabularVisuals
);
/**
 * Getting page hotel cumulative  tabular visuals into the component
 */
export const selectPaceHotelCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelCumulativeTabularVisuals
);
/**
 * Getting pace hotel lag align cumulative visuals into the component
 */
export const selectPaceHotelLagAlignedCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceHotelCumulativeLagAlignedTabularVisuals
);
/**
 * Getting pace segment widget tabular visuals filter list state to pace time component
 */
export const selectPaceSegmentTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.paceSegmentTabularFilterList
);
/**
 * Getting pace segment tabular visuals to pace  time component
 */
export const selectPaceSegmentTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentTabularVisuals
);
/**
 * Getting page segment lag align tabular visuals into the component
 */
export const selectPaceSegmentLagAlignedTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentLagAlignedTabularVisuals
);
/**
 * Getting page segment cumulative  tabular visuals into the component
 */
export const selectPaceSegmentCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentCumulativeTabularVisuals
);
/**
 * Getting pace segment lag align cumulative visuals into the component
 */
export const selectPaceSegmentCumulativeLagAlignedTabularVisuals = createSelector(
  [selectDomain],
  (state) => state.paceSegmentCumulativeLagAlignedTabularVisuals
);
/**
 *  Getting  cancel count is display state to the  component
 */
export const selectCxlCountIsDisplay = createSelector(
  [selectDomain],
  (state) => state.cxlCountIsDisplay
);
/**
 *  Getting is pace cancellation filter list to the component
 */
export const selectPaceCancellationFilterList = createSelector(
  [selectDomain],
  (state) => state.paceCancellationFilterList
);
/**
 *  Getting is pace forecast disabled state to the component
 */
export const selectIsPaceForecastDisabled = createSelector(
  [selectDomain],
  (state) => state.isForecastDisabled
);
/**
 * Getting is focus on set state to the component
 */
export const selectIsFocusOnSet = createSelector(
  [selectDomain],
  (state) => state.isFocusOnSet
);