import { useEffect } from 'react';
import { Grid, Fade } from '@mui/material';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { TAB_VALUE } from 'modules/dashboard/constants';
import UseVisualSwap from 'modules/dashboard/components/tab-container/trend-tabs/hooks/use-visual-swap';
import TestWidget from './trend-widgets/test';
import useTestTrendSegment from '../../hooks/use-trend-segment-test';
import useTestTrendTime from '../../hooks/use-trend-time-test';
import useTestTrendHotel from '../../hooks/use-trend-hotel-test';
/**
 * Trend widget parent component
 * @param {Number} value - Trend time widget tab value
 * @param {Boolean} active - whether trend time tab is active
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 */
const TrendTestWidget = ({
  active,
  value,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  UseVisualSwap();
  useTestTrendSegment();
  useTestTrendHotel();
  useTestTrendTime();
  // Triggered when tab value or active tab change
  useEffect(() => {
    // Scroll up when the trend time tab is clicked
    if (value === TAB_VALUE.TREND_TIME) window.scrollTo(0, 0);
  }, [value, active]);
  //
  return (
    active && (
      <Fade in={active} {...{ timeout: 500 }}>
        <Grid
          container
          direction="row"
          sx={{
            display: value === TAB_VALUE.TEST_TREND_TIME ? '' : 'none',
          }}
          className={styles.mainGrid}
          alignItems="flex-start"
        >
          <TestWidget
            value={value}
            latestDate={latestDate}
            reportType={reportType}
            hotelTimezone={hotelTimezone}
            toggleDisabled={toggleDisabled}
          />
        </Grid>
      </Fade>
    )
  );
};
//
export default TrendTestWidget;
