/**
 * Verify callback feature related request structures are defined here
 */
const API = {
  POST_VERIFY_TOKEN: {
    path: '/auth/verify-tokens',
    method: 'POST',
  },
};
//
export default API;
