/* eslint-disable no-param-reassign */
import { useState } from 'react';
import Box from '@mui/material/Box';
import { X, Trash, Save, Edit } from 'react-feather';
import { Tooltip } from '@mui/material';
import { Modal } from 'modules/common/components';
import { GridRowModes, DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
// set initial row values
const initialRows = [
  {
    id: 'R0001',
    roomTypeCode: 'R0001',
    roomType: 'Queen',
    roomCount: 0,
  },
  {
    id: 'R0002',
    roomTypeCode: 'R0002',
    roomType: 'Single',
    roomCount: 50,
  },
  {
    id: 'R0003',
    roomTypeCode: 'R0003',
    roomType: 'Double double',
    roomCount: 30,
  },
  {
    id: 'R0004',
    roomTypeCode: 'R0004',
    roomType: 'Queen',
    roomCount: 5,
  },
  {
    id: 'R0005',
    roomTypeCode: 'R0005',
    roomType: 'Single',
    roomCount: 3,
  },
];
/**
 * Table component to display hotel room configurations
 * @param {*} isShow
 * @param {*} setSelectedEventCategoryId
 */
const HotelsGrid = () => {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [editId, setEditId] = useState(null);
  // start row editing
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  // stop row editing
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  // set row edit mode
  const handleEditClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };
  // save row and set view mode
  const handleSaveClick = () => {
    setRowModesModel({
      ...rowModesModel,
      [editId]: { mode: GridRowModes.View },
    });
    setOpenEditModal(false);
  };
  // delete row
  const handleDeleteClick = () => {
    setRows(rows.filter((row) => row.id !== deleteID));
    setOpenDeleteModal(false);
  };
  // cancel edits and set view mode
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  // update row
  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
    };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  // define column configurations
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'roomTypeCode',
      headerName: 'Room type code',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      editable: true,
    },
    {
      field: 'roomType',
      headerName: 'Room type',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      editable: true,
    },
    {
      field: 'roomCount',
      headerName: 'Number of rooms',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <Save size={14} />
                </Tooltip>
              }
              label="Save"
              className="textPrimary"
              onClick={() => {
                setEditId(id);
                setOpenEditModal(true);
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <X size={14} />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        //
        return [
          <GridActionsCellItem
            icon={<Edit size={14} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <Trash size={14} />
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              setDeleteID(id);
              setOpenDeleteModal(true);
            }}
            color="error"
          />,
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: 'black',
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        columnVisibilityModel={{
          id: false,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{
          newEditingApi: true,
        }}
      />
      <Modal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title="Delete Hotel"
        content="Are you sure you want to delete the hotel?"
        handleSuccess={() => handleDeleteClick()}
        closeLabel="Cancel"
        successLabel="Delete"
        variant="contained"
        color="error"
      />
      <Modal
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        title="Update Hotel"
        content="Are you sure you want to update the hotel ?"
        handleSuccess={() => handleSaveClick()}
        closeLabel="Cancel"
        successLabel="Update"
        variant="contained"
        color="primary"
      />
    </Box>
  );
};
//
export default HotelsGrid;
