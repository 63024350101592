import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from 'modules/common/notifications/slice';
import CustomToggleSwitch from 'modules/common/components/toggle-switch';
import { useEffect, useState } from 'react';
import SaveFilter from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/save-filter';
import AggregationTransferList from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/aggregation-transfer-list';
import QueryBox from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/query-box';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { X } from 'react-feather';
import {
  selectSavedAggregationFilters,
  selectShowCustomGroupingFilterName,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
/**
 * Custom aggregation filter component is implemented
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @param {Array} focusOnList - List of focus on dropdown values
 * @param {String} breakdown - Selected breakdown
 * @returns
 */
const CustomAggregation = ({ open, onClose, focusOnList, breakdown }) => {
  const dispatch = useDispatch();
  const showFilterName = useSelector(selectShowCustomGroupingFilterName);
  const filters = useSelector(selectSavedAggregationFilters);
  //
  const [group1, setGroup1] = useState([]);
  const [group2, setGroup2] = useState([]);
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loadList, setLoadList] = useState([]);
  // create saved custom grouping dropdown list
  useEffect(() => {
    if (filters?.totalData?.length > 0) {
      const list = filters?.totalData?.map((filter) => ({
        id: filter?.queryPersist?.filterName,
        label: filter?.queryPersist?.filterName,
        query: filter?.queryPersist?.query,
        breakdown: filter?.queryPersist?.breakdown,
      }));
      setLoadList(list);
    }
  }, [filters?.totalData]);
  // Triggered once when the component mounted
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  // Triggered when the selected breakdown changes
  useEffect(() => {
    // Update global and local states of custom group on breakdown change
    setSelectedValue(null);
    setGroup1([]);
    setGroup2([]);
    dispatch(trendActions.setSegmentSetAQuery(''));
    dispatch(trendActions.setSegmentSetBQuery(''));
    dispatch(trendActions.setSelectedFilterName(''));
    dispatch(trendActions.setSegmentSetAName(''));
    dispatch(trendActions.setSegmentSetBName(''));
  }, [breakdown]);
  //
  useEffect(() => {
    // Update global and local states of custom group on show filter name change
    if (!showFilterName) {
      setSelectedValue(null);
      setGroup1([]);
      setGroup2([]);
      dispatch(trendActions.setSegmentSetAQuery(''));
      dispatch(trendActions.setSegmentSetBQuery(''));
      dispatch(trendActions.setSelectedFilterName(''));
      dispatch(trendActions.setSegmentSetAName(''));
      dispatch(trendActions.setSegmentSetBName(''));
      dispatch(trendActions.setSegmentGroup1([]));
      dispatch(trendActions.setSegmentGroup2([]));
    }
  }, [showFilterName]);
  //
  const resetForm = () => {
    setSelectedValue(null);
    setGroup1([]);
    setGroup2([]);
    dispatch(trendActions.setSegmentSetAQuery(''));
    dispatch(trendActions.setSegmentSetBQuery(''));
    dispatch(trendActions.setSelectedFilterName(''));
    dispatch(trendActions.setSegmentSetAName(''));
    dispatch(trendActions.setSegmentSetBName(''));
    dispatch(trendActions.setSegmentGroup1([]));
    dispatch(trendActions.setSegmentGroup2([]));
  };
  //
  const modalAction = (reason, action) => {
    if (reason && reason === 'backdropClick') return;
    onClose(action);
  };
  //
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => modalAction(reason, false)}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: 2 }}>
        <Grid container item flexDirection="row" justifyContent="space-between" xs={12}>
          <Grid item xs={4}>
            <Typography variant="h6">Add/Edit Custom Grouping</Typography>
          </Grid>

          <Grid item xs={5}>
            <Autocomplete
              sx={{
                minWidth: 150,
              }}
              value={selectedValue}
              options={loadList ?? []}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              isOptionEqualToValue={(option, value) => value !== null && option?.id === value?.id}
              filterSelectedOptions
              onChange={(event, value) => {
                setLoadFilter(true);
                setSelectedValue(value);
                dispatch(trendActions.setSegmentSetAQuery(value?.query?.setA?.query));
                dispatch(trendActions.setSegmentSetBQuery(value?.query?.setB?.query));
                dispatch(trendActions.setSelectedFilterName(value?.label));
                dispatch(
                  trendActions.setSegmentSetAName(
                    value?.query?.setA?.name ? value?.query?.setA?.name : ''
                  )
                );
                dispatch(
                  trendActions.setSegmentSetBName(
                    value?.query?.setB?.name ? value?.query?.setB?.name : ''
                  )
                );
              }}
              disableClearable
              disabled={loadList?.length < 1}
              renderInput={(params) => (
                <TextField {...params} label="Saved Groupings" size="small" color="primary" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                resetForm();
                modalAction(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <X />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 4,
          }}
        >
          <AggregationTransferList
            focusOnList={focusOnList}
            group1={group1}
            group2={group2}
            setGroup1={(value) => setGroup1(value)}
            setGroup2={(value) => setGroup2(value)}
            isQueryEnabled={isQueryEnabled}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 2,
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          <CustomToggleSwitch
            label="Advanced Querying"
            title=""
            onChange={(event) => setIsQueryEnabled(event.target.checked)}
            checked={isQueryEnabled}
          />

          <QueryBox
            breakdownSubCategories={focusOnList}
            setGroup1={setGroup1}
            setGroup2={setGroup2}
            onClose={() => {
              modalAction(false);
            }}
            open={open}
            isQueryEnabled={isQueryEnabled}
            loadFilter={loadFilter}
            setLoadFilter={setLoadFilter}
          />
        </Box>
        <SaveFilter
          breakdown={breakdown}
          group1={group1}
          group2={group2}
          setGroup1={setGroup1}
          setGroup2={setGroup2}
          onClose={() => {
            modalAction(false);
          }}
          selectedCustomGrouping={selectedValue}
          setCustomGrouping={setSelectedValue}
          resetForm={resetForm}
        />
      </DialogContent>
    </Dialog>
  );
};
//
export default CustomAggregation;
