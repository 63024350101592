/**
 * A preset configuration for rate plans.
 *
 * @typedef {Object} RatePlanPreset
 * @property {Object} columns - Configuration for table columns.
 * @property {number[]} columns.width - Array of widths for each column, where the first two columns have widths of 25 and 40, and the next 16 columns have a width of 10.
 * @property {string} columns.end - Specifies the ending column, represented by the letter 'R'.
 * @property {Object} table - Configuration for the table.
 * @property {number} table.start - The starting index of the table.
 * @property {number} table.end - The ending index of the table.
 */

/**
 * A preset for the rate plan table layout, defining column widths and table boundaries.
 *
 * @type {RatePlanPreset}
 */
export const ratePlanPreset = {
  rows: {
    header: [11],
  },
  columns: {
    width: [25, 40, ...Array(16).fill(12)],
    end: 'R',
  },
  table: {
    start: 11,
    end: 31,
    dataStart: 12,
  },
  bordered: true,
  totalrow: false,
};

export default ratePlanPreset;
