import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import {
  selectTrendSegmentVisuals,
  selectWeeklyTrendSegmentVisuals,
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectCxlIsDisplayTS,
  selectAvgLosIsDisplayTS,
  selectAvgBookingWindowIsDisplayTS,
  selectOccupiedRoomsIsDisplayTS,
  selectRevenueIsDisplayTS,
  selectAdrIsDisplayTS,
  selectTrendSegmentCumulativeVisuals,
  selectTrendSegmentPercentageVisuals,
  selectIsPercentageChange,
  selectTrendHotelNormalizedView,
  selectIsMovingAverage,
  selectMovingAverageVisualTS,
  selectTestTrendSegmentFilterList,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { findVisual } from 'modules/dashboard/components/tab-container/trend-tabs/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
/**
 * Trend Segment report with filters
 * @param {Function} setIsSegmentLoaded - set whether trend segment visuals are loaded
 * @returns
 */
const TestTrendSegmentGraphicalWidget = ({ setIsSegmentLoaded }) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTestTrendSegmentFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const visuals = useSelector(selectTrendSegmentVisuals);
  const weeklyVisuals = useSelector(selectWeeklyTrendSegmentVisuals);
  const cumulativeVisuals = useSelector(selectTrendSegmentCumulativeVisuals);
  const tokenDetails = useSelector(selectEmbedToken);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTS);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayTS);
  const adrIsDisplay = useSelector(selectAdrIsDisplayTS);
  const avgBookingWindowIsDisplay = useSelector(selectAvgBookingWindowIsDisplayTS);
  const avgLosIsDisplay = useSelector(selectAvgLosIsDisplayTS);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayTS);
  const percentageVisuals = useSelector(selectTrendSegmentPercentageVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const movingAverageVisuals = useSelector(selectMovingAverageVisualTS);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  //
  const [isInitialTotalRevenueLoaded, setIsInitialTotalRevenueLoaded] = useState(false);
  const [isInitialTotalOccupiedRoomsLoaded, setIsInitialTotalOccupiedRoomsLoaded] = useState(false);
  const [isInitialAdrLoaded, setIsInitialAdrLoaded] = useState(false);
  const [isInitialAvgBookingWindowLoaded, setIsInitialAvgBookingWindowLoaded] = useState(false);
  const [isInitialCancellationsLoaded, setIsInitialCancellationsLoaded] = useState(false);
  const [isInitialAvgLoSLoaded, setIsInitialAvgLoSLoaded] = useState(false);
  useEffect(() => {
    setIsSegmentLoaded(
      (isInitialTotalRevenueLoaded && isInitialTotalOccupiedRoomsLoaded && isInitialAdrLoaded) ||
        isInitialAvgBookingWindowLoaded ||
        isInitialAvgLoSLoaded ||
        isInitialCancellationsLoaded
    );
  }, [
    isInitialTotalRevenueLoaded,
    isInitialTotalOccupiedRoomsLoaded,
    isInitialAdrLoaded,
    isInitialAvgBookingWindowLoaded,
    isInitialAvgLoSLoaded,
    isInitialCancellationsLoaded,
  ]);
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = findVisual(cumulativeVisuals, index, weeklyViewToggle, [
        REPORT_TYPE.WEEKLY_TREND_SEGMENT,
        REPORT_TYPE.TREND_SEGMENT,
      ]);
    } else if (weeklyViewToggle) {
      found = findVisual(weeklyVisuals, index, true, [REPORT_TYPE.TREND_SEGMENT_CUMULATIVE]);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = findVisual(
        percentageVisuals,
        index,
        true,
        [REPORT_TYPE.TREND_SEGMENT_PERCENTAGE],
        true
      );
    } else if (isMovingAverage) {
      found = findVisual(movingAverageVisuals, index, true, [
        REPORT_TYPE.TREND_SEGMENT_CUMULATIVE,
        REPORT_TYPE.TREND_SEGMENT_MOVING_AVERAGE,
      ]);
    } else {
      found = findVisual(visuals, index, true, [
        REPORT_TYPE.TREND_SEGMENT_CUMULATIVE,
        REPORT_TYPE.TREND_SEGMENT,
      ]);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    <>
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(0, styles.paceVisual, () => {}, setIsInitialTotalRevenueLoaded)}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTS(!revenueIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Trend Segment visuals"
      />
      <VisualGrid
        isDisplay={occupiedRoomsIsDisplay}
        visualName={getVisualName(1)}
        visual={getWidget(1, styles.paceVisual, () => {}, setIsInitialTotalOccupiedRoomsLoaded)}
        onClick={() => {
          dispatch(trendActions.setOccupiedRoomIsDisplayTS(!occupiedRoomsIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Trend Segment visuals"
      />
      <VisualGrid
        isDisplay={adrIsDisplay}
        visualName={getVisualName(2)}
        visual={getWidget(2, styles.paceVisual, () => {}, setIsInitialAdrLoaded)}
        onClick={() => {
          dispatch(trendActions.setAdrIsDisplayTS(!adrIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Trend Segment visuals"
      />
      {getWidget(4, styles.paceVisual, () => {}, setIsInitialAvgBookingWindowLoaded) && (
        <VisualGrid
          isDisplay={avgBookingWindowIsDisplay}
          visualName={getVisualName(4)}
          visual={getWidget(4, styles.paceVisual, () => {}, setIsInitialAvgBookingWindowLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgBookingWindowIsDisplayTS(!avgBookingWindowIsDisplay));
          }}
          information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Trend Segment visuals"
        />
      )}
      {getWidget(3, styles.paceVisual, () => {}, setIsInitialAvgLoSLoaded) && (
        <VisualGrid
          isDisplay={avgLosIsDisplay}
          visualName={getVisualName(3)}
          visual={getWidget(3, styles.paceVisual, () => {}, setIsInitialAvgLoSLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgLosIsDisplayTS(!avgLosIsDisplay));
          }}
          information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Trend Segment visuals"
        />
      )}
      {getWidget(5, styles.paceVisual, () => {}, setIsInitialCancellationsLoaded) && (
        <VisualGrid
          isDisplay={cxlIsDisplay}
          visualName={getVisualName(5)}
          visual={getWidget(5, styles.paceVisual, () => {}, setIsInitialCancellationsLoaded)}
          onClick={() => {
            dispatch(trendActions.setCxlIsDisplayTS(!cxlIsDisplay));
          }}
          information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Trend Segment visuals"
        />
      )}
    </>
  );
};
//
export default TestTrendSegmentGraphicalWidget;
