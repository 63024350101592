import { Box, Popover as MuiPopover, List } from '@mui/material';
import styled from '@emotion/styled';

export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 250px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;
//
export const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;
//
export const ScrollList = styled(List)`
  height: 270px;
`;
