/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';

const selectDomain = (state) => state[SLICE.FORGOT_PASSWORD] || initialState;
/**
 * Getting loader state to forgot password component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (forgotPasswordState) => forgotPasswordState.loading
);
