import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Popper } from '@mui/material';
import { useState } from 'react';
/**
 * Information popper component implementation
 * @param {String} information - the content which needs to show
 * @param {String} placement - where it should be shows (left to right or right to left)
 * @returns
 */
const InformationPopper = ({ information, placement = 'left-start' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <InfoOutlinedIcon
        className="info-icon"
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        color="primary"
      />
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        placement={placement}
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: false,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: false,
            options: {
              altAxis: true,
              altBoundary: false,
              tether: false,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
        ]}
      >
        <Typography
          sx={{
            p: 2,
            backgroundColor: '#e0e0e0',
            borderRadius: 1,
          }}
        >
          {information}
        </Typography>
      </Popper>
    </>
  );
};
//
export default InformationPopper;
