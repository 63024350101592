/* eslint-disable no-restricted-globals */
import { isAfter, isBefore, isEqual, startOfDay } from 'date-fns';

/**
 * Function to handle SQL LIKE pattern matching
 * @param {string} dataValue - The value to be matched
 * @param {string} pattern - The SQL LIKE pattern
 * @returns {boolean} - Whether the value matches the pattern
 */
function like(dataValue, pattern) {
  // This regular expression is used to escape special characters in a string for literal interpretation.
  // It replaces characters with their escaped versions to prevent them from being interpreted as special characters in a regular expression.
  const escapedPattern = pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  // Replace SQL wildcards with regex equivalents
  // % represents zero, one, or multiple characters, replace with .*
  // _ represents a single character, replace with .
  const regexPattern = escapedPattern.replace(/%/g, '.*').replace(/_/g, '.');

  const regex = new RegExp(`^${regexPattern}$`, 'i');
  return regex.test(dataValue);
}

/**
 * Function to check if a value is a valid date
 * @param {any} value - The value to be checked
 * @returns {boolean} - Whether the value is a valid date
 */
function isDate(value) {
  const parsedDate = Date.parse(value);
  return !isNaN(parsedDate) && isNaN(value);
}

/**
 * Function to evaluate expressions within the dataset
 * @param {Object} expression - The expression to evaluate
 * @param {Object} entry - The data entry being evaluated
 * @returns {boolean} - Whether the entry satisfies the expression
 */
const evaluateExpression = (expression, entry) => {
  if (expression.type === 'BinaryExpression') {
    if (expression.operator === 'AND') {
      return (
        evaluateExpression(expression.left, entry) && evaluateExpression(expression.right, entry)
      );
    }
    if (expression.operator === 'OR') {
      return (
        evaluateExpression(expression.left, entry) || evaluateExpression(expression.right, entry)
      );
    }
  } else if (expression.type === 'UnaryExpression') {
    if (expression.operator === 'NOT') {
      return !evaluateExpression(expression.argument, entry);
    }
  } else if (expression.type === 'Comparison') {
    const { field, operator, value } = expression;
    let entryValue = entry[field];
    let cleanedSearchValue = value;
    let searchMin;
    let searchMax;
    if (operator === 'BETWEEN') {
      [searchMin, searchMax] = JSON.parse(cleanedSearchValue.replace(/'/g, '"'));
    }
    const numberOperators = ['>', '<', '>=', '<='];
    const isNumericComparison = numberOperators.includes(operator) || !isNaN(cleanedSearchValue);
    const isDateComparison =
      operator === 'BETWEEN'
        ? isDate(entryValue) && isDate(searchMin) && isDate(searchMax)
        : isDate(entryValue) && isDate(cleanedSearchValue);

    // Handle numeric and date comparisons by converting values appropriately
    if (isNumericComparison || isDateComparison) {
      entryValue = isDateComparison ? startOfDay(new Date(entryValue)) : Number(entryValue);
      cleanedSearchValue = isDateComparison
        ? startOfDay(new Date(cleanedSearchValue))
        : Number(cleanedSearchValue);
    } else {
      // Convert values to lower case strings for string comparisons
      entryValue = (entryValue || '').toString().toLowerCase();
      cleanedSearchValue = (cleanedSearchValue || '')
        ?.replace(/^['"](.*)['"]$/, '$1')
        .toLowerCase(); // This regular expression is used to remove leading and trailing single or double quotes from a string,
    }

    const isValidNumberComparison = (val1, val2) => !Number.isNaN(val1) && !Number.isNaN(val2);
    if (isNumericComparison && !isValidNumberComparison(entryValue, cleanedSearchValue))
      return false;

    switch (operator) {
      case '=':
      case 'EQUAL':
        // If the value contains wildcards, use the LIKE comparison
        if (
          !isDateComparison &&
          (String(cleanedSearchValue).includes('%') || String(cleanedSearchValue).includes('_'))
        ) {
          return like(entryValue, cleanedSearchValue);
        }
        return isDateComparison
          ? isEqual(entryValue, cleanedSearchValue)
          : entryValue === cleanedSearchValue;
      case '!=':
      case '<>':
      case 'NOT EQUAL':
        return isDateComparison
          ? !isEqual(entryValue, cleanedSearchValue)
          : entryValue !== cleanedSearchValue;
      case '>':
        return isDateComparison
          ? isAfter(entryValue, cleanedSearchValue)
          : entryValue > cleanedSearchValue;
      case '<':
        return isDateComparison
          ? isBefore(entryValue, cleanedSearchValue)
          : entryValue < cleanedSearchValue;
      case '>=':
        return isDateComparison
          ? isEqual(entryValue, cleanedSearchValue) || isAfter(entryValue, cleanedSearchValue)
          : entryValue >= cleanedSearchValue;
      case '<=':
        return isDateComparison
          ? isEqual(entryValue, cleanedSearchValue) || isBefore(entryValue, cleanedSearchValue)
          : entryValue <= cleanedSearchValue;
      case 'IN':
        try {
          // Parse the search value as a JSON array and check if entryValue is in the array
          cleanedSearchValue = JSON.parse(cleanedSearchValue?.replace(/'/g, '"'));
          return cleanedSearchValue.includes(entryValue);
        } catch (e) {
          return false;
        }
      case 'NOT IN':
        try {
          // Parse the search value as a JSON array and check if entryValue is not in the array
          cleanedSearchValue = JSON.parse(cleanedSearchValue.replace(/'/g, '"'));
          return !cleanedSearchValue.includes(entryValue);
        } catch (e) {
          return false;
        }
      case 'LIKE':
        // Use the like function for LIKE comparison
        return like(entryValue, cleanedSearchValue);
      case 'NOT LIKE':
        // Use the like function for NOT LIKE comparison
        return !like(entryValue, cleanedSearchValue);
      case 'IS':
        // Check for NULL value
        if (cleanedSearchValue.toUpperCase() === 'NULL') {
          return entryValue !== null && entryValue !== undefined && entryValue === '';
        }
        break;
      case 'IS NOT':
        // Check for NOT NULL value
        if (cleanedSearchValue.toUpperCase() === 'NULL') {
          return entryValue !== null && entryValue !== undefined && entryValue !== '';
        }
        break;
      case 'BETWEEN':
        try {
          const parsedEntryValue = isDateComparison
            ? startOfDay(new Date(entryValue))
            : Number(entryValue);
          const parsedMin = isDateComparison ? startOfDay(new Date(searchMin)) : Number(searchMin);
          const parsedMax = isDateComparison ? startOfDay(new Date(searchMax)) : Number(searchMax);
          //
          return isDateComparison
            ? (isEqual(parsedEntryValue, parsedMin) || isAfter(parsedEntryValue, parsedMin)) &&
                (isEqual(parsedEntryValue, parsedMax) || isBefore(parsedEntryValue, parsedMax))
            : parsedEntryValue >= parsedMin && parsedEntryValue <= parsedMax;
        } catch (e) {
          return false;
        }
      default:
        return false;
    }
  }
  return false;
};

/**
 * Function to filter a dataset based on a tokenized expression
 * @param {Object} token - The tokenized expression
 * @param {Array} dataset - The dataset to be filtered
 * @returns {Array} - The filtered dataset
 */
const evaluate = (token, dataset) => dataset.filter((entry) => evaluateExpression(token, entry));
//
export default evaluate;
