export const paceTimeDailyPreset = {
  rows: {
    header: [12, 13],
  },
  columns: {
    width: [25, 10, ...Array(15).fill(12)],
    end: 'O',
  },
  table: {
    start: 12,
    end: 44,
    dataStart: 14,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceTimeCumulativeLagAlignedPreset = {
  rows: {
    header: [12, 13],
  },
  columns: {
    width: [25, 10, ...Array(18).fill(12)],
    end: 'O',
  },
  table: {
    start: 12,
    end: 44,
    dataStart: 14,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceTimeCumulativePreset = {
  rows: {
    header: [12, 13],
  },
  columns: {
    width: [25, 10, ...Array(18).fill(12)],
    end: 'O',
  },
  table: {
    start: 12,
    end: 44,
    dataStart: 14,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceTimeLagAlinedPreset = {
  rows: {
    header: [12, 13],
  },
  columns: {
    width: [25, 10, ...Array(15).fill(12)],
    end: 'M',
  },
  table: {
    start: 12,
    end: 44,
    dataStart: 14,
  },
  totalrow: false,
  bordered: true,
};
