/**
 * Here mentioned sign-up feature request structure
 */
const API = {
  POST_SIGN_UP: {
    path: '/organizations/:organizationId/users/user-signup',
    method: 'POST',
  },
};
export default API;
//
export const organization = 'PHG';
//
export const ACCEPTED_STATUS = 'ACCEPTED';
