import { useSelector } from 'react-redux';
import { selectLoader } from 'modules/sign-up/selectors';
import { Loader } from 'modules/common/components';
import SignUpFormView from '../sign-up-form';
/**
 * Parent sign up component define here. All the sub component load into here
 * @returns
 */
const SignUpView = () => {
  //
  const loading = useSelector(selectLoader);
  //
  return (
    <Loader loading={loading}>
      <SignUpFormView />
    </Loader>
  );
};
//
export default SignUpView;
