/**
 * Define all table the color constants, font families and primary colors here
 */
export const PRIMARY_COLOR = '#2B3FD2';
//
export const PAGE_BACKGROUND_COLOR = '#FBFBFE';
//
export const FONT_FAMILY = 'Lexend, Tahoma, Geneva, Verdana, sans-serif';
//
export const TABLE_HEADER_COLOR = 'rgba(176, 196, 232)';
//
export const TABLE_SELECT_CELL_COLOR = 'rgba(217, 226, 244, 1)';
//
export const TABLE_HEADER_FONT_COLOR = 'black';
