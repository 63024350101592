import { useRoutes } from 'react-router-dom';
import setRoutes from './routes';

/**
 * Register all the route using useRoute hook
 * @returns
 */
const Router = () => useRoutes(setRoutes());
//
export default Router;
