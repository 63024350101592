import { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoader, selectUsers } from 'modules/users/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { usersActions } from 'modules/users/slice';
import PAGINATION from 'modules/common/constants/pagination-limits';
import ORDER_BY from 'modules/common/constants/order-by';
import { Loader } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import { CardContent, Grid, Card, Typography } from '@mui/material';
import UserDataDrawerView from '../user-data-drawer';
import UsersTableView from '../users-table';
import AddUserFormDialog from '../add-user';
import { Button } from './style';
/**
 * Parent user view component. All the sub sections load into here
 * @param {*} theme - application theme
 * @returns
 */
const UsersView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const organizationId = useSelector(selectOrganizationId);
  const users = useSelector(selectUsers);
  //
  const [selectedUserId, setSelectedUserId] = useState({});
  const [open, setOpen] = useState(false);
  const [show, isShow] = useState(false);
  // get users list
  useEffect(() => {
    dispatch(
      usersActions.usersList({
        organizationId,
        query: `limit=${PAGINATION.USERS_PER_PAGE_COUNT}`,
      })
    );
  }, []);
  // get user selection hotel list
  useEffect(() => {
    window.scrollTo(0, 0);
    const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}&forceFetch=true`;
    dispatch(
      usersActions.getSelectionHotelsList({
        organizationId,
        query,
      })
    );
  }, []);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h2">Configure Users</Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
              Add User
            </Button>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              {!isEmpty(users) && (
                <UsersTableView isShow={isShow} setSelectedUserId={setSelectedUserId} />
              )}
            </Grid>
            <UserDataDrawerView show={show} isShow={isShow} userId={selectedUserId} />
          </Grid>
        </CardContent>
      </Card>
      <AddUserFormDialog open={open} onClose={() => setOpen(false)} />
    </Loader>
  );
};
//
export default withTheme(UsersView);
