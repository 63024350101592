import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { BOOKING_DATE, SLICE, TAGS } from 'modules/dashboard/constants';

/**
 * Initial states of Pace Time widget functions are defined here
 */
export const initialState = {
  regionMapFilterList: [],
  regionMapBookingDate: {
    startDate: new Date(BOOKING_DATE).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  regionMapChartBreakdown: null,
  regionMapFocusOn: [],
  regionalMapOtb: false,
  isRegionDetailed: false,
  regionalMapUrl: '',
  regionalBreakdownTabularView: false,
  //
  isFocusOnSet : false
};
/**
 * All actions related to Pace Time widget feature are defined here
 */
export const regionalMapSlice = createSlice({
  name: SLICE.REGIONAL_MAP,
  initialState,
  reducers: {
    setRegionMapFilterList(state, action) {
      state.regionMapFilterList = action?.payload;
    },

    setRegionMapBookingDate(state, action) {
      state.regionMapBookingDate = action?.payload;
    },
    setRegionMapChartBreakdown(state, action) {
      state.regionMapChartBreakdown = action?.payload;
    },
    setRegionMapFocusOn(state, action) {
      state.regionMapFocusOn = action?.payload;
    },
    setRegionalMapOtb(state, action) {
      state.regionalMapOtb = action?.payload;
    },
    setIsRegionDetailed(state, action) {
      state.isRegionDetailed = action?.payload;
    },
    setRegionalMapUrl(state, action) {
      state.regionalMapUrl = action.payload;
    },
    setRegionalBreakdownTabularView(state, action) {
      state.regionalBreakdownTabularView = action?.payload;
    },
    setIsFocusOnSet (state, action){
      state.isFocusOnSet = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => ({
        ...state,
        regionMapChartBreakdown: action?.payload?.results?.find(
          (filter) =>
            filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN && TAGS.REGION) &&
            filter?.isDefault === true
        )?.id,
      }));
  },
});
//
export const { actions: regionalMapActions } = regionalMapSlice;
