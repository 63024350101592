/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { signUpActions } from './slice';
import API from './constants';
/**
 * Sign up generator function for user creation
 * @param {*} param0
 */
export function* signUpGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SIGN_UP, payload);
    yield put(signUpActions.signUpSucceeded(response));
  } catch (error) {
    yield put(signUpActions.signUpFailed(error.message));
  }
}
//
export function* signUpSaga() {
  yield takeLatest(signUpActions.signUp.type, signUpGenerator);
}
//
export default signUpSaga;
