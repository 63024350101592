/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { Popper } from '@mui/material';

const StyledPopper = styled(Popper)({
  // Custom styles for positioning
  width: 'auto !important',
});

export const CustomPopper = (props) => (
    <StyledPopper
      {...props}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10], // Control the offset [horizontal, vertical]
          },
        },
      ]}
    />
  );