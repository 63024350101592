/* eslint-disable import/no-cycle */
import { plotPaceActions } from 'modules/dashboard/components/tab-container/3d-pace-tab/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { API } from 'modules/dashboard/components/tab-container/3d-pace-tab/constants';

/**
 * Plotly data generator function to obtain data for plotly visualization
 * @param {*} param0
 */
export function* getPlotDataGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PLOT_DATA, payload);
    yield put(plotPaceActions.getPlotDataSucceeded(response));
  } catch (error) {
    yield put(plotPaceActions.getPlotDataFailed(error?.message));
  }
}
//
export function* plotPaceSaga() {
  yield takeLatest(plotPaceActions.getPlotData.type, getPlotDataGenerator);
}
//
export default plotPaceSaga;
