/**
 * Here mentioned rate calendar upload feature request structure
 */
const API = {
  POST_FORECAST_UPLOAD: {
    path: '/organizations/:organizationId/files/booking-forecast/upload',
    method: 'POST',
  },
  POST_FORECAST_FORCE_UPLOAD: {
    path: '/organizations/:organizationId/files/booking-forecast/upload?forceUpdate=true',
    method: 'POST',
  },
};
//
export default API;
