import { useRef, useState } from 'react';
import { Box, Button, Card, Grid, Typography, Link, Stack } from '@mui/material';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { rateCalendarUploadActions } from 'modules/rate-calendar-upload/slice';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectForceUploadConfirmation } from 'modules/rate-calendar-upload/selectors';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { AlertDialog } from 'modules/common/components';
import { Container, Alert, Root } from './style';
/**
 * Component for drop files in rate calendar upload view
 * @returns
 */
const RateCalendarUpload = () => {
  const dispatch = useDispatch();
  //
  const dropzoneRef = useRef(null);
  //
  const notification = useSelector(selectNotification);
  const forceUpload = useSelector(selectForceUploadConfirmation);
  const organizationId = useSelector(selectOrganizationId);
  //
  const [files, setFiles] = useState([]);
  // update file
  const handleSubmit = (file) => {
    dispatch(
      rateCalendarUploadActions.rateCalendarUpload({
        file,
        organizationId,
      })
    );
  };
  // open drop zone modal
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  // handle flow when force upload fails
  const handleForceUploadDialogClose = () => {
    dispatch(rateCalendarUploadActions.rateCalendarForceUploadFailed());
  };
  // upload force file upload
  const handleForceUploadAccept = () => {
    dispatch(
      rateCalendarUploadActions.rateCalendarForceUpload({
        file: files[0],
        organizationId,
      })
    );
  };
  // populate files
  const fileSection = files.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));
  //
  return (
    <Root>
      <Grid>
        <Box>
          <Card
            sx={{
              p: 5,
            }}
          >
            <Typography component="h2" variant="body1" align="center">
              Upload Forecast
            </Typography>
            <Dropzone
              ref={dropzoneRef}
              noClick
              noKeyboard
              onDrop={(file) => {
                setFiles(file);
              }}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="container">
                  <div
                    {...getRootProps({
                      className: 'dropzone',
                    })}
                  >
                    <Container>
                      <input {...getInputProps()} />
                      <Typography component="h2" variant="body1">
                        Drag and drop your file here
                      </Typography>
                    </Container>
                    <Typography component="h2" variant="caption">
                      {
                        'Please ensure the file has extension .xlsx, is < 100 KB and follows the template'
                      }
                    </Typography>
                    <Stack>
                      <Button onClick={openDialog}>Open File Dialog</Button>
                    </Stack>
                  </div>
                  {notification?.isEnabled && (
                    <Alert mt={2} mb={3} severity={notification?.type}>
                      {notification?.message}
                    </Alert>
                  )}
                  <aside>
                    <ul>{fileSection}</ul>
                  </aside>
                </section>
              )}
            </Dropzone>
            <Stack>
              <Button
                variant="contained"
                disabled={files.length !== 1}
                sx={{
                  mb: 2,
                }}
                onClick={() => handleSubmit(files[0])}
              >
                Upload
              </Button>
              <Link
                href="https://phgdatadevstacwebapp.blob.core.windows.net/static/Monthly_UserInput_Template_V1_220824.xlsx"
                sx={{ mx: 'auto' }}
              >
                Download a blank template here
              </Link>
            </Stack>
          </Card>
        </Box>
      </Grid>
      {forceUpload && (
        <AlertDialog
          open={forceUpload}
          handleClose={handleForceUploadDialogClose}
          handleAccept={handleForceUploadAccept}
          title="Warning!"
          message="Data already exists. Would you like to overwrite with this new upload?"
        />
      )}
    </Root>
  );
};
//
export default RateCalendarUpload;
