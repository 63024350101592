import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  // selectHotelDropdownList,
  selectIsDemoUser,
  selectEnableHotelGroups,
  // selectHotelGroupDropdownList,
  selectReportType,
  selectTabValue,
} from 'modules/dashboard/selectors';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendSegmentFocusOn,
  selectTrendHotelNormalizedView,
  selectTrendHotelFocusOn,
  selectEnableSecondaryHotelGroups,
  selectTrendSegmentChartBreakdown,
  selectIsSegmentDetailed,
  selectIsForecast,
  selectActiveTrendTab,
  selectAdjustedEndDate,
  selectGranularity,
  selectTabularView,
  selectAdjustedStartDate,
  selectIsMovingAverage,
  selectMovingAverage,
  selectIsFocusOnSet,
  selectIsFocusAllSelected,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import {
  getBookingDateSchema,
  getGranularitySchema,
  getGroupASchema,
  getGroupHotelFilterSchema,
  getHotelGroupASchema,
  getHotelGroupOrderSchema,
  getHotelGroupSchema,
  getHotelIdSchema,
  getHotelNameOrderSchema,
  getIsForecastSchema,
  getMovingAverageSchema,
  getNormalizedViewSchema,
  getPmsForecastSchema,
  getPmsSyncToggleSchema,
  getSegmentFocusOnSchema,
  getTargetDateSchema,
  getTrendHotelPmsSyncToggleSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { ENVIRONMENT } from 'config';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import { REPORT_TYPE, TAB_VALUE } from 'modules/dashboard/constants';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import { GRANULARITY } from '../constants';
/**
 * Custom hook to generate Trend Hotel widget filter schemas
 */
const useTrendHotel = () => {
  const dispatch = useDispatch();
  //
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const targetStartDate = useSelector(selectWeeklyTrendStartDate);
  const targetEndDate = useSelector(selectWeeklyTrendEndDate);
  const isCumulative = useSelector(selectIsCumulative);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  // const hotelDropdownList = useSelector(selectHotelDropdownList);
  const isDemoUser = useSelector(selectIsDemoUser);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const isTrendForecast = useSelector(selectIsForecast);
  const activeTab = useSelector(selectActiveTrendTab);
  const reportType = useSelector(selectReportType);
  const isTabular = useSelector(selectTabularView);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverage = useSelector(selectMovingAverage);
  const tabValue = useSelector(selectTabValue);
  const IsFocusOnSet = useSelector(selectIsFocusOnSet);
  const isFocusAllSelected = useSelector(selectIsFocusAllSelected);
  //
  const [previousHotel, setPreviousHotel] = useState(null);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  const persistValues = Object.values(serializedState)[1];
  const persistedHotelName = JSON.parse(persistValues).hotelName;
  //
  useEffect(() => {
    // Persist hotel focus on value on browser refresh
    setPreviousHotel(persistedHotelName);
    if (previousHotel !== null) {
      // dispatch(trendActions.setTrendHotelFocusOn(hotelDropdownList[0]));
    }
  }, [hotelId]);

  // Generate filter list for trend hotel visuals
  useEffect(() => {
    if (
      !IsFocusOnSet ||
      tabValue !== TAB_VALUE.TREND_TIME ||
      reportType !== REPORT_TYPE.TREND_TIME ||
      !targetDate ||
      !bookingDate ||
      !visualFilters ||
      !hotelId ||
      !chartBreakdown
    ) {
      return;
    }
    const isForecastFilter = getIsForecastSchema(visualFilters, isTrendForecast);
    const pmsSyncForecastFilter = getPmsForecastSchema(visualFilters, pmsSyncToggle);
    let startDate;
    let endDate;
    if (isTabular) {
      if (weeklyViewToggle) {
        startDate = targetStartDate;
        endDate = targetEndDate;
      } else if (granularity === GRANULARITY.DAILY) {
        startDate = targetDate?.startDate;
        endDate = targetDate?.endDate;
      } else {
        startDate = adjustedStartDate;
        endDate = adjustedEndDate;
      }
    } else if (isCumulative) {
      startDate = targetDate?.startDate;
      endDate = targetDate?.endDate;
    } else {
      startDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      endDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, startDate, endDate);
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const groupFocusOnIds = hotelFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    let hotelGroup1Filter;
    const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    //
    const groupHotelFilter = getGroupHotelFilterSchema(visualFilters);
    if (enableHotelGroups) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds, groupHotelFilter);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId], groupHotelFilter);
    }
    //
    const groupAFilter = getGroupASchema(visualFilters);
    let hotelGroupAFilter;
    if (enableSecondaryHotelGroups) {
      hotelGroupAFilter = getHotelGroupASchema(groupFocusOnIds, groupAFilter);
    } else {
      hotelGroupAFilter = getHotelGroupASchema([hotelFocusOn?.databricksId], groupAFilter);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelNameOrderFilter = getHotelNameOrderSchema(visualFilters, isDemoUser);
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const trendHotelPmsSyncFilter = getTrendHotelPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);
    //
    const hotelFilters = [secondaryHotelIdFilter, hotelGroupAFilter, hotelGroup1Filter];
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    const granularityFilter = getGranularitySchema(visualFilters, granularity);
    const movingAverageFilter = getMovingAverageSchema(visualFilters, movingAverage);
    //
    if (bookingDateFilter && targetDateFilter && hotelFilters && segmentFocusOnFilter) {
      let filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        targetDateFilter,
        hotelNameOrderFilter,
        ...pmsSyncForecastFilter,
        hotelGroupOrderFilter,
        ...hotelFilters,
        segmentFocusOnFilter,
        isForecastFilter,
      ];
      if (isMovingAverage) {
        filters = [...filters, movingAverageFilter];
      }
      if (activeTab === REPORT_TYPE.TREND_HOTEL) {
        storeVisualMapper(VISUAL_MAPPER.trendHotelFilterList);
        //
        writeLog('V2 Trend Hotel Filters', filters);
      }
      dispatch(trendActions.updateTrendHotelFilterList(filters));
      if (normalizedFilter) {
        filters = [...filters, normalizedFilter];
        if (activeTab === REPORT_TYPE.TREND_HOTEL) {
          storeVisualMapper(VISUAL_MAPPER.trendHotelNormalizedFilterList);
          //
          writeLog('V2 Trend Hotel Normalized Filters', filters);
        }
        dispatch(trendActions.setTrendHotelNormalizedFilterList(filters));
      }
      // tabular filter list
      let tabularFilters = [
        ...pmsSyncFilter,
        ...trendHotelPmsSyncFilter,
        bookingDateFilter,
        targetDateFilter,
        hotelGroupOrderFilter,
        ...hotelFilters,
        normalizedFilter,
        movingAverageFilter,
        segmentFocusOnFilter,
        isForecastFilter,
      ];
      if (!weeklyViewToggle) {
        tabularFilters = [...tabularFilters, granularityFilter];
      }
      //
      if (activeTab === REPORT_TYPE.TREND_HOTEL) {
        storeVisualMapper(VISUAL_MAPPER.trendHotelTabularFilterList);
        writeLog('v2 Trend Hotel Tabular Filters', tabularFilters);
      }
      dispatch(trendActions.updateTrendHotelTabularFilterList(tabularFilters));
    }
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    pmsSyncToggle,
    hotelFocusOn,
    weeklyViewToggle,
    targetStartDate,
    targetEndDate,
    isCumulative,
    normalizedViewToggle,
    enableHotelGroups,
    enableSecondaryHotelGroups,
    chartBreakdown,
    isDetailed,
    focusOn,
    isTrendForecast,
    reportType,
    activeTab,
    previousHotel,
    granularity,
    adjustedStartDate,
    adjustedEndDate,
    isTabular,
    isMovingAverage,
    movingAverage,
    tabValue,
    IsFocusOnSet,
    isFocusAllSelected,
  ]);
};
//
export default useTrendHotel;
