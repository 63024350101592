/**
 * event category configuration feature request structure
 */
const API = {
  GET_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories?:query',
    method: 'GET',
  },
  ADD_MINOR_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories/:majorEventCategoryId/minor-event-categories',
    method: 'POST',
  },
  GET_MINOR_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories/:majorEventCategoryId/minor-event-categories?:query',
    method: 'GET',
  },
  UPDATE_MINOR_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories/:majorEventCategoryId/minor-event-categories',
    method: 'PATCH',
  },
  DELETE_MINOR_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories/:majorEventCategoryId/minor-event-categories/:minorEventCategoryId',
    method: 'DELETE',
  },
};
//
export default API;
