import { Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ENVIRONMENT } from 'config';
import ROUTES from 'modules/common/constants/route';
import { BigAvatar, Wrapper } from './style';
/**
 * Auth section wrapper components define here
 * @param {*} children - child components
 * @returns
 */
const AuthWrapper = ({ children }) => {
  // get current location path
  const location = useLocation();
  // checks the current path location and returns the related component wrapped with passed down child component
  return (
    <>
      {location.pathname === ROUTES.LOGIN && (
        <Wrapper>
          {ENVIRONMENT.APP_LOGO && (
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <BigAvatar
                variant="square"
                alt={ENVIRONMENT.APP_NAME}
                src={ENVIRONMENT.APP_LOGO}
                sx={{ '& .MuiAvatar-img': { objectFit: 'contain !important' } }}
              />
            </Grid>
          )}
          <Typography component="h1" variant="h4" align="center" gutterBottom>
          Preferred Revenue Optimization Platform
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Sign in to your account to continue
          </Typography>
          {children}
        </Wrapper>
      )}
      {location.pathname === ROUTES.FORGOT_PASSWORD && (
        <Wrapper>
          {ENVIRONMENT.APP_LOGO && (
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <BigAvatar
                variant="square"
                alt={ENVIRONMENT.APP_NAME}
                src={ENVIRONMENT.APP_LOGO}
                sx={{ '& .MuiAvatar-img': { objectFit: 'contain !important' } }}
              />
            </Grid>
          )}
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your email to reset your password
          </Typography>
          {children}
        </Wrapper>
      )}
      {location.pathname === ROUTES.RESET_PASSWORD && (
        <Wrapper>
          {ENVIRONMENT.APP_LOGO && (
            <Grid>
              <BigAvatar
                variant="square"
                alt={ENVIRONMENT.APP_NAME}
                src={ENVIRONMENT.APP_LOGO}
                sx={{ '& .MuiAvatar-img': { objectFit: 'contain !important' } }}
              />
            </Grid>
          )}
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your new password to reset your password
          </Typography>
          {children}
        </Wrapper>
      )}
      {location.pathname === ROUTES.SIGN_UP && (
        <Wrapper>
          {ENVIRONMENT.APP_LOGO && (
            <Grid container justifyContent="center" alignItems="center" paddingBottom={2}>
              <BigAvatar
                variant="square"
                alt={ENVIRONMENT.APP_NAME}
                src={ENVIRONMENT.APP_LOGO}
                sx={{ '& .MuiAvatar-img': { objectFit: 'contain !important' } }}
              />
            </Grid>
          )}
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Sign Up
          </Typography>

          {children}
        </Wrapper>
      )}
    </>
  );
};
//
export default AuthWrapper;
