import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveTrendTab,
  selectIsCumulative,
  selectIsTrendDeAggregate,
  selectTabularView,
  selectTrendTimeFilterList,
  selectTrendTimeVisuals,
  selectTrendTimeWeeklyView,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import {
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  selectTrendTargetDate,
  selectEmbedToken,
} from 'modules/dashboard/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { CustomToggleSwitch } from 'modules/common/components';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { isSameDay } from 'date-fns';
import { formatDate, getVisualName } from 'modules/dashboard/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import TrendTimeSegmentTabularWidget from './trend-time-widget/time-segment-tabular-visual-view';
import TrendTimeSegmentGraphicalWidget from './trend-time-widget/time-segment-graphical-visual-view';
import TestTrendFilter from '../../filter-panel/test';
import TestTrendSegmentGraphicalWidget from './trend-segment-widget/graphical-visual-view-test';
import TestTrendSegmentTabularWidget from './trend-segment-widget/tabular-visual-view-test';
import TestTrendTimeGraphicalWidget from './trend-time-widget/graphical-visual-view-test';
import TestTrendTimeTabularWidget from './trend-time-widget/tabular-visual-view-test';
import TestTrendHotelTabularWidget from './trend-hotel-widget/tabular-visual-view-test';
import TestTrendHotelGraphicalWidget from './trend-hotel-widget/graphical-visual-view-test';
/**
 * Trend tab widget implementation
 * @param {Number} value - Trend time widget tab value
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const TestWidget = ({ value, latestDate, reportType, hotelTimezone, toggleDisabled }) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendTimeFilterList);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeVisuals);
  const activeTab = useSelector(selectActiveTrendTab);
  const tabularView = useSelector(selectTabularView); // States are obtained from redux store. Refer each selector function for details.
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const startDate = useSelector(selectWeeklyTrendStartDate);
  const endDate = useSelector(selectWeeklyTrendEndDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const isCumulative = useSelector(selectIsCumulative);
  const isTrendDeAggregate = useSelector(selectIsTrendDeAggregate);
  //
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTimeLoaded, setIsTimeLoaded] = useState(false);
  const [isSegmentLoaded, setIsSegmentLoaded] = useState(false);
  const [isHotelLoaded, setIsHotelLoaded] = useState(false);
  // To get visuals based on the configuration
  const getWidget = (index, cssClassName) => {
    const found = visuals?.find((visual) => visual?.order === index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filterList}
        />
      )
    );
  };
  // To determine is visual loaded state for trend tabs
  useEffect(() => {
    switch (activeTab) {
      case REPORT_TYPE.TREND_TIME:
        setIsLoaded(isTimeLoaded);
        break;
      case REPORT_TYPE.TREND_SEGMENT:
        setIsLoaded(isSegmentLoaded);
        break;
      case REPORT_TYPE.TREND_HOTEL:
        setIsLoaded(isHotelLoaded);
        break;
      default:
        break;
    }
  }, [isTimeLoaded, isSegmentLoaded, isHotelLoaded, activeTab]);
  //
  const [label, setLabel] = useState(null);
  // Triggered when startDate, endDate, targetDate, weeklyViewToggle, isCumulative values change
  useEffect(() => {
    // Show adjusted stay dates for weekly view toggle on
    if (isCumulative) {
      setLabel(null);
    } else if (
      weeklyViewToggle &&
      (!isSameDay(new Date(startDate), new Date(targetDate.startDate)) ||
        !isSameDay(new Date(endDate), new Date(targetDate.endDate)))
    ) {
      setLabel(
        `Stay date selection adjusted.  New stay date range from: ${formatDate(
          startDate
        )} to ${formatDate(endDate)}`
      );
    } else {
      setLabel(null);
    }
  }, [startDate, endDate, targetDate, weeklyViewToggle, isCumulative]);
  //
  return (
    <>
      <Grid
        item
        xs={2}
        md={2}
        cc={2.5}
        sx={{
          ml: {
            xs: 0,
            cc: 0.25,
            md: 0,
          },
        }}
      >
        <TestTrendFilter
          reportType={reportType}
          visual={getWidget(100, styles.symmetricComparisonWindow)}
          visualName={getVisualName(visuals, 100)}
          tabValue={value}
          toggleDisabled={toggleDisabled}
          latestDate={latestDate}
          hotelTimezone={hotelTimezone}
          isLoaded={isLoaded}
        />
      </Grid>
      <Grid container item xs={10} cc={9.25} md={10} direction="column">
        <Grid item container justifyContent="flex-end" xs={12}>
          <CustomToggleSwitch
            onChange={(event) => dispatch(trendActions.setTabularView(event.target.checked))}
            label="Tabular View"
            checked={tabularView}
            paddingBottom={1}
          />
        </Grid>
        <Grid item container justifyContent="space-around">
          {label && (
            <Typography paddingBottom={1} className={styles.weeklyLabel}>
              {label}
            </Typography>
          )}
        </Grid>
        {activeTab === REPORT_TYPE.TREND_TIME &&
          !isTrendDeAggregate &&
          (tabularView ? (
            <TestTrendTimeTabularWidget />
          ) : (
            <TestTrendTimeGraphicalWidget setIsTimeLoaded={setIsTimeLoaded} />
          ))}
        {activeTab === REPORT_TYPE.TREND_TIME &&
          isTrendDeAggregate &&
          (tabularView ? (
            <TrendTimeSegmentTabularWidget />
          ) : (
            <TrendTimeSegmentGraphicalWidget setIsTimeLoaded={setIsTimeLoaded} />
          ))}
        {activeTab === REPORT_TYPE.TREND_SEGMENT &&
          (tabularView ? (
            <TestTrendSegmentTabularWidget />
          ) : (
            <TestTrendSegmentGraphicalWidget setIsSegmentLoaded={setIsSegmentLoaded} />
          ))}
        {activeTab === REPORT_TYPE.TREND_HOTEL &&
          (tabularView ? (
            <TestTrendHotelTabularWidget />
          ) : (
            <TestTrendHotelGraphicalWidget setIsHotelLoaded={setIsHotelLoaded} />
          ))}
      </Grid>
    </>
  );
};
//
export default TestWidget;
