import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
/**
 * Dialog box to display no data message
 * @param {Boolean} open - State to open/ close the dialog
 * @param {Function} onClose - Function to call when modal closed
 * @returns
 */
const NoDataDialog = ({ open, onClose }) => {
  //
  const modalAction = (action) => {
    onClose(action);
  };
  //
  return (
    <Dialog
      open={open}
      onClose={() => modalAction(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogContent>
        <Typography variant="h6" paddingBottom={3} align="center">
          No data available to export.
        </Typography>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={() => modalAction(false)} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
//
export default NoDataDialog;
