import { Autocomplete, Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'modules/common/auth/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import {
  selectHotelDropdownList,
  selectHotelName,
  selectIsSignedIn,
  selectHotelGroupDropdownList,
  selectEnableHotelGroups,
  selectMergedHotelList,
  selectHotelGroups,
  selectIsDemoUser,
  selectPredefinedHotelGroups,
} from 'modules/dashboard/selectors';
import { useEffect, useState } from 'react';
import { getHotelValuesFromList } from 'modules/common/utils/array';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import { UiController, events } from 'modules/common/ui-controller';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { toDate } from 'modules/dashboard/functions';
import ORDER_BY from 'modules/common/constants/order-by';
import { ENVIRONMENT } from 'config';
import AddHotelGroup from 'modules/common/components/add-hotel-group/components/add-hotel-group-view';
import { Apartment } from '@mui/icons-material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { TextField, CustomEditIcon } from './style';

/**
 * Navigation bar hotel dropdown common component that uses
 * across modules for display hotels dropdown and select hotels
 */
const NavbarHotelDropdown = () => {
  const dispatch = useDispatch();
  //
  const selectedHotelName = useSelector(selectHotelName);
  const organizationId = useSelector(selectOrganizationId);
  const hotelList = useSelector(selectHotelDropdownList);
  const groupsList = useSelector(selectHotelGroupDropdownList);
  const isSignIn = useSelector(selectIsSignedIn);
  const authUser = useSelector(selectAuthUser);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const selectedHotelGroups = useSelector(selectHotelGroups);
  const mergedHotelList = useSelector(selectMergedHotelList);
  const isDemoUser = useSelector(selectIsDemoUser);
  const predefinedHotelGroups = useSelector(selectPredefinedHotelGroups);
  //
  const [hotelCommand, setHotelCommand] = useState(null);
  const [hotelGroupCommand, setHotelGroupCommand] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [noOfRooms, setNoOfRooms] = useState(0);
  const [modifiedList, setModifiedList] = useState([]);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  // get hotel list
  useEffect(() => {
    if (!isSignIn && serializedState) {
      return;
    }
    const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}`;
    dispatch(
      dashboardActions.getHotelList({
        organizationId,
        query,
      })
    );
    const forceQuery = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}&forceFetch=true`;
    dispatch(
      dashboardActions.getAllHotelList({
        organizationId,
        query: forceQuery,
      })
    );
    dispatch(
      authActions.getOrganization({
        organizationId,
      })
    );
  }, []);
  //
  useEffect(() => {
    if (authUser && organizationId) {
      const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`;
      dispatch(
        dashboardActions.getHotelGroupList({
          organizationId,
          userId: authUser?.id,
          query,
        })
      );
      const query2 = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=0`;
      dispatch(
        dashboardActions.getLoadHotelGroupList({
          organizationId,
          userId: authUser?.id,
          query: query2,
        })
      );
      dispatch(
        dashboardActions.getCommandFilterDropDownList({
          organizationId,
          userId: authUser?.id,
          query: `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`,
        })
      );
      dispatch(
        dashboardActions.getAssignedHotelGroupList({
          organizationId,
          userId: authUser?.id,
        })
      );
    }
  }, [authUser, organizationId]);
  // get selected hotel , set pms date and set selected hotel for configure event categories
  useEffect(() => {
    const hotelData = hotelList?.filter((hotel) => hotel?.id === selectedHotelName?.id);
    if (!enableHotelGroups && hotelData && hotelData.length > 0 && hotelData[0]?.pmsDate) {
      dispatch(dashboardActions.setPmsDate(toDate(new Date(hotelData[0]?.pmsDate))));
    } else if (enableHotelGroups && hotelData && hotelData?.hotels?.length > 0) {
      const pmsDates = hotelData?.hotels?.map((hotel) => {
        if (hotel?.pmsDate) {
          return toDate(new Date(hotel?.pmsDate));
        }
        return null;
      });
      dispatch(dashboardActions.setGroupPmsDates(pmsDates));
    } else {
      dispatch(dashboardActions.setPmsDate(null));
      dispatch(dashboardActions.setGroupPmsDates(null));
    }
    dispatch(configureEventCategoriesActions.setHotelId(selectedHotelName?.id));
  }, [selectedHotelName, hotelList, groupsList, enableHotelGroups]);
  // calculate no of rooms per hotel/hotel set
  useEffect(() => {
    setNoOfRooms(0);
    if (selectedHotelName !== null && enableHotelGroups) {
      setIsEditable(true);
      selectedHotelName?.hotels?.forEach((hotel) => {
        setNoOfRooms((prev) => prev + parseInt(hotel?.roomCapacity, 10));
      });
    } else if (selectedHotelName !== null) {
      setIsEditable(false);
      setNoOfRooms((prev) => prev + parseInt(selectedHotelName?.roomCapacity, 10));
    }
  }, [selectedHotelName, enableHotelGroups]);

  /* Temporarily commented out as the filter forecast dropdown is not in use. */
  // checked difference in start and end date in stay date range and get forecast filters
  // useEffect(() => {
  //   if (selectedHotelName) {
  //     const hotelId = selectedHotelName?.id;
  //     if (
  //       differenceInCalendarDays(new Date(targetDate.startDate), new Date(targetDate.endDate)) === 0
  //     ) {
  //       const query = `limit=${PAGINATION.FORECAST_DATA}&stayDate=${formatDate(
  //         targetDate.startDate,
  //         YEAR_MONTH_DAY_PATTERN
  //       )}`;
  //       dispatch(dashboardActions.getForecastFilters({ organizationId, hotelId, query }));
  //     } else {
  //       const query = `limit=${PAGINATION.FORECAST_DATA}&stayDate=${formatDate(
  //         targetDate.startDate,
  //         YEAR_MONTH_DAY_PATTERN
  //       )}&toStayDate=${formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN)}`;
  //       dispatch(dashboardActions.getForecastFilters({ organizationId, hotelId, query }));
  //     }
  //   } else {
  //     dispatch(dashboardActions.setForecastFilters([]));
  //   }
  // }, [selectedHotelName, targetDate]);

  // execute set hotel ui controller command flow
  useEffect(() => {
    if (hotelCommand !== null && hotelList?.length > 0) {
      const hotelsSelected = getHotelValuesFromList(hotelCommand, hotelList);
      if (hotelsSelected?.length > 0) {
        dispatch(dashboardActions.setEnableHotelGroups(false));
        dispatch(dashboardActions.setHotelName(hotelsSelected[0]));
      }
      dispatch(dashboardActions.setHotelChange(true));
      setHotelCommand(null);
    }
  }, [hotelCommand, hotelList?.length]);
  // execute set hotel ui controller command flow
  useEffect(() => {
    if (
      hotelGroupCommand !== null &&
      (groupsList?.length > 0 || predefinedHotelGroups?.length > 0)
    ) {
      const hotelGroupsSelected = getHotelValuesFromList(hotelGroupCommand, [
        ...groupsList,
        ...predefinedHotelGroups,
      ]);
      if (hotelGroupsSelected?.length > 0) {
        dispatch(dashboardActions.setEnableHotelGroups(true));
        dispatch(dashboardActions.setHotelName(hotelGroupsSelected[0]));
      }
      dispatch(dashboardActions.setHotelChange(true));
      setHotelGroupCommand(null);
    }
  }, [hotelGroupCommand, groupsList?.length, predefinedHotelGroups?.length]);
  // get set hotel command value
  const setHotelHandler = (data) => {
    setHotelCommand(data);
  };
  // get set hotel command value
  const setHotelGroupHandler = (data) => {
    setHotelGroupCommand(data);
  };
  // subscribe ui controller events
  useEffect(() => {
    UiController.subscribe(events.SET_HOTEL, setHotelHandler);
    return () => {
      UiController.unsubscribe(events.SET_HOTEL, setHotelHandler);
    };
  }, []);
  //
  useEffect(() => {
    UiController.subscribe(events.SET_HOTEL_GROUP, setHotelGroupHandler);
    return () => {
      UiController.unsubscribe(events.SET_HOTEL_GROUP, setHotelGroupHandler);
    };
  }, []);
  //
  const handleAddHotelToFavorites = (e, option) => {
    if (groupsList?.some((group) => group?.label === option?.label)) {
      const favoriteHotelSets = groupsList
        ?.map(() => {
          const matchingGroup = selectedHotelGroups?.results?.find(
            ({ hotelGroups }) => hotelGroups && hotelGroups?.hotelGroupName === option?.id
          );
          return matchingGroup
            ? {
                hotelGroups: [
                  {
                    hotelQuery: matchingGroup?.hotelGroups?.hotelQuery,
                    hotelGroupName: matchingGroup?.hotelGroups?.hotelGroupName,
                    isFavorite: !option?.isFavorite,
                  },
                ],
                userId: authUser?.id,
                organizationId,
              }
            : null;
        })
        ?.filter(Boolean)[0];
      dispatch(dashboardActions.addHotelGroupToFavorites(favoriteHotelSets));
    } else {
      dispatch(
        dashboardActions.addHotelToFavorite({
          organizationId,
          userId: authUser?.id,
          hotelId: option?.id,
        })
      );
    }
  };
  //
  useEffect(() => {
    if (enableHotelGroups) {
      setIsEditable(!selectedHotelName?.isPredefined);
    }
  }, [selectedHotelName, enableHotelGroups]);
  //
  const modifyOptions = (options) => {
    const modifiedOptions = [];

    options?.forEach((option) => {
      if (option.isFavorite && !option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 3 });
      } else if (!option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 1 });
      }
      if (option.isFavorite && !option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 1 });
      }
      if (option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 2 });
      }
    });

    // Sort options first by group, then by label
    return modifiedOptions.sort((a, b) => {
      if (a.group === b.group) {
        if (isDemoUser) {
          return Number(a?.label?.split(' ')[1]) - Number(b?.label?.split(' ')[1]);
        }
        return a.label.localeCompare(b.label);
      }
      return b.group - a.group;
    });
  };
  //

  useEffect(() => {
    if (mergedHotelList?.length > 0) {
      const modifiedOptions = modifyOptions(mergedHotelList);
      setModifiedList(modifiedOptions);
    }
  }, [mergedHotelList, isDemoUser]);
  //
  return (
    <Grid container flexDirection="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
      <Grid item container justifyContent="flex-end" alignItems="center" flexDirection="column">
        <Autocomplete
          id="primary-hotel-dropdown"
          size="small"
          sx={{
            minWidth: 200,
            mt: 3,
            mb: -1,
            '& .MuiOutlinedInput-root': {
              color: '#e5e5e5',
            },
            '& .MuiSvgIcon-root': {
              color: '#e5e5e5',
              width: '2rem',
              height: '2rem',
              top: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiAutocomplete-input': {
              fontSize: { md: 13, lg: 15 },
              fontWeight: 'bold',
            },
          }}
          options={modifiedList ?? []}
          getOptionLabel={(option) => (option.label ? option.label : '')}
          groupBy={(option) =>
            // eslint-disable-next-line no-nested-ternary
            option.group === 3 ? 'Favorites' : option.group === 2 ? 'Predefined' : 'NonFavorites'
          }
          renderOption={(props, option) => (
            <Grid
              id={`hotel-id-${option?.id}`}
              key={`${option?.id}-${option?.isPredefined}`}
              container
              justifyContent="space-between"
              direction="row"
            >
              <Grid item xs={11}>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                  {!option.isPredefined &&
                    groupsList?.some((group) => group.label === option?.label) && (
                      <IconButton>
                        <Tooltip title="Hotel Set">
                          <Apartment />
                        </Tooltip>
                      </IconButton>
                    )}
                  {option.isPredefined && (
                    <IconButton>
                      <Tooltip title="Hotel Set">
                        <Apartment />
                      </Tooltip>
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={1}>
                {!option.isPredefined && (
                  <IconButton
                    key={`${option?.id}-${option?.isPredefined}-icon`}
                    onClick={(e) => handleAddHotelToFavorites(e, option)}
                  >
                    <Tooltip
                      title={option.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                    >
                      {option.isFavorite ? (
                        <StarIcon sx={{ height: 16, width: 16 }} />
                      ) : (
                        <StarBorderIcon sx={{ height: 16, width: 16 }} />
                      )}
                    </Tooltip>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )}
          renderGroup={(params) => {
            const sortedGroups = ['Favorites', 'Predefined', 'NonFavorites'].map((groupName) => {
              if (params.group === groupName && params?.children?.length > 0) {
                return (
                  <Grid key={`Groups-${params.group}`}>
                    <Grid key="Favorites">
                      {groupName === 'Favorites' && params?.children?.length > 0 && (
                        <>
                          {params.children}
                          <br />
                          <Divider sx={{ bgcolor: '#61616173' }} variant="middle" />
                          <br />
                        </>
                      )}
                    </Grid>
                    <Grid key="Predefined">
                      {groupName === 'Predefined' && params?.children?.length > 0 && (
                        <>
                          <Typography paddingLeft={2} variant="subtitle2">
                            Pre-Defined Hotel Sets
                          </Typography>
                          {params.children}
                          <br />
                          <Divider sx={{ bgcolor: '#61616173' }} variant="middle" />
                          <br />
                        </>
                      )}
                    </Grid>
                    <Grid key="NonFavorites">
                      {groupName === 'NonFavorites' &&
                        params?.children?.length > 0 &&
                        params.children}
                    </Grid>
                  </Grid>
                );
              }
              return null;
            });

            // Filter out null values and render sorted groups
            return sortedGroups.filter((group) => group !== null);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, value) => {
            dispatch(dashboardActions.setHotelName(value));
          }}
          disableClearable
          value={selectedHotelName ?? []}
          renderInput={(params) => <TextField {...params} size="small" color="primary" />}
          componentsProps={{
            paper: {
              sx: {
                minWidth: 350,
                '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
              },
            },
          }}
        />
        <Grid item container justifyContent="flex-end" mr={8}>
          <Typography textAlign="right">Avl Rms: {noOfRooms ?? 0}</Typography>
        </Grid>
      </Grid>
      <Tooltip title="Add/Edit Hotel Set">
        <span>
          <IconButton
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
            sx={{
              '&.Mui-disabled': {
                background: 'transparent', // 'gray',
                //  color: 'white',
              },
              ml: 1,
            }}
          >
            <CustomEditIcon size="15px" />
          </IconButton>
        </span>
      </Tooltip>

      <AddHotelGroup
        open={open}
        onClose={() => setOpen(false)}
        hotelList={hotelList ?? []}
        selectedHotelGroupName={selectedHotelName}
        isEditable={isEditable}
      />
    </Grid>
  );
};
//
export default NavbarHotelDropdown;
