import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { withTheme } from '@emotion/react';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { selectEventCategoryConfigurations } from 'modules/event-configuration/selectors';
import MAJOR_CATEGORY_COLUMNS from 'modules/common/constants/major-event-categories-table';
/**
 * Table component to display major event categories
 * @param {*} isShow - function to set state to display side drawer view
 * @param {*} setSelectedEventCategoryId - function to set selected event category id
 */
const MajorEventsTableView = ({ isShow, setSelectedMajorEventId }) => {
  const eventCategories = useSelector(selectEventCategoryConfigurations);
  //
  const [filteredEventCategories, setFilteredEventCategories] = useState([]);
  // triggers when event categories state is changed
  useEffect(() => {
    // set formatted event categories
    const formattedEventCategories = eventCategories?.results?.map((eventCategory) => ({
      id: eventCategory?.id,
      name: eventCategory?.name,
    }));
    setFilteredEventCategories(formattedEventCategories);
  }, [eventCategories]);
  /**
   * set major event category when row changes
   * @param {*} ids  - selected row ids
   */
  const onSelectedRowChange = (ids) => {
    // check if selected rows are existed and set selected row id
    if (ids?.length) {
      setSelectedMajorEventId(ids[0]);
      isShow(true);
    }
  };
  //
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        columnVisibilityModel={{
          id: false,
        }}
        initialState={{
          pagination: {
            pageSize: 10,
          },
        }}
        rowsPerPageOptions={[5, 10, 20]}
        rowHeight={50}
        rows={filteredEventCategories || []}
        columns={MAJOR_CATEGORY_COLUMNS}
        onSelectionModelChange={onSelectedRowChange}
      />
    </Box>
  );
};
//
export default withTheme(MajorEventsTableView);
