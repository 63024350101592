/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
/**
 * Here mentioned hotel configuration feature add hotel validations
 */
export const addHotelValidation = yup.object().shape({
  roomTypeCode: yup.string().trim().required('Room Type Code is a required field'),
  roomType: yup.string().trim().required('Room Type is a required field'),
  roomCount: yup.number().required('Number of Rooms is a required field'),
});
