import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  UiController,
  breakdownList,
  commands,
  events,
  paceVariableNames,
} from 'modules/common/ui-controller';
import { KEYS, RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { getValuesFromList } from 'modules/common/utils/array';
import {
  getTimeFrameOption,
  setCompareWindowCommandValue,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { iPreferActions } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';

/**
 * Custom hook to execute trend i prefer filter command flow
 * @param {Boolean} customBooking
 * @param {Function} handleComparisonChange
 * @param {*} setCustomDatesRef
 * @param {Boolean} isLoaded
 * @param {String} dateComparison
 * @param {Array} chartBreakdownList
 * @param {Array} focusOnList
 * @param {Array} visualFilters
 * @param {Array} timeFrameList
 * @param {Function} handleToggleChange
 * @param {Array} metricSelectionList
 */
const useIPreferCommandFlow = (
  customBooking,
  handleComparisonChange,
  setCustomDatesRef,
  isLoaded,
  dateComparison,
  chartBreakdownList,
  focusOnList,
  visualFilters,
  timeFrameList,
  handleToggleChange,
  metricSelectionList
) => {
  const dispatch = useDispatch();
  // local states for command flow
  const [bookingDateValueCommand, setBookingDateValueCommand] = useState(null);
  const [breakDownValueOptions, setBreakDownValueOptions] = useState(null);
  const [focusOnCommandValue, setFocusOnCommandValue] = useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [customDatesValueCommand, setCustomDatesValueCommand] = useState(null);
  const [metricSelectionValueCommand, setMetricSelectionValueCommand] = useState(null);
  const [timeFrameValueCommand, setTimeFrameValueCommand] = useState(null);
  // execute setting compare window command value
  const setIPreferCompareWindowHandler = (value) => {
    // check if 'otb' value selected as compare window and custom booking is false
    setCompareWindowCommandValue(value, customBooking, handleComparisonChange);
    setCompareWindowValueCommand(null);
  };
  // Reference function to set cutom date range when custom option is selected in compare window
  setCustomDatesRef.current = () => {
    setTimeout(() => {
      const datesExtracted = customDatesValueCommand?.split('-');
      // reset custom dates
      dispatch(dashboardActions.setsIPreferCustomDate(datesExtracted[0]));
      // emit event to set custom date range
      UiController.setCustomDateRange(customDatesValueCommand);
      setCustomDatesValueCommand(null);
    }, 2000);
  };
  // Triggers when customDatesValueCommand, dateComparison, isLoaded values change
  useEffect(() => {
    if (customDatesValueCommand !== null && isLoaded) {
      // checks if date comparison value is 'custom'
      if (dateComparison && dateComparison === KEYS.CUSTOM) {
        setCustomDatesRef?.current();
      }
    }
  }, [customDatesValueCommand, dateComparison, isLoaded]);
  // Triggers when customBooking, customBookingCommandValue, isLoaded values change
  useEffect(() => {
    if (
      bookingDateValueCommand !== null &&
      customBooking &&
      bookingDateValueCommand !== commands.OTB &&
      isLoaded
    ) {
      // emit event to set booking date range
      UiController.setBookingDateRange(bookingDateValueCommand);
      setBookingDateValueCommand(null);
    }
  }, [customBooking, bookingDateValueCommand, isLoaded]);
  // Triggers when customBookingCommandValue, isLoaded values change
  useEffect(() => {
    if (bookingDateValueCommand !== null && isLoaded) {
      // checks if booking date range value is 'otb'
      if (bookingDateValueCommand === commands.OTB) {
        dispatch(iPreferActions.setIPreferOtb(false));
        dispatch(iPreferActions.setIPreferBookingDate(RESET_BOOKING_DATE));
        setBookingDateValueCommand(null);
      } else {
        // open custom booing date picker
        dispatch(iPreferActions.setIPreferOtb(true));
      }
    }
  }, [bookingDateValueCommand, isLoaded]);
  // Triggers when breakDownValueOptions, chartBreakdownList, isLoaded values change
  useEffect(() => {
    if (chartBreakdownList.length > 0 && breakDownValueOptions !== null && isLoaded) {
      // get breakdown option value
      const breakdownOption = breakdownList[breakDownValueOptions?.substring(0, 2)];
      // get isDetailed state from command
      const detailsStatus = parseInt(breakDownValueOptions?.substring(2, 4), 10);
      if (breakdownOption !== undefined) {
        const option = chartBreakdownList?.filter((val) => val?.label?.includes(breakdownOption));
        if (option?.length > 0) {
          dispatch(iPreferActions.setIPreferChartBreakdown(option[0]?.id));
        }
        // set isDetailed  state
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(detailsStatus)) {
          dispatch(iPreferActions.setIPreferIsDetailed(detailsStatus === 1));
          dispatch(iPreferActions.setIPreferFocusOn([]));
        }
      }
      setBreakDownValueOptions(null);
    }
  }, [breakDownValueOptions, chartBreakdownList?.length, isLoaded]);
  // Triggers when focusOnCommandValue, focusOnList, isLoaded values change
  useEffect(() => {
    if (focusOnList.length > 0 && focusOnCommandValue && isLoaded) {
      setTimeout(() => {
        // get selected focus on values from command value
        const optionsSelected = getValuesFromList(focusOnCommandValue, focusOnList);
        if (optionsSelected?.length > 0) {
          dispatch(iPreferActions.setIPreferFocusOn(optionsSelected));
        } else {
          dispatch(iPreferActions.setIPreferFocusOn([]));
        }
        setFocusOnCommandValue(null);
      }, 2000);
    }
  }, [focusOnCommandValue, focusOnList?.length, isLoaded]);
  // Triggers when alignmentCommandValue, visualFilters, isLoaded values change
  useEffect(() => {
    if (alignmentCommandValue !== null && visualFilters && isLoaded) {
      // set alignment toggle state command value
      handleToggleChange({
        target: {
          checked: alignmentCommandValue,
        },
      });
      setAlignmentCommandValue(null);
    }
  }, [alignmentCommandValue, visualFilters, isLoaded]);
  // Triggers when compareWindowValueCommand, customBooking, dateComparison, isLoaded values change
  useEffect(() => {
    if (dateComparison && compareWindowValueCommand && isLoaded) {
      setIPreferCompareWindowHandler(compareWindowValueCommand);
    }
  }, [compareWindowValueCommand, customBooking, dateComparison, isLoaded]);
  // Triggers when metricSelectionValueCommand, isLoaded and metricSelectionList values change
  useEffect(() => {
    if (metricSelectionValueCommand !== null && metricSelectionList?.length > 0 && isLoaded) {
      // get metric selection option form list
      const variable = paceVariableNames[metricSelectionValueCommand];
      if (variable !== undefined) {
        dispatch(iPreferActions.setIPreferMetricSelection(variable));
      }
      setMetricSelectionValueCommand(null);
    }
  }, [isLoaded, metricSelectionValueCommand, metricSelectionList?.length]);
  // Triggers when timeFrameValueCommand, isLoaded and timeFrameList values change
  useEffect(() => {
    if (timeFrameValueCommand !== null && timeFrameList?.length > 0 && isLoaded) {
      // get time frame value form timeFrame list
      const timeFrameOption = getTimeFrameOption(timeFrameList, timeFrameValueCommand);
      if (timeFrameOption) {
        dispatch(iPreferActions.setIPreferTimeFrame(timeFrameOption?.id));
      }
      setTimeFrameValueCommand(null);
    }
  }, [isLoaded, timeFrameValueCommand, timeFrameList?.length]);
  // Listener function to set time frame command value
  const setTimeFrameHandler = (value) => {
    setTimeFrameValueCommand(value);
  };
  // Listener function to set metric selection command value
  const setMetricSelectionHandler = (value) => {
    setMetricSelectionValueCommand(value);
  };
  // Listener function to set custom date range command value for custom compare window
  const setCustomDateRangeValue = (data) => {
    setCustomDatesValueCommand(data);
  };
  // Listener function to set compare window command value
  const setCompareWindowValue = (data) => {
    setCompareWindowValueCommand(data);
  };
  // Listener function to set booking date range command value
  const setIPreferCustomBookingHandler = (value) => {
    setBookingDateValueCommand(value);
  };
  // Listener function to set breakdown command value
  const setBreakDownValue = (data) => {
    setBreakDownValueOptions(data);
  };
  // Listener function to set focus on value
  const setFocusOnValue = (data) => {
    setFocusOnCommandValue(data);
  };
  // Listener function to set alignment value
  const setIPreferAlignmentToggleHandler = (value) => {
    setAlignmentCommandValue(value);
  };
  // Add event listener function to set time frame command value
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_TIME_FRAME, setTimeFrameHandler);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_TIME_FRAME, setTimeFrameHandler);
    };
  }, []);
  // Add event listener function to set metric selection command value
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_METRIC_SELECTION, setMetricSelectionHandler);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_METRIC_SELECTION, setMetricSelectionHandler);
    };
  }, []);
  // Add event listener function to set custom date range command value for custom compare window
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    };
  }, []);
  // Add event listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_COMPARE_WINDOW, setCompareWindowValue);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_COMPARE_WINDOW, setCompareWindowValue);
    };
  }, []);
  // Add event listener function to set alignment command value
  useEffect(() => {
    UiController.subscribe(events.IPREFER_ALIGNMENT, setIPreferAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.IPREFER_ALIGNMENT, setIPreferAlignmentToggleHandler);
    };
  }, []);
  // Add event listener function to set custom date range command value for i prefer tab
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_CUSTOM_BOOKING, setIPreferCustomBookingHandler);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_CUSTOM_BOOKING, setIPreferCustomBookingHandler);
    };
  }, []);
  // Add event listener function to set breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_BREAKDOWN, setBreakDownValue);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_BREAKDOWN, setBreakDownValue);
    };
  }, []);
  // Add event listener function to set focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_IPREFER_FOCUS_ON, setFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_IPREFER_FOCUS_ON, setFocusOnValue);
    };
  }, []);
};
//
export default useIPreferCommandFlow;
