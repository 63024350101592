import { signInActions } from 'modules/sign-in/slice';
/**
 * Here is define the custom middleware
 * @param {*} store
 * @returns
 */
const customMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case signInActions.signIn.type:
      next(action);
      break;
    default:
      next(action);
      break;
  }
};
//
export default customMiddleware;
