import styled from '@emotion/styled';
import { Badge, Popover as MuiPopover, List } from '@mui/material';

export const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;
//
export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;
//
export const ScrollList = styled(List)`
  height: 370px;
  overflow-y: auto;
  width: 470px
`;
