import { Global, css } from '@emotion/react';
/**
 * Application global style component is defined here
 * @param {*} props
 * @returns
 */
const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }
    `}
  />
);
//
export default GlobalStyle;
