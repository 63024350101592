/**
 * Here mentioned rate calendar feature request structure
 */
const API = {
  GET_EVENTS: {
    path: '/organizations/:organizationId/hotels/:hotelId/events?:query',
    method: 'GET',
  },
  GET_EVENT: {
    path: '/organizations/:organizationId/hotels/:hotelId/events/:id',
    method: 'GET',
  },
  GET_FORECAST_CONSTRAINT: {
    path: '/organizations/:organizationId/hotels/:hotelId/forecast-constraints?:query',
    method: 'GET',
  },
  GET_FORECAST_RATE: {
    path: '/organizations/:organizationId/hotels/:hotelId/forecast-rates?:query',
    method: 'GET',
  },
  GET_HOTEL: {
    path: '/organizations/:organizationId/hotels/:hotelId',
    method: 'GET',
  },
  GET_ROOM_CONSUMPTION_DATA: {
    path: '/organizations/:organizationId/hotels/:hotelId/room-consumption-data?:query',
    method: 'GET',
  },
  POST_EVENT: {
    path: '/organizations/:organizationId/hotels/:hotelId/events',
    method: 'POST',
  },
  PATCH_EVENT: {
    path: '/organizations/:organizationId/hotels/:hotelId/events/:id',
    method: 'PATCH',
  },
  PATCH_FORECAST_CONSTRAINT: {
    path: '/organizations/:organizationId/hotels/:hotelId/forecast-constraint/:id',
    method: 'PATCH',
  },
  PATCH_FORECAST_RATE: {
    path: '/organizations/:organizationId/hotels/:hotelId/forecast-rate/:id',
    method: 'PATCH',
  },
  DELETE_EVENT: {
    path: '/organizations/:organizationId/hotels/:hotelId/events/:id',
    method: 'DELETE',
  },
  GET_MINOR_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories/:majorEventCategoryId/minor-event-categories?:query',
    method: 'GET',
  },
  GET_MAJOR_EVENT_CATEGORIES: {
    path: '/organizations/:organizationId/major-event-categories?:query',
    method: 'GET',
  },
};
//
export default API;
