import { Grid, List } from '@mui/material';
import ROUTES from 'modules/common/constants/route';
import { ENVIRONMENT } from 'config';
import { ListItemButton, ListItemText, Wrapper } from './style';
/**
 * Footer common component that uses across modules as footer
 */
const Footer = () => {
  const openNewTab = (path) => {
    const newTab = window.open(path, '_blank');
    newTab.focus();
  };
  //
  const openPdfInNewTab = () => {
    const pdfUrl = `${ENVIRONMENT.USER_MANUAL_URL?.replace('LTS', 'PDF')}/user-manual.pdf`;
    window.open(pdfUrl, '_blank', 'noopener,noreferrer');
  };
  //
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton component="a" onClick={() => openNewTab(ROUTES.SUPPORT)}>
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton component="a" onClick={() => openNewTab(ROUTES.USER_MANUAL)}>
              <ListItemText primary="Help" />
            </ListItemButton>
            <ListItemButton component="a" onClick={() => openNewTab(ROUTES.FAQ)}>
              <ListItemText primary="FAQ" />
            </ListItemButton>
            <ListItemButton component="a" onClick={openPdfInNewTab}>
              <ListItemText primary="Help (PDF)" />
            </ListItemButton>
            {/* <ListItemButton component="a" href="#">
              <ListItemText primary="Privacy" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Terms of Service" />
            </ListItemButton> */}
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemText sx={{ mt: 3 }} primary={ENVIRONMENT.APP_VERSION} />
          </List>
          <List>
            <ListItemButton>
              <ListItemText primary={`© ${new Date().getFullYear()} ${ENVIRONMENT.APP_NAME}`} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
//
export default Footer;
