/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

/**
 * Here mentioned rate calendar feature validation in add event
 */
export const addEventValidation = yup.object().shape({
  date: yup.string().trim().required('Date is a required field'),
  majorCategory: yup.string().trim().required('Major Category is a required field'),
  minorCategory: yup.string().trim().required('Minor Category is a required field'),
  description: yup.string().trim().required('Description is a required field'),
});
