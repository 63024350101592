import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/dashboard/components/tab-container/summary-tab/slice';
//
const selectDomain = (state) => state[SLICE.SUMMARY] || initialState;

/**
 * Getting summary widget booking date state to summary widget component
 */
export const selectSummaryBookingDate = createSelector(
  [selectDomain],
  (state) => state.summaryBookingDate
);
/**
 * Getting chart breakdown state to summary widget component
 */
export const selectBreakdown = createSelector([selectDomain], (state) => state.chartBreakdown);

/**
 * Getting date comparison state to summary widget component
 */
export const selectDateComparison = createSelector([selectDomain], (state) => state.dateComparison);

/**
 * Getting summary report filter list state to summary widget component
 */
export const selectSummaryFilterList = createSelector(
  [selectDomain],
  (state) => state.summaryFilterList
);
/**
 * Getting alignment state to summary widget component
 */
export const selectAlignment = createSelector([selectDomain], (state) => state.alignment);
/**
 *  Getting summary widget alignment toggle state to summary widget component
 */
export const selectSummaryAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.summaryAlignmentToggle
);
/**
 * Getting summary otb state for summary widget component
 */
export const selectSummaryOtb = createSelector([selectDomain], (state) => state?.summaryOtb);

/**
 *Getting is summary custom date state for summary widget component
 */
export const selectSummaryCustomDate = createSelector(
  [selectDomain],
  (state) => state?.summaryCustomDate
);

/**
 *Getting is summary start date state for summary widget component for custom date comparison
 */
export const selectSummaryStartDate = createSelector(
  [selectDomain],
  (state) => state?.summaryStartDate
);

/**
 *Getting is summary end date state for summary widget component for custom date comparison
 */
export const selectSummaryEndDate = createSelector(
  [selectDomain],
  (state) => state?.summaryEndDate
);

/**
 *Getting is summary date selected state for summary widget component for custom date comparison
 */
export const selectSummaryDateSelected = createSelector(
  [selectDomain],
  (state) => state?.summaryDateSelected
);
/**
 * Getting summary widget visual toggle state to summary widget component
 */
export const selectSummaryVisualToggle = createSelector(
  [selectDomain],
  (state) => state.summaryVisualToggle
);
/**
 *  Getting generated summary tab url
 */
export const selectSummaryUrl = createSelector([selectDomain], (state) => state.summaryUrl);
/**
 * Getting symmetric comparison toggle state for summary widget component
 */
export const selectSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.symmetricComparisonToggle
);
/**
 *Getting is detailed in summary tab state for summary widget component
 */
export const selectIsDetailed = createSelector([selectDomain], (state) => state?.isDetailed);
/**
 * Getting pace visual filter list state to summary widget component
 */
export const selectPaceVisualFilterList = createSelector(
  [selectDomain],
  (state) => state.paceVisualFilterList
);
/**
 *  Getting pace visual display state to the component
 */
export const selectPaceVisualIsDisplay = createSelector(
  [selectDomain],
  (state) => state.paceVisualIsDisplay
);
/**
 *  Getting symmetric comparison visual display state to the component
 */
export const selectSymmetricComparisonIsDisplay = createSelector(
  [selectDomain],
  (state) => state.symmetricComparisonIsDisplay
);
/**
 *  Getting revenue visual set display state to the component
 */
export const selectRevenueSetIsDisplay = createSelector(
  [selectDomain],
  (state) => state.revenueSetIsDisplay
);
/**
 *  Getting cancel count visual set display state to the component
 */
export const selectCxlSetIsDisplay = createSelector(
  [selectDomain],
  (state) => state.cxlSetIsDisplay
);
/**
 *  Getting selected enable secondary hotel state to the component
 */
export const selectEnableSecondaryHotelGroups = createSelector(
  [selectDomain],
  (state) => state.enableSecondaryHotelGroups
);
/**
 *  Getting hotel view focus on state to dashboard component
 */
export const selectHotelFocusOn = createSelector([selectDomain], (state) => state.hotelFocusOn);
/**
 *Getting normalized view state for the component
 */
export const selectNormalizedView = createSelector(
  [selectDomain],
  (state) => state?.normalizedView
);
/**
 *Getting summary visual state for the component
 */
export const selectSummaryVisual = createSelector([selectDomain], (state) => state?.summaryVisuals);
/**
 *Getting summary hotel comparison visual state for the component
 */
export const selectSummaryHotelVisual = createSelector(
  [selectDomain],
  (state) => state?.summaryHotelVisuals
);
/**
 *Getting is summary hotel comparison state for the component
 */
export const selectSummaryHotelComparison = createSelector(
  [selectDomain],
  (state) => state?.isSummaryHotelComparison
);
