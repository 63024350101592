import { CssBaseline } from '@mui/material';
import GlobalStyle from 'modules/common/style/global';
import { Outlet } from 'react-router-dom';
import { Root } from './style';
/**
 * Parent layout for the entire auth components that wraps outlet with Auth wrapper
 * @returns
 */
const SignInLayout = () => (
  <Root>
    <CssBaseline />
    <GlobalStyle />
    <Outlet />
  </Root>
);
//
export default SignInLayout;
