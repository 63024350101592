/* eslint-disable import/no-cycle */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { selectRefreshToken } from 'modules/common/auth/selectors';
import { authActions } from 'modules/common/auth/slice';
import API from 'modules/common/auth/constants';
/**
 * Get the logged user details generator function
 * @param {*} param0
 */
export function* authFetchUserGenerator({ payload: { userId } }) {
  try {
    const response = yield call(request, API.POST_FETCH_USER, { userId }, true);
    yield put(authActions.fetchUserSucceeded(response));
  } catch (err) {
    yield put(authActions.fetchUserFailed('User request failed'));
  }
}
/**
 * Sign out logged user generator function
 */
export function* authSignOutUserGenerator() {
  try {
    const refreshToken = yield select(selectRefreshToken);
    const response = yield call(request, API.POST_SIGN_OUT, {
      refreshToken: refreshToken ?? '',
    });
    yield put(authActions.signOutSucceeded(response));
  } catch (err) {
    yield put(authActions.signOutFailed(err?.message));
  }
}
/**
 * Get new access token based on the refresh token generator function
 * @param {*} param0
 */
export function* getRefreshTokens({ payload }) {
  try {
    const response = yield call(request, API.POST_REFRESH_TOKENS, payload);
    yield put(authActions.refreshTokenSucceeded(response));
  } catch (err) {
    yield put(authActions.refreshTokenFailed(err?.message));
  }
}
/**
 * get organization details
 */
export function* getOrganizationGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ORGANIZATION, payload, true);
    yield put(authActions.getOrganizationSucceeded(response));
  } catch (err) {
    yield put(authActions.getOrganizationFailed('Organization request failed'));
  }
}
/**
 * get organization details
 */
export function* updateOrganizationGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_ORGANIZATION, payload);
    yield put(authActions.updateOrganizationSucceeded(response));
  } catch (error) {
    yield put(authActions.updateOrganizationFailed(error?.message));
  }
}
//
export function* authSaga() {
  yield takeLatest(authActions.fetchUser.type, authFetchUserGenerator);
  yield takeLatest(authActions.signOut.type, authSignOutUserGenerator);
  yield takeLatest(authActions.refreshToken.type, getRefreshTokens);
  yield takeLatest(authActions.getOrganization.type, getOrganizationGenerator);
  yield takeLatest(authActions.updateOrganization.type, updateOrganizationGenerator);
}
//
export default authSaga;
