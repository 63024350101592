import { useState, useEffect, useRef } from 'react';
import { Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import {
  selectVisualFilters,
  selectPmsSync,
  selectTrendTargetDate,
  selectTrendOtb,
  selectTrendBookingStyle,
  selectIsSignedIn,
  selectBreakdownDetails,
  selectHotelName,
  selectHotelDropdownList,
  selectEnableHotelGroups,
  selectHotelGroupDropdownList,
  selectMergedHotelList,
  selectsHotelForecast,
  selectPredefinedHotelGroups,
} from 'modules/dashboard/selectors';
import {
  selectIsCumulative,
  selectTrendTimeCustomDate,
  selectTrendTimeEndDate,
  selectTrendTimeStartDate,
  selectTrendTimeWeeklyView,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignmentToggle,
  selectTrendTimeDateComparison,
  selectTrendTimeAlignment,
  selectTrendHotelFocusOn,
  selectIsSegmentDetailed,
  selectSegmentSetAName,
  selectSegmentSetBName,
  selectSelectedFilterName,
  selectShowCustomGroupingFilterName,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectTrendHotelNormalizedView,
  selectEnableSecondaryHotelGroups,
  selectIsForecast,
  selectIsForecastDisabled,
  selectActiveTrendTab,
  selectSymmetricComparisonIsDisplay,
  selectIsPrimary,
  selectIsPercentageChange,
  selectIsPercentageChangeDisabled,
  selectIsTrendDeAggregate,
  selectIsTrendDeAggregateDisabled,
  selectTabularView,
  selectIsTrendTimeWeeklyViewDisabled,
  selectIsMovingAverage,
  selectMovingAverage,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import {
  getComparisonDateRange,
  getSelectedBreakdown,
  shortcut,
  timeout,
} from 'modules/dashboard/functions';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import {
  TAB_VALUE,
  SHORTCUT_KEY,
  OTB_VALUE,
  KEYS,
  TREND_STAY_DATE,
  RESET_BOOKING_DATE,
  TAGS,
  REPORT_TYPE,
  ALIGNED,
} from 'modules/dashboard/constants';
import ActionButton from 'modules/common/components/action-button';
import { CustomStack } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { ENVIRONMENT } from 'config';
import { isSaturday, isSunday, nextSaturday, previousSunday } from 'date-fns';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import PmsToggle from 'modules/dashboard/components/tab-container/inner-filter/components/pms-toggle';
import WeeklyCumulativeWindow from 'modules/dashboard/components/tab-container/inner-filter/components/weekly-cumulative-window';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import TrendTimeVsComparisonWindow from 'modules/dashboard/components/tab-container/inner-filter/components/trend-time-vs-compare-window';
import {
  getDateComparisonFilter,
  getFocusOnFilter,
  getMovingAverageFilter,
  getSubAlignmentFilter,
  getSubBreakdownFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import PAGINATION from 'modules/common/constants/pagination-limits';
import useTrendTimeCommandFlow from 'modules/dashboard/components/tab-container/trend-tabs/hooks/use-trend-time-command-flow';
import {
  BreakdownWindow,
  CompareHotelsWindow,
} from 'modules/dashboard/components/tab-container/inner-filter';
import {
  selectAuthUser,
  selectOrganization,
  selectOrganizationId,
} from 'modules/common/auth/selectors';
import useTrendSegmentCommandFlow from '../../hooks/use-trend-segment-command-flow';
import useTrendHotelCommandFlow from '../../hooks/use-trend-hotel-command-flow';
import MovingAverageWindow from '../../../inner-filter/components/moving-average-window';

/**
 * Filter panel implementation for Trend Widget
 * @param {Number} tabValue - Pace widget tab value
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Boolean} hotelTimezone - Timezone of selected hotel
 * @param {Number} toggleDisabled - PMS sync toggle disabled state
 * @param {Boolean} isLoaded - Visual is loaded state
 * @param {String} reportType - Report type of active tab
 * @param {Object} visual- Symmetric comparison visual object
 * @param {String} visualName - Display name for symmetric comparison visual
 * @returns
 */
const TestTrendFilter = ({
  reportType,
  visual,
  visualName,
  tabValue,
  toggleDisabled,
  latestDate,
  hotelTimezone,
  isLoaded,
}) => {
  const dispatch = useDispatch();
  const setCustomDatesRef = useRef(null);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const organization = useSelector(selectOrganization);
  const customBooking = useSelector(selectTrendBookingStyle);
  const vOTB = useSelector(selectTrendOtb);
  const alignment = useSelector(selectTrendTimeAlignment);
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const targetDate = useSelector(selectTrendTargetDate);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const isSignIn = useSelector(selectIsSignedIn);
  const isCumulative = useSelector(selectIsCumulative);
  const customDate = useSelector(selectTrendTimeCustomDate);
  const startDate = useSelector(selectTrendTimeStartDate);
  const endDate = useSelector(selectTrendTimeEndDate);
  const isTrendForecast = useSelector(selectIsForecast);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const breakdownData = useSelector(selectBreakdownDetails);
  const selectedFilterName = useSelector(selectSelectedFilterName);
  const showFilterName = useSelector(selectShowCustomGroupingFilterName);
  const setAName = useSelector(selectSegmentSetAName);
  const setBName = useSelector(selectSegmentSetBName);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const hotelList = useSelector(selectHotelDropdownList);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelGroupList = useSelector(selectHotelGroupDropdownList);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const isForecastDisabled = useSelector(selectIsForecastDisabled);
  const activeTab = useSelector(selectActiveTrendTab);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const isPrimary = useSelector(selectIsPrimary);
  const isTrendPercentageChange = useSelector(selectIsPercentageChange);
  const isTrendPercentageDisabled = useSelector(selectIsPercentageChangeDisabled);
  const isTrendDeAggregate = useSelector(selectIsTrendDeAggregate);
  const isTrendDeAggregateDisabled = useSelector(selectIsTrendDeAggregateDisabled);
  const isTabular = useSelector(selectTabularView);
  const isWeeklyViewDisabled = useSelector(selectIsTrendTimeWeeklyViewDisabled);
  const mergedHotelList = useSelector(selectMergedHotelList);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverage = useSelector(selectMovingAverage);
  const isHotelForecast = useSelector(selectsHotelForecast);
  const organizationId = useSelector(selectOrganizationId);
  const currentUser = useSelector(selectAuthUser);
  const predefinedHotelGroups = useSelector(selectPredefinedHotelGroups);
  //
  const [dateComparisonList, setDateComparisonList] = useState([]);
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = useState();
  const [movingAverageList, setMovingAverageList] = useState([]);
  const [open, setOpen] = useState(false);
  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(dashboardActions.setTrendOtb(value));
    dispatch(dashboardActions.setTrendBookingStyle(!customBooking));
    dispatch(dashboardActions.setTrendBookingDate(RESET_BOOKING_DATE));
  };
  // Reset button function to set filter panel values to default
  const clearSearch = async () => {
    dispatch(trendActions.setIsPrimary(false));
    dispatch(dashboardActions.setTrendOtb(0));
    dispatch(dashboardActions.setTrendBookingStyle(false));
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, false);
    dispatch(
      trendActions.setTrendTimeDateComparison(
        subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0]?.value
      )
    );

    dispatch(trendActions.setTrendTimeAlignmentToggle(subAlignmentFilter?.type === ALIGNED));
    dispatch(dashboardActions.setTrendBookingDate(RESET_BOOKING_DATE));
    dispatch(dashboardActions.setPmsSync(false));
    dispatch(trendActions.setTrendTimeWeeklyView(false));
    dispatch(trendActions.setTrendSymmetricComparisonToggle(true));
    await timeout(2000);
    dispatch(trendActions.setIsCumulative(false));
    dispatch(trendActions.setTrendTimeStartDate(TREND_STAY_DATE.startDate));
    dispatch(trendActions.setTrendTimeEndDate(TREND_STAY_DATE.endDate));
    dispatch(trendActions.setTrendTimeCustomDate(KEYS.START_DATE));
    dispatch(trendActions.setTrendTimeDateSelected(TREND_STAY_DATE.startDate));
    dispatch(trendActions.setTrendSegmentFocusOn([]));
    dispatch(trendActions.setSegmentGroup1([]));
    dispatch(trendActions.setSegmentGroup2([]));
    dispatch(
      trendActions.setTrendSegmentChartBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0].id
      )
    );
    const movingAverageFilter = visualFilters?.find(
      (filter) => filter?.tags?.includes(TAGS.MOVING_AVERAGE) && filter?.isDefault === true
    );
    dispatch(
      trendActions.setMovingAverage({
        id: movingAverageFilter?.id,
        label: movingAverageFilter?.values[0],
      })
    );
    dispatch(trendActions.setIsMovingAverage(false));
    dispatch(trendActions.setIsSegmentDetailed(false));
    dispatch(trendActions.setIsTrendForecast(false));
    dispatch(trendActions.setIsTrendPercentageChange(false));
    dispatch(trendActions.setTrendHotelNormalizedView(false));
    dispatch(
      trendActions.setIsForecastDisabled(
        (!organization?.enableForecastFilter || !isHotelForecast) ?? false
      )
    );
    dispatch(trendActions.setIsTrendDeAggregate(false));
    dispatch(trendActions.setSymmetricComparisonIsDisplay(false));
  };
  // set custom date comparison value
  const setArbitraryComparisonDate = () => {
    dispatch(trendActions.setTrendTimeDateSelected(TREND_STAY_DATE.startDate));
    dispatch(trendActions.setTrendTimeCustomDate(KEYS.START_DATE));
    const dateRange = getComparisonDateRange(
      TREND_STAY_DATE.startDate,
      targetDate.startDate,
      targetDate.endDate,
      KEYS.START_DATE
    );
    //
    dispatch(trendActions.setTrendTimeStartDate(dateRange?.comparisonStartDate.toISOString()));
    dispatch(trendActions.setTrendTimeEndDate(dateRange?.comparisonEndDate.toISOString()));
  };
  // Date comparison checkbox change handler
  const handleDateComparisonChange = (event) => {
    const { value } = event.target;
    dispatch(trendActions.setTrendTimeDateComparison(value));
    if (value === KEYS.CUSTOM) {
      setArbitraryComparisonDate();
    }
  };
  // Triggered when values of alignment, visualFilters, alignmentToggle change
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment && visualFilters) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      // Persist values on browser refresh
      const serializedState = JSON.parse(
        localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`)
      );
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        trendActions.setTrendTimeDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0]?.value
        )
      );
    }
  }, [alignment, visualFilters, alignmentToggle]);
  // Triggered when alignmentToggle, visualFilters values change
  useEffect(() => {
    // Set alignment list
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, alignmentToggle);
    dispatch(
      trendActions.setTrendTimeAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [alignmentToggle, visualFilters]);

  // Set alignment toggle on if weekly toggle is on
  useEffect(() => {
    if (weeklyViewToggle) dispatch(trendActions.setTrendTimeAlignmentToggle(true));
  }, [weeklyViewToggle]);

  // Triggered when targetDate, weeklyViewToggle value changes
  useEffect(() => {
    // Set stay dates based on weekly toggle
    if (weeklyViewToggle) {
      if (isSunday(new Date(targetDate.startDate))) {
        dispatch(
          dashboardActions.setWeeklyTrendStartDate(new Date(targetDate.startDate).toISOString())
        );
      } else {
        dispatch(
          dashboardActions.setWeeklyTrendStartDate(
            previousSunday(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isSaturday(new Date(targetDate.endDate))) {
        dispatch(
          dashboardActions.setWeeklyTrendEndDate(new Date(targetDate.endDate).toISOString())
        );
      } else {
        dispatch(
          dashboardActions.setWeeklyTrendEndDate(
            nextSaturday(new Date(targetDate.endDate)).toISOString()
          )
        );
      }
    }
  }, [targetDate, weeklyViewToggle]);
  // Triggered when visualFilters value changes
  useEffect(() => {
    // Set breakdown list
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
    const movingAvgList = getMovingAverageFilter(visualFilters);
    if (movingAvgList) {
      setMovingAverageList(movingAvgList);
    }
  }, [visualFilters]);

  // Triggered when the values of isDetailed, chartBreakdown or visualFilters change
  useEffect(() => {
    // Assign values to selected breakdown on chart breakdown or isDetails toggle change
    const breakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    setSelectedBreakdown(breakdown);
  }, [isDetailed, chartBreakdown, visualFilters]);

  useEffect(() => {
    // set breakdown focus on filter list
    if (chartBreakdown) {
      const focusOnFilter = getFocusOnFilter(breakdownData, selectedBreakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [chartBreakdown, visualFilters, breakdownData, selectedBreakdown]);

  useEffect(() => {
    // Assign forecast disabled state on breakdown focus on change, percentage toggle and active tab change
    if (activeTab === REPORT_TYPE.TREND_SEGMENT || focusOn?.length) {
      dispatch(trendActions.setIsForecastDisabled(true));
      dispatch(trendActions.setIsTrendForecast(false));
    } else if (activeTab === REPORT_TYPE.TREND_TIME && isTrendDeAggregate) {
      dispatch(trendActions.setIsForecastDisabled(true));
      dispatch(trendActions.setIsTrendForecast(false));
    } else if (isTrendPercentageChange) {
      dispatch(trendActions.setIsForecastDisabled(true));
      dispatch(trendActions.setIsTrendForecast(false));
    } else if (isMovingAverage) {
      dispatch(trendActions.setIsForecastDisabled(true));
      dispatch(trendActions.setIsTrendForecast(false));
    } else {
      dispatch(
        trendActions.setIsForecastDisabled(
          (!organization?.enableForecastFilter || !isHotelForecast) ?? false
        )
      );
    }
  }, [
    isDetailed,
    chartBreakdown,
    focusOn,
    activeTab,
    organization,
    isTrendDeAggregate,
    isTrendPercentageChange,
    isMovingAverage,
    isHotelForecast,
  ]);
  //
  useEffect(() => {
    // Assign percentage disabled state on cumulative view, weekly view normalized view
    if (
      isCumulative ||
      weeklyViewToggle ||
      normalizedViewToggle ||
      isTrendDeAggregate ||
      !isMovingAverage
    ) {
      dispatch(trendActions.setIsTrendPercentageChange(false));
      dispatch(trendActions.setIsTrendPercentageChangeDisabled(true));
    } else {
      dispatch(trendActions.setIsTrendPercentageChangeDisabled(false));
    }
  }, [
    isCumulative,
    weeklyViewToggle,
    normalizedViewToggle,
    isTrendPercentageChange,
    isTrendDeAggregate,
    isMovingAverage,
  ]);
  //
  useEffect(() => {
    // Assign percentage disabled state on cumulative view, weekly view normalized view
    if (activeTab !== REPORT_TYPE.TREND_TIME) {
      dispatch(trendActions.setIsTrendDeAggregate(false));
      dispatch(trendActions.setIsTrendDeAggregateDisabled(true));
    } else if (activeTab === REPORT_TYPE.TREND_TIME && isTrendPercentageChange) {
      dispatch(trendActions.setIsTrendDeAggregate(false));
      dispatch(trendActions.setIsTrendDeAggregateDisabled(true));
    } else {
      dispatch(trendActions.setIsTrendDeAggregateDisabled(false));
    }
  }, [activeTab, isTrendPercentageChange]);
  useEffect(() => {
    // Assign weekly view disabled state on cumulative view, active tab and tabular view
    if (isTabular && isCumulative) {
      dispatch(trendActions.setTrendTimeWeeklyView(false));
      dispatch(trendActions.setTrendTimeWeeklyViewDisabled(true));
    } else {
      dispatch(trendActions.setTrendTimeWeeklyViewDisabled(false));
    }
  }, [isTabular, isCumulative]);
  // Triggered when dateComparison value changes
  useEffect(() => {
    if (dateComparison) {
      if (!dateComparison.includes(KEYS.CUSTOM)) {
        setArbitraryComparisonDate();
      }
    }
  }, [dateComparison]);

  // Triggered when customBooking, dateComparison, visualFilters values change
  useEffect(() => {
    // Assign default value to date comparison when OTB is selected for booking window
    if (!customBooking && dateComparison === OTB_VALUE) {
      const subAlignmentFilter = getSubAlignmentFilter(visualFilters, true);
      dispatch(
        trendActions.setTrendTimeDateComparison(
          subAlignmentFilter?.values?.find((filter) => filter.isDefault === true)[0]?.value
        )
      );
    }
  }, [customBooking, dateComparison, visualFilters]);
  // Triggered when weeklyViewToggle and setIsCumulative
  useEffect(() => {
    if (isCumulative || weeklyViewToggle) {
      dispatch(trendActions.setIsMovingAverage(false));
    }
  }, [isCumulative, weeklyViewToggle]);
  // Shortcut function to weekly view toggle
  shortcut(tabValue, TAB_VALUE.TREND_TIME, SHORTCUT_KEY.WEEKLY_TOGGLE, () =>
    dispatch(trendActions.setTrendTimeWeeklyView(!weeklyViewToggle))
  );
  // Shortcut function to set pms sync value
  shortcut(tabValue, TAB_VALUE.TREND_TIME, SHORTCUT_KEY.PMS_TOGGLE, () =>
    dispatch(dashboardActions.setPmsSync(!pmsSyncToggle))
  );
  // Alignment toggle change handler
  const handleAlignmentToggleChange = (event) => {
    dispatch(trendActions.setTrendTimeAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    setDateComparisonList(dateComparisonFilter);
    dispatch(
      trendActions.setTrendTimeDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0]?.value
      )
    );
  };
  // Reset focus on custom aggregation values
  const resetOptions = () => {
    dispatch(trendActions.setTrendSegmentFocusOn([]));
    dispatch(trendActions.setSegmentGroup1([]));
    dispatch(trendActions.setSegmentGroup2([]));
  };
  // reset focus on option when hotel group option is selected
  useEffect(() => {
    resetOptions();
  }, [enableHotelGroups]);
  /**
   * set breakdown value
   * @param {*} event - onChanged event
   */
  const handleBreakdownChange = (event) => {
    dispatch(trendActions.setTrendSegmentChartBreakdown(event.target.value));
    dispatch(trendActions.setTrendSegmentFocusOn([]));
    dispatch(trendActions.setSegmentGroup1([]));
    dispatch(trendActions.setSegmentGroup2([]));
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };
  /**
   * set isDetailed value
   * @param {*} event - onChanged event
   */
  const handleIsDetailedChange = (event) => {
    dispatch(trendActions.setIsSegmentDetailed(event.target.checked));
    resetOptions();
  };
  // get custom aggregation groups list based on breakdown
  useEffect(() => {
    if (!organizationId || !currentUser || !chartBreakdown) return;
    const query = `limit=${PAGINATION.DROPDOWN_AGGREGATION_FILTER_PER_PAGE}&breakdown=${chartBreakdown}&page=0&filterName=&searchEnabled=true`;
    dispatch(trendActions.getAggregationFilter({ organizationId, userId: currentUser?.id, query }));
  }, [chartBreakdown]);
  // Trend time command flow implementation
  useTrendTimeCommandFlow(
    isLoaded,
    customBooking,
    visualFilters,
    handleAlignmentToggleChange,
    dateComparison,
    setArbitraryComparisonDate,
    setCustomDatesRef,
    isForecastDisabled,
    false,
    movingAverageList
  );
  //
  useTrendSegmentCommandFlow(
    isLoaded,
    customBooking,
    chartBreakdownList,
    resetOptions,
    focusOnList,
    isDetailed
  );
  //
  useTrendHotelCommandFlow(hotelList, [...hotelGroupList, ...predefinedHotelGroups], isLoaded);
  //
  return (
    <>
      <PmsToggle
        disabled={toggleDisabled}
        value={pmsSyncToggle}
        onChange={(event) => dispatch(dashboardActions.setPmsSync(event.target.checked))}
      />
      <br />
      <BookingWindow
        id="auto-trend-booking"
        vOTB={vOTB}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={0}
      />
      <br />
      <WeeklyCumulativeWindow
        isCumulative={isCumulative}
        weeklyViewToggle={weeklyViewToggle}
        handleIsCumulativeChange={(event) =>
          dispatch(trendActions.setIsCumulative(event.target.checked))
        }
        handleWeeklyViewChange={(event) =>
          dispatch(trendActions.setTrendTimeWeeklyView(event.target.checked))
        }
        isForecastToggle
        handleIsForecastChange={(event) =>
          dispatch(trendActions.setIsTrendForecast(event.target.checked))
        }
        isTrendForecast={isTrendForecast}
        isForecastDisabled={isForecastDisabled}
        isPercentageToggle
        isTrendPercentageChange={isTrendPercentageChange}
        isTrendPercentageDisabled={isTrendPercentageDisabled}
        handleIsPercentageToggleChange={(event) =>
          dispatch(trendActions.setIsTrendPercentageChange(event.target.checked))
        }
        isWeeklyViewDisabled={isWeeklyViewDisabled}
        tabName="trend"
      />
      <br />
      <MovingAverageWindow
        movingAverage={movingAverage}
        movingAverageList={movingAverageList ?? []}
        handleMovingAverageChange={(event, value) => dispatch(trendActions.setMovingAverage(value))}
        handleIsMovingAverageChange={(event) => {
          dispatch(trendActions.setIsMovingAverage(event.target.checked));
          if (event.target.checked) dispatch(trendActions.setIsTrendDeAggregate(false));
        }}
        isMovingAverage={isMovingAverage}
        isDisabled={isCumulative || weeklyViewToggle}
        id="auto-trend-moving-average"
      />
      <br />
      <BreakdownWindow
        id="auto-trend-breakdown"
        isDetailed={isDetailed}
        chartBreakdown={chartBreakdown}
        chartBreakdownList={chartBreakdownList}
        handleIsDetailedChange={handleIsDetailedChange}
        handleBreakdownChange={handleBreakdownChange}
        isFocusOnAvailable
        focusOnList={focusOnList}
        focusOn={focusOn}
        focusOnLabel={focusOn?.length ? 'Focused on' : 'Select Focus on'}
        handleFocusOnChange={(event, value) => {
          dispatch(trendActions.setTrendSegmentFocusOn(value));
        }}
        isGroupingAvailable
        onGroupClick={() => {
          setOpen(true);
          dispatch(trendActions.setShowCustomGroupingFilterName(false));
        }}
        setOpen={setOpen}
        openGroupingDialog={open}
        showFilterName={showFilterName}
        setAName={setAName}
        setBName={setBName}
        selectedFilterName={selectedFilterName}
        isDeAggregateToggle
        isTrendDeAggregate={isTrendDeAggregate}
        isTrendDeAggregateDisabled={isTrendDeAggregateDisabled || isMovingAverage}
        handleIsDeAggregateToggleChange={(event) =>
          dispatch(trendActions.setIsTrendDeAggregate(event.target.checked))
        }
      />
      <br />
      <CompareHotelsWindow
        selectedHotelName={selectHotelName}
        hotelList={hotelList ?? []}
        mergedHotelList={mergedHotelList ?? []}
        focusOn={hotelFocusOn}
        focusOnLabel={hotelFocusOn?.length ? 'Compared with' : 'Select Hotel'}
        handleCompareHotelsChange={(event, value) => {
          dispatch(trendActions.setTrendHotelFocusOn(value));
        }}
        normalizedViewToggle={normalizedViewToggle}
        handleTrendHotelNormalize={(event) =>
          dispatch(trendActions.setTrendHotelNormalizedView(event.target.checked))
        }
        enableSecondaryHotelGroups={enableSecondaryHotelGroups}
        hotelGroupList={hotelGroupList}
        isTrendPercentageChange={isTrendPercentageChange}
      />
      <br />
      <TrendTimeVsComparisonWindow
        customBooking={customBooking}
        alignmentToggle={alignmentToggle}
        handleToggleChange={handleAlignmentToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        visualName={visualName}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleDateComparisonChange}
        handleSymmetricComparisonChange={(event) =>
          dispatch(trendActions.setTrendSymmetricComparisonToggle(event.target.checked))
        }
        weeklyViewToggle={weeklyViewToggle}
        customDate={customDate}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        targetDate={targetDate}
        handleCustomDates={(event) =>
          dispatch(trendActions.setTrendTimeCustomDate(event.target.value))
        }
        isDisplay={symmetricComparisonIsDisplay}
        isDisplayOnClickAction={() =>
          dispatch(trendActions.setSymmetricComparisonIsDisplay(!symmetricComparisonIsDisplay))
        }
        activeTab={activeTab}
        isPrimary={isPrimary}
        handlePrimaryChange={(event) => dispatch(trendActions.setIsPrimary(event.target.checked))}
        id="auto-trend-time-comparison-window"
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default TestTrendFilter;
