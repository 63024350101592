import { sortAndDistinctByField } from '../../../../common/utils/array';
import evaluate from './evaluator';
import lexer from './lexer';
import parse from './parser';

/**
 * Handles data filtering based on a logical query expression.
 * @param {string} query - The logical query expression to filter the dataset.
 * @param {Array<Object>} dataset - The dataset to be filtered.
 * @returns {Array<Object>} - The filtered dataset with unique items, sorted by the 'label' property.
 *
 */
const dataHandler = (query, dataset) => {
  let filteredData = [];
  try {
    const tokens = lexer(query, dataset);
    const abstractSyntaxTree = parse(tokens);
    filteredData = evaluate(abstractSyntaxTree, dataset);
  } catch (error) {
    throw new Error(`Error: ${error.message}`);
  }

  // Remove duplicate items and sort by 'label'
  return sortAndDistinctByField(filteredData, 'name');
};
//
export default dataHandler;
