import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import {
  selectAdrIsDisplayPT,
  selectCxlCountIsDisplay,
  selectCxlIsDisplayPT,
  selectOccupiedRoomsIsDisplayPT,
  selectPaceCancellationFilterList,
  selectPaceCumulativeView,
  selectPaceLagAligned,
  selectPaceTimeAbsoluteCumulativeVisuals,
  selectPaceTimeLagAlignedCumulativeVisuals,
  selectPaceTimeLagAlignedVisuals,
  selectPaceTimeOccupiedRoomFilterList,
  selectPaceTimeRevenueFilterList,
  selectPaceTimeVisuals,
  selectRevenueIsDisplayPT,
} from '../../../../selectors';
import { paceActions } from '../../../../slice';
import { isEmpty } from '../../../../../../../../common/helpers/object';
/**
 * Pace widget report with out filters
 * @param {Boolean} setIsTimeLoaded - Visual loading state
 * @returns
 */
const PaceTimeGraphicalWidget = ({ setIsTimeLoaded }) => {
  const dispatch = useDispatch();
  //
  const tokenDetails = useSelector(selectEmbedToken);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const paceTimeRevenueFilterList = useSelector(selectPaceTimeRevenueFilterList);
  const occupiedRoomsFilterList = useSelector(selectPaceTimeOccupiedRoomFilterList);
  const paceLagAligned = useSelector(selectPaceLagAligned);
  const paceCumulative = useSelector(selectPaceCumulativeView);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayPT);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayPT);
  const adrIsDisplay = useSelector(selectAdrIsDisplayPT);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayPT);
  const cxlCountIsDisplay = useSelector(selectCxlCountIsDisplay);
  const cancellationFilterList = useSelector(selectPaceCancellationFilterList);
  //
  const paceTimeVisuals = useSelector(selectPaceTimeVisuals);
  const paceTimeLagAlignedVisuals = useSelector(selectPaceTimeLagAlignedVisuals);
  const paceTimeAbsoluteCumulativeVisuals = useSelector(selectPaceTimeAbsoluteCumulativeVisuals);
  const paceTimeLagAlignedCumulativeVisuals = useSelector(
    selectPaceTimeLagAlignedCumulativeVisuals
  );
  //
  const [isPace1VisualsLoaded, setIsPace1VisualLoaded] = useState(false);
  const [isPace2VisualsLoaded, setIsPace2VisualLoaded] = useState(false);
  const [isPace3VisualsLoaded, setIsPace3VisualLoaded] = useState(false);
  const [isPace4VisualsLoaded, setIsPace4VisualLoaded] = useState(false);
  const [isPace5VisualsLoaded, setIsPace5VisualLoaded] = useState(false);
  //
  const getVisual = (index) => {
    let found;
    if (paceCumulative && !paceLagAligned) {
      found = paceTimeAbsoluteCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceCumulative && paceLagAligned) {
      found = paceTimeLagAlignedCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && !paceCumulative) {
      found = paceTimeLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else {
      found = paceTimeVisuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = paceTimeRevenueFilterList
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(report) => setPBIReport(report)}
          setIsLoaded={(loading) => setIsLoaded(loading)}
        />
      )
    );
  };
  //
  const getVisualName = (order) => {
    const found = getVisual(order);
    return found?.name;
  };
  //
  useEffect(() => {
    // Calculate S based on the given conditions
    const pair1 = revenueIsDisplay ? revenueIsDisplay && isPace1VisualsLoaded : true;
    const pair2 = occupiedRoomsIsDisplay ? occupiedRoomsIsDisplay && isPace2VisualsLoaded : true;
    const pair3 = adrIsDisplay ? adrIsDisplay && isPace3VisualsLoaded : true;
    const pair4 = cxlIsDisplay ? cxlIsDisplay && isPace4VisualsLoaded : true;
    const pair5 = cxlCountIsDisplay ? cxlCountIsDisplay && isPace5VisualsLoaded : true;

    if (
      revenueIsDisplay &&
      isPace1VisualsLoaded &&
      occupiedRoomsIsDisplay &&
      isPace2VisualsLoaded &&
      adrIsDisplay &&
      isPace3VisualsLoaded &&
      cxlIsDisplay &&
      isPace4VisualsLoaded &&
      cxlCountIsDisplay &&
      isPace5VisualsLoaded
    ) {
      setIsTimeLoaded(true);
    } else {
      setIsTimeLoaded(pair1 || pair2 || pair3 || pair4 || pair5);
    }
  }, [
    revenueIsDisplay,
    isPace1VisualsLoaded,
    occupiedRoomsIsDisplay,
    isPace2VisualsLoaded,
    adrIsDisplay,
    isPace3VisualsLoaded,
    cxlIsDisplay,
    isPace4VisualsLoaded,
    cxlCountIsDisplay,
    isPace5VisualsLoaded,
  ]);
  //
  return (
    !isEmpty(paceTimeRevenueFilterList) && (
      <>
        <VisualGrid
          visualName={getVisualName(0)}
          isDisplay={revenueIsDisplay}
          visual={getWidget(0, styles.paceVisual, () => {}, setIsPace1VisualLoaded)}
          onClick={() => {
            dispatch(paceActions.setRevenueIsDisplayPT(!revenueIsDisplay));
          }}
        />
        <VisualGrid
          visualName={getVisualName(1)}
          isDisplay={occupiedRoomsIsDisplay}
          visual={getWidget(
            1,
            styles.paceVisual,
            () => {},
            setIsPace2VisualLoaded,
            occupiedRoomsFilterList
          )}
          onClick={() => {
            dispatch(paceActions.setOccupiedRoomIsDisplayPT(!occupiedRoomsIsDisplay));
          }}
        />
        <VisualGrid
          visualName={getVisualName(2)}
          isDisplay={adrIsDisplay}
          visual={getWidget(2, styles.paceVisual, () => {}, setIsPace3VisualLoaded)}
          onClick={() => {
            dispatch(paceActions.setAdrIsDisplayPT(!adrIsDisplay));
          }}
        />
        <VisualGrid
          visualName={getVisualName(3)}
          isDisplay={cxlIsDisplay}
          visual={getWidget(3, styles.paceVisual, () => {}, setIsPace4VisualLoaded)}
          onClick={() => {
            dispatch(paceActions.setCxlIsDisplayPT(!cxlIsDisplay));
          }}
        />
        <VisualGrid
          visualName={getVisualName(4)}
          isDisplay={cxlCountIsDisplay}
          visual={getWidget(
            4,
            styles.paceVisual,
            () => {},
            setIsPace5VisualLoaded,
            cancellationFilterList
          )}
          onClick={() => {
            dispatch(paceActions.setCxlCountIsDisplay(!cxlCountIsDisplay));
          }}
        />
      </>
    )
  );
};
//
export default PaceTimeGraphicalWidget;
