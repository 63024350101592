/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import { rateCalenderActions } from './slice';
import API from './constants';

/**
 * Get event details generator function to get event data
 * @param {*} param0
 */
export function* getEventDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_EVENTS, payload);
    yield put(rateCalenderActions.getEventDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getEventDetailsFailed(error?.message));
  }
}
/**
 * Get forecast constraints details generator function to get forecast constraints data
 * @param {*} param0
 */
export function* getForecastDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_FORECAST_CONSTRAINT, payload);
    yield put(rateCalenderActions.getForecastDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getForecastDetailsFailed(error?.message));
  }
}
/**
 * Get forecast rates details generator function to get forecast rates data
 * @param {*} param0
 */
export function* getRateDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_FORECAST_RATE, payload);
    yield put(rateCalenderActions.getRateDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getRateDetailsFailed(error?.message));
  }
}
/**
 * Get hotel details generator function to get hotel data
 * @param {*} param0
 */
export function* getHotelDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_HOTEL, payload);
    yield put(rateCalenderActions.getHotelDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getHotelDetailsFailed(error?.message));
  }
}
/**
 * Get room consumption details generator function to get room consumption data
 * @param {*} param0
 */
export function* getRoomConsumptionDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ROOM_CONSUMPTION_DATA, payload);
    yield put(rateCalenderActions.getRoomConsumptionDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getRoomConsumptionDetailsFailed(error?.message));
  }
}
/**
 * Add event details generator function to add event
 * @param {*} param0
 */
export function* addEventDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_EVENT, payload);
    yield put(rateCalenderActions.addEventDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.addEventDetailsFailed(error?.message));
  }
}
/**
 * Delete event details generator function to delete event
 * @param {*} param0
 */
export function* deleteEventDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_EVENT, payload);
    yield put(rateCalenderActions.deleteEventDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.deleteEventDetailsFailed(error?.message));
  }
}
/**
 * Update event details generator function to update event
 * @param {*} param0
 */
export function* updateEventDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_EVENT, payload);
    yield put(rateCalenderActions.updateEventDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.updateEventDetailsFailed(error?.message));
  }
}
/**
 * Get major event categories generator function to obtain registered major event categories
 * @param {*} param0
 */
export function* getMajorEventCategoriesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_MAJOR_EVENT_CATEGORIES, payload);
    yield put(rateCalenderActions.getMajorEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getMajorEventCategoriesFailed(error?.message));
  }
}
/**
 * Get minor event categories generator function to obtain registered minor event categories
 * @param {*} param0
 */
export function* getMinorEventCategoriesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_MINOR_EVENT_CATEGORIES, payload);
    yield put(rateCalenderActions.getMinorEventCategoriesSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getMinorEventCategoriesFailed(error?.message));
  }
}
/**
 * Get previous year room consumption details generator function to get previous year room consumption data
 * @param {*} param0
 */
export function* getPreviousYearRoomConsumptionDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ROOM_CONSUMPTION_DATA, payload);
    yield put(rateCalenderActions.getPreviousYearRoomConsumptionDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getPreviousYearRoomConsumptionDetailsFailed(error?.message));
  }
}
/**
 * Get previous year forecast rates details generator function to get previous year forecast rates data
 * @param {*} param0
 */
export function* getPreviousYearRateDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_FORECAST_RATE, payload);
    yield put(rateCalenderActions.getPreviousYearRateDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getPreviousYearRateDetailsFailed(error?.message));
  }
}
/**
 * Get previous year forecast constraints details generator function to get previous year forecast constraints data
 * @param {*} param0
 */
export function* getPreviousYearForecastDetailsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_FORECAST_CONSTRAINT, payload);
    yield put(rateCalenderActions.getPreviousYearForecastDetailsSucceeded(response));
  } catch (error) {
    yield put(rateCalenderActions.getPreviousYearForecastDetailsFailed(error?.message));
  }
}
//
export function* rateCalendarSaga() {
  yield takeLatest(rateCalenderActions.getEventDetails.type, getEventDetailsGenerator);
  yield takeLatest(rateCalenderActions.getForecastDetails.type, getForecastDetailsGenerator);
  yield takeLatest(rateCalenderActions.getRateDetails.type, getRateDetailsGenerator);
  yield takeLatest(rateCalenderActions.getHotelDetails.type, getHotelDetailsGenerator);
  yield takeLatest(rateCalenderActions.addEventDetails.type, addEventDetailsGenerator);
  yield takeLatest(rateCalenderActions.deleteEventDetails.type, deleteEventDetailsGenerator);
  yield takeLatest(rateCalenderActions.updateEventDetails.type, updateEventDetailsGenerator);
  yield takeLatest(
    rateCalenderActions.getRoomConsumptionDetails.type,
    getRoomConsumptionDetailsGenerator
  );
  yield takeLatest(
    rateCalenderActions.getMajorEventCategories.type,
    getMajorEventCategoriesGenerator
  );
  yield takeLatest(
    rateCalenderActions.getMinorEventCategories.type,
    getMinorEventCategoriesGenerator
  );
  yield takeLatest(
    rateCalenderActions.getPreviousYearRoomConsumptionDetails.type,
    getPreviousYearRoomConsumptionDetailsGenerator
  );
  yield takeLatest(
    rateCalenderActions.getPreviousYearRateDetails.type,
    getPreviousYearRateDetailsGenerator
  );
  yield takeLatest(
    rateCalenderActions.getPreviousYearForecastDetails.type,
    getPreviousYearForecastDetailsGenerator
  );
}
//
export default rateCalendarSaga;
