import { Alert as MuiAlert } from '@mui/material';
import { spacing } from '@mui/system';
import styled from '@emotion/styled';

export const Alert = styled(MuiAlert)(spacing);
//
export const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: block;
  min-height: 100%;
`;
//
export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #bdbdbd;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
