import EVENTS from 'modules/common/constants/events';
/**
 * Define all the event showing calender color schema
 */
const EVENT_COLORS = {
  [EVENTS.ANNUAL_SPORTS_EVENT]: '#B9273C',
  [EVENTS.WEATHER_FORCASTABLE]: '#F9EC2B',
  [EVENTS.WEATHER_NON_FORCASTABLE]: '#B43292',
  [EVENTS.US_HOLIDAY]: '#FCC71F',
  [EVENTS.US_LONG_WEEKEND]: '#8E61A6',
  [EVENTS.FESTIVAL]: '#FFA657',
  [EVENTS.ENTERTAINMENT]: '#323B96',
  [EVENTS.CITY_WIDE_CONFERENCE]: '#F0642F',
  [EVENTS.CONFERENCE_AND_SHOWS]: '#01AFC8',
  [EVENTS.HOTEL_GROUP_BOOKINGS]: '#EB2A2D',
  [EVENTS.OTHER]: '#6AB98C',
  [EVENTS.REGULAR_SPORTS_EVENT]: '#6FBB10',
};
//
export default EVENT_COLORS;
