import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/dashboard/components/tab-container/3d-pace-tab/slice';
//
const selectDomain = (state) => state[SLICE.PLOT_PACE] || initialState;
/**
 * Getting loader state to 3d pace component
 */
export const selectLoader = createSelector([selectDomain], (state) => state.loading);
/**
 *  Getting plotly data state to 3d pace component
 */
export const selectPlotData = createSelector([selectDomain], (state) => state.plotData?.results);
/**
 * Getting 3d plot map target date state for 3d pace component
 */
export const selectPlotTargetDate = createSelector([selectDomain], (state) => state.plotTargetDate);
/**
 * Getting plot pace pace variable state for 3d pace component
 */
export const selectPaceIsOccupied = createSelector([selectDomain], (state) => state?.isOccupied);
/**
 * Getting plot pace horizon state for 3d pace component
 */
export const selectPlotPaceHorizon = createSelector(
  [selectDomain],
  (state) => state?.plotPaceHorizon
);
/**
 *  Getting generated plot tab url
 */
export const selectPlotUrl = createSelector([selectDomain], (state) => state.plotUrl);
