/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';

const selectDomain = (state) => state[SLICE.VERIFY_CALLBACK] || initialState;
/**
 * Getting loader state to verify callback component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (verifyCallbackState) => verifyCallbackState.loading
);
/**
 * Getting email id state to the component
 */
export const selectEmailId = createSelector(
  [selectDomain],
  (verifyCallbackState) => verifyCallbackState.emailId
);
/**
 * Getting organization id state to the component
 */
export const selectUserInvite = createSelector(
  [selectDomain],
  (verifyCallbackState) => verifyCallbackState.userInvite
);
/**
 * Getting token state to the component
 */
export const selectUserInviteToken = createSelector(
  [selectDomain],
  (verifyCallbackState) => verifyCallbackState.token
);
