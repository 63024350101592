/* eslint-disable import/prefer-default-export */
import { Box } from '@mui/material';
import styled from '@emotion/styled';
/**
 * Event index style is defined here
 */
export const BoxIcon = styled(Box)`
  top: 214px;
  left: 476px;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  opacity: 1;
  border-radius: 50%;
`;
