import { differenceInCalendarDays } from 'date-fns';
import { KEYS, TAGS } from 'modules/dashboard/constants';
import { nameFilter } from 'modules/dashboard/functions';
import { GRANULARITY } from './constants';

/**
 * Find visual function used in trend tabs widget
 * @param {Array} array - Visual array
 * @param {string} index - Order of the visual
 * @param {Boolean} includeTag - Whether to include the tags or exclude them from final result
 * @param {Array} tags - Tags list
 * @returns
 */
export const findVisual = (array, index, includeTag, tags, percentage = false) =>
  array?.find((visual) => {
    if (!percentage && (index === 0 || index === 1 || index === 5)) {
      return !visual?.tags?.includes(...tags) && visual?.order === index;
    }
    return includeTag
      ? visual?.tags?.includes(...tags) && visual?.order === index
      : !visual?.tags?.includes(...tags) && visual?.order === index;
  });
/**
 * Determines the granularity based on the difference in calendar days between
 * the start and end dates of a target date range.
 * @param {Object} targetDate - The target date range object with 'startDate' and 'endDate'.
 * @param {Date} targetDate.startDate - The start date of the target range.
 * @param {Date} targetDate.endDate - The end date of the target range.
 * @returns {string|undefined} - The calculated granularity, or undefined if not matched.
 */

export const getGranularity = (targetDate) => {
  let granularity;
  const difference =
    differenceInCalendarDays(new Date(targetDate.endDate), new Date(targetDate.startDate)) + 1;
  //
  if (difference <= 60) {
    granularity = GRANULARITY.DAILY;
  } else if (difference < 365) {
    granularity = GRANULARITY.WEEKLY;
  } else if (difference <= 1460) {
    granularity = GRANULARITY.MONTHLY;
  } else {
    granularity = GRANULARITY.YEARLY;
  }
  return granularity;
};

/**
 * Generate power bi filter schema for breakdown and focus on filters
 * @param {Array} visualFilters - List of registered visual filters
 * @param {String} chartBreakdown  - selected breakdown values
 * @param {Boolean} isDetailed - state of detailed toggle
 * @param {Array} focusOn - selected focus on values
 * @returns
 */
export const getTSBreakdownAndFocusOnSchema = (
  visualFilters,
  chartBreakdown,
  isDetailed,
  focusOn,
  segmentGroup1,
  segmentGroup2
) => {
  const breakdownName = visualFilters?.filter((filter) => filter?.id?.includes(chartBreakdown))[0]
    ?.type;
  const breakdownVisual = visualFilters?.filter(
    (filter) => filter?.tags?.includes(TAGS.AGGREGATION) && filter?.type === breakdownName
  );
  let segmentFilter;
  let focusOnFilter;
  if (breakdownVisual?.length) {
    const type = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual[0]?.type,
    ]);
    const subType = nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.columnName, [
      breakdownVisual?.[0]?.values?.[0]?.value,
    ]);
    if (isDetailed) {
      if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
        segmentFilter = type;
      } else {
        segmentFilter = subType;
      }
    } else if (breakdownVisual?.[0]?.tags?.includes(TAGS.DETAILED)) {
      segmentFilter = subType;
    } else {
      segmentFilter = type;
    }
    const focusOnValues = focusOn?.map((item) => item?.label);
    if (focusOnValues.includes('\u200BTotal')) {
      focusOnValues.push('\u200BOverall');
    }
    if (isDetailed && (segmentGroup1?.length || segmentGroup2?.length)) {
      focusOnFilter = focusOnValues?.length
        ? nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.values[0]?.columnName, [
            ...focusOnValues,
            KEYS.SET_A,
            KEYS.SET_B,
          ])
        : nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.values[0]?.columnName, [
            KEYS.SET_A,
            KEYS.SET_B,
          ]);
    } else {
      focusOnFilter = focusOnValues?.length
        ? nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.values[0]?.columnName, [
            ...focusOnValues,
            KEYS.SET_A,
            KEYS.SET_B,
          ])
        : nameFilter(breakdownVisual[0]?.tableName, breakdownVisual[0]?.values[0]?.columnName, [
            'No values selected',
            KEYS.SET_A,
            KEYS.SET_B,
          ]);
    }
  }

  return [segmentFilter, focusOnFilter];
};
