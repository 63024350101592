import { DataGrid } from '@mui/x-data-grid';
import { withTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectUsers } from 'modules/users/selectors';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
// define table column visibility modal
const columnVisibilityModel = {
  id: false,
};
// define column configurations
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'userName',
    headerName: 'User Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'emailAddress',
    headerName: 'Email Address',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'role',
    headerName: 'Role',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
];
/**
 * User list handle by this component
 * @param {*} isShow - set show/hide state of side drawer
 * @param {*} setSelectedUserId - selected user id
 * @returns
 */
const UsersTableView = ({ isShow, setSelectedUserId }) => {
  const users = useSelector(selectUsers);
  //
  const [filteredUsers, setFilteredUsers] = useState([]);
  // set formatted user details
  useEffect(() => {
    const formattedUsers = users?.results?.map((user) => ({
      id: user?.id,
      emailAddress: user?.email,
      userName: user?.username,
      firstName: user?.firstName,
      lastName: user?.lastName,
      role: user?.roles[0]?.name,
    }));
    setFilteredUsers(formattedUsers);
  }, [users]);
  // set selected user base on row
  const onSelectedRowChange = (ids) => {
    if (ids?.length) {
      setSelectedUserId(ids[0]);
      isShow(true);
    }
  };
  //
  return (
    <div style={{ height: 500, flex: 1 }}>
      <DataGrid
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'white',
            color: TABLE_HEADER_FONT_COLOR,
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'white',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        rowHeight={50}
        rows={filteredUsers || []}
        columns={columns}
        onSelectionModelChange={onSelectedRowChange}
      />
    </div>
  );
};
//
export default withTheme(UsersTableView);
