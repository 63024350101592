import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import InfoIcon from '@mui/icons-material/Info';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { selectForecastDisabledText, selectsHotelForecast } from '../../../../../selectors';
/**
 * Booking pace window component common for the dashboard component
 * @param {*} paceHorizon - Pace horizon selected state
 * @param {Array} paceList - Pace values list
 * @param {*} paceVariable - Pace variable selected state
 * @param {Function} handlePaceHorizonChange - Function to set pace horizon value
 * @param {Function} handlePaceVariableChange - Function to set pace variable value
 * @param {Boolean} isPlot - check 3d plot tab
 * @param {Array} paceVariableList - Pace variable list
 * @param {Boolean} visualToggle - is Far Field visual shown
 * @param handleForecastFilter
 * @param enableForecastFilter
 * @returns
 */
const BookingPaceWindow = ({
  paceHorizon,
  paceList,
  paceVariable,
  handlePaceHorizonChange,
  handlePaceVariableChange,
  isPlot,
  paceVariableList = [],
  visualToggle,
  handleForecastFilterChange,
  enableForecastFilter,
  summaryForecastFilter,
  showFilter,
}) => {
  const isHotelForecast = useSelector(selectsHotelForecast);
  const ForecastDisabledText = useSelector(selectForecastDisabledText);
  //
  return (
    <CustomStack cssClassName={styles.innerFilter}>
      {isPlot && (
        <FormControl>
          <FormLabel id="breakdown-label">Booking Pace Parameter</FormLabel>
          <RadioGroup
            value={paceVariable}
            name="radio-buttons-group"
            onChange={handlePaceVariableChange}
          >
            <FormControlLabel
              sx={{
                my: -1,
              }}
              value={false}
              control={<Radio />}
              label="Revenue"
            />
            <FormControlLabel
              sx={{
                my: -1,
              }}
              value
              control={<Radio />}
              label="Occupied Rooms"
            />
          </RadioGroup>
        </FormControl>
      )}
      <FormControl>
        {showFilter && (
          <Grid sx={{ mb: 3 }}>
            <CustomToggleSwitch
              label="Forecasts"
              title="Enable/Disable the forecast filter"
              onChange={handleForecastFilterChange}
              checked={enableForecastFilter && summaryForecastFilter}
              disabled={!enableForecastFilter}
            />
            {!isHotelForecast && (
              <Typography sx={{ fontSize: 11.5, color: 'gray' }}>{ForecastDisabledText}</Typography>
            )}
          </Grid>
        )}

        <Grid container alignItems="center">
          <FormLabel>Booking Pace Horizon</FormLabel>
          <Tooltip
            color="primary"
            className={styles.infoIcon}
            title="Not settable when extended forecasts are displayed."
          >
            <InfoIcon />
          </Tooltip>
        </Grid>
        <RadioGroup value={paceHorizon ?? null} onChange={handlePaceHorizonChange}>
          {paceList
            ?.sort(
              (element1, element2) =>
                element1.label?.[0] &&
                element2.label?.[0] &&
                Number(element1?.label?.[0]?.split(' ')[0]) -
                  Number(element2?.label?.[0]?.split(' ')[0])
            )
            ?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio />}
                label={item?.label}
                sx={{
                  my: -1,
                }}
                disabled={!isPlot && visualToggle}
              />
            ))}
        </RadioGroup>
      </FormControl>
      {!isPlot && (
        <FormControl>
          <FormLabel sx={{ paddingBottom: 1 }}>Booking Pace Parameter</FormLabel>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={paceVariable}
            exclusive
            onChange={handlePaceVariableChange}
          >
            {paceVariableList?.map((item) => (
              <ToggleButton key={item?.id} value={item?.label}>
                {item?.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
      )}
    </CustomStack>
  );
};
//
export default BookingPaceWindow;
