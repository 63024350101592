import { differenceInSeconds } from 'date-fns';

// Function to store visual mapper data in localStorage
export const storeVisualMapper = () => {
  // update the localStorage timestamp value to calculate the time taken once the visual is rendered
  localStorage.setItem('visualTimeDifference', JSON.stringify(new Date().toISOString()))
};
// Function to retrieve visual load duration from localStorage
export const retrieveVisualLoadDuration = (currentData) => {
  const visualStartTime = JSON.parse(localStorage.getItem('visualTimeDifference'));
  const timedifferenceinMilisecs = differenceInSeconds(
    currentData,
    new Date(visualStartTime)
  );

  return `${timedifferenceinMilisecs} seconds`
};
