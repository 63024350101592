import { useEffect, useState } from 'react';
import { UiController, events, tabs } from 'modules/common/ui-controller';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { TAB_VALUE } from 'modules/dashboard/constants';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import { iPreferActions } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';
import { workbookActions } from '../components/tab-container/workbook-report/slice';
import { paceActions } from '../components/tab-container/pace-tabs/slice';
import { selectUrlOrder, selectUrlTab } from '../selectors';

/**
 * Custom hook to execute main command flow
 * @param {Number} tabValue
 * @param {Function} setTabValue
 * @param {Boolean} toggleDisabled
 */
const useMainCommandFlow = (tabValue, setTabValue, toggleDisabled) => {
  const dispatch = useDispatch();
  const urlOrder = useSelector(selectUrlOrder);
  const urlTab = useSelector(selectUrlTab);
  // Local states to set command flow states values
  const [pmsState, setPmsState] = useState(null);
  const [compareHotelCommandValue, setCompareHotelCommandValue] = useState(null);
  const [weeklyViewCommandValue, setWeeklyViewCommandValue] = useState(null);
  const [cumulativeViewCommandValue, setCumulativeViewCommandValue] = useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  const [symmetricComparisonCommandValue, setSymmetricComparisonCommandValue] = useState(null);
  const [compareWindowCommandValue, setCompareWindowCommandValue] = useState(null);
  const [paceHorizonCommandValue, setPaceHorizonCommandValue] = useState(null);
  const [paceVariableCommandValue, setPaceVariableCommandValue] = useState(null);
  const [bookingDateCommandValue, setBookingDateCommandValue] = useState(null);
  const [segmentCommandValue, setSegmentCommandValue] = useState(null);
  const [focusOnCommandValue, setFocusOnCommandValue] = useState(null);
  const [normalizedViewCommandValue, setNormalizedViewCommandValue] = useState(null);
  const [customDateRangeCommandValue, setCustomDateRangeCommandValue] = useState(null);
  const [customA, setCustomA] = useState(null);
  const [customB, setCustomB] = useState(null);
  const [tabularViewCommandValue, setTabularViewCommandValue] = useState(null);
  const [metricSelectionValueCommand, setMetricSelectionValueCommand] = useState(null);
  const [timeFrameValueCommand, setTimeFrameValueCommand] = useState(null);
  const [lagAlignedValueCommand, setLagAlignedValueCommand] = useState(null);
  const [bookingForecastValueCommand, setBookingForecastValueCommand] = useState(null);
  const [percentageValueCommand, setPercentageValueCommand] = useState(null);
  const [extendingForecastValueCommand, setExtendingForecastValueCommand] = useState(null);
  const [symmetricComparisonCollapseValueCommand, setSymmetricComparisonCollapseValueCommand] =
    useState(null);
  const [visualCollapseValueCommand, setVisualCollapseValueCommand] = useState(null);
  const [forecastValueCommand, setForecastValueCommand] = useState(null);
  const [secondaryHotelValueCommand, setSecondaryHotelValueCommand] = useState(null);
  const [activeTabValueCommand, setActiveTabValueCommand] = useState(null);
  const [breakdownToggleValueCommand, setBreakdownToggleValueCommand] = useState(null);
  const [percentageChangeToggleValueCommand, setPercentageChangeToggleValueCommand] =
    useState(null);
  const [segmentBreakdownToggleValueCommand, setSegmentBreakdownToggleValueCommand] =
    useState(null);
  const [utmBreakdownValueCommand, setUtmBreakdownValueCommand] = useState(null);
  const [utmFocusOnValueCommand, setUtmFocusOnValueCommand] = useState(null);
  const [bookingPaceWindowValueCommand, setBookingPaceWindowValueCommand] = useState(null);
  const [scaleValueCommand, setScaleValueCommand] = useState(null);
  const [stepsValueCommand, setStepsValueCommand] = useState(null);
  const [movingAverageValueCommand, setMovingAverageValueCommand] = useState(null);
  const [currencyValueCommand, setCurrencyValueCommand] = useState(null);
  const [dateTypeValueCommand, setDateTypeValueCommand] = useState(null);
  const [ratePlanValueCommand, setRatePlanValueCommand] = useState(null);
  // Listener function to dispatch function to set pms sync state
  const setPmsSyncHandler = (data) => {
    dispatch(dashboardActions.setPmsSync(data === 1));
    setPmsState(null);
    dispatch(dashboardActions.setUrlOrder(100));
    dispatch(dashboardActions.setUrlTab(null));
  };
  // Listener function to set weekly view command value - wkk
  const setWeeklyViewHandler = (data) => {
    setWeeklyViewCommandValue(data);
  };
  // Listener function to dispatch function to set tab value
  const setTabHandler = (data) => {
    setTabValue(tabs[data] ?? 0);
    dispatch(dashboardActions.setTabChange(true));
  };
  // Listener function to set alignment toggle command value - alm
  const setAlignmentToggleHandler = (data) => {
    setAlignmentCommandValue(data);
  };
  // Listener function to set symmetric comparison toggle command value - sym
  const setSymmetricComparisonToggleHandler = (data) => {
    setSymmetricComparisonCommandValue(data);
  };
  // Listener function to set booking date range command value - bok
  const setBookingDateTabHandler = (data) => {
    setBookingDateCommandValue(data);
  };
  // Listener function to set cumulative view command value - cum
  const setCumulativeViewHandler = (data) => {
    setCumulativeViewCommandValue(data);
  };
  // Listener function to set compare window command value - cmp
  const setCompareWindowHandler = (data) => {
    setCompareWindowCommandValue(data);
  };
  // Listener function to set pace horizon command value - bph
  const setPaceHorizonHandler = (data) => {
    setPaceHorizonCommandValue(data);
  };
  // Listener function to set pace command value - bpv
  const setPaceVariableHandler = (data) => {
    setPaceVariableCommandValue(data);
  };
  // Listener function to set breakdown command value - seg
  const setSegmentHandler = (data) => {
    setSegmentCommandValue(data);
  };
  // Listener function to set focus on dropdown command value - fcs
  const setFocusOnHandler = (data) => {
    setFocusOnCommandValue(data);
  };
  // Listener function to set normalized view command value - nor
  const setNormalizedViewHandler = (data) => {
    setNormalizedViewCommandValue(data);
  };
  // Listener function to set pms sync command value - pms
  const setPmsSyncValue = (data) => {
    setPmsState(data);
  };
  // Listener function to set custom date range command value
  const setCustomDatesHandler = (data) => {
    setCustomDateRangeCommandValue(data);
  };
  // Listener function to set setA command value - setA
  const setAValue = (value) => {
    setCustomA(value);
  };
  // Listener function to set setB command value - setB
  const setBValue = (value) => {
    setCustomB(value);
  };
  //  Listener function to set metric selection command value
  const setMetricSelectionHandler = (value) => {
    setMetricSelectionValueCommand(value);
  };
  //  Listener function to set time frame command value
  const setTimeFrameHandler = (value) => {
    setTimeFrameValueCommand(value);
  };
  //  Listener function to set lag aligned command value
  const setLagAlignedHandler = (value) => {
    setLagAlignedValueCommand(value);
  };
  //  Listener function to set booking forecast command value
  const setBookingForecastHandler = (value) => {
    setBookingForecastValueCommand(value);
  };
  //  Listener function to set percentage command value
  const setPercentageHandler = (value) => {
    setPercentageValueCommand(value);
  };
  //  Listener function to set extending forecast command value
  const setExtendingForecastHandler = (value) => {
    setExtendingForecastValueCommand(value);
  };
  //  Listener function to set symmetric comparison collapse command value
  const setSymmetricComparisonCollapseHandler = (value) => {
    setSymmetricComparisonCollapseValueCommand(value);
  };
  //  Listener function to set visual collapse command value
  const setVisualCollapseHandler = (value) => {
    setVisualCollapseValueCommand(value);
  };
  //  Listener function to set forecast command value
  const setForecastHandler = (value) => {
    setForecastValueCommand(value);
  };
  //  Listener function to set secondary hotel value
  const setSecondaryHotelHandler = (value) => {
    setSecondaryHotelValueCommand(value);
  };
  //  Listener function to set secondary hotel value
  const setActiveTabHandler = (value) => {
    setActiveTabValueCommand(value);
  };
  //  Listener function to set breakdown toggle value
  const setBreakdownToggleHandler = (value) => {
    setBreakdownToggleValueCommand(value);
  };
  //  Listener function to set percentage toggle value
  const setPercentageChangeToggleHandler = (value) => {
    setPercentageChangeToggleValueCommand(value);
  };
  //  Listener function to set segment breakdown toggle value
  const setSegmentBreakdownToggleHandler = (value) => {
    setSegmentBreakdownToggleValueCommand(value);
  };
  //  Listener function to set utm breakdown value
  const setUTMBreakdownHandler = (value) => {
    setUtmBreakdownValueCommand(value);
  };
  //  Listener function to set utm focus on value
  const setUTMFocusOnHandler = (value) => {
    setUtmFocusOnValueCommand(value);
  };
  // Listener function to set booking pace window value
  const setBookingPaceWindowHandler = (value) => {
    setBookingPaceWindowValueCommand(value);
  };
  //  Listener function to set steps value
  const setStepsHandler = (value) => {
    setStepsValueCommand(value);
  };
  // Listener function to set scale factor value
  const setScaleHandler = (value) => {
    setScaleValueCommand(value);
  };
  // Listener function to set scale factor value
  const setMovingAverageHandler = (value) => {
    setMovingAverageValueCommand(value);
  };
  // Listener function to set scale factor value
  const setCurrencyHandler = (value) => {
    setCurrencyValueCommand(value);
  };
  // Listener function to set scale factor value
  const setDateTypeHandler = (value) => {
    setDateTypeValueCommand(value);
  };
  // Listener function to set scale factor value
  const setRatePlanHandler = (value) => {
    setRatePlanValueCommand(value);
  };
  // Triggers when dateTypeValueCommand, tabValue values change
  useEffect(() => {
    if (dateTypeValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookDateType(dateTypeValueCommand);
          break;
        default:
          break;
      }
    }
  }, [dateTypeValueCommand, tabValue, urlTab]);
  // Triggers when ratePlanValueCommand, tabValue values change
  useEffect(() => {
    if (ratePlanValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookRatePlan(ratePlanValueCommand);
          break;
        default:
          break;
      }
    }
  }, [ratePlanValueCommand, tabValue, urlTab]);
  // Triggers when currencyValueCommand, tabValue values change
  useEffect(() => {
    if (currencyValueCommand !== null && tabValue === urlTab) {
      dispatch(dashboardActions.setLocalCurrency(String(currencyValueCommand).toUpperCase()));
      setCurrencyValueCommand(null);
    }
  }, [currencyValueCommand, tabValue, urlTab]);
  // Triggers when movingAverageValueCommand, tabValue values change
  useEffect(() => {
    if (movingAverageValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendMovingAverage(movingAverageValueCommand);
          break;
        default:
          break;
      }
      setMovingAverageValueCommand(null);
    }
  }, [movingAverageValueCommand, tabValue, urlTab]);
  // Triggers when stepsValueCommand, tabValue values change
  useEffect(() => {
    if (stepsValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickSteps(stepsValueCommand);
          break;
        default:
          break;
      }
      setStepsValueCommand(null);
    }
  }, [stepsValueCommand, tabValue, urlTab]);
  // Triggers when scaleValueCommand, tabValue values change
  useEffect(() => {
    if (scaleValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickScaleFactor(scaleValueCommand);
          break;
        default:
          break;
      }
      setScaleValueCommand(null);
    }
  }, [scaleValueCommand, tabValue, urlTab]);
  // Triggers when bookingPaceWindowValueCommand, tabValue values change
  useEffect(() => {
    if (bookingPaceWindowValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.PACE:
          UiController.setPaceBookingPaceWindow(bookingPaceWindowValueCommand);
          break;
        default:
          break;
      }
      setBookingPaceWindowValueCommand(null);
    }
  }, [bookingPaceWindowValueCommand, tabValue, urlTab]);
  // Triggers when utmBreakdownValueCommand, tabValue values change
  useEffect(() => {
    if (utmBreakdownValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebCLickUTMBreakdown(utmBreakdownValueCommand);
          break;
        default:
          break;
      }
      setUtmBreakdownValueCommand(null);
    }
  }, [utmBreakdownValueCommand, tabValue, urlTab]);
  // Triggers when utmFocusOnValueCommand, tabValue values change
  useEffect(() => {
    if (utmFocusOnValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebCLickUTMFocusOn(utmFocusOnValueCommand);
          break;
        default:
          break;
      }
      setUtmFocusOnValueCommand(null);
    }
  }, [utmFocusOnValueCommand, tabValue, urlTab]);
  // Triggers when segmentBreakdownToggleValueCommand, tabValue values change
  useEffect(() => {
    if (segmentBreakdownToggleValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendSegmentBreakdownToggle(segmentBreakdownToggleValueCommand);
          break;
        default:
          break;
      }
      setSegmentBreakdownToggleValueCommand(null);
    }
  }, [segmentBreakdownToggleValueCommand, tabValue, urlTab]);
  // Triggers when percentageChangeToggleValueCommand, tabValue values change
  useEffect(() => {
    if (percentageChangeToggleValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendPercentageChangeToggle(percentageChangeToggleValueCommand);
          break;
        default:
          break;
      }
      setPercentageChangeToggleValueCommand(null);
    }
  }, [percentageChangeToggleValueCommand, tabValue, urlTab]);
  // Triggers when breakdownToggleValueCommand, tabValue values change
  useEffect(() => {
    if (breakdownToggleValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendBreakdownToggle(breakdownToggleValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceBreakdownToggle(breakdownToggleValueCommand);
          break;
        default:
          break;
      }
      setBreakdownToggleValueCommand(null);
    }
  }, [breakdownToggleValueCommand, tabValue, urlTab]);
  // Triggers when activeTabValueCommand, tabValue values change
  useEffect(() => {
    if (activeTabValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendActiveTab(activeTabValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceActiveTab(activeTabValueCommand);
          break;
        default:
          break;
      }
      setActiveTabValueCommand(null);
    }
  }, [activeTabValueCommand, tabValue, urlTab]);
  // Triggers when secondaryHotelValueCommand, tabValue values change
  useEffect(() => {
    if (secondaryHotelValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryHotelGroups(secondaryHotelValueCommand);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookHotelGroups(secondaryHotelValueCommand);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendHotelGroups(secondaryHotelValueCommand);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickHotelGroups(secondaryHotelValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceHotelGroups(secondaryHotelValueCommand);
          break;
        default:
          break;
      }
      setSecondaryHotelValueCommand(null);
    }
  }, [secondaryHotelValueCommand, tabValue, urlTab]);
  // Triggers when extendingForecastValueCommand, tabValue values change
  useEffect(() => {
    if (forecastValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryForecastToggle(forecastValueCommand);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendForecastToggle(forecastValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceForecastToggle(forecastValueCommand);
          break;
        default:
          break;
      }
      setForecastValueCommand(null);
    }
  }, [forecastValueCommand, tabValue, urlTab]);
  // Triggers when extendingForecastValueCommand, tabValue values change
  useEffect(() => {
    if (symmetricComparisonCollapseValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummarySymmetricComparisonCollapse(
            symmetricComparisonCollapseValueCommand
          );
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookSymmetricComparisonCollapse(
            symmetricComparisonCollapseValueCommand
          );
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendSymmetricComparisonCollapse(symmetricComparisonCollapseValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceSymmetricComparisonCollapse(symmetricComparisonCollapseValueCommand);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickSymmetricComparisonCollapse(
            symmetricComparisonCollapseValueCommand
          );
          break;
        case TAB_VALUE.TREND_TABULAR:
          UiController.setTabularSymmetricComparisonCollapse(
            symmetricComparisonCollapseValueCommand
          );
          break;
        default:
          break;
      }
      setSymmetricComparisonCollapseValueCommand(null);
    }
  }, [symmetricComparisonCollapseValueCommand, tabValue, urlTab]);
  // Triggers when extendingForecastValueCommand, tabValue values change
  useEffect(() => {
    if (visualCollapseValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryVisualCollapse(visualCollapseValueCommand);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendVisualCollapse(visualCollapseValueCommand);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookVisualCollapse(visualCollapseValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceVisualCollapse(visualCollapseValueCommand);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickVisualCollapse(visualCollapseValueCommand);
          break;
        case TAB_VALUE.TREND_TABULAR:
          UiController.setTabularVisualCollapse(visualCollapseValueCommand);
          break;
        default:
          break;
      }
      setVisualCollapseValueCommand(null);
    }
  }, [visualCollapseValueCommand, tabValue, urlTab]);
  // Triggers when extendingForecastValueCommand, tabValue values change
  useEffect(() => {
    if (
      extendingForecastValueCommand !== null &&
      tabValue === TAB_VALUE.SUMMARY &&
      tabValue === urlTab
    ) {
      UiController.setSummaryExtendingForecast(extendingForecastValueCommand);
      setExtendingForecastValueCommand(null);
    }
  }, [extendingForecastValueCommand, tabValue, urlTab]);
  // Triggers when percentageValueCommand, tabValue values change
  useEffect(() => {
    if (
      percentageValueCommand !== null &&
      tabValue === TAB_VALUE.PACE_TIME_WIDGET &&
      tabValue === urlTab
    ) {
      UiController.setPaceTimePercentage(percentageValueCommand);
      setPercentageValueCommand(null);
    }
    if (percentageValueCommand !== null && tabValue === TAB_VALUE.PACE && tabValue === urlTab) {
      UiController.setPacePercentage(percentageValueCommand);
      setPercentageValueCommand(null);
    }
  }, [percentageValueCommand, tabValue, urlTab]);
  // Triggers when bookingForecastValueCommand, tabValue values change
  useEffect(() => {
    if (
      bookingForecastValueCommand !== null &&
      tabValue === TAB_VALUE.PACE_TIME_WIDGET &&
      tabValue === urlTab
    ) {
      UiController.setPaceTimeBookingForecast(bookingForecastValueCommand);
      setBookingForecastValueCommand(null);
    }
  }, [bookingForecastValueCommand, tabValue, urlTab]);
  // Triggers when lagAlignedValueCommand, tabValue values change
  useEffect(() => {
    if (lagAlignedValueCommand !== null && tabValue === urlTab) {
      switch (tabValue) {
        case TAB_VALUE.PACE_TIME_WIDGET:
          UiController.setPaceTimeLagAligned(lagAlignedValueCommand);
          break;
        case TAB_VALUE.PACE_SEGMENT_WIDGET:
          UiController.setPaceSegmentLagAligned(lagAlignedValueCommand);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceLagAligned(lagAlignedValueCommand);
          break;
        default:
          break;
      }
      setLagAlignedValueCommand(null);
    }
  }, [lagAlignedValueCommand, tabValue, urlTab]);
  // Triggers when timeFrameValueCommand, tabValue values change
  useEffect(() => {
    if (timeFrameValueCommand !== null && tabValue === TAB_VALUE.I_PREFER && tabValue === urlTab) {
      UiController.setIPreferTimeFrame(timeFrameValueCommand);
      setTimeFrameValueCommand(null);
    }
  }, [timeFrameValueCommand, tabValue, urlTab]);
  // Triggers when metricSelectionValueCommand, tabValue values change
  useEffect(() => {
    if (
      metricSelectionValueCommand !== null &&
      tabValue === TAB_VALUE.I_PREFER &&
      tabValue === urlTab
    ) {
      UiController.setIPreferMetricSelection(metricSelectionValueCommand);
      setTimeFrameValueCommand(null);
    }
  }, [metricSelectionValueCommand, tabValue, urlTab]);
  // Triggers when tab value ,command setA value changes
  useEffect(() => {
    if (customA !== null && tabValue === TAB_VALUE.TREND_TIME && tabValue === urlTab) {
      // emit event set A custom aggregation values
      setTimeout(() => {
        UiController.setCustomAggregationAValue(customA);
        setCustomA(null);
      }, 2000);
    }
  }, [customA, tabValue, urlTab]);
  // Triggers when tab value , command setB value changes
  useEffect(() => {
    if (customB !== null && tabValue === TAB_VALUE.TREND_TIME && tabValue === urlTab) {
      // emit event set B custom aggregation values
      setTimeout(() => {
        UiController.setCustomAggregationBValue(customB);
        setCustomB(null);
      }, 2000);
    }
  }, [customB, tabValue, urlTab]);
  // Triggers when tab value , normalized command value changes
  useEffect(() => {
    if (normalizedViewCommandValue !== null && tabValue === urlTab) {
      // emit event based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryNormalizedView(normalizedViewCommandValue);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookNormalizedView(normalizedViewCommandValue);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendHotelNormalizedView(normalizedViewCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickNormalizedView(normalizedViewCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceNormalizedView(normalizedViewCommandValue);
          break;
        default:
          break;
      }
      setNormalizedViewCommandValue(null);
    }
  }, [normalizedViewCommandValue, tabValue, urlTab]);
  // Triggers when tab value , focus on command value changes
  useEffect(() => {
    if (focusOnCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.REGION_MAP:
          UiController.setRegionalMapFocusOn(focusOnCommandValue);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendSegmentFocusOn(focusOnCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickFocusOn(focusOnCommandValue);
          break;
        case TAB_VALUE.I_PREFER:
          UiController.setIPreferFocusOn(focusOnCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceFocusOn(focusOnCommandValue);
          break;
        default:
          break;
      }
      setFocusOnCommandValue(null);
    }
  }, [focusOnCommandValue, tabValue, urlTab]);
  // Triggers when tab value , breakdown command value changes
  useEffect(() => {
    if (segmentCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryBreakdown(segmentCommandValue);
          break;
        case TAB_VALUE.REGION_MAP: {
          setTimeout(() => {
            UiController.setReginalMapBreakdown(segmentCommandValue);
          }, 2000);
          break;
        }
        case TAB_VALUE.TREND_TIME: {
          setTimeout(() => {
            UiController.setTrendSegmentBreakdown(segmentCommandValue);
          }, 2000);
          break;
        }
        case TAB_VALUE.I_PREFER: {
          setTimeout(() => {
            UiController.setIPreferBreakdown(segmentCommandValue);
          }, 2000);
          break;
        }
        case TAB_VALUE.PACE: {
          setTimeout(() => {
            UiController.setPaceBreakdown(segmentCommandValue);
          }, 2000);
          break;
        }
        default:
          break;
      }
      setSegmentCommandValue(null);
    }
  }, [segmentCommandValue, tabValue, urlTab]);
  // Triggers when tab value , pace variable command value changes
  useEffect(() => {
    if (paceVariableCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryPaceVariable(paceVariableCommandValue);
          break;
        case TAB_VALUE.PACE_TIME_WIDGET:
          UiController.setPaceTimePaceVariable(paceVariableCommandValue);
          break;
        case TAB_VALUE.PACE_3D:
          UiController.setPlotPaceVariable(paceVariableCommandValue);
          break;
        default:
          break;
      }
      setPaceVariableCommandValue(null);
    }
  }, [paceVariableCommandValue, tabValue, urlTab]);
  // Triggers when tab value , pace horizon command value changes
  useEffect(() => {
    if (paceHorizonCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryPaceHorizon(paceHorizonCommandValue);
          break;
        case TAB_VALUE.PACE_3D:
          UiController.setPlotPaceHorizon(paceHorizonCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickPaceHorizon(paceHorizonCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPacePaceHorizon(paceHorizonCommandValue);
          break;
        default:
          break;
      }
      setPaceHorizonCommandValue(null);
    }
  }, [paceHorizonCommandValue, tabValue, urlTab]);
  // Triggers when tab value , compare window command value changes
  useEffect(() => {
    if (compareWindowCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryCompareWIndow(compareWindowCommandValue);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookCompareWIndow(compareWindowCommandValue);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendTimeCompareWIndow(compareWindowCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickCompareWIndow(compareWindowCommandValue);
          break;
        case TAB_VALUE.I_PREFER:
          UiController.setIPreferCompareWIndow(compareWindowCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceCompareWIndow(compareWindowCommandValue);
          break;
        default:
          break;
      }
      setCompareWindowCommandValue(null);
    }
  }, [compareWindowCommandValue, tabValue, urlTab]);
  // Triggers when tab value , custom date range command value changes
  useEffect(() => {
    if (customDateRangeCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryCustomDateRange(customDateRangeCommandValue);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookCustomDateRange(customDateRangeCommandValue);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendTimeCustomDateRange(customDateRangeCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickCustomDateRange(customDateRangeCommandValue);
          break;
        case TAB_VALUE.I_PREFER:
          UiController.setIPreferCustomDateRange(customDateRangeCommandValue);
          break;
        default:
          break;
      }
      setCustomDateRangeCommandValue(null);
    }
  }, [customDateRangeCommandValue, tabValue, urlTab]);
  // Triggers when tab value , cumulative view command value changes
  useEffect(() => {
    if (cumulativeViewCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME: {
          if (urlOrder === 2) {
            dispatch(trendActions.setIsCumulative(cumulativeViewCommandValue === 1));
            dispatch(dashboardActions.setUrlOrder(3));
            setCumulativeViewCommandValue(null);
          }
          break;
        }
        case TAB_VALUE.WEB_CLICK:
          dispatch(webClickActions.setWebClickCumulativeView(cumulativeViewCommandValue === 1));
          setCumulativeViewCommandValue(null);
          break;
        case TAB_VALUE.PACE:
          if (urlOrder === 2) {
            dispatch(paceActions.setPaceCumulativeView(cumulativeViewCommandValue === 1));
            dispatch(dashboardActions.setUrlOrder(3));
            setCumulativeViewCommandValue(null);
          }
          break;
        default:
          break;
      }
    }
  }, [cumulativeViewCommandValue, tabValue, urlOrder, urlTab]);
  // Triggers when tab value , booking date range command value changes
  useEffect(() => {
    if (bookingDateCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryCustomBooking(bookingDateCommandValue);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookCustomBooking(bookingDateCommandValue);
          break;
        case TAB_VALUE.REGION_MAP:
          UiController.setRelationalMapCustomBooking(bookingDateCommandValue);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendCustomBooking(bookingDateCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickCustomBooking(bookingDateCommandValue);
          break;
        case TAB_VALUE.I_PREFER:
          UiController.setWebIPreferCustomBooking(bookingDateCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceCustomBooking(bookingDateCommandValue);
          break;
        default:
          break;
      }
      setBookingDateCommandValue(null);
    }
  }, [bookingDateCommandValue, tabValue, urlTab]);
  // Triggers when tab value , symmetric comparison command value changes
  useEffect(() => {
    if (symmetricComparisonCommandValue !== null && tabValue === urlTab) {
      // execute dispatch functions based on active tab
      if (tabValue === TAB_VALUE.SUMMARY && urlOrder === 6) {
        dispatch(
          summaryActions.setSymmetricComparisonToggle(symmetricComparisonCommandValue === 1)
        );
        setSymmetricComparisonCommandValue(null);
        dispatch(dashboardActions.setUrlOrder(7));
      }
      if (tabValue === TAB_VALUE.TREND_TIME && urlOrder === 7) {
        dispatch(
          trendActions.setTrendSymmetricComparisonToggle(symmetricComparisonCommandValue === 1)
        );
        dispatch(dashboardActions.setUrlOrder(8));
        setSymmetricComparisonCommandValue(null);
      }
      if (tabValue === TAB_VALUE.WEB_CLICK) {
        dispatch(
          webClickActions.setWebClickSymmetricComparisonToggle(
            symmetricComparisonCommandValue === 1
          )
        );
        setSymmetricComparisonCommandValue(null);
      }
      if (tabValue === TAB_VALUE.I_PREFER) {
        dispatch(
          iPreferActions.setIPreferSymmetricComparisonToggle(symmetricComparisonCommandValue === 1)
        );
        setSymmetricComparisonCommandValue(null);
      }
      if (tabValue === TAB_VALUE.WORKBOOK_REPORT && urlOrder === 5) {
        dispatch(
          workbookActions.setSymmetricComparisonToggle(symmetricComparisonCommandValue === 1)
        );
        setSymmetricComparisonCommandValue(null);
        dispatch(dashboardActions.setUrlOrder(6));
      }
      if (tabValue === TAB_VALUE.PACE && urlOrder === 9) {
        dispatch(
          paceActions.setPaceSymmetricComparisonToggle(symmetricComparisonCommandValue === 1)
        );
        setSymmetricComparisonCommandValue(null);
        dispatch(dashboardActions.setUrlOrder(10));
      }
    }
  }, [symmetricComparisonCommandValue, tabValue, urlOrder, urlTab]);
  // Triggers when tab value , alignment  command value changes
  useEffect(() => {
    if (alignmentCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      if (tabValue === TAB_VALUE.SUMMARY) {
        UiController.setSummaryAlignment(alignmentCommandValue === 1);
      }
      if (tabValue === TAB_VALUE.WORKBOOK_REPORT) {
        UiController.setWorkbookAlignment(alignmentCommandValue === 1);
      }
      if (tabValue === TAB_VALUE.TREND_TIME) {
        UiController.setTrendTimeAlignment(alignmentCommandValue === 1);
      }
      if (tabValue === TAB_VALUE.WEB_CLICK) {
        UiController.setWebClickAlignment(alignmentCommandValue === 1);
      }
      if (tabValue === TAB_VALUE.I_PREFER) {
        UiController.setIPreferAlignment(alignmentCommandValue === 1);
      }
      if (tabValue === TAB_VALUE.PACE) {
        UiController.setPaceAlignment(alignmentCommandValue === 1);
      }
      setAlignmentCommandValue(null);
    }
  }, [alignmentCommandValue, tabValue, urlTab]);
  // Triggers when tab value , weekly view command value changes
  useEffect(() => {
    if (weeklyViewCommandValue !== null && tabValue === urlTab) {
      // execute dispatch functions based on active tab
      if (tabValue === TAB_VALUE.TREND_TIME && urlOrder === 12) {
        dispatch(trendActions.setTrendTimeWeeklyView(weeklyViewCommandValue === 1));
        dispatch(dashboardActions.setUrlOrder(13));
        setWeeklyViewCommandValue(null);
      }
      if (tabValue === TAB_VALUE.WEB_CLICK) {
        dispatch(webClickActions.setWebClickWeeklyView(weeklyViewCommandValue === 1));
        setWeeklyViewCommandValue(null);
      }
    }
  }, [weeklyViewCommandValue, tabValue, urlOrder, urlTab]);
  // Triggers when tab value , tabular view command value changes
  useEffect(() => {
    if (tabularViewCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendTimeTabularView(tabularViewCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceTabularView(tabularViewCommandValue);
          break;
        case TAB_VALUE.REGION_MAP:
          UiController.setRegionalMapTabularView(tabularViewCommandValue);
          break;
        default:
          break;
      }
      setTabularViewCommandValue(null);
    }
  }, [tabularViewCommandValue, tabValue, urlTab]);
  // Triggers when tab value , compare hotels command value changes
  useEffect(() => {
    if (compareHotelCommandValue !== null && tabValue === urlTab) {
      // emit events based on active tab
      switch (tabValue) {
        case TAB_VALUE.SUMMARY:
          UiController.setSummaryHotelCompareHotels(compareHotelCommandValue);
          break;
        case TAB_VALUE.WORKBOOK_REPORT:
          UiController.setWorkbookHotelCompareHotels(compareHotelCommandValue);
          break;
        case TAB_VALUE.TREND_TIME:
          UiController.setTrendHotelCompareHotels(compareHotelCommandValue);
          break;
        case TAB_VALUE.WEB_CLICK:
          UiController.setWebClickCompareHotels(compareHotelCommandValue);
          break;
        case TAB_VALUE.PACE:
          UiController.setPaceCompareHotels(compareHotelCommandValue);
          break;
        default:
          break;
      }
      setCompareHotelCommandValue(null);
    }
  }, [compareHotelCommandValue, tabValue, urlTab]);
  // // Listener function to set compare hotels command value
  const setCompareHotelsHandler = (data) => {
    setCompareHotelCommandValue(data);
  };
  // // Listener function to set tabular view command value
  const setTabularViewHandler = (data) => {
    setTabularViewCommandValue(data);
  };
  // Triggers when toggleDisabled and pmsState changes
  useEffect(() => {
    if (pmsState !== null && urlOrder === 18) {
      if (!toggleDisabled) {
        setPmsSyncHandler(pmsState);
      } else {
        dispatch(dashboardActions.setUrlOrder(100));
        dispatch(dashboardActions.setUrlTab(null));
      }
    }
  }, [toggleDisabled, pmsState, urlOrder]);

  /**
   * Triggers in first component rendering
   * This is where add listener functions to all the ui controller commands to set values and emit listener functions in particular tab.
   * As for event driven flow, this is the first step, adding event listener for events
   * Then we emit these events command values
   * commands values are provided as parameters to listener functions
   */
  useEffect(() => {
    UiController.subscribe(events.SET_PMS_SYNC, setPmsSyncValue);
    UiController.subscribe(events.WEEKLY_VIEW, setWeeklyViewHandler);
    UiController.subscribe(events.ALIGNMENT, setAlignmentToggleHandler);
    UiController.subscribe(events.SYMMETRIC_COMPARISON, setSymmetricComparisonToggleHandler);
    UiController.subscribe(events.SET_TAB, setTabHandler);
    UiController.subscribe(events.SET_BOOKING_DATE, setBookingDateTabHandler);
    UiController.subscribe(events.SET_CUMULATIVE_VIEW, setCumulativeViewHandler);
    UiController.subscribe(events.SET_COMPARE_WINDOW, setCompareWindowHandler);
    UiController.subscribe(events.SET_PACE_HORIZON, setPaceHorizonHandler);
    UiController.subscribe(events.SET_PACE_VARIABLE, setPaceVariableHandler);
    UiController.subscribe(events.SET_BREAKDOWN, setSegmentHandler);
    UiController.subscribe(events.SET_FOCUS_ON, setFocusOnHandler);
    UiController.subscribe(events.SET_STEPS, setStepsHandler);
    UiController.subscribe(events.SET_SCALE, setScaleHandler);
    UiController.subscribe(events.SET_COMPARE_HOTELS, setCompareHotelsHandler);
    UiController.subscribe(events.SET_NORMALIZED_VIEW, setNormalizedViewHandler);
    UiController.subscribe(events.SET_CUSTOM_DATE_RANGE_VALUE, setCustomDatesHandler);
    UiController.subscribe(events.SET_CUSTOM_AGGREGATION_SET_A, setAValue);
    UiController.subscribe(events.SET_CUSTOM_AGGREGATION_SET_B, setBValue);
    UiController.subscribe(events.SET_TABULAR_VIEW, setTabularViewHandler);
    UiController.subscribe(events.SET_METRIC_SELECTION, setMetricSelectionHandler);
    UiController.subscribe(events.SET_TIME_FRAME, setTimeFrameHandler);
    UiController.subscribe(events.SET_LAG_ALIGNED, setLagAlignedHandler);
    UiController.subscribe(events.SET_BOOKING_FORECAST, setBookingForecastHandler);
    UiController.subscribe(events.SET_PERCENTAGE, setPercentageHandler);
    UiController.subscribe(events.SET_EXTENDING_FORECAST, setExtendingForecastHandler);
    UiController.subscribe(
      events.SET_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseHandler
    );
    UiController.subscribe(events.SET_VISUAL_COLLAPSE, setVisualCollapseHandler);
    UiController.subscribe(events.SET_FORECAST, setForecastHandler);
    UiController.subscribe(events.SET_SECONDARY_HOTEL_GROUP, setSecondaryHotelHandler);
    UiController.subscribe(events.SET_ACTIVE_TAB, setActiveTabHandler);
    UiController.subscribe(events.SET_BREAKDOWN_TOGGLE, setBreakdownToggleHandler);
    UiController.subscribe(events.SET_PERCENTAGE_TOGGLE, setPercentageChangeToggleHandler);
    UiController.subscribe(events.SET_SEGMENT_BREAKDOWN_TOGGLE, setSegmentBreakdownToggleHandler);
    UiController.subscribe(events.SET_UTM_BREAKDOWN, setUTMBreakdownHandler);
    UiController.subscribe(events.SET_UTM_FOCUS_ON, setUTMFocusOnHandler);
    UiController.subscribe(events.SET_BOOKING_PACE_WINDOW, setBookingPaceWindowHandler);
    UiController.subscribe(events.SET_MOVING_AVERAGE, setMovingAverageHandler);
    UiController.subscribe(events.SET_CURRENCY, setCurrencyHandler);
    UiController.subscribe(events.SET_DATE_TYPE, setDateTypeHandler);
    UiController.subscribe(events.SET_RATE_PLAN, setRatePlanHandler);
    return () => {
      UiController.unsubscribe(events.WEEKLY_VIEW, setWeeklyViewHandler);
      UiController.unsubscribe(events.ALIGNMENT, setAlignmentToggleHandler);
      UiController.unsubscribe(events.SYMMETRIC_COMPARISON, setSymmetricComparisonToggleHandler);
      UiController.unsubscribe(events.SET_TAB, setTabHandler);
      UiController.unsubscribe(events.SET_BOOKING_DATE, setBookingDateTabHandler);
      UiController.unsubscribe(events.SET_CUMULATIVE_VIEW, setCumulativeViewHandler);
      UiController.unsubscribe(events.SET_COMPARE_WINDOW, setCompareWindowHandler);
      UiController.unsubscribe(events.SET_PACE_HORIZON, setPaceHorizonHandler);
      UiController.unsubscribe(events.SET_PACE_VARIABLE, setPaceVariableHandler);
      UiController.unsubscribe(events.SET_BREAKDOWN, setSegmentHandler);
      UiController.unsubscribe(events.SET_FOCUS_ON, setFocusOnHandler);
      UiController.unsubscribe(events.SET_STEPS, setStepsHandler);
      UiController.unsubscribe(events.SET_SCALE, setScaleHandler);
      UiController.unsubscribe(events.SET_COMPARE_HOTELS, setCompareHotelsHandler);
      UiController.unsubscribe(events.SET_NORMALIZED_VIEW, setNormalizedViewHandler);
      UiController.unsubscribe(events.SET_PMS_SYNC, setPmsSyncValue);
      UiController.unsubscribe(events.SET_CUSTOM_DATE_RANGE_VALUE, setCustomDatesHandler);
      UiController.unsubscribe(events.SET_CUSTOM_AGGREGATION_SET_A, setAValue);
      UiController.unsubscribe(events.SET_CUSTOM_AGGREGATION_SET_B, setBValue);
      UiController.unsubscribe(events.SET_TABULAR_VIEW, setTabularViewHandler);
      UiController.unsubscribe(events.SET_METRIC_SELECTION, setMetricSelectionHandler);
      UiController.unsubscribe(events.SET_TIME_FRAME, setTimeFrameHandler);
      UiController.unsubscribe(events.SET_LAG_ALIGNED, setLagAlignedHandler);
      UiController.unsubscribe(events.SET_BOOKING_FORECAST, setBookingForecastHandler);
      UiController.unsubscribe(events.SET_PERCENTAGE, setPercentageHandler);
      UiController.unsubscribe(events.SET_EXTENDING_FORECAST, setExtendingForecastHandler);
      UiController.unsubscribe(
        events.SET_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseHandler
      );
      UiController.unsubscribe(events.SET_VISUAL_COLLAPSE, setVisualCollapseHandler);
      UiController.unsubscribe(events.SET_FORECAST, setForecastHandler);
      UiController.unsubscribe(events.SET_SECONDARY_HOTEL_GROUP, setSecondaryHotelHandler);
      UiController.unsubscribe(events.SET_ACTIVE_TAB, setActiveTabHandler);
      UiController.unsubscribe(events.SET_BREAKDOWN_TOGGLE, setBreakdownToggleHandler);
      UiController.unsubscribe(events.SET_PERCENTAGE_TOGGLE, setPercentageChangeToggleHandler);
      UiController.unsubscribe(
        events.SET_SEGMENT_BREAKDOWN_TOGGLE,
        setSegmentBreakdownToggleHandler
      );
      UiController.unsubscribe(events.SET_UTM_BREAKDOWN, setUTMBreakdownHandler);
      UiController.unsubscribe(events.SET_UTM_FOCUS_ON, setUTMFocusOnHandler);
      UiController.unsubscribe(events.SET_BOOKING_PACE_WINDOW, setBookingPaceWindowHandler);
      UiController.unsubscribe(events.SET_MOVING_AVERAGE, setMovingAverageHandler);
      UiController.unsubscribe(events.SET_CURRENCY, setCurrencyHandler);
      UiController.unsubscribe(events.SET_DATE_TYPE, setDateTypeHandler);
      UiController.unsubscribe(events.SET_RATE_PLAN, setRatePlanHandler);
    };
  }, []);
};

export default useMainCommandFlow;
