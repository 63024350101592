/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import customMiddleware from 'modules/common/middleware/custom-middleware';
import { authSlice } from 'modules/common/auth/slice';
import { signInSlice } from 'modules/sign-in/slice';
import { persistReducer, persistStore } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';
import { ENVIRONMENT } from 'config';
import { forgotPasswordSlice } from 'modules/forgot-password/slice';
import { resetPasswordSlice } from 'modules/reset-password/slice';
import { notificationSlice } from 'modules/common/notifications/slice';
import { verifyCallbackSlice } from 'modules/verify-callback/slice';
import { dashboardSlice } from 'modules/dashboard/slice';
import { rateCalenderSlice } from 'modules/rate-calendar/slice';
import logger from 'modules/common/utils/logger';
import { rateCalendarUploadSlice } from 'modules/rate-calendar-upload/slice';
import { usersSlice } from 'modules/users/slice';
import { configureEventCategoriesSlice } from 'modules/event-configuration/slice';
import { summarySlice } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { trendSlice } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { plotPaceSlice } from 'modules/dashboard/components/tab-container/3d-pace-tab/slice';
import { regionalMapSlice } from 'modules/dashboard/components/tab-container/regional-map-tab/slice';
import { SLICE } from 'modules/dashboard/constants';
import { webClickSlice } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import { iPreferSlice } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';
import { workbookSlice } from 'modules/dashboard/components/tab-container/workbook-report/slice';
import rootSaga from 'root-saga';
import { paceSlice } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
//
export const sagaMiddleware = createSagaMiddleware();
/**
 * White listed slices which needs to be persist with encryption
 */
const persistConfig = {
  version: ENVIRONMENT.PERSIST_VERSION,
  key: ENVIRONMENT.PERSIST_KEY,
  storage,
  stateReconciler: hardSet,
  whitelist: [
    SLICE.BASE_AUTH,
    SLICE.DASHBOARD,
    SLICE.SUMMARY,
    SLICE.PACE_TIME,
    SLICE.PACE_SEGMENT,
    SLICE.PLOT_PACE,
    SLICE.REGIONAL_MAP,
    SLICE.WEB_CLICK,
    SLICE.I_PREFER,
    SLICE.WORKBOOK_REPORT,
    SLICE.TREND,
    SLICE.PACE,
  ],
  writeFailHandler(error) {
    logger.error(error);
  },
};
/**
 * Combine all the persist and non persist reducers
 */
const reducers = combineReducers({
  [SLICE.BASE_AUTH]: authSlice.reducer,
  [SLICE.SIGN_IN]: signInSlice.reducer,
  [SLICE.FORGOT_PASSWORD]: forgotPasswordSlice.reducer,
  [SLICE.RESET_PASSWORD]: resetPasswordSlice.reducer,
  [SLICE.NOTIFICATION]: notificationSlice.reducer,
  [SLICE.VERIFY_CALLBACK]: verifyCallbackSlice.reducer,
  [SLICE.DASHBOARD]: dashboardSlice.reducer,
  [SLICE.RATE_CALENDAR_UPLOAD]: rateCalendarUploadSlice.reducer,
  [SLICE.USERS]: usersSlice.reducer,
  [SLICE.RATE_CALENDAR]: rateCalenderSlice.reducer,
  [SLICE.CONFIGURE_EVENT_CATEGORIES]: configureEventCategoriesSlice.reducer,
  [SLICE.SUMMARY]: summarySlice.reducer,
  [SLICE.PLOT_PACE]: plotPaceSlice.reducer,
  [SLICE.REGIONAL_MAP]: regionalMapSlice.reducer,
  [SLICE.WEB_CLICK]: webClickSlice.reducer,
  [SLICE.I_PREFER]: iPreferSlice.reducer,
  [SLICE.WORKBOOK_REPORT]: workbookSlice.reducer,
  [SLICE.TREND]: trendSlice.reducer,
  [SLICE.PACE]: paceSlice.reducer,
});
/**
 * Define what are the slice which needs to be persist
 */
const persistedReducer = persistReducer(persistConfig, reducers);
/**
 * Register all the slices into the main store with encryption
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: { ignoredActions: ['persist/PERSIST'] },
    }),
    sagaMiddleware,
    customMiddleware,
  ],
});
sagaMiddleware.run(rootSaga);
persistStore(store);
//
export default store;
