import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { startOfMonth, subMonths, endOfMonth } from 'date-fns';
import { TAGS, SLICE, BOOKING_DATE } from 'modules/dashboard/constants';
import { capitalizeFirst } from 'modules/common/helpers/strings';
/**
 * Initial states of iPrefer widget functions are defined here
 */
const stayDate = {
  startDate: new Date(startOfMonth(subMonths(new Date(), 1))).toISOString(),
  endDate: new Date(endOfMonth(subMonths(new Date(), 1))).toISOString(),
  key: 'selection',
};
export const initialState = {
  iPreferTargetDate: stayDate,
  iPreferStartDate: stayDate.startDate,
  iPreferEndDate: stayDate.endDate,
  iPreferDateSelected: stayDate.startDate,
  iPreferCustomDate: 'start',
  iPreferOtb: false,
  iPreferBookingDate: {
    startDate: new Date(BOOKING_DATE).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  iPreferChartBreakdown: null,
  iPreferAlignment: null,
  iPreferDateComparison: null,
  iPreferAlignmentToggle: false,
  iPreferSymmetricComparisonToggle: true,
  iPreferIsDetailed: false,
  iPreferFocusOn: [],
  iPreferMetricSelection: null,
  iPreferFilterList: [],
  iPreferTimeComparisonFilterList: [],
  iPreferCustomerCountFilterList: [],
  iPreferTimeFrame: null,
  iPreferUrl: '',
};
/**
 * All actions related to iPrefer widget feature are defined here
 */
export const iPreferSlice = createSlice({
  name: SLICE.I_PREFER,
  initialState,
  reducers: {
    setIPreferUrl(state, action) {
      state.iPreferUrl = action.payload;
    },
    setIPreferTargetDate(state, action) {
      state.iPreferTargetDate = action?.payload;
    },
    setIPreferStartDate(state, action) {
      state.iPreferStartDate = action?.payload;
    },
    setIPreferEndDate(state, action) {
      state.iPreferEndDate = action?.payload;
    },
    setIPreferDateSelected(state, action) {
      state.iPreferDateSelected = action?.payload;
    },
    setIPreferCustomDate(state, action) {
      state.iPreferCustomDate = action?.payload;
    },
    setIPreferOtb(state, action) {
      state.iPreferOtb = action?.payload;
    },
    setIPreferBookingDate(state, action) {
      state.iPreferBookingDate = action?.payload;
    },
    setIPreferChartBreakdown(state, action) {
      state.iPreferChartBreakdown = action?.payload;
    },
    setIPreferAlignment(state, action) {
      state.iPreferAlignment = action?.payload;
    },
    setIPreferDateComparison(state, action) {
      state.iPreferDateComparison = action?.payload;
    },
    setIPreferAlignmentToggle(state, action) {
      state.iPreferAlignmentToggle = action?.payload;
    },
    setIPreferSymmetricComparisonToggle(state, action) {
      state.iPreferSymmetricComparisonToggle = action?.payload;
    },
    setIPreferIsDetailed(state, action) {
      state.iPreferIsDetailed = action?.payload;
    },
    setIPreferFocusOn(state, action) {
      state.iPreferFocusOn = action?.payload;
    },
    setIPreferMetricSelection(state, action) {
      state.iPreferMetricSelection = action?.payload;
    },
    setIPreferFilterList(state, action) {
      state.iPreferFilterList = action?.payload;
    },
    setIPreferTimeComparisonFilterList(state, action) {
      state.iPreferTimeComparisonFilterList = action?.payload;
    },
    setIPreferCustomerCountFilterList(state, action) {
      state.iPreferCustomerCountFilterList = action?.payload;
    },
    setIPreferTimeFrame(state, action) {
      state.iPreferTimeFrame = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        );
        return {
          ...state,
          iPreferChartBreakdown: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
          )?.id,
          iPreferAlignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          iPreferDateComparison: dateComparisonFilter?.values?.find(
            (filter) => filter.isDefault === true
          )?.value,
          iPreferTimeFrame: action?.payload?.results?.find(
            (filter) =>
              filter?.tags?.includes(TAGS.I_PREFER_TIME_FRAME) && filter?.isDefault === true
          )?.id,
          iPreferMetricSelection: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.METRIC_SELECTION) && filter?.isDefault === true
          )?.values[0],
        };
      });
  },
});
//
export const { actions: iPreferActions } = iPreferSlice;
