/**
 * Define entire application timeout intervals
 */
const TIME_OUTS = {
  REDIRECT_TO_LOGIN: 5,
  SESSION_OBSERVER_TIME: 5000,
  TIME_INTERVAL_FOR_PING_SQL_WAREHOUSE: 1800000,
  NOTIFICATION_INTERVAL: 60000,
};
//
export default TIME_OUTS;
