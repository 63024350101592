/**
 * Function to retrieve url tail from prompted sentences
 * @param {*} com -  sentence part
 * @returns
 */
const getUrlTail = (com) => {
  let urlTail = '';
  // check whether there is another sentence after URL_TAIL and extract url
  if (com.substring(com.indexOf('URL_TAIL==') + 10).includes('.')) {
    urlTail = com.substring(com.indexOf('URL_TAIL==') + 10)?.split('.')[0];
  } else {
    urlTail = com.substring(com.indexOf('URL_TAIL==') + 10);
  }
  return urlTail;
};
//
export default getUrlTail;
