import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { CheckboxDropdown, CustomStack, CustomToggleSwitch } from 'modules/common/components';
import ActionButton from 'modules/common/components/action-button';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { CustomAggregation } from '../custom-aggregation';
import { COMMON_FOCUS_ON_OPTIONS } from '../../../trend-tabs/constants';

/**
 * Breakdown window component common for the dashboard component
 * @param {Boolean} isDetailed - State of Detailed toggle
 * @param {*} chartBreakdown - Selected chart breakdown value
 * @param {Array} chartBreakdownList - chart breakdown list
 * @param {Function} handleIsDetailedChange -  Function to set isDetailed state change
 * @param {Function} handleBreakdownChange -  Function to set breakdown state change
 * @param {Boolean} isFocusOnAvailable - state of focus on section hide or show
 * @param {Array} focusOnList - focus on list
 * @param {*} focusOn - Selected focus on value
 * @param {*} focusOnLabel - focus on section label
 * @param {Function} handleFocusOnChange -  Function to set focus on state change
 * @returns
 */
const TrendBreakdownWindow = ({
  isDetailed,
  chartBreakdown,
  chartBreakdownList,
  handleIsDetailedChange,
  handleBreakdownChange,
  isFocusOnAvailable,
  focusOnList = [],
  focusOn = [],
  focusOnLabel = '',
  handleFocusOnChange = () => {},
  isGroupingAvailable = false,
  onGroupClick = () => {},
  setOpen = () => {},
  openGroupingDialog = false,
  showFilterName = false,
  setAName = '',
  setBName = '',
  selectedFilterName = '',
  isDeAggregateToggle = false,
  isTrendDeAggregate = false,
  isTrendDeAggregateDisabled = false,
  handleIsDeAggregateToggleChange = () => {},
  id = '',
  isPrimary,
  handlePrimaryChange,
  allSelected = false,
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" pt={1}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flext-start"
          xs={12}
          md={12}
          sm={12}
          xl={6}
          lg={12}
          sx={{ paddingTop: { xl: 0, md: 4, sm: 4 }, paddingBottom: 0, height: '35px' }}
        >
          <CustomToggleSwitch
            label="Breakdown"
            title=""
            onChange={handlePrimaryChange}
            checked={isPrimary}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          xl={6}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{
            height: '35px',
          }}
        >
          <Grid item>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDetailed}
                    onChange={handleIsDetailedChange}
                    id={`${id}-is-detailed`}
                  />
                }
                sx={{
                  '& .css-1cn7wjw-MuiTypography-root': { fontSize: 12 },
                }}
                label="Details"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {isDeAggregateToggle && (
        <CustomToggleSwitch
          label="VS. Time / Hotel"
          title=""
          onChange={handleIsDeAggregateToggleChange}
          checked={isTrendDeAggregate ?? false}
          disabled={isTrendDeAggregateDisabled}
          sx={{ pt: 4, pb: 4 }}
        />
      )}
      <RadioGroup
        value={chartBreakdown ?? null}
        onChange={handleBreakdownChange}
        id={`${id}-breakdown`}
      >
        {chartBreakdownList?.map((item) => (
          <FormControlLabel
            key={item?.id}
            value={item?.id}
            control={<Radio />}
            label={item?.label}
            sx={{
              my: -1,
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
    {isFocusOnAvailable && (
      <FormControl>
        <CheckboxDropdown
          options={focusOnList}
          values={focusOn}
          label={focusOnLabel}
          onchange={handleFocusOnChange}
          isOptionEqualTo={(option, value) => option?.id === value?.id}
          id={id ? `${id}-focus-on` : ''}
          allSelected={allSelected}
          {...(isTrendDeAggregate && {
            disabledOption: COMMON_FOCUS_ON_OPTIONS.OVERALL.label,
          })}
        />
      </FormControl>
    )}
    <>
      <ActionButton
        id="auto-trend-custom-grouping"
        label="Custom Grouping"
        onClick={onGroupClick}
        disabled={!isGroupingAvailable}
      />
      <CustomAggregation
        open={openGroupingDialog}
        onClose={() => setOpen(false)}
        focusOnList={isPrimary && !isTrendDeAggregate ? focusOnList.slice(2) : focusOnList.slice(1)}
        breakdown={chartBreakdown}
      />
      {showFilterName && selectedFilterName && (
        <Typography id="auto-trend-filter-name">Custom Grouping: {selectedFilterName}</Typography>
      )}
      {showFilterName && setAName && <Typography>Group A: {setAName}</Typography>}
      {showFilterName && setBName && <Typography>Group B: {setBName}</Typography>}
    </>
  </CustomStack>
);
//
export default TrendBreakdownWindow;
