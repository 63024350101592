/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Grid, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import './style.css';
import { ENVIRONMENT } from 'config';
import axios from 'axios';
/**
 * User manual page define here
 * @returns
 */
const UserManual = () => {
  const [content, setContent] = useState(null);

  const [headings, setHeadings] = useState([]);

  const getHtmlContent = async (blobUrl) => {
    try {
      const response = await axios.get(blobUrl);
      let htmlContent = response.data;
      htmlContent = htmlContent.replace(/images/g, `${ENVIRONMENT.USER_MANUAL_URL}/images`);

      // Add font family styles globally
      const styleTag = `
          <style>
            body, p, div, h1, h2, h3, h4, h5, h6, span {
              font-family: 'Lexend' !important; 
            }
          </style>
          `;
      // Inject the style tag at the beginning of the HTML content
      htmlContent = styleTag + htmlContent;
      setContent(htmlContent);
    } catch (error) {
      console.error('Error fetching the HTML content:', error);
    }
  };
  useEffect(() => {
    getHtmlContent(`${ENVIRONMENT.USER_MANUAL_URL}/user-manual.html`);
  }, []);

  useEffect(() => {
    if (content != null) {
      // Grab the div element by ID
      const testDiv = document.getElementById('test');
      if (testDiv) {
        // Grab all h1 to h5 tags inside the div
        const hTags = testDiv.querySelectorAll('h1, h2, h3, h4, h5');
        // Map the tags to an array of objects with id and text content
        const headingsData = Array.from(hTags).map((tag) => {
          // Generate a random ID if the tag doesn't have one
          if (!tag.id) {
            tag.id = new Date().getMilliseconds;
          }
          return {
            id: tag.id,
            text: tag.querySelector('span')?.textContent || tag.textContent,
            tag: tag.tagName.toLowerCase(),
          };
        });
        setHeadings(headingsData);
      }
    }
  }, [content]);

  const scrollToSection = (id) => {
    // Scroll to the section with the given id
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  //
  return (
    <Grid container spacing={2} direction="row">
      <Grid
        item
        xs={3}
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          height: '100vh',
          backgroundColor: '#2e384a',
          paddingTop: 5,
          paddingRight: 5,
        }}
      >
        <div>
          <ul className="user-manual-ul">
            {headings.map((heading) => {
              let headingClass = '';

              if (heading.tag === 'h1') headingClass = 'heading-h1';
              if (heading.tag === 'h2') headingClass = 'heading-h2';
              if (heading.tag === 'h3') headingClass = 'heading-h3';
              if (heading.tag === 'h4') headingClass = 'heading-h4';

              return (
                <li
                  className={`user-manual-li ${headingClass}`}
                  onClick={() => scrollToSection(heading.id)}
                  key={heading.id}
                  style={{
                    cursor: 'pointer',
                    fontSize: heading.tag === 'h1' ? '18px' : '15px',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Lexend',
                      color: heading.tag === 'h1' ? 'white' : '#9e9e9e',
                    }}
                  >
                    {heading.text}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </Grid>
      <Grid
        item
        xs={9}
        justifyContent="space-between"
        sx={{
          marginTop: 5,
          marginLeft: '35%',
          marginRight: '10%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div id="test" dangerouslySetInnerHTML={{ __html: content }} />
        </Box>
      </Grid>
    </Grid>
  );
};
//
export default UserManual;
