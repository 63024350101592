import { createSlice } from '@reduxjs/toolkit';
import {
  startOfMonth,
  subMonths,
  endOfMonth,
  previousSunday,
  isSunday,
  isSaturday,
  nextSaturday,
  differenceInCalendarDays,
} from 'date-fns';
import { decode } from 'html-entities';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { signInActions } from 'modules/sign-in/slice';
import { DEMO_ROLE } from 'modules/common/constants/roles';
import {
  BOOKING_DATE,
  TAGS,
  REPORT_TYPE,
  KEYS,
  SLICE,
  CURRENCY_TYPES,
  WORKBOOK_DATE_SELECTION,
} from 'modules/dashboard/constants';
import { getHotelFocusOnValue } from 'modules/dashboard/functions';
import ERROR_MESSAGE_TYPES from 'modules/common/constants/error-messages';
import { isEmpty } from 'modules/common/helpers/object';
import { getCurrencySchema } from './components/tab-container/inner-filter/functions';
//
const bookingStartDate = new Date(BOOKING_DATE).toISOString();
const stayDate = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};
const trendStayDate = {
  startDate: new Date(startOfMonth(subMonths(new Date(), 1))).toISOString(),
  endDate: new Date(endOfMonth(subMonths(new Date(), 1))).toISOString(),
  key: 'selection',
};
const weeklyTrendStayDate = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};
if (isSunday(new Date(trendStayDate.startDate))) {
  weeklyTrendStayDate.startDate = new Date(trendStayDate.startDate).toISOString();
} else {
  weeklyTrendStayDate.startDate = previousSunday(new Date(trendStayDate.startDate)).toISOString();
}
if (isSaturday(new Date(trendStayDate.endDate))) {
  weeklyTrendStayDate.endDate = new Date(trendStayDate.endDate).toISOString();
} else {
  weeklyTrendStayDate.endDate = nextSaturday(new Date(trendStayDate.endDate)).toISOString();
}
/**
 * Initial states of Dashboard function are defined here
 */
export const initialState = {
  loading: false,
  pingLoading: false,
  isSessionTimedOut: false,
  reportType: REPORT_TYPE.SUMMARY,
  embedTokenDetails: {},
  hotelList: [],
  latestUpdatedHotelList: {},
  hotelName: null,
  visuals: {},
  visualFilters: {},
  pmsDate: null,
  groupPmsDates: null,
  pmsSync: false,
  breakdown: [],
  isSignedIn: true,
  lastUpdatedDate: null,
  hotelChange: false,
  tabChange: false,
  isDemoUser: false,
  userRole: null,
  tabAccess: {},
  isUserConfigurationNotExist: false,
  isTokenRevoked: false,
  tabValue: 0,
  currentUrl: '',
  commandUrlFilterList: {},
  commandUrlFilterDropdownList: {},
  commandFilterLoader: false,
  landingPage: {},
  utmBreakdown: [],
  //
  targetDate: stayDate,
  summaryPaceHorizon: null,
  summaryPaceVariable: null,
  summaryForecastFilter: true,
  forecastFilters: [],
  //
  paceOtb: 0,
  paceBookingStyle: false,
  paceBookingDate: {
    startDate: new Date(bookingStartDate).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  //
  trendTargetDate: trendStayDate,
  weeklyTrendStartDate: weeklyTrendStayDate.startDate,
  weeklyTrendEndDate: weeklyTrendStayDate.endDate,
  trendBookingDate: {
    startDate: new Date(bookingStartDate).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  trendOtb: 0,
  trendBookingStyle: false,
  //
  hotelGroups: {},
  hotelGroupsList: [],
  hotelGroupsDropdownList: [],
  predefinedHotelList: [],
  existedHotelGroup: null,
  hotelGroupName: '',
  hotelGroupQuery: '',
  enableHotelGroups: false,
  notifications: [],
  mergedHotelList: [],
  favoriteHotels: [],
  nonFavoriteHotels: [],
  assignedHotelGroups: null,
  allHotelList: [],
  IsSavedCommandFilterLoaded: false,
  currencyFilter: {},
  localCurrency: CURRENCY_TYPES.USD,
  currencyList: [
    {
      id: CURRENCY_TYPES.USD,
      label: CURRENCY_TYPES.USD,
    },
  ],
  //
  workbookDateType: WORKBOOK_DATE_SELECTION[0],
  isHotelForecast: null,
  hotelForecastDisabledText: 'Forecasts not available',
  isHotelDeleted: false,
  //
  urlOrder: null,
  urlTab: null,
};
/**
 * All actions related to dashboard feature are defined here
 */
export const dashboardSlice = createSlice({
  name: SLICE.DASHBOARD,
  initialState,
  reducers: {
    getEmbedToken(state) {
      state.loading = true;
    },
    getEmbedTokenSucceeded(state, action) {
      state.loading = false;
      state.embedTokenDetails = action?.payload;
    },
    getEmbedTokenFailed(state) {
      state.loading = false;
    },
    setHotelName(state, action) {
      state.hotelName = action?.payload;
      state.enableHotelGroups = action?.payload && 'hotels' in action.payload;
    },
    setReportType(state, action) {
      state.reportType = action?.payload;
    },
    getVisuals(state) {
      state.loading = true;
    },
    getVisualsSucceeded(state, action) {
      state.loading = false;
      state.visuals = action?.payload;
    },
    getVisualsFailed(state) {
      state.loading = false;
    },
    getVisualFilters(state) {
      state.loading = true;
    },
    getVisualFiltersSucceeded(state, action) {
      state.loading = false;
      state.visualFilters = action?.payload;
      state.summaryPaceHorizon = action?.payload?.results?.filter(
        (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
      )[0]?.id;
      state.paceWidgetPaceHorizon = action?.payload?.results?.filter(
        (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
      )[0]?.id;
      state.summaryPaceVariable = action?.payload?.results?.filter(
        (filter) => filter?.tags?.includes(TAGS.SUMMARY_PACE_VARIABLE) && filter?.isDefault === true
      )[0]?.values?.[0];
      state.currencyFilter = getCurrencySchema(action?.payload?.results);
    },
    getVisualFiltersFailed(state) {
      state.loading = false;
    },
    getHotelList(state) {
      state.loading = true;
    },
    getHotelListSucceeded(state, action) {
      state.loading = false;
      const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
      state.hotelList = hotel?.hotelDropdownList;
    },
    getHotelListFailed(state) {
      state.loading = false;
    },
    getAllHotelList(state) {
      state.loading = true;
    },
    getAllHotelListSucceeded(state, action) {
      state.loading = false;
      const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
      state.allHotelList = hotel?.hotelDropdownList;
    },
    getAllHotelListFailed(state) {
      state.loading = false;
    },
    getLatestUpdatedHotelList(state) {
      state.loading = true;
    },
    getLatestUpdatedHotelListSucceeded(state, action) {
      state.loading = false;
      state.latestUpdatedHotelList = action?.payload;
    },
    getLatestUpdatedHotelListFailed(state) {
      state.loading = false;
    },
    setPmsDate(state, action) {
      state.pmsDate = action?.payload;
    },
    setGroupPmsDates(state, action) {
      state.groupPmsDates = action?.payload;
    },
    setPmsSync(state, action) {
      state.pmsSync = action?.payload;
    },
    getBreakdown(state) {
      state.loading = true;
    },
    getBreakdownSucceeded(state, action) {
      state.loading = false;
      state.breakdown = action?.payload?.results;
    },
    getBreakdownFailed(state) {
      state.loading = false;
    },
    pingSqlWareHouse(state) {
      state.pingLoading = true;
    },
    pingSqlWareHouseSucceeded(state) {
      state.pingLoading = false;
    },
    pingSqlWareHouseFailed(state) {
      state.pingLoading = false;
    },
    getSystemInformation(state) {
      state.loading = true;
    },
    getSystemInformationSucceeded(state, action) {
      state.loading = false;
      state.lastUpdatedDate = action?.payload?.results?.find(
        (filter) => filter?.key === KEYS.LAST_UPDATED_DATE
      )?.value;
    },
    getSystemInformationFailed(state) {
      state.loading = false;
    },
    setTabChange(state, action) {
      state.tabChange = action.payload;
    },
    setHotelChange(state, action) {
      state.hotelChange = action.payload;
    },
    setCurrentUrl(state, action) {
      state.currentUrl = action.payload;
    },
    setTabValue(state, action) {
      state.tabValue = action.payload;
    },
    setTabAccess(state, action) {
      state.tabAccess = action?.payload;
    },
    setTokenRevocationError(state, action) {
      state.isTokenRevoked = action?.payload;
    },
    setSessionTimeOut(state, action) {
      state.isSessionTimedOut = action?.payload;
    },
    setIsSignedIn(state, action) {
      state.isSignedIn = action?.payload;
    },
    resetUserConfigurationStatus(state) {
      state.isUserConfigurationNotExist = false;
    },
    saveCommandUrlFilter(state) {
      state.loading = true;
    },
    saveCommandUrlSucceeded(state, action) {
      state.loading = false;
      state.commandUrlFilterList = action?.payload;
    },
    saveCommandUrlFailed(state) {
      state.loading = false;
    },
    getCommandFilter(state) {
      state.commandFilterLoader = true;
    },
    getCommandFilterSucceeded(state, action) {
      state.commandFilterLoader = false;
      state.commandUrlFilterList = action?.payload;
    },
    getCommandFilterFailed(state) {
      state.commandFilterLoader = false;
    },
    getCommandFilterDropDownList(state) {
      state.commandFilterLoader = true;
    },
    getCommandFilterDropDownListSucceeded(state, action) {
      state.commandFilterLoader = false;
      state.commandUrlFilterDropdownList = action?.payload;
    },
    getCommandFilterDropDownListFailed(state) {
      state.commandFilterLoader = false;
    },
    deleteCommandFilter(state) {
      state.loading = true;
    },
    deleteCommandFilterSucceeded(state) {
      state.loading = false;
    },
    deleteCommandFilterFailed(state) {
      state.loading = false;
    },
    getScrollCommandFilter(state) {
      state.commandFilterLoader = true;
    },
    getScrollCommandFilterSucceeded(state, action) {
      state.commandFilterLoader = false;
      state.commandUrlFilterList = {
        ...action.payload,
        results: [...state.commandUrlFilterList.results, ...action.payload.results],
      };
    },
    getScrollCommandFilterFailed(state) {
      state.commandFilterLoader = false;
    },
    getUtmBreakdown(state) {
      state.loading = true;
    },
    getUtmBreakdownSucceeded(state, action) {
      state.loading = false;
      state.utmBreakdown = action?.payload?.results;
    },
    getUtmBreakdownFailed(state) {
      state.loading = false;
    },
    //
    setPaceHorizon(state, action) {
      state.summaryPaceHorizon = action?.payload;
    },
    setPaceVariable(state, action) {
      state.loading = false;
      state.summaryPaceVariable = action?.payload;
    },
    setSummaryForecastFilter(state, action) {
      state.summaryForecastFilter = action?.payload;
    },
    setTargetDate(state, action) {
      state.targetDate = action?.payload;
    },
    getForecastFilters(state) {
      state.loading = true;
    },
    getForecastFiltersSucceeded(state, action) {
      state.loading = false;
      state.forecastFilters = action?.payload?.results
        ?.map((item) => ({
          label: capitalizeFirst(item?.label),
          pmsSyncOff: item?.pmsSyncOff,
          variable: item?.variable,
          stayDate: item?.stayDate.split('T')[0] ?? null,
          date: item.label.substring(item.label.indexOf(' ', item.label.indexOf(' ') + 1) + 1),
        }))
        .sort((a, b) => differenceInCalendarDays(new Date(b.date), new Date(a.date)));
    },
    getForecastFiltersFailed(state) {
      state.loading = false;
    },
    setForecastFilters(state, action) {
      state.forecastFilters = action?.payload;
    },
    setPaceOtb(state, action) {
      state.paceOtb = action?.payload;
    },
    setPaceBookingStyle(state, action) {
      state.paceBookingStyle = action?.payload;
    },
    setPaceBookingDate(state, action) {
      state.paceBookingDate = action?.payload;
    },
    //
    setTrendBookingDate(state, action) {
      state.trendBookingDate = action?.payload;
    },
    setTrendTargetDate(state, action) {
      state.trendTargetDate = action?.payload;
    },
    setTrendOtb(state, action) {
      state.trendOtb = action?.payload;
    },
    setTrendBookingStyle(state, action) {
      state.trendBookingStyle = action?.payload;
    },
    setWeeklyTrendStartDate(state, action) {
      state.weeklyTrendStartDate = action?.payload;
    },
    setWeeklyTrendEndDate(state, action) {
      state.weeklyTrendEndDate = action?.payload;
    },
    getHotelGroupList(state) {
      state.loading = true;
    },
    getHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.hotelGroups = action?.payload;
    },
    getHotelGroupListFailed(state, action) {
      state.loading = false;
      if (action?.payload?.message === ERROR_MESSAGE_TYPES.USER_CONFIGURATION_NOT_FOUND) {
        state.isUserConfigurationNotExist = true;
      }
    },
    setHotelGroupDropdownList(state, action) {
      state.hotelGroupsDropdownList = action?.payload;
    },
    setPredefinedHotelList(state, action) {
      state.predefinedHotelList = action?.payload;
    },
    setSelectedHotelGroup(state, action) {
      if (state && state.hotelName && 'hotels' in state.hotelName) {
        if (!isEmpty(state.hotelName) && action?.payload?.hotelGroupDropdownList?.length === 0) {
          state.hotelName = action?.payload?.hotelList[0];
        } else if (state.hotelName && action?.payload?.hotelGroupDropdownList?.length > 0) {
          const newGroup = action?.payload?.hotelGroupDropdownList?.find(
            (a) => a?.id === state.hotelName?.id
          );
          if (newGroup) {
            state.hotelName = newGroup;
          } else {
            state.hotelName = action?.payload?.hotelList[0];
          }
        } else {
          state.hotelName = action?.payload?.hotelList[0];
        }
      }
      state.enableHotelGroups = state && state.hotelName && 'hotels' in state.hotelName;
    },
    getLoadHotelGroupList(state) {
      state.loading = false;
    },
    getLoadHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.hotelGroupsList = action?.payload;
    },
    getLoadHotelGroupListFailed(state) {
      state.loading = false;
    },
    createHotelGroup(state) {
      state.loading = true;
    },
    createHotelGroupSucceeded(state, action) {
      state.loading = false;
      state.hotelGroupsList.results = state?.hotelGroupsList?.results?.map((object) => {
        const updatedGroup = action?.payload?.results?.find(
          (updatedObject) =>
            updatedObject?.hotelGroups?.hotelGroupName === object?.hotelGroups?.hotelGroupName
        );
        if (updatedGroup) return updatedGroup;
        return object;
      });
      state.hotelGroups.results = state?.hotelGroups?.results?.map((object) => {
        const updatedGroup = action?.payload?.results?.find(
          (updatedObject) =>
            updatedObject?.hotelGroups?.hotelGroupName === object?.hotelGroups?.hotelGroupName
        );
        if (updatedGroup) {
          return updatedGroup;
        }
        return object;
      });

      // Add new groups if not found in the state
      const existingGroupNames = state.hotelGroups?.results?.map(
        (obj) => obj?.hotelGroups?.hotelGroupName
      );
      action?.payload?.results?.forEach((updatedObject) => {
        if (!existingGroupNames?.includes(updatedObject?.hotelGroups?.hotelGroupName)) {
          state?.hotelGroups?.results?.push(updatedObject);
        }
      });
    },
    createHotelGroupFailed(state) {
      state.loading = false;
    },
    getHotelGroupNameValidation(state) {
      state.loading = true;
    },
    getHotelGroupNameValidationSucceeded(state, action) {
      state.loading = false;
      state.existedHotelGroup = action?.payload;
    },
    getHotelGroupNameValidationFailed(state) {
      state.loading = false;
    },
    resetHotelGroupNameValidationStatus(state) {
      state.existedHotelGroup = null;
    },
    setSelectedHotelGroupName(state, action) {
      state.hotelGroupName = action.payload;
    },
    deleteHotelGroup(state) {
      state.loading = true;
    },
    deleteHotelGroupSucceeded(state, action) {
      state.loading = false;
      state.isHotelDeleted = true;
      const [, hotelGroupName] = action.payload.query.split('=');
      state.hotelGroupsList.results = state?.hotelGroupsList?.results?.filter(
        (object) => object?.hotelGroups?.hotelGroupName !== hotelGroupName
      );
      state.hotelGroups.results = state?.hotelGroups?.results?.filter(
        (object) => object?.hotelGroups?.hotelGroupName !== hotelGroupName
      );
    },
    deleteHotelGroupFailed(state) {
      state.loading = false;
    },
    setHotelGroupQuery(state, action) {
      state.hotelGroupQuery = decode(action.payload);
    },
    setEnableHotelGroups(state, action) {
      state.enableHotelGroups = action.payload;
    },
    setLandingPage(state, action) {
      state.landingPage = action.payload;
    },
    resetLandingPage(state) {
      state.landingPage = {};
    },
    getNotificationsList() {},
    getNotificationsListSucceeded(state, action) {
      state.notifications = action.payload.results;
    },
    getNotificationsListFailed() {},
    updateNotification(state) {
      state.loading = false;
    },
    updateNotificationSucceeded(state, action) {
      state.loading = false;
      state.notifications = state.notifications?.map((notification) => {
        if (notification.id === action.payload.id) {
          return action.payload;
        }
        return notification;
      });
    },
    updateNotificationFailed(state) {
      state.loading = false;
    },
    setMergedHotelList(state, action) {
      state.mergedHotelList = action?.payload;
    },
    setFavoriteHotelList(state, action) {
      state.favoriteHotels = action?.payload;
    },
    setNonFavoriteHotelList(state, action) {
      state.nonFavoriteHotels = action?.payload;
    },
    addHotelToFavorite(state) {
      state.loading = true;
    },
    addHotelToFavoriteSucceeded(state, action) {
      state.loading = false;
      state.hotelList = state?.hotelList?.map((hotel) => ({
        ...hotel,
        isFavorite: action?.payload?.includes(hotel?.id),
      }));
    },
    addHotelToFavoriteFailed(state) {
      state.loading = false;
    },
    addHotelGroupToFavorites(state) {
      state.loading = true;
    },
    addHotelGroupToFavoritesSucceeded(state, action) {
      state.loading = false;
      state.hotelGroupsList.results = state?.hotelGroupsList?.results?.map((object) => {
        const updatedGroup = action?.payload?.results?.find(
          (updatedObject) =>
            updatedObject?.hotelGroups?.hotelGroupName === object?.hotelGroups?.hotelGroupName
        );
        if (updatedGroup) return updatedGroup;
        return object;
      });
      state.hotelGroups.results = state?.hotelGroups?.results?.map((object) => {
        const updatedGroup = action?.payload?.results?.find(
          (updatedObject) =>
            updatedObject?.hotelGroups?.hotelGroupName === object?.hotelGroups?.hotelGroupName
        );
        if (updatedGroup) return updatedGroup;
        return object;
      });
    },
    addHotelGroupToFavoritesFailed(state) {
      state.loading = false;
    },
    getAssignedHotelGroupList(state) {
      state.loading = true;
    },
    getAssignedHotelGroupListSucceeded(state, action) {
      state.loading = false;
      state.assignedHotelGroups = action?.payload;
    },
    getAssignedHotelGroupListFailed(state) {
      state.loading = false;
      state.assignedHotelGroups = [];
    },
    setIsSavedCommandFilterLoaded(state, action) {
      state.IsSavedCommandFilterLoaded = action.payload;
    },
    setCurrencyFilter(state, action) {
      state.currencyFilter = action?.payload;
    },
    setLocalCurrency(state, action) {
      state.localCurrency = action.payload;
    },
    setLocalCurrencyList(state, action) {
      const list = state.currencyList?.filter((currency) => currency.id === CURRENCY_TYPES.USD);
      const newCurrencyObj = {
        id: action.payload,
        label: action.payload,
      };
      //
      const arr = Array.from(new Set([...list, newCurrencyObj]));
      state.currencyList = arr;
    },
    removeOtherLocalCurrenciesFromList(state) {
      state.currencyList = [
        {
          id: CURRENCY_TYPES.USD,
          label: CURRENCY_TYPES.USD,
        },
      ];
      state.localCurrency = CURRENCY_TYPES.USD;
    },
    setWorkbookDateType(state, action) {
      state.workbookDateType = action?.payload;
    },
    setIsHotelForecast(state, action) {
      state.isHotelForecast = action?.payload;
    },
    setHotelForecastDisabledText(state, action) {
      state.hotelForecastDisabledText = action?.payload;
    },
    setIsHotelDeleted(state, action) {
      state.isHotelDeleted = action?.payload;
    },
    setUrlOrder(state, action) {
      state.urlOrder = action?.payload;
    },
    setUrlTab(state, action) {
      state.urlTab = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(signInActions.signInSucceeded, (state, action) => ({
        ...state,
        isDemoUser: action?.payload?.user?.roles[0]?.name === DEMO_ROLE,
        userRole: action?.payload?.user?.roles[0]?.name,
      }))
      .addCase(signInActions.signInFailed, (state) => ({
        ...state,
        tokens: {},
        isDemoUser: false,
        userRole: null,
      }));
  },
});
//
export const { actions: dashboardActions } = dashboardSlice;
