import { Grid, Typography } from '@mui/material';
import { BoxIcon } from './style';
/**
 * Event index with color codes is defined here
 * @returns
 */
const EventIndex = () => (
  <Grid container direction="row" p={3}>
    <Grid item container direction="row" xs={3}>
      <BoxIcon sx={{ backgroundColor: '#6FBB10' }} alignSelf="center" mr={1} />
      <Typography variant="caption" alignSelf="center">
        Regular sports event
      </Typography>
    </Grid>
    <Grid item container direction="row" xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#B9273C' }} alignSelf="center" mr={1} />
      <Typography variant="caption" alignSelf="center">
        Special / Annual sports event
      </Typography>
    </Grid>
    <Grid item container direction="row" xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#F9EC2B' }} alignSelf="center" mr={1} />
      <Typography variant="caption" alignSelf="center">
        Weather (forecastable)
      </Typography>
    </Grid>
    <Grid item container direction="row" xs={3} wrap="nowrap">
      <BoxIcon className="icon" sx={{ backgroundColor: '#B43292' }} alignSelf="center" mr={1} />
      <Typography variant="caption" alignSelf="center">
        Weather/Natural disaster(non–forecastable)
      </Typography>
    </Grid>
    <Grid item container direction="row" xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#FCC71F' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Holiday</Typography>
    </Grid>
    <Grid item container direction="row" xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#8E61A6' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Long Weekend</Typography>
    </Grid>
    <Grid container direction="row" item xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#FFA657' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Festivals</Typography>
    </Grid>
    <Grid container direction="row" item xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#323B96' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Entertainment</Typography>
    </Grid>
    <Grid container direction="row" item xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#F0642F' }} alignSelf="center" mr={1} />
      <Typography variant="caption">City wide conferences (w/rooms)</Typography>
    </Grid>
    <Grid container direction="row" item xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#01AFC8' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Conferences&shows (no rooms)</Typography>
    </Grid>
    <Grid container direction="row" item xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#EB2A2D' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Hotel group bookings</Typography>
    </Grid>
    <Grid container direction="row" item xs={3}>
      <BoxIcon className="icon" sx={{ backgroundColor: '#6AB98C' }} alignSelf="center" mr={1} />
      <Typography variant="caption">Others</Typography>
    </Grid>
  </Grid>
);
//
export default EventIndex;
