import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';

/**
 * Getting entire users state into the component
 */
const selectDomain = (state) => state[SLICE.USERS] || initialState;
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector([selectDomain], (usersState) => usersState.loading);
/**
 * Getting the users list state into the component
 */
export const selectUsers = createSelector([selectDomain], (usersState) => usersState?.usersList);
/**
 * Getting the roles list state into the component
 */
export const selectRoleList = createSelector([selectDomain], (usersState) => usersState.roleList);
/**
 * Getting the filtered user by user id state into the component
 */
const getFirstParam = (_, param) => param;
export const selectUserById = createSelector(
  [selectUsers, getFirstParam],
  (filteredUsers, userId) => filteredUsers?.results?.filter((user) => user.id === userId)[0]
);
/**
 * Getting the user hotels list state into the component
 */
export const selectUserHotelsList = createSelector(
  [selectDomain],
  (usersState) => usersState.hotelsList?.results
);
/**
 * Getting the updated user state into the component
 */
export const selectUpdatedUser = createSelector(
  [selectDomain],
  (usersState) => usersState.updatedUser
);
/**
 * Getting the  hotels selection list for dropdown  state into the component
 */
export const selectHotelsSelectionList = createSelector([selectDomain], (usersState) =>
  usersState.hotelSelectionList?.results?.map((data) => ({
    label: data?.name,
    id: data?.id,
    pmsDate: data?.pmsStartDate,
  }))
);
/**
 * Getting user by id state into the component
 */
export const selectCurrentUser = createSelector(
  [selectDomain],
  (usersState) => usersState.currentUser
);
/**
 * Getting custom landing page state into the component
 */
export const selectLandingPage = createSelector(
  [selectDomain],
  (usersState) => usersState.landingPage
);
/**
 * Getting is custom landing page updated state into the component
 */
export const selectIsLandingPageUpdated = createSelector(
  [selectDomain],
  (usersState) => usersState.isLandingPageUpdated
);
/**
 * Getting is stay dates preset list state into the component
 */
export const selectStayDatesPresetList = createSelector(
  [selectDomain],
  (usersState) => usersState.stayDatesPresetList
);
/**
 * Getting currency data state to user  component
 */
export const selectCurrencyData = createSelector([selectDomain], (hotelState) => hotelState.currencyData);
