/* eslint-disable no-underscore-dangle */
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useEffect, useState } from 'react';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoader, selectCalendarEventList } from 'modules/rate-calendar/selectors';
import { selectHotelName } from 'modules/dashboard/selectors';
import { Grid, Typography } from '@mui/material';
import { rateCalenderActions } from 'modules/rate-calendar/slice';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { sub } from 'date-fns';
import { Loader } from 'modules/common/components';
import useCalendar from 'modules/rate-calendar/hooks/use-calender';
import { isEmpty } from 'modules/common/helpers/object';
import { heatMapOffBackground, selectedDateColor } from 'modules/common/constants/heatmap-config';
import { FullCalendarWrapper, Card, CardContent, BoxIcon } from './style';
import EventIndex from '../event-index';
import MetricsEventsDrawer from '../metrics-and-events-drawer';
import './index.scss';
/**
 * Rate calendar and events are defined here
 * @param {*} param0
 * @returns
 */
const Calender = ({ heatMap, isChecked, handleChange }) => {
  useCalendar(heatMap);
  //
  const dispatch = useDispatch();
  //
  const hotel = useSelector(selectHotelName);
  const organizationId = useSelector(selectOrganizationId);
  const loading = useSelector(selectLoader);
  const calendarEventsList = useSelector(selectCalendarEventList);
  //
  const [height, setHeight] = useState('900px');
  const [previous, setPrevious] = useState();
  const [dates, setDates] = useState({
    startDate: '',
    endDate: '',
  });
  const [eventInformation, setEventInformation] = useState({
    date: '',
    specialEvents: [],
    topHeader: {
      title: {
        remaining: '',
        available: '',
      },
      fontSize: '',
      textColor: '',
    },
    middleBand: {
      title: '',
      fontSize: '',
      textColor: '',
    },
    footer: {
      titleFirst: '',
      titleSecond: '',
      fontSize: '',
      textColor: '',
    },
  });
  //
  useEffect(() => {
    if (dates.startDate && dates.endDate && hotel) {
      dispatch(
        rateCalenderActions.getEventDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${dates.startDate}&to=${dates.endDate}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
      //
      dispatch(
        rateCalenderActions.getForecastDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${dates.startDate}&to=${dates.endDate}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
      //
      dispatch(
        rateCalenderActions.getRateDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${dates.startDate}&to=${dates.endDate}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
      //
      dispatch(
        rateCalenderActions.getHotelDetails({
          organizationId,
          hotelId: hotel?.id,
        })
      );
      //
      dispatch(
        rateCalenderActions.getRoomConsumptionDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${dates.startDate}&to=${dates.endDate}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
      dispatch(
        rateCalenderActions.getPreviousYearRoomConsumptionDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${sub(new Date(dates.startDate), {
            years: 1,
            days: 1,
          })}&to=${sub(new Date(dates.endDate), {
            years: 1,
            days: 1,
          })}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
      dispatch(
        rateCalenderActions.getPreviousYearRateDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${sub(new Date(dates.startDate), {
            years: 1,
            days: 1,
          })}&to=${sub(new Date(dates.endDate), {
            years: 1,
            days: 1,
          })}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
      //
      dispatch(
        rateCalenderActions.getPreviousYearForecastDetails({
          query: `limit=${PAGINATION.EVENTS_PER_PAGE}&from=${sub(new Date(dates.startDate), {
            years: 1,
            days: 1,
          })}&to=${sub(new Date(dates.endDate), {
            years: 1,
            days: 1,
          })}`,
          organizationId,
          hotelId: hotel?.id,
        })
      );
    }
    dispatch(rateCalenderActions.setSelectedDate(''));
  }, [hotel, dates]);
  //
  useEffect(() => {
    dispatch(rateCalenderActions.setSelectedDate(''));
  }, [heatMap]);
  //
  const handleMonthChange = (payload) => {
    if (payload) {
      if (payload.view.type === 'dayGridMonth') {
        setHeight('900px');
      } else {
        setHeight('200px');
      }
      //
      setDates({
        startDate: new Date(payload.startStr).toISOString(),
        endDate: new Date(payload.endStr).toISOString(),
      });
    }
  };
  //
  const handleDateCellClick = (info) => {
    dispatch(rateCalenderActions.setSelectedDate(new Date(info.event._def.publicId).toISOString()));
    if (previous) {
      const prevColor = heatMap ? previous.event._def.ui.backgroundColor : heatMapOffBackground;
      previous.el.style['background-color'] = prevColor;
    }
    // eslint-disable-next-line no-param-reassign
    info.el.style['background-color'] = selectedDateColor;
    setPrevious(info);
    setEventInformation({ ...info.event.extendedProps }, handleChange(true));
  };
  //
  const renderEventContent = (eventInfo) => {
    const { specialEvents, topHeader, middleBand, footer } = eventInfo.event.extendedProps;
    //
    return (
      <Grid container direction="column" px={2} mt={2}>
        <Grid container direction="row">
          {specialEvents &&
            specialEvents.map((e) => <BoxIcon key={e?.id} sx={{ backgroundColor: e?.fill }} />)}
          {isEmpty(specialEvents) && <BoxIcon sx={{ backgroundColor: 'transparent' }} />}
        </Grid>
        <Typography
          disabled
          sx={{ color: topHeader.textColor, paddingTop: 4 }}
          variant={topHeader.fontSize}
        >
          {topHeader?.title?.remaining}/{topHeader?.title?.available}
        </Typography>
        <Typography disabled sx={{ color: middleBand.textColor }} variant={middleBand.fontSize}>
          {middleBand?.title}
        </Typography>
        {!middleBand?.title && <Grid height="35px" sx={{ backgroundColor: 'transparent' }} />}
        <Grid
          mt={5}
          container
          direction="row"
          justifyContent="space-between"
          sx={{ backgroundColor: 'transparent' }}
        >
          <Typography disabled sx={{ color: footer.textColor }} variant={footer.fontSize}>
            {footer?.titleFirst}
          </Typography>
          <Typography disabled sx={{ color: footer.textColor }} variant={footer.fontSize}>
            {footer?.titleSecond}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  //
  return (
    <Loader loading={loading}>
      <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
        <Card mb={6} ml={6}>
          <CardContent>
            <FullCalendarWrapper>
              <FullCalendar
                contentHeight={height}
                initialView="dayGridMonth"
                initialDate={new Date()}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                eventContent={renderEventContent}
                displayEventTime={false}
                datesSet={handleMonthChange}
                events={calendarEventsList}
                headerToolbar={{
                  left: 'prev,today,next',
                  center: 'title',
                  right: 'dayGridWeek dayGridMonth',
                }}
                eventClick={handleDateCellClick}
                dateClick={() => handleChange(true)}
                eventStartEditable={false}
              />
            </FullCalendarWrapper>
            <EventIndex />
          </CardContent>
        </Card>
      </Grid>
      <MetricsEventsDrawer isChecked={isChecked} event={eventInformation} />
    </Loader>
  );
};
//
export default Calender;
