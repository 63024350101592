import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTrendHotelFocusOn,
  selectTrendTimeDateComparison,
  selectEnableSecondaryHotelGroups,
  selectIsPrimary,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { selectEnableHotelGroups, selectHotelName } from 'modules/dashboard/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { REPORT_TYPE } from 'modules/dashboard/constants';
// Custom hook to determine the displayed visual type based on hotel selection and date comparison
const UseVisualSwap = () => {
  const dispatch = useDispatch();
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const selectedHotel = useSelector(selectHotelName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const isPrimary = useSelector(selectIsPrimary);
  /**
   * Different hotels/ hotel groups selected in primary and secondary dropdowns -> Display Trend Hotel visuals
   * Same hotel/ hotel group selected in primary and secondary dropdowns AND only 'PRIMARY' option selected on date comparison -> Display Trend Segment visuals
   * Else -> Display Trend Time visuals
   */
  useEffect(() => {
    if (isPrimary) {
      dispatch(trendActions.setActiveTrendTab(REPORT_TYPE.TREND_SEGMENT));
    } else if (hotelFocusOn?.id !== selectedHotel?.id) {
      dispatch(trendActions.setActiveTrendTab(REPORT_TYPE.TREND_HOTEL));
    } else {
      dispatch(trendActions.setActiveTrendTab(REPORT_TYPE.TREND_TIME));
    }
  }, [
    dateComparison,
    hotelFocusOn,
    selectedHotel,
    enableHotelGroups,
    enableSecondaryHotelGroups,
    isPrimary,
  ]);

  // useEffect(() => {
  //   if (hotelFocusOn?.id !== selectedHotel?.id) {
  //     dispatch(trendActions.setActiveTrendTab(REPORT_TYPE.TREND_HOTEL));
  //   } else if (isPrimary) {
  //     dispatch(trendActions.setActiveTrendTab(REPORT_TYPE.TREND_SEGMENT));
  //   } else {
  //     dispatch(trendActions.setActiveTrendTab(REPORT_TYPE.TREND_TIME));
  //   }
  // }, [
  //   dateComparison,
  //   hotelFocusOn,
  //   selectedHotel,
  //   enableHotelGroups,
  //   enableSecondaryHotelGroups,
  //   isPrimary,
  // ]);
};
//
export default UseVisualSwap;
