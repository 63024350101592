/**
 * Define all the .env file related keys
 */
const ENVIRONMENT = {
  BACKEND_API: process.env?.REACT_APP_BACKEND_API,
  APP_NAME: process.env?.REACT_APP_APP_NAME,
  APP_VERSION: process.env?.REACT_APP_VERSION,
  PERSIST_ENCRYPTED_SECRET_KEY: process.env.REACT_APP_PERSIST_ENCRYPTED_SECRET_KEY,
  PERSIST_KEY: process.env.REACT_APP_PERSIST_KEY,
  PERSIST_VERSION: process.env.REACT_APP_PERSIST_VERSION,
  PBI_BASIC_SCHEMA: process.env.REACT_APP_PBI_BASIC_SCHEMA,
  PBI_ADVANCED_SCHEMA: process.env.REACT_APP_PBI_ADVANCED_SCHEMA,
  APP_LOGO: process.env.REACT_APP_LOGO,
  APP_LOGO_LIGHT: process.env.REACT_APP_LOGO_LIGHT,
  APP_FAVICON: process.env.REACT_APP_FAVICON,
  APP_URL: process.env.REACT_APP_URL,
  ENCRYPTION: process.env.REACT_APP_URL_ENCRYPTION,
  CHAT_ENABLED: process.env.REACT_APP_CHAT_ENABLED,
  TIMEOUT_DELAY: process.env.REACT_APP_TIMEOUT_DELAY,
  ENABLE_BULK_UPLOAD: process.env.REACT_APP_ENABLE_BULK_UPLOAD,
  ALLOW_EXTENDED_FORECASTS: process.env.REACT_APP_ALLOW_EXTENDED_FORECASTS,
  KC_DOMAIN: process.env?.REACT_APP_KC_DOMAIN,
  KC_REALM: process.env?.REACT_APP_KC_REALM,
  KC_CLIENT_ID: process.env?.REACT_APP_CLIENT_ID,
  KC_PKCE_CHALLENGE_METHOD: process.env?.REACT_APP_KC_PKCE_CHALLENGE_METHOD,
  KC_PKCE_VERIFIER_LENGTH: parseInt(process.env?.REACT_APP_KC_PKCE_VERIFIER_LENGTH, 10),
  PBI_EMBED_DEBUG_MODE: process.env?.REACT_APP_PBI_EMBED_DEBUG_MODE,
  AZURE_CDN_URL: process.env?.REACT_APP_AZURE_CDN_URL,
  FARO_URL: process.env?.REACT_APP_FARO_URL,
  FARO_ENV: process.env?.REACT_APP_FARO_ENV,
  ENABLE_FARO: process.env?.REACT_APP_ENABLE_FARO,
  USER_MANUAL_URL: process.env?.REACT_APP_USER_MANUAL_URL,
};
//
export default ENVIRONMENT;
