import { Fade } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTheme } from '@emotion/react';
import { TABLE_HEADER_FONT_COLOR } from 'modules/common/constants/style';
import { useSelector } from 'react-redux';
import { selectMetricsRows } from 'modules/rate-calendar/selectors';
import useMetrics from 'modules/rate-calendar/hooks/use-metrics';
/**
 * Metrics table column is defined here
 */
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    hide: true,
  },
  {
    field: 'metrics',
    headerName: 'Metrics',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'value',
    headerName: 'Value',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'previousValue',
    headerName: 'Previous Year Value',
    headerClassName: 'super-app-theme--header',
    flex: 1.5,
    align: 'right',
    headerAlign: 'right',
  },
];
/**
 * Metric table view define here
 * @param {*} param0
 * @returns
 */
const MetricsTableView = ({ event, value }) => {
  //
  useMetrics(event);
  //
  const rows = useSelector(selectMetricsRows);
  //
  return (
    value === 0 && (
      <Fade in={value === 0} {...{ timeout: 500 }}>
        <div
          style={{
            height: 500,
            flex: 1,
            marginTop: 10,
          }}
        >
          <DataGrid
            sx={{
              borderBottom: 'none',
              '& .super-app-theme--header': {
                backgroundColor: 'white',
                color: TABLE_HEADER_FONT_COLOR,
              },
              '& .MuiDataGrid-main': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rowHeight={49}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            pageSize={9}
            hideFooter
          />
        </div>
      </Fade>
    )
  );
};
//
export default withTheme(MetricsTableView);
