import { Backdrop, CircularProgress } from '@mui/material';
/**
 * Loader common component that uses across modules for indicate loading state
 * @param children - child component which needs to wrap from the loader
 * @param loading - loading state
 */
const Loader = ({ children, loading }) => (
  <>
    <Backdrop
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#00000066',
        display: 'flex',
      })}
      open={loading}
    >
      <CircularProgress thickness={4} />
    </Backdrop>
    {children}
  </>
);
//
export default Loader;
