export const paceHotelDailyPreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(15).fill(12)],
    end: 'Q',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceHotelCumulativeLagAlignedPreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(18).fill(12)],
    end: 'T',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceHotelCumulativePreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(18).fill(12)],
    end: 'Q',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
//
export const paceHotelLagAlinedPreset = {
  rows: {
    header: [10, 11],
  },
  columns: {
    width: [25, 10, ...Array(15).fill(12)],
    end: 'Q',
  },
  table: {
    start: 10,
    end: 44,
    dataStart: 12,
  },
  totalrow: false,
  bordered: true,
};
