import { TABULAR_VIEWS, UiController, events } from 'modules/common/ui-controller';
import {
  PACE_HORIZON_SELECTION,
  REPORT_TYPE,
  RESET_BOOKING_DATE,
} from 'modules/dashboard/constants';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paceActions } from '../slice';
import { setCompareWindowCommandValue } from '../../inner-filter/functions';
import {
  selectActivePaceTab,
  selectAdrIsDisplayPH,
  selectAdrIsDisplayPS,
  selectAdrIsDisplayPT,
  selectCxlCountIsDisplay,
  selectCxlIsDisplayPH,
  selectCxlIsDisplayPS,
  selectCxlIsDisplayPT,
  selectOccupiedRoomsIsDisplayPH,
  selectOccupiedRoomsIsDisplayPS,
  selectOccupiedRoomsIsDisplayPT,
  selectPaceBookingPaceWindow,
  selectRevenueIsDisplayPH,
  selectRevenueIsDisplayPS,
  selectRevenueIsDisplayPT,
} from '../selectors';

/**
 * Custom hook to execute pace time widget filter command flow in pace tab
 * @param {Boolean} isLoaded
 * @param {Array} paceList
 * @param {Array} percentageList
 * @param {Array} visualFilters
 * @param {Boolean} customBooking
 * @param {String} dateComparison
 * @param {Function} handleComparisonChange
 * @param {Function} handleToggleChange
 */
const usePaceTimeCommandFlow = (
  isLoaded,
  paceList,
  percentageList,
  visualFilters,
  customBooking,
  dateComparison,
  handleComparisonChange,
  handleToggleChange
) => {
  const dispatch = useDispatch();
  //
  const urlOrder = useSelector(selectUrlOrder);
  //
  const activeTab = useSelector(selectActivePaceTab);
  const paceWindowSelection = useSelector(selectPaceBookingPaceWindow);

  const [paceHorizonCommandValue, setPaceHorizonCommandValue] = useState(null);
  const [lagAlignedCommandValue, setLagAlignedCommandValue] = useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [percentageValueCommand, setPercentageValueCommand] = useState(null);
  const [symmetricComparisonCollapseCommandValue, setSymmetricComparisonCollapseCommandValue] =
    useState(null);
  const [breakdownToggleCommandValue, setBreakdownToggleCommandValue] = useState(null);
  const [forecastCommandValue, setForecastCommandValue] = useState(null);
  const [visualCollapseCommandValue, setVisualCollapseCommandValue] = useState(null);
  const [activeTabCommandValue, setActiveTabCommandValue] = useState(null);
  const [customBookingCommandValue, setCustomBookingCommandValue] = useState(null);
  const [bookingPaceWindowValueCommand, setBookingPaceWindowValueCommand] = useState(null);
  const [tabularViewCommandValue, setTabularViewCommandValue] = useState(null);
  //
  const revenueIsDisplayPT = useSelector(selectRevenueIsDisplayPT);
  const occupiedRoomsIsDisplayPT = useSelector(selectOccupiedRoomsIsDisplayPT);
  const adrIsDisplayPT = useSelector(selectAdrIsDisplayPT);
  const cxlIsDisplayPT = useSelector(selectCxlIsDisplayPT);
  const cxlCountIsDisplay = useSelector(selectCxlCountIsDisplay);
  //
  const revenueIsDisplayPS = useSelector(selectRevenueIsDisplayPS);
  const occupiedRoomsIsDisplayPS = useSelector(selectOccupiedRoomsIsDisplayPS);
  const adrIsDisplayPS = useSelector(selectAdrIsDisplayPS);
  const cxlIsDisplayPS = useSelector(selectCxlIsDisplayPS);
  //
  const revenueIsDisplayPH = useSelector(selectRevenueIsDisplayPH);
  const occupiedRoomsIsDisplayPH = useSelector(selectOccupiedRoomsIsDisplayPH);
  const adrIsDisplayPH = useSelector(selectAdrIsDisplayPH);
  const cxlIsDisplayPH = useSelector(selectCxlIsDisplayPH);

  // Triggers when customBookingCommandValue, isLoaded change
  useEffect(() => {
    if (
      bookingPaceWindowValueCommand !== null &&
      customBookingCommandValue !== null &&
      isLoaded &&
      urlOrder === 4
    ) {
      if (bookingPaceWindowValueCommand === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON) {
        dispatch(paceActions.setPaceBookingPaceWindow(PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON));
        dispatch(paceActions.setPaceBookingDate(RESET_BOOKING_DATE));
        setBookingPaceWindowValueCommand(null);
        dispatch(dashboardActions.setUrlOrder(5));
        setCustomBookingCommandValue(null);
      } else {
        dispatch(paceActions.setPaceBookingPaceWindow(PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW));
        dispatch(dashboardActions.setUrlOrder(5));
      }
    }
  }, [customBookingCommandValue, bookingPaceWindowValueCommand, isLoaded, urlOrder]);
  // Triggers when customBookingCommandValue,bookingPaceWindowValueCommand, and paceWindowSelection,  isLoaded change
  useEffect(() => {
    if (
      bookingPaceWindowValueCommand !== null &&
      customBookingCommandValue !== null &&
      paceWindowSelection === PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW &&
      bookingPaceWindowValueCommand === PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW &&
      isLoaded &&
      urlOrder === 5
    ) {
      // emit set custom booking date range event
      UiController.setBookingDateRange(customBookingCommandValue);
      setCustomBookingCommandValue(null);
      setBookingPaceWindowValueCommand(null);
      dispatch(dashboardActions.setUrlOrder(6));
    }
  }, [
    customBookingCommandValue,
    bookingPaceWindowValueCommand,
    paceWindowSelection,
    isLoaded,
    urlOrder,
  ]);

  // Triggers when paceHorizonCommandValue, isLoaded, paceList change
  useEffect(() => {
    if (paceHorizonCommandValue !== null && isLoaded && paceList?.length > 0 && urlOrder === 5) {
      // get pace horizon value
      const paces = paceList?.filter((pace) => pace?.label[0].includes(paceHorizonCommandValue));
      if (paces?.length > 0) {
        dispatch(dashboardActions.setPaceHorizon(paces[0]?.id));
      }
      setPaceHorizonCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(6));
    }
  }, [paceHorizonCommandValue, isLoaded, paceList?.length, urlOrder]);
  // Triggers when lagAlignedCommandValue, isLoaded change
  useEffect(() => {
    if (lagAlignedCommandValue !== null && isLoaded && urlOrder === 1) {
      // set lag aligned toggle value
      dispatch(paceActions.setPaceLagAligned(lagAlignedCommandValue === 1));
      setLagAlignedCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(2));
    }
  }, [lagAlignedCommandValue, isLoaded, urlOrder]);
  // Triggers when percentageValueCommand, focusOnList, isLoaded values change
  useEffect(() => {
    if (percentageList?.length > 0 && percentageValueCommand && isLoaded && urlOrder === 15) {
      const option = percentageList?.filter((val) => val?.label?.includes(percentageValueCommand));
      if (option?.length > 0) {
        dispatch(paceActions.setPercentage(option[0]?.id));
      }
      setPercentageValueCommand(null);
      dispatch(dashboardActions.setUrlOrder(16));
    }
  }, [percentageValueCommand, percentageList?.length, isLoaded, urlOrder]);
  // Triggers when alignmentCommandValue, visualFilters, isLoaded values change
  useEffect(() => {
    if (alignmentCommandValue !== null && visualFilters && isLoaded && urlOrder === 8) {
      // set alignment toggle value
      handleToggleChange({
        target: {
          checked: alignmentCommandValue,
        },
      });
      setAlignmentCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(9));
    }
  }, [alignmentCommandValue, visualFilters, isLoaded, urlOrder]);
  // Triggers when compareWindowValueCommand, customBooking, dateComparison, isLoaded values change
  useEffect(() => {
    if (dateComparison && compareWindowValueCommand && isLoaded && urlOrder === 13) {
      setCompareWindowCommandValue(
        compareWindowValueCommand,
        customBooking,
        handleComparisonChange
      );
      setCompareWindowValueCommand(null);
      dispatch(dashboardActions.setUrlOrder(14));
    }
  }, [compareWindowValueCommand, customBooking, dateComparison, isLoaded, urlOrder]);
  // Triggers when symmetricComparisonCollapseCommandValue, isLoaded change
  useEffect(() => {
    if (symmetricComparisonCollapseCommandValue !== null && isLoaded && urlOrder === 10) {
      dispatch(
        paceActions.setSymmetricComparisonIsDisplay(symmetricComparisonCollapseCommandValue === 1)
      );
      setSymmetricComparisonCollapseCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(11));
    }
  }, [symmetricComparisonCollapseCommandValue, isLoaded, urlOrder]);
  // Triggers when breakdownToggleCommandValue, isLoaded change
  useEffect(() => {
    if (breakdownToggleCommandValue !== null && isLoaded && urlOrder === 11) {
      dispatch(paceActions.setIsPrimary(breakdownToggleCommandValue === 1));
      setBreakdownToggleCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(12));
    }
  }, [breakdownToggleCommandValue, isLoaded, urlOrder]);
  // Triggers when forecastCommandValue and isLoaded value change
  useEffect(() => {
    if (forecastCommandValue !== null && isLoaded && urlOrder === 14) {
      dispatch(paceActions.setIsPaceForecast(forecastCommandValue === 1));
      setForecastCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(15));
    }
  }, [forecastCommandValue, isLoaded, urlOrder]);
  // Triggers when visualCollapseCommandValue, activeTabCommandValue, isPrimary  and isLoaded value change
  useEffect(() => {
    if (
      visualCollapseCommandValue !== null &&
      isLoaded &&
      activeTabCommandValue !== null &&
      String(activeTabCommandValue) === activeTab &&
      urlOrder === 100
    ) {
      if (activeTab === REPORT_TYPE.PACE_SEGMENT_WIDGET && activeTabCommandValue === activeTab) {
        dispatch(paceActions.setRevenueIsDisplayPS(false));
        dispatch(paceActions.setOccupiedRoomIsDisplayPS(false));
        dispatch(paceActions.setAdrIsDisplayPS(false));
        dispatch(paceActions.setCxlIsDisplayPS(false));
        dispatch(paceActions.setCxlCountIsDisplay(false));
        setTimeout(() => {
          dispatch(dashboardActions.setUrlOrder(101));
        }, 2000);
      }

      if (activeTab === REPORT_TYPE.PACE_TIME_WIDGET && activeTabCommandValue === activeTab) {
        dispatch(paceActions.setRevenueIsDisplayPT(false));
        dispatch(paceActions.setOccupiedRoomIsDisplayPT(false));
        dispatch(paceActions.setAdrIsDisplayPT(false));
        dispatch(paceActions.setCxlIsDisplayPT(false));
        dispatch(paceActions.setCxlCountIsDisplay(false));
        setTimeout(() => {
          dispatch(dashboardActions.setUrlOrder(101));
        }, 2000);
      }
      if (activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET && activeTabCommandValue === activeTab) {
        dispatch(paceActions.setRevenueIsDisplayPH(false));
        dispatch(paceActions.setOccupiedRoomIsDisplayPH(false));
        dispatch(paceActions.setAdrIsDisplayPH(false));
        dispatch(paceActions.setCxlIsDisplayPH(false));
        dispatch(paceActions.setCxlCountIsDisplay(false));
        setTimeout(() => {
          dispatch(dashboardActions.setUrlOrder(101));
        }, 2000);
      }
    }
  }, [visualCollapseCommandValue, activeTabCommandValue, activeTab, isLoaded, urlOrder]);

  useEffect(() => {
    if (
      visualCollapseCommandValue !== null &&
      activeTabCommandValue !== null &&
      String(activeTabCommandValue) === activeTab &&
      urlOrder === 101
    ) {
      const visuals = visualCollapseCommandValue?.split(',');
      visuals?.forEach((visual) => {
        // get the visual collapse enable/disable status
        const status = parseInt(visual?.substring(1), 10);
        // get the type of visual view
        const visualName = visual?.substring(0, 1);
        if (visualName && status !== undefined) {
          if (
            activeTab === REPORT_TYPE.PACE_SEGMENT_WIDGET &&
            activeTabCommandValue === activeTab &&
            (revenueIsDisplayPS &&
              occupiedRoomsIsDisplayPS &&
              adrIsDisplayPS &&
              cxlIsDisplayPS &&
              cxlCountIsDisplay) === false
          ) {
            switch (visualName) {
              case TABULAR_VIEWS.TOTAL_REVENUE:
                dispatch(paceActions.setRevenueIsDisplayPS(status === 1));
                break;
              case TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS:
                dispatch(paceActions.setOccupiedRoomIsDisplayPS(status === 1));
                break;
              case TABULAR_VIEWS.ADR:
                dispatch(paceActions.setAdrIsDisplayPS(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS:
                dispatch(paceActions.setCxlIsDisplayPS(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS_COUNT:
                dispatch(paceActions.setCxlCountIsDisplay(status === 1));
                break;
              default:
                break;
            }
            setVisualCollapseCommandValue(null);
            setActiveTabCommandValue(null);
            dispatch(dashboardActions.setUrlOrder(102));
          }
          if (
            activeTab === REPORT_TYPE.PACE_TIME_WIDGET &&
            activeTabCommandValue === activeTab &&
            (revenueIsDisplayPT &&
              occupiedRoomsIsDisplayPT &&
              adrIsDisplayPT &&
              cxlIsDisplayPT &&
              cxlCountIsDisplay) === false
          ) {
            switch (visualName) {
              case TABULAR_VIEWS.TOTAL_REVENUE:
                dispatch(paceActions.setRevenueIsDisplayPT(status === 1));
                break;
              case TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS:
                dispatch(paceActions.setOccupiedRoomIsDisplayPT(status === 1));
                break;
              case TABULAR_VIEWS.ADR:
                dispatch(paceActions.setAdrIsDisplayPT(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS:
                dispatch(paceActions.setCxlIsDisplayPT(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS_COUNT:
                dispatch(paceActions.setCxlCountIsDisplay(status === 1));
                break;
              default:
                break;
            }
            setVisualCollapseCommandValue(null);
            setActiveTabCommandValue(null);
            dispatch(dashboardActions.setUrlOrder(102));
          }
          if (
            activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET &&
            activeTabCommandValue === activeTab &&
            (revenueIsDisplayPH &&
              occupiedRoomsIsDisplayPH &&
              adrIsDisplayPH &&
              cxlIsDisplayPH &&
              cxlCountIsDisplay) === false
          ) {
            switch (visualName) {
              case TABULAR_VIEWS.TOTAL_REVENUE:
                dispatch(paceActions.setRevenueIsDisplayPH(status === 1));
                break;
              case TABULAR_VIEWS.TOTAL_OCCUPIEDROOMS:
                dispatch(paceActions.setOccupiedRoomIsDisplayPH(status === 1));
                break;
              case TABULAR_VIEWS.ADR:
                dispatch(paceActions.setAdrIsDisplayPH(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS:
                dispatch(paceActions.setCxlIsDisplayPH(status === 1));
                break;
              case TABULAR_VIEWS.CANCELLATIONS_COUNT:
                dispatch(paceActions.setCxlCountIsDisplay(status === 1));
                break;
              default:
                break;
            }
            setVisualCollapseCommandValue(null);
            setActiveTabCommandValue(null);
            dispatch(dashboardActions.setUrlOrder(102));
          }
        }
      });
    }
  }, [
    visualCollapseCommandValue,
    activeTabCommandValue,
    activeTab,
    urlOrder,
    revenueIsDisplayPT,
    occupiedRoomsIsDisplayPT,
    adrIsDisplayPT,
    cxlIsDisplayPT,
    cxlCountIsDisplay,
    revenueIsDisplayPS,
    occupiedRoomsIsDisplayPS,
    adrIsDisplayPS,
    cxlIsDisplayPS,
    revenueIsDisplayPH,
    occupiedRoomsIsDisplayPH,
    adrIsDisplayPH,
    cxlIsDisplayPH,
  ]);
  // Triggers when tabularViewCommandValue and isLoaded states change
  useEffect(() => {
    if (tabularViewCommandValue !== null && isLoaded && urlOrder === 16) {
      // tabular view command flow
      dispatch(paceActions.setTabularView(Number(tabularViewCommandValue) === 1));
      setTabularViewCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(18));
    }
  }, [tabularViewCommandValue, isLoaded, urlOrder]);
  // Listener function ot pace horizon command value
  const setPaceHorizonHandler = (value) => {
    setPaceHorizonCommandValue(value);
  };
  // Listener function set pace variable command value
  const setLagAlignedHandler = (value) => {
    setLagAlignedCommandValue(value);
  };
  // Listener function to set alignment value
  const setPaceAlignmentToggleHandler = (value) => {
    setAlignmentCommandValue(value);
  };
  // Listener function to set compare window command value
  const setCompareWindowValue = (data) => {
    setCompareWindowValueCommand(data);
  };
  // Listener function to set compare window command value
  const setPercentageValue = (data) => {
    setPercentageValueCommand(data);
  };
  // Listener function to set symmetric comparison collapse value
  const setSymmetricComparisonCollapseValue = (data) => {
    setSymmetricComparisonCollapseCommandValue(data);
  };
  // Listener function to set breakdown toggle value
  const setBreakdownToggleValue = (data) => {
    setBreakdownToggleCommandValue(data);
  };
  // Listener function to set forecast toggle value
  const setForecastToggleValueHandler = (value) => {
    setForecastCommandValue(value);
  };
  // Listener function to set visual collapse values
  const setVisualCollapseValue = (data) => {
    setVisualCollapseCommandValue(data);
  };
  // Listener function to set active tab value
  const setActiveTrendTabValue = (data) => {
    setActiveTabCommandValue(data);
  };
  // Listener function to set custom booking date range command
  const setPaceCustomBookingHandler = (value) => {
    setCustomBookingCommandValue(value);
  };
  // Listener function to set booking pace window value
  const setBookingPaceWindowHandler = (value) => {
    setBookingPaceWindowValueCommand(value);
  };
  // Listener function to set tabular view value
  const setPaceTabularViewValue = (value) => {
    setTabularViewCommandValue(value);
  };
  // Add Listener function to set pace horizon command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_PACE_HORIZON, setPaceHorizonHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_PACE_HORIZON, setPaceHorizonHandler);
    };
  }, []);
  // Add Listener function to set lag aligned command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_LAG_ALIGNED, setLagAlignedHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_LAG_ALIGNED, setLagAlignedHandler);
    };
  }, []);
  // Add event listener function to set alignment command value
  useEffect(() => {
    UiController.subscribe(events.PACE_ALIGNMENT, setPaceAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.PACE_ALIGNMENT, setPaceAlignmentToggleHandler);
    };
  }, []);
  // Add event listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_COMPARE_WINDOW, setCompareWindowValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_COMPARE_WINDOW, setCompareWindowValue);
    };
  }, []);
  // Add event listener function to set percentage command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_PERCENTAGE, setPercentageValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_PERCENTAGE, setPercentageValue);
    };
  }, []);
  // Add Listener function to set symmetric comparison collapse
  useEffect(() => {
    UiController.subscribe(
      events.SET_PACE_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_PACE_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseValue
      );
    };
  }, []);
  // Add Listener function to set breakdown toggle
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_BREAKDOWN_TOGGLE, setBreakdownToggleValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_BREAKDOWN_TOGGLE, setBreakdownToggleValue);
    };
  }, []);
  // Add event listener function to set forecast toggle command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_FORECAST, setForecastToggleValueHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_FORECAST, setForecastToggleValueHandler);
    };
  }, []);
  // Add Listener function to set visual collapse
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_VISUAL_COLLAPSE, setVisualCollapseValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_VISUAL_COLLAPSE, setVisualCollapseValue);
    };
  }, []);
  // Add Listener function to set active trend tab
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_ACTIVE_TAB, setActiveTrendTabValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_ACTIVE_TAB, setActiveTrendTabValue);
    };
  }, []);
  // Add Listener function to set custom booking command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_CUSTOM_BOOKING, setPaceCustomBookingHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_CUSTOM_BOOKING, setPaceCustomBookingHandler);
    };
  }, []);
  // Add Listener function to set booking pace window command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_BOOKING_PACE_WINDOW, setBookingPaceWindowHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_BOOKING_PACE_WINDOW, setBookingPaceWindowHandler);
    };
  }, []);
  // Add event listener function to set tabular view toggle
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_TABULAR_VIEW, setPaceTabularViewValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_TABULAR_VIEW, setPaceTabularViewValue);
    };
  }, []);
};

export default usePaceTimeCommandFlow;
