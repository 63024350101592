import { UiController } from 'modules/common/ui-controller';
import {
  CHAT_ROLES,
  EXECUTION_TYPE,
  consoleCommands,
} from 'modules/common/ui-controller/utils/constants';
import {
  executeMultipleCommands,
  setHotelCommand,
  setTabCommand,
} from 'modules/common/ui-controller/utils/functions';
import { setUrlTailAndChatMessage } from 'modules/common/utils/chat-util';
import { selectHotelChangeState, selectTabChangeState } from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/**
 * Custom hook function to handle chat flow functions
 * @param {String} streamData - chat response
 * @param {Function} setStreamData -  Function to set chat response data
 * @param {String} commandSet - direct command url set
 * @param {Function} setCommandSet - Function to set direct command
 * @param {Function} setChatState - Function to set chat message array
 * @param {Array} chatState - chat message array
 * @param {Boolean} isOpen - chat console open/close state
 * @param {Function} setMsg - Function to set input field
 */
const useChat = (
  streamData,
  setStreamData,
  commandSet,
  setCommandSet,
  setChatState,
  chatState,
  isOpen,
  setMsg
) => {
  const dispatch = useDispatch();
  // global selectors
  const tabChange = useSelector(selectTabChangeState);
  const hotelChange = useSelector(selectHotelChangeState);
  // local states
  const [url, setUrl] = useState('');
  const [chatMsg, setChatMsg] = useState('');
  // triggers when streamData state value changes
  useEffect(() => {
    if (streamData) {
      // reset tab and hotel change states
      dispatch(dashboardActions.setTabChange(false));
      dispatch(dashboardActions.setHotelChange(false));
      setUrl('');
      setChatMsg('');
      // check stream data string includes "URL_TAIL==" substring
      setUrlTailAndChatMessage(streamData, setUrl, setChatMsg);
    }
  }, [streamData]);
  // triggers when chatMsg value changes
  useEffect(() => {
    if (chatMsg) {
      // add system msg into chat state array
      setChatState([
        ...chatState,
        {
          message: chatMsg,
          role: CHAT_ROLES.SYSTEM,
        },
      ]);
    }
  }, [chatMsg]);
  // triggers when hotelChange, tabChange and url values change
  useEffect(() => {
    // if hotel and tab commands are executed, execute rest of the commands
    if (hotelChange && tabChange && url) {
      try {
        // execute multiple commands
        executeMultipleCommands(url, EXECUTION_TYPE.CONVERSATION);
      } catch (err) {
        UiController.onError(err);
      }
    }
  }, [tabChange, hotelChange, url]);
  // triggers when url value changes
  useEffect(() => {
    if (url) {
      // execute the command flow when url set
      // checks if tab command includes and set tab
      if (url?.includes(consoleCommands.TAB)) {
        setTabCommand(url, EXECUTION_TYPE.CONVERSATION);
      } else {
        dispatch(dashboardActions.setTabChange(true));
      }
      // checks if hotel command includes and set hotel
      if (url?.includes(consoleCommands.HOTEL) || url?.includes(consoleCommands.HOTEL_GROUP)) {
        setHotelCommand(url, EXECUTION_TYPE.CONVERSATION);
      } else {
        dispatch(dashboardActions.setHotelChange(true));
      }
    }
  }, [url]);
  // triggers when hotelChange, tabChange and commandSet values change
  useEffect(() => {
    if (hotelChange && tabChange && commandSet) {
      executeMultipleCommands(commandSet, EXECUTION_TYPE.CONVERSATION);
      setCommandSet('');
    }
  }, [tabChange, hotelChange, commandSet]);
  // triggers when open the console
  useEffect(() => {
    // reset chat console options
    if (isOpen) {
      setChatState([]);
      setUrl('');
      setMsg('');
      setCommandSet('');
      setChatMsg('');
      setStreamData('');
    }
  }, [isOpen]);
};

export default useChat;
