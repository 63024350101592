import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';
/**
 * Getting entire rate calendar upload state into the component
 */
const selectDomain = (state) => state[SLICE.RATE_CALENDAR_UPLOAD] || initialState;
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector(
  [selectDomain],
  (rateCalendarUploadState) => rateCalendarUploadState.loading
);
/**
 * Getting entire rate calendar upload forced upload confirmation state into the component
 */
export const selectForceUploadConfirmation = createSelector(
  [selectDomain],
  (rateCalendarUploadState) => rateCalendarUploadState?.forceUploadConfirmation
);
