/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { signInActions } from 'modules/sign-in/slice';
/**
 * Define the the rate calendar upload feature initial state
 */
export const initialState = {
  loading: false,
  forceUploadConfirmation: false,
};
/**
 * Here mentioned all the rate calendar upload feature related actions
 */
export const rateCalendarUploadSlice = createSlice({
  name: SLICE.RATE_CALENDAR_UPLOAD,
  initialState,
  reducers: {
    rateCalendarUpload(state) {
      state.loading = true;
    },
    rateCalendarUploadSucceeded(state) {
      state.loading = false;
    },
    rateCalendarUploadFailed(state, action) {
      state.loading = false;
      state.forceUploadConfirmation = action?.payload?.message === 'duplicate data found';
    },
    rateCalendarForceUpload(state) {
      state.loading = true;
    },
    rateCalendarForceUploadSucceeded(state) {
      state.loading = false;
      state.forceUploadConfirmation = false;
    },
    rateCalendarForceUploadFailed(state) {
      state.loading = false;
      state.forceUploadConfirmation = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});
//
export const { actions: rateCalendarUploadActions } = rateCalendarUploadSlice;
