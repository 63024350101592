import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { CheckboxDropdown, CustomStack, CustomToggleSwitch } from 'modules/common/components';
import ActionButton from 'modules/common/components/action-button';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { CustomAggregation } from '../custom-aggregation';
/**
 * Breakdown window component common for the dashboard component
 * @param {Boolean} isDetailed - State of Detailed toggle
 * @param {*} chartBreakdown - Selected chart breakdown value
 * @param {Array} chartBreakdownList - chart breakdown list
 * @param {Function} handleIsDetailedChange -  Function to set isDetailed state change
 * @param {Function} handleBreakdownChange -  Function to set breakdown state change
 * @param {Boolean} isFocusOnAvailable - state of focus on section hide or show
 * @param {Array} focusOnList - focus on list
 * @param {*} focusOn - Selected focus on value
 * @param {*} focusOnLabel - focus on section label
 *  @param {Function} handleFocusOnChange -  Function to set focus on state change
 * @returns
 */
const BreakdownWindow = ({
  isDetailed,
  chartBreakdown,
  chartBreakdownList,
  handleIsDetailedChange,
  handleBreakdownChange,
  isFocusOnAvailable,
  focusOnList = [],
  focusOn = [],
  focusOnLabel = '',
  handleFocusOnChange = () => {},
  isGroupingAvailable = false,
  onGroupClick = () => {},
  setOpen = () => {},
  openGroupingDialog = false,
  showFilterName = false,
  setAName = '',
  setBName = '',
  selectedFilterName = '',
  isDeAggregateToggle = false,
  isTrendDeAggregate = false,
  isTrendDeAggregateDisabled = false,
  handleIsDeAggregateToggleChange = () => {},
  id = '',
  allSelected = false,
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ color: '#0009' }}>Breakdown</Typography>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={isDetailed}
                onChange={handleIsDetailedChange}
                id={`${id}-is-detailed`}
              />
            }
            sx={{ '& .css-1cn7wjw-MuiTypography-root': { fontSize: 12 } }}
            label="Details"
          />
        </FormControl>
      </Grid>
      <RadioGroup
        value={chartBreakdown ?? null}
        onChange={handleBreakdownChange}
        id={`${id}-breakdown`}
      >
        {chartBreakdownList?.map((item) => (
          <FormControlLabel
            key={item?.id}
            value={item?.id}
            control={<Radio />}
            label={item?.label}
            sx={{
              my: -1,
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
    {isFocusOnAvailable && (
      <FormControl>
        <CheckboxDropdown
          options={focusOnList}
          values={focusOn}
          label={focusOnLabel}
          onchange={handleFocusOnChange}
          isOptionEqualTo={(option, value) => option?.id === value?.id}
          id={id ? `${id}-focus-on` : ''}
          allSelected={allSelected}
        />
      </FormControl>
    )}
    {isDetailed && isGroupingAvailable && (
      <>
        <ActionButton
          id="auto-trend-custom-grouping"
          label="Custom Grouping"
          onClick={onGroupClick}
        />
        <CustomAggregation
          open={openGroupingDialog}
          onClose={() => setOpen(false)}
          focusOnList={focusOnList}
          breakdown={chartBreakdown}
        />
        {showFilterName && selectedFilterName && (
          <Typography id="auto-trend-filter-name">Custom Grouping: {selectedFilterName}</Typography>
        )}
        {showFilterName && setAName && <Typography>Group A: {setAName}</Typography>}
        {showFilterName && setBName && <Typography>Group B: {setBName}</Typography>}
      </>
    )}
    {isDeAggregateToggle && (
      <CustomToggleSwitch
        label="Segment-wise Breakdown"
        title=""
        onChange={handleIsDeAggregateToggleChange}
        checked={isTrendDeAggregate ?? false}
        disabled={isTrendDeAggregateDisabled}
      />
    )}
  </CustomStack>
);
//
export default BreakdownWindow;
