import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * This custom hook help for getting query string data into the component
 * @returns
 */
export default function useQuery() {
  const { search } = useLocation();
  //
  return useMemo(() => new URLSearchParams(search), [search]);
}
