/* eslint-disable import/named */
import { EXECUTION_TYPE, UiController, breakdownList, events } from 'modules/common/ui-controller';
import { getTrendValuesFromList, getValuesFromList, isIncluded } from 'modules/common/utils/array';
import { dashboardActions } from 'modules/dashboard/slice';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trendActions } from '../slice';
/**
 * Custom hook to execute trend segment filter command flow
 * @param {Boolean} isLoaded
 * @param {Boolean} customBooking
 * @param {Array} chartBreakdownList
 * @param {Function} resetOptions
 * @param {Array} focusOnList
 * @param {Boolean} isDetailed
 */
const useTrendSegmentCommandFlow = (
  isLoaded,
  customBooking,
  chartBreakdownList,
  resetOptions,
  focusOnList,
  isDetailed
) => {
  const dispatch = useDispatch();
  //
  const urlOrder = useSelector(selectUrlOrder);
  //
  const [options, setOptions] = useState(null);
  const [breakDownValueOptions, setBreakDownValueOptions] = useState(null);
  const [customAValue, setCustomA] = useState(null);
  const [customBValue, setCustomB] = useState(null);

  // set breakdown command value
  const setTrendSegmentBreakdownHandler = (value) => {
    // get breakdown option
    const breakdownOption = breakdownList[value?.substring(0, 2)];
    // get isDetailed status from command value
    const detailsStatus = parseInt(value?.substring(2, 4), 10);
    if (breakdownOption !== undefined) {
      const option = chartBreakdownList?.filter((val) => val?.label?.includes(breakdownOption));
      if (option?.length > 0) {
        dispatch(trendActions.setTrendSegmentChartBreakdown(option[0]?.id));
      }
      // set Detailed toggle state
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(detailsStatus)) {
        dispatch(trendActions.setIsSegmentDetailed(detailsStatus === 1));
      }
      //
      resetOptions();
    }
    setBreakDownValueOptions(null);
    dispatch(dashboardActions.setUrlOrder(4));
  };
  // set focus on command value
  const setTrendSegmentFocusOnHandler = (data) => {
    // get corresponding focus on value and execute dispatch function
    const optionsSelected = getTrendValuesFromList(data, focusOnList);
    if (optionsSelected?.length > 0) {
      dispatch(trendActions.setTrendSegmentFocusOn(optionsSelected));
    } else {
      dispatch(trendActions.setTrendSegmentFocusOn([]));
    }
    setOptions(null);
    dispatch(dashboardActions.setUrlOrder(16));
  };
  // Triggers when options, focusOnList, isLoaded values change
  useEffect(() => {
    if (
      focusOnList.length > 0 &&
      options !== null &&
      isIncluded(focusOnList, options) &&
      isLoaded &&
      urlOrder === 15
    ) {
      setTimeout(() => {
        setTrendSegmentFocusOnHandler(options);
      }, 2000);
    } else if (
      focusOnList.length > 0 &&
      options !== null &&
      options?.length === 1 &&
      options[0] === EXECUTION_TYPE.EMPTY &&
      isLoaded &&
      urlOrder === 15
    ) {
      setTimeout(() => {
        dispatch(trendActions.setTrendSegmentFocusOn([]));
        setOptions(null);
        dispatch(dashboardActions.setUrlOrder(16));
      }, 2000);
    }
  }, [options, focusOnList?.length, isLoaded, urlOrder]);
  // Triggers when breakDownValueOptions, chartBreakdownList, isLoaded values change
  useEffect(() => {
    if (
      chartBreakdownList.length > 0 &&
      breakDownValueOptions !== null &&
      isLoaded &&
      urlOrder === 3
    ) {
      setTrendSegmentBreakdownHandler(breakDownValueOptions);
    }
  }, [breakDownValueOptions, chartBreakdownList?.length, isLoaded, urlOrder]);
  // Listener function to set focus on value
  const setFocusOnValue = (data) => {
    setOptions(data);
  };
  // Listener function to set breakdown command value
  const setBreakDownValue = (data) => {
    setBreakDownValueOptions(data);
  };
  // Listener function to set custom aggregation command values
  const setAggregationAValue = (value) => {
    setCustomA(value);
  };
  // Listener function to set custom aggregation command values
  const setAggregationBValue = (value) => {
    setCustomB(value);
  };
  // Triggers when customAValue , focusOnList and isLoaded values change
  useEffect(() => {
    if (customAValue !== null && focusOnList && isLoaded && isDetailed && urlOrder === 15) {
      // get focus on options for setA
      const optionsSelectedA = getValuesFromList(customAValue, focusOnList);
      if (optionsSelectedA?.length === customAValue?.length) {
        dispatch(trendActions.setSegmentGroup1(optionsSelectedA));
        setCustomA(null);
      }
    }
  }, [customAValue, isLoaded, focusOnList, isDetailed, urlOrder]);
  // Triggers when  customBValue, focusOnList and isLoaded values change
  useEffect(() => {
    if (customBValue !== null && focusOnList && isLoaded && isDetailed && urlOrder === 15) {
      // get focus on options for setB
      const optionsSelectedB = getValuesFromList(customBValue, focusOnList);
      if (optionsSelectedB?.length === customBValue?.length) {
        dispatch(trendActions.setSegmentGroup2(optionsSelectedB));
        setCustomB(null);
      }
    }
  }, [customBValue, isLoaded, focusOnList, isDetailed, urlOrder]);

  // Add event listener function to set breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_SEGMENT_BREAKDOWN, setBreakDownValue);
    return () => {
      UiController.unsubscribe(events.SET_SEGMENT_BREAKDOWN, setBreakDownValue);
    };
  }, []);

  // Add event listener function to set focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_TREND_SEGMENT_FOCUS_ON, setFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_TREND_SEGMENT_FOCUS_ON, setFocusOnValue);
    };
  }, []);

  // Add event listener function to set tabular view command value
  useEffect(() => {
    UiController.subscribe(events.SET_CUSTOM_AGGREGATION_B_VALUE, setAggregationBValue);
    UiController.subscribe(events.SET_CUSTOM_AGGREGATION_A_VALUE, setAggregationAValue);
    return () => {
      UiController.unsubscribe(events.SET_CUSTOM_AGGREGATION_A_VALUE, setAggregationAValue);
      UiController.unsubscribe(events.SET_CUSTOM_AGGREGATION_B_VALUE, setAggregationBValue);
    };
  }, []);
};
export default useTrendSegmentCommandFlow;
