import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { notificationActions } from 'modules/common/notifications/slice';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { addHotelValidation } from '../../validation/add-hotel-form-validation';
import { Alert, TextField } from './style';
/**
 * Modal component for add hotels
 * @param {*} open
 * @param {*} onClose
 * @returns
 */
const AddHotelFormDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  // reset notifications and close the modal
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  // toast notifications
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, {
        type: TOAST_TYPES.SUCCESS,
      });
      modalAction(false);
    }
  }, [notification]);
  //
  // eslint-disable-next-line no-unused-vars
  const onSubmitAddHotel = async (values) => {
    /**
     * Integrate add hotels api here
     */
  };
  // set initial values
  const initialValues = {
    roomTypeCode: '',
    roomType: '',
    roomCount: '',
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Hotel</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addHotelValidation}
          onSubmit={onSubmitAddHotel}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                name="roomTypeCode"
                value={values.roomTypeCode}
                error={Boolean(touched.roomTypeCode && errors.roomTypeCode)}
                helperText={touched.roomTypeCode && errors.roomTypeCode}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Room Type Code"
              />
              <TextField
                name="roomType"
                value={values.roomType}
                error={Boolean(touched.roomType && errors.roomType)}
                helperText={touched.roomType && errors.roomType}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Room Type"
              />
              <TextField
                name="roomCount"
                type="number"
                value={values.roomCount}
                error={Boolean(touched.roomCount && errors.roomCount)}
                helperText={touched.roomCount && errors.roomCount}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Number of Rooms"
              />
              <DialogActions>
                <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddHotelFormDialog;
