import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';
import { spacing } from '@mui/system';

export const Button = styled(MuiButton)(spacing);
//
export const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
