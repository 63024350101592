import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectTokenRevocation } from 'modules/dashboard/selectors';
import ROUTES from 'modules/common/constants/route';
/**
 * Pop up common component that uses across modules for display session timeout messages
 */
const TokenRevokedPopup = () => {
  const navigate = useNavigate();
  const isTokenRevoked = useSelector(selectTokenRevocation);
  //
  return (
    <Dialog
      open={isTokenRevoked ?? false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ borderRadius: 50 }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        Session Expired!
      </DialogTitle>
      <DialogContent id="alert-dialog-description" sx={{ textAlign: 'center', mx: 10 }}>
        You have been forcefully logged out from the system. Please login again to continue working.
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-around',
          pb: 5,
        }}
      >
        <Button
          sx={{ px: 10 }}
          autoFocus
          variant="contained"
          onClick={() => navigate(ROUTES.LOGIN)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
//
export default TokenRevokedPopup;
