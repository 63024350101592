import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
//
const selectDomain = (state) => state[SLICE.TREND] || initialState;
/**
 *  Getting trend time view alignment state to trend time component
 */
export const selectTrendTimeAlignment = createSelector(
  [selectDomain],
  (state) => state.trendTimeAlignment
);
/**
 *  Getting trend time view date comparison state to trend time component
 */
export const selectTrendTimeDateComparison = createSelector(
  [selectDomain],
  (state) => state.trendTimeDateComparison
);
/**
 *  Getting trend time view filter list state to trend time component
 */
export const selectTrendTimeFilterList = createSelector(
  [selectDomain],
  (state) => state.trendTimeFilterList
);
/**
 *  Getting trend time tabular view filter list state to trend time component
 */
export const selectTrendTimeTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.trendTimeTabularFilterList
);
/**
 *  Getting trend time view alignment toggle state to trend time component
 */
export const selectTrendTimeAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.trendTimeAlignmentToggle
);
/**
 *Getting is trend time custom date state for trend time component
 */
export const selectTrendTimeCustomDate = createSelector(
  [selectDomain],
  (state) => state?.trendTimeCustomDate
);
/**
 * Getting trend time weekly view state for trend time component
 */
export const selectTrendTimeWeeklyView = createSelector(
  [selectDomain],
  (state) => state.trendTimeWeeklyView
);
/**
 *Getting is trend time start date state for trend time component for custom date comparison
 */
export const selectTrendTimeStartDate = createSelector(
  [selectDomain],
  (state) => state?.trendTimeStartDate
);
/**
 *Getting is trend time end date state for trend time component for custom date comparison
 */
export const selectTrendTimeEndDate = createSelector(
  [selectDomain],
  (state) => state?.trendTimeEndDate
);
/**
 * Getting is trend time date selected state for trend time component for custom date comparison
 */
export const selectTrendTimeDateSelected = createSelector(
  [selectDomain],
  (state) => state?.trendTimeDateSelected
);
/**
 *  Getting generated trend time tab url
 */
export const selectTrendTimeUrl = createSelector([selectDomain], (state) => state.trendTimeUrl);
/**
 * Getting trend symmetric comparison toggle state for trend time component
 */
export const selectTrendSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.trendSymmetricComparisonToggle
);
/**
 * Getting total revenue, table view enable status into the trend time component
 */
export const selectTotalRevenueIsTable = createSelector(
  [selectDomain],
  (state) => state.totalRevenueIsTableView
);
/**
 * Getting total occupied rooms table view enable status into the trend time component
 */
export const selectTotalOccupiedRoomIsTable = createSelector(
  [selectDomain],
  (state) => state.totalOccupiedRoomIsTableView
);
/**
 * Getting average daily rate, table view enable status into the trend time component
 */
export const selectAverageDailyRateIsTable = createSelector(
  [selectDomain],
  (state) => state.averageDailyRateIsTableView
);
/**
 * Getting average booking window table enable status into the trend time component
 */
export const selectAverageBookingWindowIsTable = createSelector(
  [selectDomain],
  (state) => state.averageBookingWindowIsTableView
);
/**
 * Getting average length of stay table enable status into the trend time component
 */
export const selectAverageLengthOfStayIsTable = createSelector(
  [selectDomain],
  (state) => state.averageLengthOfStayIsTableView
);
/**
 * Getting number of cancellation table status into the trend time component
 */
export const selectNumberOfCancellationIsTable = createSelector(
  [selectDomain],
  (state) => state.numberOfCancellationIsTableView
);
/**
 * Getting reservation count table status into the trend time component
 */
export const selectReservationCountIsTable = createSelector(
  [selectDomain],
  (state) => state.reservationCountIsTableView
);
/**
 * Getting is cumulative state for trend time component
 */
export const selectIsCumulative = createSelector([selectDomain], (state) => state?.isCumulative);
/**
 * Getting is trend hotel aggregate state to dashboard component
 */
export const selectIsTrendHotelAggregate = createSelector(
  [selectDomain],
  (state) => state.isTrendHotelAggregate
);
/**
 *  Getting trend hotel view focus on state to dashboard component
 */
export const selectTrendHotelFocusOn = createSelector(
  [selectDomain],
  (state) => state.trendHotelFocusOn
);
/**
 * Getting is segment detailed  state for trend  component
 */
export const selectIsSegmentDetailed = createSelector(
  [selectDomain],
  (state) => state?.isSegmentDetailed
);
/**
 *  Getting segment set A name  state to trend  component
 */
export const selectSegmentSetAName = createSelector(
  [selectDomain],
  (state) => state.segmentSetAName
);
/**
 *  Getting segment set B name  state to trend  component
 */
export const selectSegmentSetBName = createSelector(
  [selectDomain],
  (state) => state.segmentSetBName
);
/**
 *  Getting selected filter name in to the component
 */
export const selectSelectedFilterName = createSelector(
  [selectDomain],
  (state) => state.selectedFilterName
);
/**
 * Getting show custom group filter name state into the trend  component
 */
export const selectShowCustomGroupingFilterName = createSelector(
  [selectDomain],
  (state) => state.showCustomGroupingFilterName
);
/**
 *  Getting trend segment view chart breakdown state to trend  component
 */
export const selectTrendSegmentChartBreakdown = createSelector(
  [selectDomain],
  (state) => state.trendSegmentChartBreakdown
);
/**
 *  Getting trend segment view focus on state to trend  component
 */
export const selectTrendSegmentFocusOn = createSelector(
  [selectDomain],
  (state) => state.trendSegmentFocusOn
);
/**
 *Getting trend hotel normalized view state for  component
 */
export const selectTrendHotelNormalizedView = createSelector(
  [selectDomain],
  (state) => state?.trendHotelNormalizedView
);
/**
 *Getting active trend tab state for the  component
 */
export const selectActiveTrendTab = createSelector(
  [selectDomain],
  (state) => state?.activeTrendTab
);
/**
 *Getting trend time visual state for the  component
 */
export const selectTrendTimeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeVisuals
);
/**
 *Getting weekly trend time visual state for the  component
 */
export const selectWeeklyTrendTimeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeWeeklyVisuals
);
/**
 *Getting trend time tabular visual state for the  component
 */
export const selectTrendTimeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeTabularVisuals
);
/**
 *Getting trend time weekly tabular visual state for the  component
 */
export const selectTrendTimeWeeklyTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeWeeklyTabularVisuals
);
/**
 *Getting trend time cumulative tabular visual state for the  component
 */
export const selectTrendTimeCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeCumulativeTabularVisuals
);
/**
 *Getting trend time cumulative visual state for the  component
 */
export const selectTrendTimeCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeCumulativeVisuals
);
/**
 *Getting trend segment visual state for the  component
 */
export const selectTrendSegmentVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentVisuals
);
/**
 *Getting trend segment tabular visual state for the  component
 */
export const selectTrendSegmentTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentTabularVisuals
);
/**
 *Getting weekly trend segment visual state for the  component
 */
export const selectWeeklyTrendSegmentVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentWeeklyVisuals
);
/**
 *Getting trend segment weekly tabular visual state for the  component
 */
export const selectTrendSegmentWeeklyTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentWeeklyTabularVisuals
);
/**
 *Getting trend segment cumulative tabular visual state for the  component
 */
export const selectTrendSegmentCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentCumulativeTabularVisuals
);
/**
 *Getting trend segment cumulative visual state for the  component
 */
export const selectTrendSegmentCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentCumulativeVisuals
);
/**
 *Getting trend hotel visual state for the  component
 */
export const selectTrendHotelVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelVisuals
);
/**
 *Getting trend hotel tabular visual state for the  component
 */
export const selectTrendHotelTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelTabularVisuals
);
/**
 *Getting weekly trend hotel visual state for the  component
 */
export const selectWeeklyTrendHotelVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelWeeklyVisuals
);
/**
 *Getting trend hotel weekly tabular visual state for the  component
 */
export const selectTrendHotelWeeklyTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelWeeklyTabularVisuals
);
/**
 *Getting trend hotel cumulative tabular visual state for the  component
 */
export const selectTrendHotelCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelCumulativeTabularVisuals
);
/**
 *Getting trend hotel cumulative visual state for the  component
 */
export const selectTrendHotelCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelCumulativeVisuals
);
/**
 *  Getting trend segment view filter list state to trend component
 */
export const selectTrendSegmentFilterList = createSelector(
  [selectDomain],
  (state) => state.trendSegmentFilterList
);

/**
 *  Getting trend segment tabular view filter list state to trend component
 */
export const selectTrendSegmentTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.trendSegmentTabularFilterList
);
/**
 *  Getting trend hotel view filter list state to the component
 */
export const selectTrendHotelFilterList = createSelector(
  [selectDomain],
  (state) => state.trendHotelFilterList
);
/**
 *  Getting trend hotel tabular view filter list state to trend time component
 */
export const selectTrendHotelTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.trendHotelTabularFilterList
);
/**
 *Getting trend hotel normalized filter list state for the component
 */
export const selectTrendHotelNormalizedFilterList = createSelector(
  [selectDomain],
  (state) => state?.trendHotelNormalizedFilterList
);
/**
 * Getting segment group 1 to trend  component
 */
export const selectSegmentGroup1 = createSelector([selectDomain], (state) => state.segmentGroup1);
/**
 *  Getting segment group 2 state to trend  component
 */
export const selectSegmentGroup2 = createSelector([selectDomain], (state) => state.segmentGroup2);
/**
 *  Getting TT revenue is display state to trend  component
 */
export const selectRevenueIsDisplayTT = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplayTT
);
/**
 *  Getting TT Occupied Rooms is display state to trend  component
 */
export const selectOccupiedRoomsIsDisplayTT = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplayTT
);
/**
 *  Getting TT Adr is display state to trend  component
 */
export const selectAdrIsDisplayTT = createSelector([selectDomain], (state) => state.adrIsDisplayTT);
/**
 *  Getting TT Avg Booking Window is display state to trend  component
 */
export const selectAvgBookingWindowIsDisplayTT = createSelector(
  [selectDomain],
  (state) => state.avgBookingWindowIsDisplayTT
);
/**
 *  Getting TT Avg Los is display state to trend  component
 */
export const selectAvgLosIsDisplayTT = createSelector(
  [selectDomain],
  (state) => state.avgLosIsDisplayTT
);
/**
 *  Getting TT Cxl count is display state to trend  component
 */
export const selectCxlIsDisplayTT = createSelector([selectDomain], (state) => state.cxlIsDisplayTT);
/**
 *  Getting TS revenue is display state to trend  component
 */
export const selectRevenueIsDisplayTS = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplayTS
);
/**
 *  Getting TS Occupied Rooms is display state to trend  component
 */
export const selectOccupiedRoomsIsDisplayTS = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplayTS
);
/**
 *  Getting TS Adr is display state to trend  component
 */
export const selectAdrIsDisplayTS = createSelector([selectDomain], (state) => state.adrIsDisplayTS);
/**
 *  Getting TS Avg Booking Window is display state to trend  component
 */
export const selectAvgBookingWindowIsDisplayTS = createSelector(
  [selectDomain],
  (state) => state.avgBookingWindowIsDisplayTS
);
/**
 *  Getting TS Avg Los is display state to trend  component
 */
export const selectAvgLosIsDisplayTS = createSelector(
  [selectDomain],
  (state) => state.avgLosIsDisplayTS
);
/**
 *  Getting TS Cxl count is display state to trend  component
 */
export const selectCxlIsDisplayTS = createSelector([selectDomain], (state) => state.cxlIsDisplayTS);
/**
 *  Getting TH revenue is display state to trend  component
 */
export const selectRevenueIsDisplayTH = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplayTH
);
/**
 *  Getting TH Occupied Rooms is display state to trend  component
 */
export const selectOccupiedRoomsIsDisplayTH = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplayTH
);
/**
 *  Getting TH Adr is display state to trend  component
 */
export const selectAdrIsDisplayTH = createSelector([selectDomain], (state) => state.adrIsDisplayTH);
/**
 *  Getting TH Avg Booking Window is display state to trend  component
 */
export const selectAvgBookingWindowIsDisplayTH = createSelector(
  [selectDomain],
  (state) => state.avgBookingWindowIsDisplayTH
);
/**
 *  Getting TH Avg Los is display state to trend  component
 */
export const selectAvgLosIsDisplayTH = createSelector(
  [selectDomain],
  (state) => state.avgLosIsDisplayTH
);
/**
 *  Getting TH Cxl count is display state to trend  component
 */
export const selectCxlIsDisplayTH = createSelector([selectDomain], (state) => state.cxlIsDisplayTH);
/**
 *  Getting tabular view state to trend  component
 */
export const selectTabularView = createSelector([selectDomain], (state) => state.tabularView);
/**
 *  Getting selected enable secondary hotel state to trend component
 */
export const selectEnableSecondaryHotelGroups = createSelector(
  [selectDomain],
  (state) => state.enableSecondaryHotelGroups
);
/**
 *  Getting is trend forecast state to the component
 */
export const selectIsForecast = createSelector([selectDomain], (state) => state.isTrendForecast);
/**
 *  Getting is trend forecast state to the component
 */
export const selectIsForecastDisabled = createSelector(
  [selectDomain],
  (state) => state.isForecastDisabled
);
/**
 *  Getting segment group A query in to the component
 */
export const selectSegmentSetAQuery = createSelector(
  [selectDomain],
  (state) => state.segmentSetAQuery
);
/**
 *  Getting segment group B query in to the component
 */
export const selectSegmentSetBQuery = createSelector(
  [selectDomain],
  (state) => state.segmentSetBQuery
);
/**
 * Getting loader state to trend  component
 */
export const selectLoader = createSelector([selectDomain], (state) => state.loading);
export const selectSavedAggregationFilters = createSelector(
  [selectDomain],
  (state) => state.savedAggregationFilters
);
/**
 * Getting is Filter name already exists state to trend  component
 */
export const selectExistedFilter = createSelector([selectDomain], (state) => state.existedFilter);
/**
 *  Getting symmetric comparison visual display state to the component
 */
export const selectSymmetricComparisonIsDisplay = createSelector(
  [selectDomain],
  (state) => state.symmetricComparisonIsDisplay
);
/**
 *  Getting is primary state to the component
 */
export const selectIsPrimary = createSelector([selectDomain], (state) => state.isPrimary);
/**
 *  Getting is percentage change state to the component
 */
export const selectIsPercentageChange = createSelector(
  [selectDomain],
  (state) => state.isTrendPercentageChange
);
/**
 *  Getting is percentage disabled state to the component
 */
export const selectIsPercentageChangeDisabled = createSelector(
  [selectDomain],
  (state) => state.isPercentageChangeDisabled
);
/**
 *Getting trend time percentage visual state for the  component
 */
export const selectTrendTimePercentageVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimePercentageVisuals
);
/**
 *Getting trend time percentage tabular visual state for the  component
 */
export const selectTrendTimePercentageTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimePercentageTabularVisuals
);
/**
 *Getting trend segment percentage visual state for the  component
 */
export const selectTrendSegmentPercentageVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentPercentageVisuals
);
/**
 *Getting trend segment percentage tabular visual state for the  component
 */
export const selectTrendSegmentPercentageTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendSegmentPercentageTabularVisuals
);
/**
 *Getting trend hotel percentage visual state for the  component
 */
export const selectTrendHotelPercentageVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelPercentageVisuals
);
/**
 *Getting trend hotel percentage tabular visual state for the  component
 */
export const selectTrendHotelPercentageTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelPercentageTabularVisuals
);
/**
 *  Getting is aggregate state to the component
 */
export const selectIsTrendDeAggregate = createSelector(
  [selectDomain],
  (state) => state.isTrendDeAggregate
);
/**
 *  Getting is aggregate disabled state to the component
 */
export const selectIsTrendDeAggregateDisabled = createSelector(
  [selectDomain],
  (state) => state.isTrendDeAggregateDisabled
);
/**
 *Getting trend time visual state for the  component
 */
export const selectTrendTimeSegmentVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeSegmentVisuals
);
/**
 *Getting weekly trend time visual state for the  component
 */
export const selectWeeklyTrendTimeSegmentVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeSegmentWeeklyVisuals
);
/**
 *Getting trend time tabular visual state for the  component
 */
export const selectTrendTimeSegmentTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeSegmentTabularVisuals
);
/**
 *Getting trend time weekly tabular visual state for the  component
 */
export const selectTrendTimeSegmentWeeklyTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeSegmentWeeklyTabularVisuals
);
/**
 *Getting trend time cumulative tabular visual state for the  component
 */
export const selectTrendTimeSegmentCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeSegmentCumulativeTabularVisuals
);
/**
 *Getting trend time cumulative visual state for the  component
 */
export const selectTrendTimeSegmentCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendTimeSegmentCumulativeVisuals
);
//
/**
 *Getting trend hotel percentage forecast off visual state for the  component
 */
export const selectTrendHotelForecastOffPercentageVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffPercentageVisuals
);
/**
 *Getting trend hotel percentage tabular forecast off visual state for the  component
 */
export const selectTrendHotelForecastOffPercentageTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffPercentageTabularVisuals
);
/**
 *Getting trend hotel forecast off  visual state for the  component
 */
export const selectTrendHotelForecastOffVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffVisuals
);
/**
 *Getting trend hotel forecast off  tabular visual state for the  component
 */
export const selectTrendHotelForecastOffTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffTabularVisuals
);
/**
 *Getting weekly trend hotel forecast off  visual state for the  component
 */
export const selectWeeklyTrendHotelForecastOffVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffWeeklyVisuals
);
/**
 *Getting trend hotel forecast off  weekly tabular visual state for the  component
 */
export const selectTrendHotelForecastOffWeeklyTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffWeeklyTabularVisuals
);
/**
 *Getting trend hotel forecast off  cumulative tabular visual state for the  component
 */
export const selectTrendHotelForecastOffCumulativeTabularVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffCumulativeTabularVisuals
);
/**
 *Getting trend hotel forecast off cumulative visual state for the  component
 */
export const selectTrendHotelForecastOffCumulativeVisuals = createSelector(
  [selectDomain],
  (state) => state?.trendHotelForecastOffCumulativeVisuals
);
/**
 *  Getting adjusted start date state to component
 */
export const selectAdjustedStartDate = createSelector(
  [selectDomain],
  (state) => state.adjustedStartDate
);
/**
 *  Getting trend booking date state to component
 */
export const selectAdjustedEndDate = createSelector(
  [selectDomain],
  (state) => state.adjustedEndDate
);
/**
 *  Getting granularity state to component
 */
export const selectGranularity = createSelector([selectDomain], (state) => state.granularity);

/**
 *  Getting weekly view disabled state to component
 */
export const selectIsTrendTimeWeeklyViewDisabled = createSelector(
  [selectDomain],
  (state) => state.isTrendTimeWeeklyViewDisabled
);
/**
 *  Getting moving average filter state to component
 */
export const selectMovingAverage = createSelector([selectDomain], (state) => state.movingAverage);
/**
 *  Getting is moving average visual state to component
 */
export const selectIsMovingAverage = createSelector(
  [selectDomain],
  (state) => state.isMovingAverage
);
/**
 *  Getting TT moving average visual to component
 */
export const selectMovingAverageVisualTT = createSelector(
  [selectDomain],
  (state) => state.trendTimeMovingAverageVisuals
);
/**
 *  Getting TT moving average tabular visual to component
 */
export const selectMovingAverageTabularVisualTT = createSelector(
  [selectDomain],
  (state) => state.trendTimeMovingAverageTabularVisuals
);
/**
 *  Getting TS moving average visual to component
 */
export const selectMovingAverageVisualTS = createSelector(
  [selectDomain],
  (state) => state.trendSegmentMovingAverageVisuals
);
/**
 *  Getting TS moving average tabular visual to component
 */
export const selectMovingAverageTabularVisualTS = createSelector(
  [selectDomain],
  (state) => state.trendSegmentMovingAverageTabularVisuals
);
/**
 *  Getting TH moving average visual to component
 */
export const selectMovingAverageVisualTH = createSelector(
  [selectDomain],
  (state) => state.trendHotelMovingAverageVisuals
);
/**
 *  Getting TH moving average visual to component
 */
export const selectMovingAverageTabularVisualTH = createSelector(
  [selectDomain],
  (state) => state.trendHotelMovingAverageTabularVisuals
);
/**
 *  Getting trend time Segment view filter list state to trend time component
 */
export const selectTrendTimeSegmentFilterList = createSelector(
  [selectDomain],
  (state) => state.trendTimeSegmentFilterList
);
/**
 *  Getting trend time Segment tabular view filter list state to trend time component
 */
export const selectTrendTimeSegmentTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.trendTimeSegmentTabularFilterList
);

// test trend tab ============================================================

/**
 *  Getting trend segment view filter list state to trend component
 */
export const selectTestTrendSegmentFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendSegmentFilterList
);
/**
 *  Getting trend segment tabular view filter list state to trend component
 */
export const selectTestTrendSegmentTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendSegmentTabularFilterList
);

/**
 *  Getting trend segment view filter list state to trend component
 */
export const selectTestTrendTimeFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendTimeFilterList
);
/**
 *  Getting trend segment tabular view filter list state to trend component
 */
export const selectTestTrendTimeTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendTimeTabularFilterList
);

/**
 *  Getting trend segment view filter list state to trend component
 */
export const selectTestTrendHotelFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendHotelFilterList
);

/**
 *  Getting trend segment tabular view filter list state to trend component
 */
export const selectTestTrendHotelTabularFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendHotelTabularFilterList
);

/**
 *  Getting trend segment tabular view filter list state to trend component
 */
export const selectTestTrendHotelNormalizedFilterList = createSelector(
  [selectDomain],
  (state) => state.testTrendHotelNormalizedFilterList
);
/**
 * Getting is focus on set state to the component
 */
export const selectIsFocusOnSet = createSelector([selectDomain], (state) => state.isFocusOnSet);
/**
 * Getting is focus all set state to the component
 */
export const selectIsFocusAllSelected = createSelector(
  [selectDomain],
  (state) => state.isFocusAllSelected
);
