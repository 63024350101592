/* eslint-disable import/no-cycle */
import { all, fork } from 'redux-saga/effects';
import { signInSaga } from 'modules/sign-in/saga';
import { authSaga } from 'modules/common/auth/saga';
import { forgotPasswordSaga } from 'modules/forgot-password/saga';
import { resetPasswordSaga } from 'modules/reset-password/saga';
import { verifyTokenSaga } from 'modules/verify-callback/saga';
import { dashboardSaga } from 'modules/dashboard/saga';
import { rateCalendarSaga } from 'modules/rate-calendar/saga';
import { rateCalendarUploadSaga } from 'modules/rate-calendar-upload/saga';
import { userSaga } from 'modules/users/saga';
import { eventCategoryConfigurationSaga } from 'modules/event-configuration/saga';
import { signUpSaga } from 'modules/sign-up/saga';
import { plotPaceSaga } from 'modules/dashboard/components/tab-container/3d-pace-tab/saga';
import { workbookReportSaga } from 'modules/dashboard/components/tab-container/workbook-report/saga';
import { trendSaga } from 'modules/dashboard/components/tab-container/trend-tabs/saga';

/**
 * Register all the saga functions into the root saga
 */
export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(signInSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(verifyTokenSaga),
    fork(dashboardSaga),
    fork(rateCalendarSaga),
    fork(userSaga),
    fork(rateCalendarUploadSaga),
    fork(eventCategoryConfigurationSaga),
    fork(signUpSaga),
    fork(trendSaga),
    fork(plotPaceSaga),
    fork(workbookReportSaga),
  ]);
}
