/**
 * Column names to generate power bi filter schema are mentioned here
 */
const COLUMN_NAMES = {
  FOCUS_ON_SEGMENT: 'Value',
  DATE_COMPARISON: 'Label',
  BOOKING_AND_TARGET_DATE: 'Date',
};
//
export default COLUMN_NAMES;
