import { COMMON_FOCUS_ON_OPTIONS } from 'modules/dashboard/components/tab-container/trend-tabs/constants';
/**
 * Function to get relevant values from application list when provide command values
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter(
      (item) =>
        item?.label?.trim()?.replace(/ +/g, '').toLowerCase() ===
        option?.trim()?.replace(/ +/g, '').toLowerCase()
    );
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to get relevant values from application list when provide command values with total option
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getTrendValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter((item) => {
      if (item?.label === COMMON_FOCUS_ON_OPTIONS.OVERALL.label) {
        return (
          'Total'?.trim()?.replace(/ +/g, '').toLowerCase() ===
          option?.trim()?.replace(/ +/g, '').toLowerCase()
        );
      }
      return (
        item?.label?.trim()?.replace(/ +/g, '').toLowerCase() ===
        option?.trim()?.replace(/ +/g, '').toLowerCase()
      );
    });
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to get matched values from application list when provide command values
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getMatchedValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter((item) =>
      item
        ?.trim()
        ?.replace(/ +/g, '')
        .toLowerCase()
        .includes(option?.trim()?.replace(/ +/g, '').toLowerCase())
    );
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to get relevant hotel  values from application list when provide command values
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getHotelValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter(
      (item) =>
        item?.label
          ?.trim()
          ?.replace(/ +/g, '')
          .toLowerCase()
          .includes(option?.trim()?.replaceAll('%20', ' ')?.replace(/ +/g, '').toLowerCase()) ||
        item?.id === option ||
        (item?.isPredefined &&
          item?.id?.trim()?.replace(/ +/g, '').toLowerCase() ===
            option?.trim()?.replaceAll('%20', ' ')?.replace(/ +/g, '').toLowerCase()) ||
        item?.altName
          ?.trim()
          ?.replace(/ +/g, '')
          .toLowerCase()
          .includes(option?.trim()?.replace(/ +/g, '').toLowerCase())
    );
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to transform string into array
 * @param {*} value
 * @returns
 */
export const transformIntoArray = (value) => {
  let arr = [];
  if (value?.includes(',')) {
    arr = value?.split(',');
  } else {
    arr.push(value);
  }
  return arr;
};

/**
 * Sorts an array of objects based on a specified field while ensuring uniqueness.
 * @param {Array<Object>} dataArray - The array of objects to be sorted.
 * @param {string} sortField - The field based on which the array should be sorted.
 * @returns {Array<Object>} - A new array of sorted and unique objects.
 */
export const sortAndDistinctByField = (dataArray, sortField) =>
  Array.from(new Set(dataArray?.map((obj) => JSON.stringify(obj))))
    ?.map(JSON.parse)
    ?.sort((a, b) => a?.[sortField]?.localeCompare(b?.[sortField]));

/**
 * Function to normalize the strings (remove spaces and convert to lowercase)
 * @param {*} str
 * @returns
 */
export const normalizeString = (str) => str.replace(/\s+/g, '').toLowerCase();
/**
 * Check if all elements in array1 are included in array2 after normalization
 * @param {*} arr
 * @param {*} target
 * @returns
 */
export const isIncluded = (arr = [], target = []) =>
  target?.every((v) =>
    arr
      ?.map((a) => (a.label === COMMON_FOCUS_ON_OPTIONS.OVERALL.label ? 'Total' : a.label))
      ?.some((obj) => normalizeString(obj) === normalizeString(v))
  );
