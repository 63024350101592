/* eslint-disable dot-notation ,radix ,prefer-destructuring */
import * as XLSX from 'xlsx-js-style';
import { formatDate } from 'modules/dashboard/functions';
import { DATE_PATTERN } from 'modules/common/constants/date-range';
import powerBIVisualPresets from './presets';

const convertColumnNamesToDecimal = (columnName) => {
  let result = 0;
  const length = columnName.length;

  for (let i = 0; i < length; i += 1) {
    // Convert each letter to its corresponding value (A=1, B=2, ..., Z=26)
    const value = columnName.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
    // Multiply the result by 26 (since it's base 26) and add the current value
    result = result * 26 + value;
  }
  // get it to right ascii offset
  result += 64;

  if (result <= 64) {
    console.log('something is wrong with columnNames');
  }

  return result;
};
//
const convertDecimalsToColumnNames = (num) => {
  num -= 64;
  let result = '';

  while (num > 0) {
    // Calculate the current letter's value (1-based index, so adjust for 0-based)
    const remainder = (num - 1) % 26;
    // Convert the remainder to a corresponding letter (A=1, ..., Z=26)
    const letter = String.fromCharCode(remainder + 'A'.charCodeAt(0));
    result = letter + result;
    // Move to the next "digit" in base 26
    num = Math.floor((num - 1) / 26);
  }

  return result;
};
//
export const excelNumberCellTransforms = {
  TO_INTEGER: (cell) => ({
    ...cell,
    t: 'n',
  }),
  TO_TWO_DECIMAL: (cell) => ({
    ...cell,
    t: 'n',
    v: Math.round(parseFloat(cell['w']) * 100) / 100,
    z: '0.00',
  }),
  TO_PERCENTAGE: (cell) => ({
    ...cell,
    t: 'n',
    // eslint-disable-next-line no-nested-ternary
    v: cell.v ? (cell.v === 0 ? 0 : parseFloat(cell.v)) : '',
    z: '0.00%',
  }),
  TO_DATE: (cell) => ({
    ...cell,
    t: 's',
    // eslint-disable-next-line no-nested-ternary
    v: cell.w,
  }),
};
//
export const setValueForeColor = (cellObj) => {
  if (cellObj.v < 0) {
    return {
      ...cellObj,
      s: {
        ...cellObj.s,
        font: {
          color: { rgb: 'ff0000' },
        },
      },
    };
  }
  return cellObj;
};

export const setNumberFormatting = (cellObj) => {
  if (!cellObj.w.includes('%') && cellObj.w.split('.')[0].length > 3) {
    if (cellObj.w.includes('.')) {
      return {
        ...cellObj,
        v: parseFloat(cellObj.w.replaceAll(',', '')),
        z: '###,###,##0.00',
      };
    }
    if (cellObj.w.includes('%')) {
      return {
        ...cellObj,
        z: '###,###,##0.00%',
      };
    }

    return {
      ...cellObj,
      z: '###,###,##0',
    };
  }
  return {
    ...cellObj,
  };
};

export const cellStyles = {
  HEADER: {
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: { rgb: '92bbea' },
    },
    font: {
      color: { rgb: 'ffffff' },
      bold: true,
    },
  },
};

export const patchMissingCells = (sheet, preset, tableHeight, tableEnd) => {
  // create a array of columns based on the decimal values for the ascii values
  // 65 - A till the right value
  Array.from(
    { length: (convertColumnNamesToDecimal(tableEnd) - 65) / 1 },
    (_, index) => 65 + index * 1
  ).forEach((clmn) => {
    let total = 0;
    let isPercentage = false;
    // loop to start to end of the table to check if all the cells are available
    for (let cell = preset.table.start; cell <= tableHeight; cell += 1) {
      if (!sheet[`${convertDecimalsToColumnNames(clmn)}${cell}`]) {
        sheet[`${convertDecimalsToColumnNames(clmn)}${cell}`] = {
          t: 's',
          v: '',
          w: '',
        };
      }
      // if there is a value in the cell add it to total
      // only if the total row is enabled
      if (preset.totalrow && sheet[`${convertDecimalsToColumnNames(clmn)}${cell}`].t === 'n') {
        total += parseFloat(sheet[`${convertDecimalsToColumnNames(clmn)}${cell}`].v);
        if (sheet[`${convertDecimalsToColumnNames(clmn)}${cell}`]?.w?.includes('%')) {
          isPercentage = true;
        }
      }
    }
    // then update the column value
    if (preset.totalrow) {
      sheet[`${convertDecimalsToColumnNames(clmn)}${tableHeight}`] = setNumberFormatting(
        setValueForeColor({
          v: total,
          w: isPercentage ? `${total}%` : total.toString(),
          font: {
            bold: true,
          },
        })
      );
    }
  });
  return sheet;
};
//
export const extractColumnNameFromCell = (cell) =>
  cell
    .split('')
    // filter numbers from char list
    .filter((char) => char.charCodeAt(0) <= 90 && char.charCodeAt(0) >= 65)
    .join('');
//
export const extractRowNumberFromCell = (cell) =>
  parseInt(cell.replace(extractColumnNameFromCell(cell), ''));
//

// default method
export const styleXlsxFiles = (updatedSheet, fileName) => {
  // select the right preset
  const preset = powerBIVisualPresets[fileName];
  const workbook = XLSX.utils.book_new();

  const tableEndChar = extractColumnNameFromCell(updatedSheet['!ref'].split(':')[1]);

  const tableRef = updatedSheet['!ref'].split(':')[1];

  const tableHeight = parseInt(
    tableRef
      .split('')
      // filter letters from char array
      .filter((char) => !(char.charCodeAt(0) <= 90 && char.charCodeAt(0) >= 65))
      .join('')
  );

  // check missing cells
  updatedSheet = patchMissingCells(updatedSheet, preset, tableHeight, tableEndChar);

  const headerRows = preset.rows.header;

  // update the header keys to bold
  // report header rows (A column)
  for (let j = 1; j < preset.rows.header[0] - 1; j += 1) {
    updatedSheet[`A${j}`].s = {
      font: {
        bold: true,
      },
    };
  }

  // add borders to the table
  // all the cell after the table preset
  Object.keys(updatedSheet).forEach((cell) => {
    let borderStyle = {
      right: {
        style: 'thin',
      },
      top: {
        style: 'thin',
      },
    };
    if (preset.bordered) {
      if (extractRowNumberFromCell(cell) >= preset.table.start) {
        // improve look when the total row is disbled
        if (!preset.totalrow && extractRowNumberFromCell(cell) === tableHeight) {
          return;
        }
        if (!preset.totalrow && extractRowNumberFromCell(cell) === tableHeight - 1) {
          borderStyle = {
            ...borderStyle,
            bottom: {
              style: 'thin',
            },
          };
        }
        if (updatedSheet[cell].s) {
          // check if the styling is already enabled for the cell
          updatedSheet[cell].s.border = borderStyle;
        } else {
          updatedSheet[cell] = {
            ...updatedSheet[cell],
            s: {
              border: borderStyle,
            },
          };
        }
        // check if the cells should merge then remove the left line
        if (preset.rows.header.includes(extractRowNumberFromCell(cell))) {
          // only merge cells in header row
          const nextColumn = convertColumnNamesToDecimal(extractColumnNameFromCell(cell)) + 1;
          // check if the nextColumn is withtin the range and the value is blank
          if (
            nextColumn <= convertColumnNamesToDecimal(tableEndChar) &&
            updatedSheet[
              `${convertDecimalsToColumnNames(nextColumn)}${extractRowNumberFromCell(cell)}`
            ]?.v === ''
          ) {
            updatedSheet[cell].s.border = {
              top: {
                style: 'thin',
              },
            };
          }
        }

        // add bottom border to the last row
        // add only if the total row is enabled
        if (preset.totalrow) {
          if (extractRowNumberFromCell(cell) === tableHeight) {
            updatedSheet[cell].s = {
              border: {
                ...updatedSheet[cell].s.border,
                bottom: {
                  style: 'thin',
                },
              },
            };
          }
        }
      }

      // set the bgcolor by row
      if (
        extractRowNumberFromCell(cell) % 2 === 1 &&
        extractRowNumberFromCell(cell) >= preset.table.dataStart
      ) {
        updatedSheet[cell].s = {
          ...updatedSheet[cell].s,
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: { rgb: 'eeeded' },
          },
        };
      }
    }
  });

  // add styles to total row
  if (preset.totalrow) {
    updatedSheet[`A${tableHeight}`] = setNumberFormatting(
      setValueForeColor({
        t: 's',
        v: 'Total',
        w: 'Total',
      })
    );
  }
  // add styles to cells
  // comment this section to get the cell ranges and then uncomment back to include transformations
  const ifAllValuesInColumnInteger = {};
  Object.keys(updatedSheet).forEach((cell) => {
    const currentCell = updatedSheet[cell];
    // add styling to the header rows
    if (headerRows.includes(extractRowNumberFromCell(cell))) {
      updatedSheet[cell].s = { ...updatedSheet[cell].s, ...cellStyles.HEADER };
    }
    // make the total row text bold
    if (preset.totalrow && extractRowNumberFromCell(cell) === tableHeight) {
      if (preset.bordered) {
        updatedSheet[cell].s = {
          font: {
            bold: true,
          },
          border: {
            top: { style: 'thin' },
          },
        };
      } else {
        updatedSheet[cell].s = {
          font: {
            bold: true,
          },
        };
      }
    }
    // replace ### with , where it supposed to a comma in field names
    if (updatedSheet[cell]?.t === "s") {
      if (updatedSheet[cell]?.v.includes('####')) {
        updatedSheet[cell] = {
          ...updatedSheet[cell],
          v: updatedSheet[cell]?.v.replace('####', ', ').replaceAll('"', '')
        }
      }
    }

    if (currentCell.t !== 's' && currentCell?.w) {
      if (currentCell?.w.includes('%')) {
        updatedSheet[cell] = setNumberFormatting(
          setValueForeColor(excelNumberCellTransforms.TO_PERCENTAGE(currentCell))
        );
      } else if (currentCell.w.replace('/', '').includes('/')) {
        // check if the value has two / making it a date string
        updatedSheet[cell] = excelNumberCellTransforms.TO_DATE(currentCell);
      } else if (currentCell?.w.includes('.')) {
        updatedSheet[cell] = setNumberFormatting(
          setValueForeColor(excelNumberCellTransforms.TO_TWO_DECIMAL(currentCell))
        );
      }
      // for int values check the other values in the same column to check if it should have decimal places or not
      else if (Number.isInteger(parseFloat(currentCell.w))) {
        // check if all values are integer
        // if table start from row 10
        let isAllInteger = ifAllValuesInColumnInteger[extractColumnNameFromCell(cell)];
        if (isAllInteger === undefined) {
          isAllInteger = true;
          for (let i = preset.table.dataStart; i < tableHeight; i += 1) {
            if (updatedSheet[`${extractColumnNameFromCell(cell)}${i}`]?.t !== 's') {
              if (!Number.isInteger(updatedSheet[`${extractColumnNameFromCell(cell)}${i}`]?.v)) {
                isAllInteger = false;
              }
            }
          }
          ifAllValuesInColumnInteger[extractColumnNameFromCell(cell)] = isAllInteger;
        }
        // make a single column container the same data format
        if (isAllInteger) {
          updatedSheet[cell] = setNumberFormatting(
            setValueForeColor(excelNumberCellTransforms.TO_INTEGER(currentCell))
          );
        } else {
          updatedSheet[cell] = setNumberFormatting(
            setValueForeColor(excelNumberCellTransforms.TO_TWO_DECIMAL(currentCell))
          );
        }
      }
    }
  });

  // merge report header row values on by one
  const mergedCellArray = [];
  for (let iter = 1; iter < preset.rows.header[0] - 1; iter += 1) {
    mergedCellArray.push(XLSX.utils.decode_range(`B${iter}:D${iter}`));
  }
  updatedSheet['!merges'] = mergedCellArray;

  if (!updatedSheet['!cols']) updatedSheet['!cols'] = [];

  // pad the cells to adjust cell sizes to show full text
  updatedSheet['!cols'] = preset.columns.width.map((width) => ({ wch: width }));

  // Convert worksheet back to XLSX format and write to file
  XLSX.utils.book_append_sheet(workbook, updatedSheet);
  XLSX.writeFile(workbook, `${fileName}_${formatDate(new Date(), DATE_PATTERN)}.xlsx`, {
    cellStyles: true,
  });
};
