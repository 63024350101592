/**
 * Function to map operators to their Power BI equivalents
 * @param {*} operator
 * @returns
 */
const mapOperator = (operator) => {
  const operatorMapping = {
    GreaterThanOrEqual: 'ge',
    GreaterThan: 'gt',
    LessThanOrEqual: 'le',
    LessThan: 'lt',
    Equal: 'eq',
    NotIn: 'ne',
    In: 'in',
  };
  //
  return operatorMapping[operator] || operator;
};
/**
 * Function to parse filter conditions and generate the corresponding string
 * @param {*} conditions
 * @param {*} tableName
 * @param {*} columnName
 * @returns
 */
const parseFilterConditions = (conditions, tableName, columnName) =>
  conditions
    .map(
      (condition) =>
        `${tableName}/${columnName} ${mapOperator(condition.operator)} datetime'${condition.value}'`
    )
    .join(' and ');
/**
 * Function to parse basic filters and generate the corresponding string
 * @param {*} obj
 * @returns
 */
const parseBasicFilter = (obj) => {
  const valuesStr = obj.values.map((value) => `'${value}'`).join(', ');
  return `${obj.target.table}/${obj.target.column} ${mapOperator(obj.operator)} ${
    mapOperator(obj.operator) === 'ne' ? valuesStr : `(${valuesStr})`
  }`;
};
/**
 * Function to parse advanced filters and generate the corresponding string
 * @param {*} obj
 * @returns
 */
const parseAdvancedFilter = (obj) => {
  const { logicalOperator } = obj;
  const conditionsStr = parseFilterConditions(obj.conditions, obj.target.table, obj.target.column);
  return conditionsStr ? `${conditionsStr} ${logicalOperator.toLowerCase()}` : '';
};
/**
 * Function to parse filters based on schema type and generate the corresponding string
 * @param {*} obj
 * @returns
 */
const parseFilter = (obj) =>
  obj.$schema === 'http://powerbi.com/product/schema#basic'
    ? parseBasicFilter(obj)
    : parseAdvancedFilter(obj);
/**
 * Function to generate the final filter string from an array of filters
 * @param {*} filterArray
 * @returns
 */
const generateFilterString = (filterArray) => {
  const filterStrings = filterArray.map(parseFilter).filter(Boolean);
  if (filterStrings.length === 0) return ''; // No filters provided

  // Join the filter strings with 'and' and remove redundant 'and's
  let result = filterStrings.join(' and ').replace(/and and/gi, 'and');

  // Ensure there are no leading or trailing spaces or 'and's
  result = result
    .trim()
    .replace(/^(and\s*)+/, '')
    .replace(/\s*(and\s*)+$/, '');

  return result;
};
/**
 * Export the main function for external use
 */
export default generateFilterString;
