import {
  COLLAPSE_VISUALS,
  UiController,
  breakdownList,
  commands,
  events,
  paceVariableNames,
} from 'modules/common/ui-controller';
import { KEYS, RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { dashboardActions } from 'modules/dashboard/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { setCompareWindowCommandValue } from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { getHotelValuesFromList } from 'modules/common/utils/array';
import { selectUrlOrder } from 'modules/dashboard/selectors';
import {
  selectCxlSetIsDisplay,
  selectPaceVisualIsDisplay,
  selectRevenueSetIsDisplay,
} from '../selectors';

/**
 * Custom hook to execute summary widget filter command flow
 * @param {Boolean} isLoaded
 * @param {Boolean} customBooking
 * @param {Array} paceList
 * @param {Array} chartBreakdownList
 * @param {Array} paceVariableList
 * @param {Array} visualFilters
 * @param {String} dateComparison
 * @param {Boolean} alignment
 * @param {Array} dateComparisonList
 * @param {Function} handleComparisonChange
 * @param {Function} handleToggleChange
 */
const useSummaryCommandFlow = (
  isLoaded,
  customBooking,
  paceList,
  chartBreakdownList,
  paceVariableList,
  visualFilters,
  dateComparison,
  alignment,
  dateComparisonList,
  handleComparisonChange,
  handleToggleChange,
  organization,
  hotelList,
  hotelGroupList,
  isHotelForecast
) => {
  const dispatch = useDispatch();
  //
  const urlOrder = useSelector(selectUrlOrder);
  const paceVisualIsDisplay = useSelector(selectPaceVisualIsDisplay);
  const revenueSetIsDisplay = useSelector(selectRevenueSetIsDisplay);
  const cxlSetIsDisplay = useSelector(selectCxlSetIsDisplay);
  //
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [customDatesValueCommand, setCustomDatesValueCommand] = useState(null);
  const [breakdownCommandValue, setBreakdownCommandValue] = useState(null);
  const [paceHorizonCommandValue, setPaceHorizonCommandValue] = useState(null);
  const [paceVariableCommandValue, setPaceVariableCommandValue] = useState(null);
  const [customBookingCommandValue, setCustomBookingCommandValue] = useState(null);
  const [extendingForecastCommandValue, setExtendingForecastCommandValue] = useState(null);
  const [symmetricComparisonCollapseCommandValue, setSymmetricComparisonCollapseCommandValue] =
    useState(null);
  const [visualCollapseCommandValue, setVisualCollapseCommandValue] = useState(null);
  const [forecastCommandValue, setForecastCommandValue] = useState(null);
  const [compareHotelsValue, setCompareHotelsValue] = useState(null);
  const [hotelGroupCommand, setHotelGroupCommand] = useState(null);
  const [normalizeCommandValue, setNormalizedCommandValue] = useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  // set pace variable command value
  const setSummaryPaceVariableHandler = (value) => {
    setPaceVariableCommandValue(value);
  };
  // set alignment toggle command value
  const setSummaryAlignmentToggleHandler = (value) => {
    setAlignmentCommandValue(value);
  };
  // Set summary compare window command value
  const setSummaryCompareWindowHandler = (value) => {
    setCompareWindowCommandValue(value, customBooking, handleComparisonChange);
    setCompareWindowValueCommand(null);
    dispatch(dashboardActions.setUrlOrder(8));
  };
  // Triggers when paceVariableCommandValue, isLoaded change
  useEffect(() => {
    if (alignmentCommandValue !== null && isLoaded && urlOrder === 5) {
      handleToggleChange({
        target: {
          checked: alignmentCommandValue,
        },
      });
      setAlignmentCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(6));
    }
  }, [alignmentCommandValue, isLoaded, urlOrder]);
  // Triggers when paceVariableCommandValue, isLoaded change
  useEffect(() => {
    if (paceVariableCommandValue !== null && isLoaded && urlOrder === 11) {
      const variable = paceVariableNames[paceVariableCommandValue];
      if (variable !== undefined) {
        dispatch(dashboardActions.setPaceVariable(variable));
      }
      setPaceVariableCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(12));
    }
  }, [paceVariableCommandValue, isLoaded, urlOrder]);
  // Triggers when customBookingCommandValue, isLoaded change
  useEffect(() => {
    if (customBookingCommandValue !== null && isLoaded && urlOrder === 1) {
      if (customBookingCommandValue === commands.OTB) {
        dispatch(summaryActions.setSummaryOtb(false));
        dispatch(summaryActions.setSummaryBookingDate(RESET_BOOKING_DATE));
        setCustomBookingCommandValue(null);
        dispatch(dashboardActions.setUrlOrder(2));
      } else {
        dispatch(summaryActions.setSummaryOtb(true));
      }
    }
  }, [customBookingCommandValue, isLoaded, urlOrder]);
  // Triggers when customBookingCommandValue,customBooking, isLoaded change
  useEffect(() => {
    if (
      customBookingCommandValue !== null &&
      customBookingCommandValue !== commands.OTB &&
      customBooking &&
      isLoaded &&
      urlOrder === 1
    ) {
      // emit set custom booking date range event
      UiController.setBookingDateRange(customBookingCommandValue);
      setCustomBookingCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(2));
    }
  }, [customBookingCommandValue, customBooking, isLoaded, urlOrder]);
  // Triggers when paceHorizonCommandValue,paceList, isLoaded change
  useEffect(() => {
    if (paceHorizonCommandValue !== null && paceList?.length > 0 && isLoaded && urlOrder === 10) {
      const paces = paceList?.filter((pace) => pace?.label[0].includes(paceHorizonCommandValue));
      if (paces?.length > 0) {
        dispatch(dashboardActions.setPaceHorizon(paces[0]?.id));
      }
      setPaceHorizonCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(11));
    }
  }, [paceHorizonCommandValue, paceList?.length, isLoaded, urlOrder]);
  // Triggers when breakdownCommandValue,chartBreakdownList, isLoaded change
  useEffect(() => {
    if (
      breakdownCommandValue !== null &&
      chartBreakdownList?.length > 0 &&
      isLoaded &&
      urlOrder === 2
    ) {
      // get breakdown option value
      const breakdownOption = breakdownList[breakdownCommandValue?.substring(0, 2)];
      // get isDetailed state from command
      const detailsStatus = parseInt(breakdownCommandValue?.substring(2, 4), 10);
      if (breakdownOption !== undefined) {
        const option = chartBreakdownList?.filter((val) => val?.label?.includes(breakdownOption));
        if (option?.length > 0) {
          dispatch(summaryActions.setBreakdown(option[0]?.id));
        }
        // set isDetailed  state
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(detailsStatus)) {
          dispatch(summaryActions.setIsDetailed(detailsStatus === 1));
        }
      }
      setBreakdownCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(3));
    }
  }, [breakdownCommandValue, chartBreakdownList?.length, isLoaded, urlOrder]);

  // Triggers when compareWindowValueCommand, customBooking,dateComparison values change
  useEffect(() => {
    if (dateComparison && compareWindowValueCommand !== null && urlOrder === 7) {
      setSummaryCompareWindowHandler(compareWindowValueCommand);
    }
  }, [compareWindowValueCommand, customBooking, dateComparison, urlOrder]);
  // Triggers when customDatesValueCommand and dateComparison values change
  useEffect(() => {
    if (customDatesValueCommand !== null && isLoaded && urlOrder >= 7) {
      const datesExtracted = customDatesValueCommand?.split('-');
      // emit event to set custom date range
      if (dateComparison && dateComparison === KEYS.CUSTOM) {
        dispatch(summaryActions.setSummaryCustomDate(datesExtracted[0]));
        UiController.setCustomDateRange(customDatesValueCommand);
        setCustomDatesValueCommand(null);
      }
    }
  }, [customDatesValueCommand, dateComparison, urlOrder, isLoaded]);
  // Triggers when extendingForecastCommandValue, isLoaded change
  useEffect(() => {
    if (extendingForecastCommandValue !== null && isLoaded && urlOrder === 12) {
      dispatch(summaryActions.setSummaryVisualToggle(extendingForecastCommandValue === 1));
      setExtendingForecastCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(18));
    }
  }, [extendingForecastCommandValue, isLoaded, urlOrder]);
  // Triggers when symmetricComparisonCollapseCommandValue, isLoaded change
  useEffect(() => {
    if (symmetricComparisonCollapseCommandValue !== null && isLoaded && urlOrder === 8) {
      dispatch(
        summaryActions.setSymmetricComparisonIsDisplay(
          symmetricComparisonCollapseCommandValue === 1
        )
      );
      setSymmetricComparisonCollapseCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(9));
    }
  }, [symmetricComparisonCollapseCommandValue, isLoaded, urlOrder]);
  // Triggers when visualCollapseCommandValue change
  useEffect(() => {
    if (visualCollapseCommandValue !== null && isLoaded && urlOrder === 100) {
      dispatch(summaryActions.setPaceVisualIsDisplay(false));
      dispatch(summaryActions.setRevenueSetIsDisplay(false));
      dispatch(summaryActions.setCxlSetIsDisplay(false));
      setTimeout(() => {
        dispatch(dashboardActions.setUrlOrder(101));
      }, 2000);
    }
  }, [visualCollapseCommandValue, isLoaded, urlOrder]);
  // Triggers when visualCollapseCommandValue change
  useEffect(() => {
    if (
      visualCollapseCommandValue !== null &&
      urlOrder === 101 &&
      (paceVisualIsDisplay && revenueSetIsDisplay && cxlSetIsDisplay) === false
    ) {
      const visuals = visualCollapseCommandValue?.split(',');
      visuals?.forEach((visual) => {
        // get the visual collapse enable/disable status
        const status = parseInt(visual?.substring(2), 10);
        // get the type of visual view
        const visualName = visual?.substring(0, 2);
        if (visualName && status !== undefined) {
          switch (visualName) {
            case COLLAPSE_VISUALS.PACE:
              dispatch(summaryActions.setPaceVisualIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.REVENUE:
              dispatch(summaryActions.setRevenueSetIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.CX:
              dispatch(summaryActions.setCxlSetIsDisplay(status === 1));
              break;
            default:
              break;
          }
        }
      });
      setVisualCollapseCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(102));
    }
  }, [
    visualCollapseCommandValue,
    urlOrder,
    paceVisualIsDisplay,
    revenueSetIsDisplay,
    cxlSetIsDisplay,
  ]);
  // Triggers when forecastCommandValue and isLoaded value change
  useEffect(() => {
    if (
      forecastCommandValue !== null &&
      isLoaded &&
      organization?.enableForecastFilter &&
      urlOrder === 9
    ) {
      dispatch(
        dashboardActions.setSummaryForecastFilter(
          organization?.enableForecastFilter && isHotelForecast && forecastCommandValue === 1
        )
      );
      setForecastCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(10));
    }
  }, [forecastCommandValue, organization?.enableForecastFilter, isLoaded, urlOrder]);
  // Triggers when compareHotelsValue, hotelList and isLoaded value change
  useEffect(() => {
    if (compareHotelsValue !== null && hotelList?.length > 0 && isLoaded && urlOrder === 3) {
      const hotelsSelected = getHotelValuesFromList(compareHotelsValue, hotelList);
      if (hotelsSelected?.length > 0) {
        dispatch(summaryActions.setEnableSecondaryHotelGroups(false));
        dispatch(summaryActions.setHotelFocusOn(hotelsSelected[0]));
      }
      setCompareHotelsValue(null);
      setTimeout(() => {
        dispatch(dashboardActions.setUrlOrder(4));
      }, 4000);
    }
  }, [compareHotelsValue, hotelList?.length, isLoaded, urlOrder]);
  // Triggers when hotelGroupCommand, hotelGroupList and isLoaded value change
  useEffect(() => {
    if (hotelGroupCommand !== null && hotelGroupList?.length > 0 && isLoaded && urlOrder === 3) {
      const hotelGroupsSelected = getHotelValuesFromList(hotelGroupCommand, hotelGroupList);
      if (hotelGroupsSelected?.length > 0) {
        dispatch(summaryActions.setEnableSecondaryHotelGroups(true));
        dispatch(summaryActions.setHotelFocusOn(hotelGroupsSelected[0]));
      }
      setHotelGroupCommand(null);
      setTimeout(() => {
        dispatch(dashboardActions.setUrlOrder(4));
      }, 4000);
    }
  }, [hotelGroupCommand, hotelGroupList?.length, isLoaded, urlOrder]);
  // Triggers when normalizeDisabled, normalizeCommandValue, isLoaded values change
  useEffect(() => {
    if (normalizeCommandValue !== null && isLoaded && urlOrder === 4) {
      // execute dispatch function to set normalized view with command value
      dispatch(summaryActions.setNormalizedView(normalizeCommandValue === 1));
      setNormalizedCommandValue(null);
      dispatch(dashboardActions.setUrlOrder(5));
    }
  }, [normalizeCommandValue, isLoaded, urlOrder]);
  // Listener function to set pace horizon value
  const setSummaryPaceHorizonHandler = (value) => {
    setPaceHorizonCommandValue(value);
  };
  // Listener function to set compare window value
  const setCompareWindowValue = (data) => {
    setCompareWindowValueCommand(data);
  };
  // Listener function to set custom date range value
  const setCustomDateRangeValue = (data) => {
    setCustomDatesValueCommand(data);
  };
  // Listener function to set breakdown command value
  const setSummaryBreakdownHandler = (value) => {
    setBreakdownCommandValue(value);
  };
  // Listener function to set custom booking date range command
  const setSummaryCustomBookingHandler = (value) => {
    setCustomBookingCommandValue(value);
  };
  // Listener function to set extending forecast toggle value
  const setExtendingForecastValue = (data) => {
    setExtendingForecastCommandValue(data);
  };
  // Listener function to set symmetric comparison collapse value
  const setSymmetricComparisonCollapseValue = (data) => {
    setSymmetricComparisonCollapseCommandValue(data);
  };
  // Listener function to set visual collapse value
  const setVisualCollapseValue = (data) => {
    setVisualCollapseCommandValue(data);
  };
  // Listener function to set forecast toggle value
  const setForecastToggleValueHandler = (value) => {
    setForecastCommandValue(value);
  };
  // Listener function to set compare hotels value
  const setCompareHotelsValueHandler = (value) => {
    setCompareHotelsValue(value);
  };
  // Listener function to set  trend hotel command value
  const setTrendHotelGroupsHandler = (data) => {
    setHotelGroupCommand(data);
  };
  // Listener function to set  normalized view command value
  const setNormalizedViewValue = (data) => {
    setNormalizedCommandValue(data);
  };
  // Add Listener function to set custom booking command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_CUSTOM_BOOKING, setSummaryCustomBookingHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_CUSTOM_BOOKING, setSummaryCustomBookingHandler);
    };
  }, []);
  // Add Listener function to set alignment command value
  useEffect(() => {
    UiController.subscribe(events.SUMMARY_ALIGNMENT, setSummaryAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.SUMMARY_ALIGNMENT, setSummaryAlignmentToggleHandler);
    };
  }, [visualFilters, alignment, dateComparisonList]);
  // Add Listener function to set pace horizon command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_PACE_HORIZON, setSummaryPaceHorizonHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_PACE_HORIZON, setSummaryPaceHorizonHandler);
    };
  }, []);
  // Add Listener function to set pace variable command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_PACE_VARIABLE, setSummaryPaceVariableHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_PACE_VARIABLE, setSummaryPaceVariableHandler);
    };
  }, [paceVariableList]);
  // Add Listener function to set breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_BREAKDOWN, setSummaryBreakdownHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_BREAKDOWN, setSummaryBreakdownHandler);
    };
  }, []);
  // Add Listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_COMPARE_WINDOW, setCompareWindowValue);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_COMPARE_WINDOW, setCompareWindowValue);
    };
  }, [dateComparison, customBooking]);
  // Add Listener function to set custom booking date range command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    };
  }, []);
  // Add Listener function to set extending forecast toggle
  useEffect(() => {
    UiController.subscribe(events.SET_EXTENDING_SUMMARY_FORECAST, setExtendingForecastValue);
    return () => {
      UiController.unsubscribe(events.SET_EXTENDING_SUMMARY_FORECAST, setExtendingForecastValue);
    };
  }, []);
  // Add Listener function to set symmetric comparison collapse
  useEffect(() => {
    UiController.subscribe(
      events.SET_SUMMARY_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_SUMMARY_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseValue
      );
    };
  }, []);
  // Add Listener function to set visual collapse
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_VISUAL_COLLAPSE, setVisualCollapseValue);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_VISUAL_COLLAPSE, setVisualCollapseValue);
    };
  }, []);
  // Add event listener function to set forecast toggle command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_FORECAST, setForecastToggleValueHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_FORECAST, setForecastToggleValueHandler);
    };
  }, []);
  // Add event listener function to set compare hotels command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_COMPARE_HOTELS, setCompareHotelsValueHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_COMPARE_HOTELS, setCompareHotelsValueHandler);
    };
  }, []);
  // Add event listener function to set hotel groups command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_HOTEL_GROUP, setTrendHotelGroupsHandler);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_HOTEL_GROUP, setTrendHotelGroupsHandler);
    };
  }, []);
  // Add event listener function to set normalize view command value
  useEffect(() => {
    UiController.subscribe(events.SET_SUMMARY_NORMALIZED_VIEW, setNormalizedViewValue);
    return () => {
      UiController.unsubscribe(events.SET_SUMMARY_NORMALIZED_VIEW, setNormalizedViewValue);
    };
  }, []);
};

export default useSummaryCommandFlow;
